

import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import {  Typography, Button, Space, Empty, Tag, Image } from "antd"
import axios from "axios";
const moment = require('moment-timezone');


const { Text } = Typography

/**
 *
 *
 * @param {*} props
 * @returns {Component}
 */
const MessagesList = forwardRef((props, ref) => {
    const { data, className, proyecto, getMessages } = props;

     /**@previousHeight  Valor que conetiene el contenedor antes de traar mas mensajes*/
    const [previousHeight, setPreviousHeight] = useState(0);

    /**@isOverflowed  si el contenedor esta desbordado*/
    const [isOverflowed, setIsOverflowed] = useState(false);

    /**@chatContainer referencia al contenedor del chat */
    const chatContainer = useRef(null);

    /**@timezone Zona horaria */
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);


    /**
     * @function scrollToBottom
     * @description Hace scroll cuando el contenedor recibe un nuevo elemento
     */
    function scrollToBottom() {
        chatContainer.current.scrollTo({
            top: chatContainer.current.scrollHeight,
            behavior: "smooth"
        });
    }

     /** Exponer funciones al componente padre */
    useImperativeHandle(ref, () => ({
        scrollToBottom
    }));

    /**Asigna a la bandera si el contenedor esta desbordad */
    useEffect(() => {
        setIsOverflowed(chatContainer.current.scrollHeight > chatContainer.current.clientHeight);

        /**Agregar el listener de scroll */
        const currentContainer = chatContainer.current;
        if (currentContainer) {
            currentContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentContainer) {
                currentContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);


    useEffect(() => {
    	if (chatContainer.current.scrollTop === 0 && data?.length > 20) {
            const newHeight = chatContainer.current.scrollHeight;
            chatContainer.current.scrollTop = newHeight - previousHeight;
        }
    },[data])

     /** Manejar el evento de scroll */
    const handleScroll = () => {
        if (chatContainer.current.scrollTop === 0) {
        	setPreviousHeight(chatContainer.current.scrollHeight);
            getMessages()
        }
    };


    /**
     * @function printHour
     * @description Muestra la hora en un formato especifico */
    const printHour = (date) => {
        let hora = moment(date)
        try {
            return hora.tz(timezone).format('HH:mm')
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * 
     * @param {*} header 
     * @returns {Component} Documento adjunto a la plantilla
     */
    const renderHeader = (header) => {
        if (header != null) {
            switch (header.type) {
                case "document":
                    return <div
                        style={{
                            height: '100px',
                            backgroundSize: 'cover',
                            backgroundImage: `${axios.defaults.baseURL}/proyectos/${proyecto?._id}?logo=${proyecto?.logo?.file}&Authorization=${sessionStorage.getItem("token")}`
                        }}
                    ></div>
                    break;
            }
        }
        return null
    }


    /**
     * 
     * @param {*} body 
     * @returns {Component} Body de la plantilla
     */
    const renderBody = (body) => {
        if (body)
            return <span>{body.text}</span>

        return null;
    }


    /**
     * 
     * @param {*} reply 
     * @returns {Component} Respuesta de la plantilla
     */
    const renderReply = (reply) => {
        if (reply)
            return <span>{reply.text}</span>

        return null;
    }

    /**
     * 
     * @param {*} buttons 
     * @returns {Component} Botones de la plantilla
     */
    const renderButtons = (buttons) => {

        let components = []
        if (buttons && buttons.length > 0) {
            buttons.map(btn => {
                components.push(<Button disabled type="link" size="small" id={btn.id} tipo={btn.type}>{btn.title}</Button>)
            });
        }

        return <Space direction="horizontal">{components}</Space>
    }

    return <div className="lista-messages" ref={chatContainer}  style={{backgroundImage: 'url("/img/admin/bg.png")'}}>
        {
            (data && data?.length) > 0 ? data.map(item => {
                return <div
                    key={item?.id}
                    className={`width-100 ${className} `}
                >
                	<div className={`message-content ${(item?.direction) == "sent" ? `right` : `left`}`}>
                        <span 
                        	className={"message-span"}
                        >
                            {(item.content?.image?.url != undefined ? <Image src={item.content?.image?.url} alt="fix"/> : null)}
                            {(item.content?.file?.url != undefined ? <a href={item.content.file.url} target="_blank" rel="noopener noreferrer">
                                {item.content.file.caption ?? "PDF"}
                            </a> : null)}
                            {(item?.content?.image?.caption ? item.content.image?.caption : null)}
                            {(item?.content?.text ? item.content.text : null)}
                            {(item?.content?.interactive ?
                                <div className="message-interactive">
                                    {renderHeader(item.content.interactive.header)}
                                    {renderBody(item.content.interactive.body)}
                                    {renderButtons(item.content.interactive.action?.buttons)}
                                    {renderReply(item.content.interactive.reply)}
                                </div>
                                : null
                            )}
                            {(item?.content?.hsm ?
                                <span>Se envio template <Text strong> {item.content.hsm.templateName}</Text></span>
                                : null
                            )}

                            {item.error?.description? <span className="message-error">
                                <span className={item.status}><br/> {item.error?.description}</span>
                            </span> : null}
                            <span className="date-span-space"></span>
                        </span>
                        <small className="date-span">{printHour(item.createdDatetime)}</small>
                        
                    </div>
                </div>
            }
            ) : <Empty className="mt-1" description="Sin mensajes"/>
        }
    </div >
})


export default MessagesList;