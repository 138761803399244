import React from "react";
import { Layout, Row, Col, Typography, Flex, Avatar } from "antd";
import Navbar from "../Navigation/Navbar-v2";
import ScrollToHashElement from "../../Widgets/ScrollToElement";
import "../../../Styles/Modules/Landing/faq.scss";
import { SmileOutlined } from "@ant-design/icons";
import FooterPublic from "../Navigation/Footer";
const { Text, Title } = Typography;
const Element = (icon = <></>, text = "") => {
  return (
    <div className="card-faq">
      <Flex horizontal justify="space-around">
        <Flex flex={1} className="center" vertical align="middle">
          <Avatar
            shape="square"
            alt="smile-image"
            className="icon"
            src={<SmileOutlined />}
          />
        </Flex>
        <Flex flex={4} vertical align="middle" justify="start">
          <Title className=" title" level={3}>
           ¿Esta es una pregunta?
          </Title>
          <Text className="text-white">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
          </Text>
        </Flex>
      </Flex>
    </div>
  );
};
const FAQ = () => {

  const data = [
    {
      icon: <SmileOutlined />,
      text: "",
    },
    {
      icon: <SmileOutlined />,
      text: "",
    },
    {
      icon: <SmileOutlined />,
      text: "",
    },
    {
      icon: <SmileOutlined />,
      text: "",
    },
    {
      icon: <SmileOutlined />,
      text: "",
    },
    {
      icon: <SmileOutlined />,
      text: "",
    },
  ];
  return (
    <>
    <Layout className="layout-landing mx-auto">
      <ScrollToHashElement behavior="smooth" inline="center" />
      <Navbar />
      <Row gutter={[32, 64]} className="mx-auto">
        {data.map((e) => (
          <Col lg={12} xs={24}>
            <Element  ïcon={e.icon} text={e.text}/>
          </Col>
        ))}
      </Row>
    </Layout>
    <FooterPublic/>
    </>
  );
};

export default FAQ;
