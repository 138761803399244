import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Space,
} from "antd";

import "../../../Styles/Modules/Landing/landing.scss";
import { PhoneOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { FiMapPin } from "react-icons/fi";
const { Title, Text, Paragraph } = Typography;

const Direccion = () => (
  <section
    className="mt-3 mb-2 section"
    id="nosotros"
    style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
  >
    <div className="ubicacion">
      <Row justify={"start"}>
        <Title className="sub-title">Dónde Estamos</Title>
      </Row>
      <Row align={"middle"} justify={"start"}>
        <Title className="title m-0">Nuestra Ubicación</Title>
      </Row>
      <Row className="mt-2 content">
        <Col
          xs={24}
          lg={10}
          order={{ xs: 2, md: 1 }}
          className="info pt-1 pb-1"
        >
          <Row align={"middle"} style={{ height: "100%" }}>
            <Col span={24}>
              <Title className="text" strong>
                <FiMapPin /> &nbsp;Dirección
              </Title>

              <Space direction="vertical">
                <Text className="text-2">Sendero de la Alameda 22</Text>
                <Text className="text-2">Rancho Blanco, Zona Esmeralda</Text>
                <Text className="text-2">Atizapán de Zaragoza</Text>
                <Text className="text-2"> 54570 MEXICO, Méx.</Text>
              </Space>
            </Col>

            <Col span={24}>
              <Title className="text" strong>
                <ClockCircleOutlined />
                &nbsp; Horarios
              </Title>

              <Space direction="vertical">
                <Text className="text-2">Lunes: 11:00 - 19:00</Text>
                <Text className="text-2">Martes - Viernes: 9:00 - 19:00</Text>
                <Text className="text-2">Sábado: 9:00 - 12:00</Text>
                <Text className="text-2">Domingo: Cerrado</Text>
              </Space>
            </Col>
            <Col span={24}>
              <Title className="text" strong>
                <PhoneOutlined className="text-white" /> Teléfono
              </Title>

              <Text className="text-2">17-40-00-63</Text>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={14} order={{ xs: 1, md: 2 }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3759.9351419188747!2d-99.31674642363032!3d19.54439788175908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21b1850dd9321%3A0x4f5c3b6edd09b555!2sVandari%20Music%20Hall!5e0!3m2!1sen!2smx!4v1715947611245!5m2!1sen!2smx"
            className="map"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Col>
      </Row>
    </div>
  </section>
);
export default Direccion