import React, { Component } from "react";
import { Row, Col, InputNumber, Modal, Form, DatePicker, TimePicker, message, Spin, Select, Tag, Divider, List, Input, Button, Typography } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

//Componentes
import ModalVentaBoletos from "./ModalVentaBoleto";
import SelectEventos from "../../Widgets/Inputs/SelectEventos";
import SelectClientes from "../../Widgets/Inputs/SelectClientes";

//scss
import "../../../Styles/Modules/CRM/Modal.scss";

const Decimal = require("decimal.js");
const { Text, Title } = Typography;
const { Option } = Select;

/**
 *
 *
 * @class FormVentas
 * @extends {Component}
 */
class FormVentas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,

			venta: {
				total: 0,
				subtotal: 0,
				iva: 0,
				detalles: [
					// boleto_id,
					// cantidad,
					// costo_unitario,
					// total,
				],
			},
			sillas_ocupadas: []
		};
	}

	formRef = React.createRef();

	componentDidMount() {
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");

		if (this.props.venta_id !== undefined) {
			this.getVenta();
		}
	}

	/**
	 * @memberof ModalVentas
	 * @method get
	 * @description Obtiene una venta de la DB
	 */
	getVenta = () => {
		this.setState({ loading: true });

		axios.get("/ventas/" + this.props.venta_id)
			.then(({ data }) => {
				let { detalles, evento_id, ...rest } = data;
				let detalles_format = this.formatDetalles(detalles, evento_id.mesas);
				data.detalles = detalles_format;
				this.setState({ venta: data })

			
				this.formRef.current.setFieldsValue({
					...data,
					fecha: dayjs(data.createdAt),
					fecha_evento: dayjs(data.evento_id.fecha),
					horario_evento: dayjs(data.evento_id.horario),
					estatus: data.estatus,
					evento_id: data.evento_id._id ? {
						value: data.evento_id._id,
						label: data.evento_id.nombre
					} : null,
					...(data.invitado) ? {
						nombre: data.invitado?.nombre,
						telefono: data.invitado?.telefono,
					} : {
						nombre: data.cliente_id?.nombre,
						telefono: data.cliente_id?.telefono,
					}
				})
			})
			.catch((error) => {
				message.error("Error al traer venta");
				console.log(error);
			})
			.finally(() => this.setState({ loading: false }));
	};


	/**
	 * @memberof ModalVentas
	 * @method getVentasAnteriores
	 * @description Obtiene las sillas que ya estan ocupadas de ventas anteriores
	 */
	getVentasAnteriores = (evento_id) => {
		axios.get("/ventas/sillas/ocupadas", {
			params: {
				evento_id
			}
		})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({ sillas_ocupadas: data })
			})
			.catch((error) => {
				console.log(error);
			})
	}

	/**
	 * @memberof ModalVentas
	 * @method onFinish
	 * @description Se ejecuta al dar enter al formulario
	 */
	onFinish = (values) => {
		if (values?.evento_id?.value) values.evento_id = values?.evento_id?.value
		if (this.props.venta_id) {
			this.update(values);
		} else {
			this.add(values);
		}
	};

	/**
	 * @memberof ModalVentas
	 * @method add
	 * @description Añade una venta a la BD
	 */
	add = (values) => {
		this.setState({ loading: true });
		let venta = this.state.venta;
		axios.post("/ventas", {
			...values,
			...venta,
		})
			.then((response) => {
				message.success("Venta creada");
				this.props.onClose();
			})
			.catch((error) => {
				console.log(error);
				message.error(error?.response?.data?.message ?? "Error al crear la venta");
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};

	/**
	 * @memberof ModalVentas
	 * @method update
	 * @description Actualiza la información de una venta
	 */
	update = (values) => {
		this.setState({ loading: true });
		let venta = this.state.venta;
		axios.put("/ventas", {
			...values,
			id: this.props.venta_id,
		})
			.then(({ data }) => {
				message.success("Venta Actualizada");
				this.props.onClose();
			})
			.catch((error) => {
				message.error(error?.response?.data?.message ?? "Error al actualizar venta");
				console.log(error);
			})
			.finally(() => this.setState({ loading: false }));
	};

	/**
	 * @memberof ModalVentas
	 * @method openSeating
	 * @description Abre el modal para seleccionar asientos
	 */
	openSeating = () => {
		this.setState({ modal_mapa_visible: true });
	};

	/**
	 * @memberof ModalVentas
	 * @method onChangeEvento
	 * @description Obtiene informacion del evento seleccionado para carga la informacion
	 */
	onChangeEvento = (e) => {
		axios.get("/eventos/" + e)
			.then(({ data }) => {
				this.formRef.current.setFieldsValue({
					fecha_evento: dayjs(data.fecha),
					horario_evento: dayjs(data.horario),
				});
				this.setState({
					evento: data,
					evento_id: data._id,
				});
			})
			.catch((error) => {
				console.log("error", error);
				message.error("Error al obtener el evento");
			});
	};

	/**
	 * @memberof ModalVentas
	 * @method addSeat
	 * @description agrega/elimina los asientos seleccionados desde el ModalVentaBoleto a la lista de detalles, despues calcula el total 
	 */
	setBoletos = (seleccionados) => {
		let venta = this.state.venta;
		venta.detalles = []
		seleccionados.forEach((item) => {
			let precio = Decimal(item.mesa.categoria_id?.precio).toDecimalPlaces(2).toNumber();
			let iva_decimal = Decimal(item.mesa.categoria_id.iva).div(100).toDecimalPlaces(2).toNumber();
			let iva_monto = Decimal(precio).mul(iva_decimal).toDecimalPlaces(2).toNumber();
			venta.detalles.push({
				...item,
				categoria_id: item.mesa.categoria_id,
				cantidad: 1,
				costo_unitario: precio,
				iva_porcentaje: item.mesa.categoria_id.iva,
				iva: iva_monto,
				total: precio + iva_monto,
			});
		});
		this.setState({ venta }, this.calculateVenta(venta));
	};

	/**
	 * @memberof ModalVentas
	 * @method calculateVenta
	 * @description Calcula el total de la venta 
	 */
	calculateVenta = (venta) => {
		venta.subtotal = venta.detalles.reduce((sum, detalle) => sum + detalle.costo_unitario, 0);
		venta.iva = venta.detalles.reduce((sum, detalle) => sum + detalle.iva, 0);
		venta.total = venta.subtotal + venta.iva;
		this.setState({ venta });
	};

	/**
	 * @memberof ModalVentas
	 * @method removeSeat
	 * @description Quita una silla de los asientos selccionados
	 */
	removeSeat = (index) => {
		let venta = this.state.venta;
		if (index >= 0 && index < venta.detalles.length) {
			venta.detalles.splice(index, 1);
			this.calculateVenta(venta);
		}
	};


	/**
	 * @memberof ModalVentas
	 * @method formatDetalles
	 * @description Formatea la informacionobetnida de la BD para carcar en el formulario
	 */
	formatDetalles = (detalles, mesas) => {
		let format_detalles = [];
		detalles.forEach(item => {
			format_detalles.push({
				categoria_id: item.asiento?.categoria_id,
				mesa: item.asiento?.mesa,
				seat: item.asiento?.silla,
				asiento: item.asiento?.silla,
				cantidad: item.cantidad,
				costo_unitario: item.costo_unitario,
				total: item.total,
			});
		});
		return format_detalles;
	}


	/**
	 * @memberof ModalVentas
	 * @method formatDetalles
	 * @description Formatea la informacionobetnida de la BD para carcar en el formulario
	 */
	formatDetallesNuevo = (detalles, mesas) => {
		let total = 0, subtotal = 0;
		
		total = Decimal(subtotal).toDecimalPlaces(2).toNumber()

		let format_detalles = [];
		detalles.forEach(item => {
			format_detalles.push({
				seccion_id: item?.seccion_id,
				categoria_id: item?.categoria,
				mesa: item?.mesa?.id,
				mesa_id: item?.mesa?._id,
				silla_id: item.silla._id,
				seat: item.silla.id,
				asiento: item.silla.id,
				cantidad: item.cantidad ?? 1,
				costo_unitario: item.costo_unitario,
				costo_total: item.costo_total,
			})
			subtotal = Decimal(subtotal).add(
				item.costo_unitario ?? 0
			).toDecimalPlaces(2).toNumber()
		})
		return {format_detalles, total: subtotal, subtotal}
	}


	getAsientosInfo = (values) => {
		axios.post("/ventas/boletos/info", {
			evento_id: this.state.evento_id,
			asientos: values
		})
			.then(({ data }) => {

				this.setState(
					(state) => {
						let { format_detalles, total, subtotal } = this.formatDetallesNuevo(data)
						state.venta.detalles = format_detalles
						state.venta.total = total 
						state.venta.subtotal = subtotal
						return state
					}
				)
			})
			.catch((error) => {

			})
			.finally(() => this.setState({ loading: false }));
	}


	render() {
		const { venta } = this.state;
		return (
			<>
				<Form
					layout="vertical"
					name="form-ventas"
					className="pd-1"
					ref={this.formRef}
					onFinish={this.onFinish}
					initialValues={{
						fecha: dayjs()
					}}
				>
					<Spin spinning={this.state.loading}>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={12}>
								<Form.Item
									name="estatus"
									label="Estatus"
									rules={[
										{
											required: true,
											message: "Por favor seleccione el Estatus",
										},
									]}
								>
									<Select
										placeholder=" Estatus"
										className="estatus-select"
									>
										<Option value={0}>
											<Tag className="tag tag-select cancelado">Cancelado</Tag>
										</Option>
										<Option value={1}>
											<Tag className="tag tag-select pendiente">Pendiente</Tag>
										</Option>
										<Option value={2}>
											<Tag className="tag tag-select parcial">Pago parcial</Tag>
										</Option>
										<Option value={3}>
											<Tag className="tag tag-select pagado">Pagado</Tag>
										</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={12}>
								<Form.Item name="fecha" label="Fecha de Compra">
									<DatePicker
										className="width-100 "
										placeholder="Fecha de Compra"
										disabled={this.props.venta_id}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Divider orientation="left">Información del Evento</Divider>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={24}>
								<Form.Item label="Evento" name="evento_id">
									<SelectEventos
										onChange={(evento_id) => {
											this.onChangeEvento(evento_id)
											this.getVentasAnteriores(evento_id)
										}}

										disabled={this.props.venta_id}
									/>
								</Form.Item>
							</Col>

							<Col xs={24} md={12}>
								<Form.Item name="fecha_evento" label="Fecha del Evento">
									<DatePicker
										className="width-100"
										placeholder="Fecha del Evento"
										disabled={true}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={12}>
								<Form.Item name="horario_evento" label="Horario del Evento">
									<TimePicker
										className="width-100"
										format={"HH:mm"}
										placeholder="Horario del Evento"
										disabled={true}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							{this.props.venta_id ? (
								<Col xs={24} lg={12}>
									<Form.Item label="Nombre" name="nombre">
										<InputNumber
											className="width-100"
											min={1}
											disabled={this.props.venta_id}
										/>
									</Form.Item>
								</Col>
							) : (
								<Col xs={24} lg={24}>
									<Form.Item label="Cliente" name="cliente_id">
										<SelectClientes />
									</Form.Item>
								</Col>
							)}
							{this.props.venta_id ? (
								<Col xs={24} lg={12}>
									<Form.Item label="Teléfono" name="telefono">
										<Input
											className="width-100"
											disabled={this.props.venta_id}	
										/>
									</Form.Item>
								</Col>
							) : null}
						</Row>

						<Row>
							<Col xs={24} lg={24}>
								<List
									size="small"
									className="component-list-modal"
									header={
										<Row
											className="width-100"
											gutter={[8, 8]}
											align="middle"
											justify={"space-around"}
										>
											<Col span={3}>Cantidad</Col>
											<Col span={5}>Categoría</Col>
											<Col span={3}>Mesa</Col>
											<Col span={3}>Silla</Col>
											<Col span={3}>Precio</Col>
											{/* <Col span={3}>IVA</Col> */}
											<Col span={1}>
												<Button
													onClick={() => this.openSeating()}
													disabled={
														this.props.venta_id || !this.state.evento_id
													}
													size="small"
													type="primary"
													className="mt-05"
													icon={<PlusOutlined />}
												></Button>
											</Col>
										</Row>
									}
									dataSource={this.state.venta.detalles}
									renderItem={(item, i) => (
										<List.Item key={i}>
											<Row
												className="width-100"
												gutter={[8, 8]}
												align="middle"
												justify={"space-around"}
											>
												<Col span={3}>{item.cantidad}</Col>
												<Col span={5}>{item.categoria_id?.nombre ?? "N/A"}</Col>
												<Col span={3}>{item.mesa}</Col>
												<Col span={3}>{item.seat}</Col>
												<Col span={3}>{item.costo_unitario.toMoney(true)}</Col>
												{/* <Col span={3}>{item.iva_porcentaje} %</Col> */}
												<Col span={1}>
													<Button
														onClick={() => {
															this.removeSeat(i);
														}}
														size="small"
														disabled={this.props.venta_id}
														type="primary"
														className="mt-05"
														icon={<MinusCircleOutlined />}
													></Button>
												</Col>
											</Row>
										</List.Item>
									)}
									footer={[
										<Row className="width-100 " justify="end">
											<Col xs={12} md={6}>
												<Text ellipsis strong>
													Subtotal
												</Text>
											</Col>
											<Col xs={12} md={4}>
												<Text ellipsis>
													$ {this.state.venta.subtotal.toMoney(true)} <small>mxn</small>
												</Text>
											</Col>
										</Row>,
										<Row className="width-100" justify="end">
											<Col xs={12} md={6}>
												<Text ellipsis strong>
													Total
												</Text>
											</Col>
											<Col xs={12} md={4}>
												<Text
													ellipsis
													strong
													type="success"
													style={{ fontSize: 18 }}
												>
													$ {venta.total.toMoney(true)} <small>mxn</small>
												</Text>
											</Col>
										</Row>,
									]}
								/>
							</Col>
						</Row>
					</Spin>
				</Form>

				<ModalVentaBoletos
					open={this.state.modal_mapa_visible}
					evento_id={this.state.evento_id}
					setBoletos={(seats) => this.setBoletos(seats)}
					// detalles={this.state.detalles_test ?? []}
					detalles={
						this.state.venta.detalles.map(({ silla_id, mesa_id, seccion_id }) => {

							// console.log("ASD", e)
							return {
								silla_id, mesa_id, seccion_id
							}
						})
					}
					sillas_ocupadas={this.state.sillas_ocupadas}
					onClose={(value) => {
						this.setState({ detalles_test: value })
						// console.log("value")
						if (value.length > 0) {
							this.getAsientosInfo(value)
						}
						this.setState({ modal_mapa_visible: false })
					}}
				/>
			</>
		);
	}
}

export default function Vista(props) {
	const { open = false, onClose = () => { }, venta_id } = props;

	return (
		<Modal
			open={open}
			onCancel={onClose}
			destroyOnClose={true}
			zIndex={1000}
			title={venta_id ? "Editar Venta" : "Crear Venta"}
			closable={true}
			cancelText="Cancelar"
			okText="Guardar"
			okButtonProps={{
				form: "form-ventas",
				key: "form-ventas",
				htmlType: "submit",
			}}
			width={800}
		>
			<FormVentas {...props} />
		</Modal>
	);
}
