import {
  Card,
  Carousel,
  Col,
  Flex,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Typography,
  message,
} from "antd";

import dayjs from "dayjs";
import axios from "axios";
import rehypeRaw from "rehype-raw";
import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import { CalendarFilled } from "@ant-design/icons";

//Componentes
import { getBanner } from "../../Utils";
import useSearch from "../../../Hooks/useSearch";
import Logged, { User } from "../../../Hooks/Logged";
import { useNavigate, useParams } from "react-router-dom";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import { ButtonDelete, ButtonEdit } from "../../Widgets/Buttons";
import {
  CardGraficaTickets,
  CardResumenVentas,
  CardTag,
} from "../../Widgets/Cards";

import VentasTab from "./Tabs/Ventas";
import TabMapa from "./Tabs/TabMapa";
import { IconCalendar, IconCash } from "../../Widgets/Iconos";

//css
import "../../../Styles/Modules/Admin/eventos.scss";
import "dayjs/locale/es-mx";

dayjs.locale("es-mx");
const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

/**
 *
 *
 * @export
 * @class DetallesEvento
 * @extends {Component}
 * @description Vista donde se ve el detalle de un evento
 */
class DetallesEvento extends Component {
  static contextType = Logged;

  back = () => {
    this.props.history.goBack();
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      evento: {
        nombre: "",
        descripcion: "",
        fecha: new Date(),
        fecha_inicio: new Date(),
        fecha_fin: new Date(),
        categorias: [],
        imagenes: [],
        comprados: 0,
      },

      evento_id: null,
      boletos_total: 0,
      boletos_vendidos: 0,
      clientes_categorias: [],
      clientes_origen: [],
      ventas_grafica: [],

      precio_min: 0,
      precio_max: 0,
    };
  }

  componentDidMount() {
    this.props.setShowSearch(false);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    if (this.props.params.evento_id) {
      this.getEvento();
      this.getEventoAnalytics();
      this.getEventoAnalyticsVentas();
    }
  }

  /**
   * @method getEvento
   * @description Obtiene el evento de la bd
   */
  getEvento = () => {
    this.setState({ loading: true });
    axios
      .get("/eventos/" + this.props.params.evento_id)
      .then(({ data }) => {
        this.setState({
          evento: data,
          categorias: data.categorias,
          precio_min: data.menor_precio,
          precio_max: data.mayor_precio,
        });
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Error al obtener el evento"
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  getEventoAnalytics = () => {
    this.setState({ loading: true });
    axios
      .get("/evento/analytics/" + this.props.params.evento_id)
      .then(({ data }) => {
        this.setState({ ...data });
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Error al obtener el evento"
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  getEventoAnalyticsVentas = (periodo = "week") => {
    axios
      .get("/evento/analytics-ventas/", {
        params: {
          periodo: periodo,
          evento_id: this.props.params.evento_id,
        },
      })
      .then(({ data }) => {
        this.setState({ ...data });
      })
      .catch((error) => {
        console.log("error", error);
        message.error(
          error?.response?.data?.message ?? "Error al obtener la grafica"
        );
      });
  };

  render() {
    return (
      <>
        <Spin spinning={this.state.loading}>
          <PageHeader
            className="admin-page-header"
            breadcrumb={{
              items: [
                {
                  key: "0",
                  title: "Eventos",
                  onClick: () => this.props.navigate("/admin/eventos"),
                  className: "hover",
                },
                {
                  key: "1",
                  title: "Evento Detalle",
                },
              ],
            }}
          ></PageHeader>

          <Content
            id="eventos-detalles"
            className="admin-content content-bg pd-1"
          >
            <Title className="title">Detalle del Evento</Title>
            <Row gutter={[20, 8]}>
              <Col xs={24} md={24} lg={24} xl={18}>
                <Flex vertical gap={22}>
                  <Card
                    id="about"
                    className="carousel"
                    cover={
                      <>
                        <Carousel
                          autoplay
                          arrows={false}
                          className="carousel-cover"
                        >
                          {this.state.evento.imagenes?.length > 0 ? (
                            this.state.evento.imagenes?.map((img) => {
                              return (
                                <img
                                  alt="evento-imagen"
                                  src={getBanner(
                                    img,
                                    this.state.evento._id,
                                    "eventos"
                                  )}
                                  className="image"
                                />
                              );
                            })
                          ) : (
                            <img
                              alt="evento-imagen"
                              src={"/img/fallback.jpg"}
                              className="image"
                            />
                          )}
                        </Carousel>
                      </>
                    }
                  >
                    <Flex
                      className="header "
                      justify="space-between"
                      align="center"
                    >
                      <Title level={2} className="header-title">
                        {this.state.evento.nombre}
                      </Title>
                      <Flex gap={12} className="header-actions">
                        <ButtonEdit
                          disabled={!this.props.canEdit}
                          onClick={() =>
                            this.props.navigate(
                              "/admin/eventos/editar/" +
                                this.props.params.evento_id
                            )
                          }
                        />
                        <ButtonDelete
                          disabled={!this.props.canDelete}
                          onConfirm={() =>
                            axios
                              .delete("/eventos/", {
                                params: {
                                  venta_id: this.props.params.evento_id,
                                },
                              })
                              .then((response) => {
                                message.success(response?.data?.message);
                                this.back();
                              })
                              .catch((error) => {
                                message.error(error?.response?.data?.message);
                              })
                          }
                        />
                      </Flex>
                    </Flex>
                    <Paragraph style={{ width: 300 }}>
                      {this.state.evento.descripcion}
                    </Paragraph>

                    <Flex
                      gap={25}
                      className=" "
                      style={{ position: "relative", bottom: "-20%" }}
                    >
                      <CardTag
                        icon={<IconCash />}
                        titulo="Precio del Boleto"
                        content={
                          <span className="info">
                            {this.state.precio_min
                              ? `${this.state.precio_min.toMoney(true)} `
                              : null}
                            {this.state.precio_max > this.state.precio_min
                              ? ` - ${this.state.precio_max.toMoney(true)} `
                              : null}
                            {!this.state.precio_min && !this.state.precio_max
                              ? `No definido`
                              : null}
                          </span>
                        }
                      ></CardTag>
                      <CardTag
                        icon={<IconCalendar />}
                        titulo="Fecha"
                        content={
                          <Text className="info">
                            {dayjs(this.state.evento.fecha).format(
                              "dddd, DD MMMM YYYY"
                            )}
                          </Text>
                        }
                      />
                    </Flex>
                  </Card>
                </Flex>
              </Col>
              <Col xs={24} md={24} lg={24} xl={6}>
                <Flex vertical gap={22}>
                  <Card id={"ticket-sales"}>
                    <Flex vertical>
                      <Text className="title">Información de Tickets</Text>
                      <Text className="amount">
                        {this.state.evento?.comprados ?? 0} /{" "}
                        {this.state.evento.totales}
                      </Text>
                      <Text className="text">Tickets Vendidos</Text>
                    </Flex>
                  </Card>

                  <CardResumenVentas
                    legend_data={this.state.ventas_grafica.map((e) => e.name)}
                    data_series={this.state.ventas_grafica.map((e) => e.value)}
                    onSelect={(periodo) =>
                      this.getEventoAnalyticsVentas(periodo)
                    }
                  />
                </Flex>
              </Col>
              <Col xs={24} md={12}>
                <CardGraficaTickets
                  titulo="Origen de prospectos"
                  legend_data={this.state.clientes_origen.map((e) => e.name)}
                  series_data={this.state.clientes_origen}
                />
              </Col>
              <Col xs={24} md={12}>
                <CardGraficaTickets
                  legend_data={this.state.clientes_categorias.map(
                    (e) => e.name
                  )}
                  series_data={this.state.clientes_categorias}
                  titulo="Ventas por categoría"
                />
              </Col>

              <Col xs={24}>
                <Card size="small">
                  <Tabs>
                    <Tabs.TabPane tab="Ventas" key="2">
                      <VentasTab evento_id={this.props.params.evento_id} />
                    </Tabs.TabPane>
                    {this.props.canViewMapa || this.props.owner ? (
                      <Tabs.TabPane tab="Mapa" key="3">
                        <TabMapa evento_id={this.props.params.evento_id} />
                      </Tabs.TabPane>
                    ) : null}
                  </Tabs>
                </Card>
              </Col>
            </Row>
          </Content>
        </Spin>
      </>
    );
  }
}

export default function Vista(props) {
  const user = React.useContext(User);
  const { setShow } = useSearch();

  const permisos = usePermissions(user?.rol_id?.permisos, {
    canEdit: ["eventos", "edit"],
    canDelete: ["eventos", "delete"],
    canCreate: ["eventos", "create"],
    canViewMapa: ["eventos", "ver_mapa"],
  });

  return (
    <DetallesEvento
      owner={user?.rol_id?.tipo === 1}
      {...props}
      setShowSearch={setShow}
      {...permisos}
      navigate={useNavigate()}
      params={useParams()}
    />
  );
}
