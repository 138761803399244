import React, { Component, useRef, useState } from "react";
import { Button, Row, Col, Typography, Card, Form, Input, message } from "antd";

import "../../../Styles/Modules/Landing/landing.scss";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { SendOutlined } from "@ant-design/icons";
import { getResponseError } from "../../Utils";

const { Title } = Typography;
const Contacto = () => {
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  const requestContact = (e) => {
    setLoading(true);

    axios
      .post("/public/contacto", e)
      .then((res) => {
        console.log("res", res);
        message.success(
          "Gracias por contactarnos. Nos comunicaremos contigo en breve."
        );

        formRef.current.resetFields();
      })
      .catch((err) => {
        message.info(
          getResponseError(
            err.response,
            "Ocurrio un error al enviar el mensaje"
          )
        );
        console.log("err", err);
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  return (
    <section
      id="contacto"
      className="section"
      style={{ paddingTop: "5rem", paddingBottom: "1rem" }}
    >
      <div className="contacto">
        <Row>
          <Title className="sub-title">Ponte en contacto con nosotros</Title>
        </Row>
        <Row align={"middle"}>
          <Title className="title m-0">Contacto</Title>
        </Row>
        <Row className="mt-2" justify={"center"}>
          <Title className="text text-center">
            Mándanos tu información, o envíanos un mensaje, y nos pondremos en{" "}
            <br />
            contacto contigo tan pronto como sea posible.
          </Title>
        </Row>
        <Row justify={"center"} className="mt-2">
          <Card className="contact-card">
            <Form
              layout={"vertical"}
              className="form-borderless pd-1 contact-form"
              requiredMark={false}
              ref={formRef}
              onFinish={requestContact}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Nombre"
                    name="nombre"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa un nombre",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Teléfono"
                    name="telefono"
                    
                    extra="Incluir el código del país (e.g., +51, +52)"
                    rules={[
                      {
                        pattern:
                          /^\+52\d{10}$|^(\d{2,3})?\d{7}$|^(\d{3}-)?\d{4}-\d{4}$/,
                        message: "El formato no coincide",
                      },
                      {
                        pattern: /\+/,
                        message: "Falta el + o el código del país en el teléfono",
                      },
                      {
                        required: true,
                        message: "Por favor ingresa un número de teléfono",
                      },
                      {
                        max: 13,
                        message: "El número teléfonico es muy largo",
                      },
                      {
                        min: 10,
                        message: "No es un número de teléfono válido",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      maxLength={13}
                      placeholder="5512345678"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Correo"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa un correo electrónico",
                      },
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Asunto"
                    name="asunto"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa un asunto",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Descripción"
                    name="descripcion"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa una descripción",
                      },
                    ]}
                  >
                    <Input.TextArea
                      autoSize={true}
                      rows={3}
                      className="width-100"
                      style={{ minHeight: 100 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={"center"}>
                <Col span={24} className="center">
                  <Form.Item
                    name="captcha"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el captcha",
                      },
                    ]}
                  >
                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={"center"}>
                <Form.Item className="flex-end">
                  <Button
                    block
                    htmlType="submit"
                    type="primary"
                    icon={<SendOutlined />}
                    loading={loading}
                  >
                    Enviar
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        </Row>
      </div>
    </section>
  );
};

export default Contacto;
