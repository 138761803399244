import React from "react";
import { Button, Image, Space } from "antd";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";


const FilesBox = ({
	id = 1,
	files = [],
	deleteFile = () => {},
	edit = false,
	preview = false,
}) => {
	return (
		<Space direction="horizontal" wrap>
			{Array.isArray(files) && files.length > 0
				? files.map((e, i) => {
								
						return (
							<div>
								{Boolean(/\.(png|jpeg|gif|webp|svg|jpg)(\?.*)?$/.test(e.name)) ? (
									<Space direction="horizontal">
										<div className="imagen-chat-contenedor">
											<Image
												preview={preview}
												src={URL.createObjectURL(e)}
												className="mb-1 image-chat"
											/>

											{edit ? (
												<Button
													type="primary"
													onClick={() => deleteFile(e, i)}
													danger
													className="btn-delete-file"
													size="small"
													icon={<DeleteOutlined />}
												/>
											) : null}
										</div>
									</Space>
								) : (
									<Space direction="horizontal pt-2">
										<div className="file-chat-contenedor ">
											<Button
												size="small"
												target="_blank"
												download
												href={URL.createObjectURL(e)}
												block
											>
												{e.name}
											</Button>
											{edit ? (
												<Button
													type="primary"
													onClick={() => deleteFile(e.file, i)}
													danger
													className="btn-delete-file"
													size="small"
													icon={<DeleteOutlined />}
												/>
											) : null}
										</div>
									</Space>
								)}
							</div>
						);
					})
				: null}
		</Space>
	);
};

export default FilesBox;
