import React from 'react'
import { Route, Routes } from "react-router-dom";

import Ventas from '../../Components/Customer/Ventas/Ventas';

/**
 * 
 * @export
 * @function RouterVentas
 * @description Router for Eventos routes 
 */
function RouterVentas(props) {
  return (
    <Routes>
      <Route path="" element={<Ventas {...props} />} />
    </Routes>
  )
}

export default RouterVentas