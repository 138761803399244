import React, { Component } from "react";
import { Row, Col, Card, Layout, message, Space, List, Typography } from "antd";
import axios from "axios";

//componentes
import { useNavigate } from "react-router-dom";
import useSearch from "../../../Hooks/useSearch";
import Logged, { User } from "../../../Hooks/Logged";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import { ButtonDelete, ButtonEdit } from "../../Widgets/Buttons";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalClientes from "./ModalCliente";

const { Text } = Typography;
const { Content } = Layout;

/**
 * @export
 * @class clientes
 * @extends {Component}
 * @description Vista de clientes
 */
class Clientes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			modal_visible: false,

			clientes: {
				data: [],
				page: 1,
				total: 0,
				limit:10
			},

			filtroSearch: "",
			searching: true,
		};
	}

	componentDidMount() {
		this.props.setShowSearch(true);

		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
		this.getClientes();
	}

	componentDidUpdate(prevProps){
	    if(prevProps.search != this.props.search){
	      this.getClientes({page: 1, search: this.props.search})
	    }
	}

	/**
	 *
	 *
	 * @param {*} [page=this.state.page] Numero de la pagina a obtener
	 * @memberof Clientes
	 * @method getClientes
	 * @description Obtiene el listado de areas
	 */
	getClientes = (
		{
			page = this.state.clientes.page,
			limit = this.state.clientes.limit,
			search = this.props.search,
		} = this.state.clientes
	) => {
		this.setState({ loading: true });
		axios
			.get("/clientes", {
				params: {
					page,
					limit,
					search,
				},
			})
			.then(({ data }) => {
				this.setState({ clientes: data });
			})
			.catch((error) => {
				console.log(error);
				message.error("Error al traer los clientes");
			})
			.finally(() => this.setState({ loading: false }));
	};

	render() {

		console.log('state',this.state)
		return (
			<>
				<PageHeader
					className="admin-page-header"
					breadcrumb={{
						items: [
							{
								key: "1",
								title: "Clientes",
							},
						],
					}}
				></PageHeader>
				<Content className="admin-content content-bg pd-1">
					<List
						loading={this.state.loading}
						className="component-list"
						itemLayout="horizontal"
						locale={{ emptyText: "Sin Clientes Asignados" }}
						dataSource={this.state.clientes.data}
						pagination={{
							current: this.state.clientes.page,
							pageSize: this.state.clientes.limit,
							total: this.state.clientes.total,
							position: "bottom",
							className: "flex-left",
							onChange: (page, limit) => this.getClientes({ page, limit }),
						}}
						header={
							<Row className="component-list-header width-100 pr-1 pl-1">
								<Col xs={24} lg={2} className="center">
									
								</Col>
								<Col xs={24} lg={6} className="center">
									<Text strong>Nombre</Text>
								</Col>
								<Col xs={24} lg={6} className="center">
									<Text strong>Email</Text>
								</Col>
								<Col xs={24} lg={6} className="center">
									<Text strong>Teléfono</Text>
								</Col>
								
							</Row>
						}
						renderItem={(item) => (
							<List.Item className="component-list-item">
								<Card className="card-list">
									<Row className="width-100 " gutter={[8, 8]}>
										<Col xs={24} lg={2}>
											<CustomAvatar
												name={item.nombre}
												url={
													item.imagen
														? {
																url:
																	axios.defaults.baseURL +
																	"/clientes/" +
																	item._id,
																imagen: item.imagen?.name,
																logo: item.imagen?.file,
															}
														: null
												}
											/>
										</Col>
										<Col xs={24} lg={6} className="center">
											<Text>{item.nombre}</Text>
										</Col>
										<Col xs={24} lg={6} className="center">
											<Text>{item.email ?? "-"}</Text>
										</Col>
										<Col xs={24} lg={6} className="center">
											<Text>{item.telefono}</Text>
										</Col>
										<Col xs={24} lg={4} className="center">
											<Space size="small" direction="horizontal">
												<ButtonEdit
													disabled={!this.props.canEdit}
													onClick={() => this.setState({ modal_cliente_visible: true, cliente_id: item._id, })}
												/>
												<ButtonDelete
													disabled={!this.props.canDelete}
													onConfirm={() =>
														axios.delete("/clientes/", {
																params: { id: item._id },
															})
															.then((response) => {
																message.success(response?.data?.message);
																this.getClientes();
															})
															.catch((error) => {
																message.error(error?.response?.data?.message);
																this.getClientes();
															})
													}
												/>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Content>
				<FloatingButton
					title="Nuevo registro"
					onClick={() => this.setState({ modal_cliente_visible: true })}
				/>

				<ModalClientes
					not_add={true}
					crm={this.state.cliente_id ? false : true}
					open={this.state.modal_cliente_visible}
					cliente_id={this.state.cliente_id}
					onClose={(prospectos)=>{
						this.setState({modal_cliente_visible: false, cliente_id: undefined})
						if(Array.isArray(prospectos) && prospectos.length > 0){
							this.getClientes()
						}
					}}
				/>
			</>
		);
	}
}

export default function Vista(props) {
	const user = React.useContext(User);
	const { setShow } = useSearch();

	const permisos = usePermissions(user?.rol_id?.permisos, {
		canEdit: ["crm", "edit"],
		canDelete: ["crm", "delete"],
		canCreate: ["crm", "create"],
	});

	return (
		<Clientes
			{...props}
			setShowSearch={setShow}
			{...permisos}
			navigate={useNavigate()}
		/>
	);
}
