import React from "react";
import { Row, Col, Typography } from "antd";
const { Text, Title } = Typography;
const Banner = () => {
  return (
    <section className="image-section2">
      <Row className="foreground" justify={"center"}>
        <Col span={24}>
          <Text className="text pl-1 pr-1 center">
            El mejor y más equipado centro de espectáculos al norte de la
            ciudad,
          </Text>
          <Text className="text pl-1 pr-1 center">
            con un escenario profesional multiusos para todo tipo de eventos.
          </Text>
        </Col>
      </Row>
    </section>
  );
};

export default Banner;
