import React, { Component, useContext, createRef } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Card,
  Divider,
  Button,
  message,
  Space,
  Carousel,
  Select,
} from "antd";
import dayjs from "dayjs";
import axios from "axios";
import rehypeRaw from "rehype-raw";
import Markdown from "react-markdown";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//Componentes
import Socket from "../../Hooks/Socket";
import Navbar from "./Navigation/Navbar-v2";
import FooterPublic from "./Navigation/Footer";
import Direccion from "./Sections/Direccion";

import ProximosEventos from "./ProximosEventos";
import Logged, { User } from "../../Hooks/Logged";
import ModalInvitado from "./Carrito/ModalInvitado";
import Seating from "../Admin/Seating/PublicSeating";
import { getBannerPublic } from "../Utils";
import NumericInput from "../Widgets/Inputs/NumericInput";
import ScrollToHashElement from "../Widgets/ScrollToElement";

const { Content } = Layout;
const { Title, Text } = Typography;

class Evento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      evento: {
        imagenes: [],
      },
      evento_id: this.props.location?.state?.evento_id,
      selectedSeatsIds: [],
      asientos_limit: this.props.cookies["cart"]?.length ?? 1,
      modal_invitado: false,
      mesas: [], //Todas las mesas del evento
      blocked: [], // Areas bloaquedas del evento
      seccion_selected: 0,

      categorias_ver: [],
    };
  }

  refStage = createRef();

  componentDidMount() {
    this.getEvento();

    if (this.state.evento_id) {
      //El cliente se una a la sala del evento
      this.props.socket.emit("/public/evento/join", this.state.evento_id);
    }

    this.props.socket.on("error", this.IO_Error);
    this.props.socket.on("sucessful", this.IO_updateEvento);
    this.props.socket.on("/public/evento/clear", this.IO_clearEvento);
    this.props.socket.on("/public/carrito-id", this.IO_getCarritoId);
    this.props.socket.on("/public/evento/update", this.IO_updateEvento);
  }

  componentWillUnmount() {
    // salir de la sala del evento
    console.log("saliendo del evento...");

    // Desuscribirse de eventos del socket
    this.props.socket.off("error", this.IO_Error);
    this.props.socket.off("sucessful", this.IO_updateEvento);
    this.props.socket.off("/public/evento/clear", this.IO_clearEvento);
    this.props.socket.off("/public/carrito-id", this.IO_getCarritoId);
    this.props.socket.off("/public/evento/update", this.IO_updateEvento);
  }

  /**
   * @memberOf Eventos
   * @method getEvento
   * @description Obtiene la informacion del evento
   */
  getEvento = () => {
    this.setState({ loading: true });
    axios
      .get("/evento/public/" + this.state.evento_id)
      .then(({ data }) => {
        data.imagen = this.formatImages(data.imagenes, data._id);
        this.setState({
          evento: data,
          mesas: data.secciones[this.state.seccion_selected].data.mesas.map(
            (m) => ({
              ...m,
              seccion_id: data.secciones[this.state.seccion_selected]._id,
            })
          ),
          categorias_ver: data.secciones
            .map((s) => s.data.mesas.map((m) => m.categoria))
            .flat(),
          blocked: data.secciones[this.state.seccion_selected].data.blocked,
        });
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Error al obtener el evento");
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @method addSeat
   * @description Cuando el cliente selecciona una silla, se manda una señal a la sala para bloquear las sillas
   * y se añade las silla a los cookies
   */
  IO_addSeat = ({ silla_id, mesa_id, seccion_id }) => {
    //Si no esta logeado abre el modal de invitados
    if (!this.props.user?._id && !this.props.cookies.carrito_id) {
      this.setState({
        modal_invitado: true,
        invitado_seleccion: { silla_id, mesa_id, seccion_id },
      });
      if (this.refStage.current) {
        this.refStage.current.clearSeats();
      }
      return;
    } else {
      this.props.socket.emit("/public/evento/silla/add", {
        id: this.state.evento_id,
        silla_id,
        mesa_id,
        seccion_id,
        usuario_id: this.props.user?._id,
        carrito_id: this.props.cookies?.carrito_id,
      });
      this.addtoCart({ silla_id, mesa_id, seccion_id });
    }
  };

  /**
   * @method deleteSeat
   * @description Cuando el cliente deselecciona una silla, se manda una señal a la sala la desbloquear la silla, la mesa
   * tambien solo si ya no tiena ninguna silla seleccionada, y la quita de los cookies
   */
  IO_deleteSeat = ({ silla_id, mesa_id, seccion_id }) => {
    console.log("IO_deleteSeat");

    if (!this.props.user?._id && !this.props.cookies.carrito_id) {
      return;
    }
    // Eliminar el asiento a la lista
    this.props.socket.emit("/public/evento/silla/delete", {
      id: this.state.evento_id,
      silla_id,
      mesa_id,
      seccion_id,
      usuario_id: this.props.user?._id,
      carrito_id: this.props.cookies?.carrito_id,
    });
    this.deletetoCart({ silla_id, mesa_id, seccion_id });
  };

  /**
   * @method IO_updateEvento
   * @description cuando un algun otro cliente selecciona una silla el metodo recibe la informacion del evento actualizada
   */
  IO_updateEvento = (data) => {
    console.log("IO_updateEvento");
    if (data) {
      this.setState({
        evento: data,
        mesas: data.secciones[this.state.seccion_selected].data.mesas.map(
          (m) => ({
            ...m,
            seccion_id: data.secciones[this.state.seccion_selected]._id,
          })
        ),
        blocked: data.secciones[this.state.seccion_selected].data.blocked,
      });
    }
  };

  /**
   * @method IO_clearEvento
   * @description cuando el cliente se haya pasado del tiempo limite, eliminara sus sillas seleccionadas
   */
  IO_clearEvento = () => {
    if (this.refStage.current) {
      this.refStage.current.clearSeats();
    }
    this.props.removeCookie("cart", { path: "/" });
    this.props.removeCookie("carrito_id", { path: "/" });
  };

  /**
   * @method IO_getCarritoId
   * @description Recibe el ID del carrito que el cliente creo al seleccionar una silla, que tiene una vida limitada
   * para ponerlo en las cookies
   */
  IO_getCarritoId = (carrito_id) => {
    this.props.setCookie("carrito_id", carrito_id, { path: "/" });
  };

  /**
   * @method IO_Error
   * @description Muestra mensajes de error que puedan suceder en el socket al procesar la informacion
   */
  IO_Error = (response) => {
    message.error(response.message);
  };

  /**
   * @method addtoCart
   * @description Añade una silla al carrito en las cookies
   */
  addtoCart = ({ silla_id, mesa_id, seccion_id }) => {
    let selectedSeats = this.props.cookies["cart"] || [];
    const exist = selectedSeats.some(
      (item) => item.silla_id.toString() === silla_id.toString()
    );

    if (!exist) {
      selectedSeats.push({ silla_id, mesa_id, seccion_id });
      this.props.setCookie("cart", selectedSeats, { path: "/" });
    }
  };

  /**
   * @method addtoCart
   * @description Elimina una silla al carrito en las cookies
   */
  deletetoCart = ({ silla_id, mesa_id, seccion_id }) => {
    let selectedSeats = this.props.cookies["cart"] || [];

    selectedSeats = selectedSeats.filter(
      (boleto) => !(boleto.silla_id.toString() === silla_id.toString())
    );

    this.props.setCookie("cart", selectedSeats, { path: "/" });
  };

  onComprarInvitado = (values) => {
    let mesa = this.state.invitado_seleccion.mesa_id;
    let silla = this.state.invitado_seleccion.silla_id;
    let seccion = this.state.invitado_seleccion.seccion_id;

    this.props.socket.emit("/public/evento/silla/add", {
      id: this.state.evento_id,
      mesa_id: mesa,
      silla_id: silla,
      seccion_id: seccion,
      invitado: values,
    });

    this.addtoCart({ silla_id: silla, mesa_id: mesa, seccion_id: seccion });

    this.setState({ modal_invitado: false, invitado_seleccion: {} });
  };

  handleChangeSeccion = (seccion_index) => {
    this.setState({ seccion_selected: seccion_index }, () => this.getEvento());
  };

  formatImages = (images, id) => {
    let format_images;

    if (Array.isArray(images))
      format_images = images.map((f) => ({
        url:
          axios.defaults.baseURL + "/evento/public/" + id + "?imagen=" + f.file,
      }));
    else {
      format_images = {
        url:
          axios.defaults.baseURL +
          "/evento/public/" +
          id +
          "?imagen=" +
          images.file,
      };
    }

    return format_images;
  };

  gotoRoute = (route) => {
    this.props.navigate(`/carrito/pago`);
  };

  render() {
    console.log("state", this.state);
    console.log("location", this.props.location);

    return (
      <Layout className="layout-landing informacion">
        <Navbar />
        <ScrollToHashElement behavior="smooth" inline="center" />

        <Carousel
          autoplay
          dots={false}
          arrows={false}
          className="image-section"
        >
          {this.state.evento?.imagenes?.map((img) => (
            <img
              alt="evento-imagen"
              src={getBannerPublic(img, this.state.evento._id, "evento/public")}
              className="image-carousel"
            />
          ))}
        </Carousel>
        <section className="transition-section ">
          <Row justify="center">
            <Title className="sub-title">#VenAVandari</Title>
          </Row>
          <Row>
            <Col span={24}>
              <Title level={1} className="title m-0">
                {this.state.evento?.nombre}
              </Title>
            </Col>
          </Row>
        </section>
        <Row justify={"center"}>
          <Content className="pd-2">
            <Row>
              <Col xs={24} sm={24} md={12} lg={6} xl={7} xxl={8} className="">
                <Row>
                  <Card className="card-info">Información General</Card>
                </Row>
                <Row>
                  <Card className="card-general mt-2">
                    <Row>
                      <Col span={24}>
                        <Text className="titulo"> Fecha del Evento </Text>
                      </Col>
                      <Col span={24}>
                        <Text className="info">
                          {dayjs(this.state.evento?.fecha).format("LL")}
                        </Text>
                      </Col>
                    </Row>
                    <Divider className="info-divder" />
                    <Row>
                      <Col span={24}>
                        <Text className="titulo"> Hora de Inicio : </Text>
                      </Col>
                      <Col span={24}>
                        <Text className="info" ellipsis={true}>
                          {dayjs(this.state.evento?.horario).format("hh:mm A")}
                        </Text>
                      </Col>
                    </Row>

                    <Divider className="info-divder" />
                  </Card>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={18}
                xl={17}
                xxl={16}
                className="pl-2 pt-2"
              >
                <Markdown
                  rehypePlugins={[rehypeRaw]}
                  className={"markdown-body"}
                >
                  {this.state?.evento?.contenido}
                </Markdown>
              </Col>
            </Row>
          </Content>
        </Row>
        <Content className="image-section2">
          <Row className="foreground pl-2 pr-2" justify={"center"}>
            <Col xs={24} md={12}>
              <Title
                level={2}
                style={{ fontSize: "38px", margin: "1rem 0", color: "white" }}
              >
                Adquiere tus Boletos
              </Title>
            </Col>
            <Col xs={24} md={12} className="flex-right"></Col>
          </Row>
        </Content>
        <Row justify={"center"} className="width-100 pd-1">
          <Col span={24} className="center mb-2">
            <Space direction="vertical">
              <Title className="text-white center">Mapa del Escenario</Title>
              <Text className="title text-white center">
                Adquiere tus boletos aquí e inicia tu proceso de compra.
              </Text>
              <Text className="text-white center">
                Ingresa la cantidad de asientos que deseas comprar, al
                seleccionar un asiento de una mesa, este pasará a no estar
                disponible por un tiempo limitado.
              </Text>
            </Space>
          </Col>
          <Col span={24} className="center mb-2">
            <Space>
              <NumericInput
                initialValue={this.props.cookies["cart"]?.length}
                onChange={(value) => this.setState({ asientos_limit: value })}
                min={
                  this.props.cookies["cart"]?.length
                    ? this.props.cookies["cart"]?.length
                    : 1
                }
              />
              <Select
                defaultValue={0}
                style={{
                  minWidth: 220,
                }}
                options={this.state.evento?.secciones?.map((elem, index) => ({
                  label: elem.nombre,
                  value: index,
                }))}
                onChange={this.handleChangeSeccion}
              />
              <Button
                type="primary"
                className="btn-gradiente-morado"
                onClick={() => this.gotoRoute("pago")}
                disabled={
                  !this.props?.cookies["cart"] ||
                  this.props?.cookies["cart"]?.length == 0
                }
              >
                Proceder al Pago
              </Button>
            </Space>
          </Col>
          <div>
            <Seating
              ref={this.refStage}
              value={{
                mesas: this.state.mesas,
                blocked: this.state.blocked,
              }}
              addSeat={this.IO_addSeat}
              deleteSeat={this.IO_deleteSeat}
              selectedSeats={this.props.cookies["cart"] ?? []}
              usuario_id={this.props.user?._id}
              carrito_id={this.props.cookies?.carrito_id}
              limit={this.state.asientos_limit}
              width={1000}
              columns={41}
              rows={65}
            ></Seating>
          </div>
        </Row>

        <Row className="center mt-3">
          <ProximosEventos limit={3} />
        </Row>

       <Direccion/>
        <FooterPublic />
        <ModalInvitado
          open={this.state.modal_invitado}
          onClose={() => this.setState({ modal_invitado: false })}
          onComprarInvitado={this.onComprarInvitado}
        />
      </Layout>
    );
  }
}

export default function Vista(props) {
  const [cookies, setCookie, removeCookie] = useCookies(["cart", "carrito_id"]);
  const user = React.useContext(User);
  const location = useLocation();
  return (
    <Evento
      {...props}
      user={user}
      params={useParams()}
      location={useLocation()}
      navigate={useNavigate()}
      socket={useContext(Socket)}
      setCookie={setCookie}
      removeCookie={removeCookie}
      cookies={cookies}
    />
  );
}
