import React, { Component } from "react";
import {
  message,
  Layout,
  Tabs,
} from "antd";

import axios from "axios";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Logged, { User } from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import useSearch from "../../../Hooks/useSearch";

import FormEvento from "./Evento/FormEvento";
import FormSeating from "./Evento/FormSeating";

const { Content } = Layout;

/**
 * @class Evento
 * @description Seccion para el crear - modificar los eventos
 */
class Evento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      evento: {
        descripcion: "",
        contenido: "",
        imagenes: [],
        mesas: undefined,
      },
      mesas: undefined,
      key: 1,
    };
  }
  formSeating = React.createRef();
  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");

      console.log("this.props.params.evento_id", this.props.params.evento_id);
    if (this.props.params.evento_id) {
      this.get();
    }
  }

  /**
   * @method get
   * @description Obtiene el evento de la bd
   */
  get = () => {
    this.setState({ loading: true });
    axios
      .get("/eventos/" + this.props.params.evento_id)
      .then(({ data }) => {

        this.setState({ evento: data, mesas: data.mesas });
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Error al obtener el evento");
      })
      .finally(() => this.setState({ loading: false }));
  };


  render() {
    return (
      <>
        <PageHeader
          className="admin-page-header"
          breadcrumb={{
            items: [
              {
                key: "0",
                title: "Eventos",
                onClick: () => this.props.navigate("/admin/eventos"),
                className: "hover",
              },
              {
                key: "1",
                title: this.props.params.evento_id
                  ? "Editar Evento "
                  : "Crear Evento ",
              },
            ],
          }}
        ></PageHeader>
        <Content className="content-event admin-content content-bg pd-1">

          <Tabs
            defaultActiveKey={1}
            onTabClick={(key) => this.setState({ key })}
            destroyInactiveTabPane
            activeKey={this.state.key}
            items={[
              {
                key: 1,
                label: "Información",
                children: (
                  <FormEvento
                    evento_id={this.props.params.evento_id}
                    setKey={(key) => this.setState({ key })}
                    {...this.props}
                  />
                ),
              },
              {
                key: 2,
                label: "Mesas",
                disabled: this.props.params.evento_id == null,
                children: (

                  <FormSeating
                    evento_id={this.props.params.evento_id}
                    {...this.props}
                  />
                )
                ,
              },
            ]}
          />

        </Content>
      </>
    );
  }
}

export default function Vista(props) {
  const user = React.useContext(User);
  const { setShow } = useSearch();

  const permisos = usePermissions(user?.rol_id?.permisos, {
    canEdit: ["eventos", "edit"],
    canDelete: ["eventos", "delete"],
    canCreate: ["eventos", "create"],
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const crm = queryParams.get('crm');

  return (
    <Evento
      return_to_crm={crm} 
      {...props}
      setShowSearch={setShow}
      {...permisos}
      navigate={useNavigate()}
      params={useParams()}
    />
  );
}
