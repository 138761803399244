import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Layout,
  message,
  Space,
  List,
  Typography,
  Button,
  Empty,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { FilePdfOutlined } from "@ant-design/icons";

//Components
import ModalVenta from "../ModalVentas";
import { ventaEstatusTag } from "../../Utils";
import useSearch from "../../../Hooks/useSearch";
import { ButtonView } from "../../Widgets/Buttons";
import PageHeader from "../../Widgets/PageHeader/PageHeader";

const { Text } = Typography;
const { Content } = Layout;

/**
 * @export
 * @class Ventas
 * @extends {Component}
 * @description Vista de Ventas del cliente
 */
class Ventas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal_visible: false,
      venta_id: undefined,
      ventas: {
        data: [],
        page: 1,
        total: 0,
      },
      filtroSearch: "",
      searching: true,
    };
  }

  componentDidMount() {
    this.props.setShowSearch(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    this.getVentas();
  }

  componentDidUpdate(prevProps) {
    if (this.props.search != prevProps.search) {
      this.getVentas();
    }
  }

  /**
   * @param {*} [page=this.state.page] Numero de la pagina a obtener
   * @memberof Areas
   * @method getVentas
   * @description Obtiene el listado de areas
   */
  getVentas = (
    {
      page = this.state.ventas.page,
      limit = this.state.ventas.limit,
      search = this.props.search,
    } = this.state.ventas
  ) => {
    this.setState({ loading: true });
    axios
      .get("/customer/ventas", {
        params: {
          page,
          limit,
          search,
        },
      })
      .then(({ data }) => {
        this.setState({ ventas: data });
      })
      .catch((error) => {
        console.log(error.response);
        message.error(
          error?.response?.data?.message ?? "Error al traer las compras"
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   *
   * @memberof Compras
   *
   * @method getPDF
   * @description Generamos el PDF del comprobante de pago
   */
  getPDF = (venta_id) => {
    let urlPDF = new URL(axios.defaults.baseURL + "/customer/venta/pdf");
    urlPDF.searchParams.append(
      "Authorization",
      sessionStorage.getItem("token")
    );
    urlPDF.searchParams.append("venta_id", venta_id);
    window.open(urlPDF.href, "_blank");
  };

  render() {
    return (
      <>
        <PageHeader
          className="admin-page-header"
          breadcrumb={{
            items: [
              {
                title: "Compras",
              },
            ],
          }}
        ></PageHeader>
        <Content className="admin-content content-bg pd-1">
          <List
            loading={this.state.loading}
            className="component-list"
            itemLayout="horizontal"
            locale={{
              emptyText: <Empty description="Sin compras registradas" />,
            }}
            dataSource={this.state.ventas.data}
            pagination={{
              current: this.state.ventas.page,
              pageSize: this.state.ventas.limit,
              total: this.state.ventas.total,
              position: "bottom",
              className: "flex-left",
              hideOnSinglePage: true,
              onChange: (page, limit) => this.getVentas({ page, limit }),
            }}
            header={
              <Row className="component-list-header width-100 pr-1 pl-1">
                <Col xs={24} lg={4} className="center">
                  <Text strong>fecha</Text>
                </Col>
                <Col xs={24} lg={3} className="center">
                  <Text strong># boletos</Text>
                </Col>
                <Col xs={24} lg={5} className="center">
                  <Text strong>evento</Text>
                </Col>
                <Col xs={24} lg={5} className="center">
                  <Text strong>monto</Text>
                </Col>
                <Col xs={24} lg={4} className="center">
                  <Text strong>estatus</Text>
                </Col>
              </Row>
            }
            renderItem={(item) => (
              <List.Item className="component-list-item">
                <Card className="card-list">
                  <Row className="width-100 " gutter={[8, 8]}>
                    <Col xs={24} lg={4} className="center">
                      <Text>{dayjs(item.fecha).format("DD/MM/YYYY")}</Text>
                    </Col>
                    <Col xs={24} lg={3} className="center">
                      <Text>{item.detalles?.length}</Text>
                    </Col>
                    <Col xs={24} lg={5} className="center">
                      <Text>{item.evento_id?.nombre}</Text>
                    </Col>
                    <Col xs={24} lg={5} className="center">
                      <Text>
                        $ {item.total ? item.total.toMoney(true) : 0}{" "}
                        <small>MXN</small>
                      </Text>
                    </Col>
                    <Col xs={24} lg={4} className="center">
                      <Text>{ventaEstatusTag(item.estatus)}</Text>
                    </Col>
                    <Col xs={24} lg={3} className="center">
                      <Space size="small" direction="horizontal">
                        <ButtonView
                          onClick={() =>
                            this.setState({
                              modal_visible: true,
                              venta_id: item._id,
                            })
                          }
                        />
                        <Button
                          icon={<FilePdfOutlined />}
                          onClick={() => this.getPDF(item._id)}
                        />
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </Content>
        <ModalVenta
          open={this.state.modal_visible}
          onClose={() =>
            this.setState({ modal_visible: false, venta_id: undefined })
          }
          venta_id={this.state.venta_id}
        />
      </>
    );
  }
}

export default function Vista(props) {
  const { setShow } = useSearch();

  return <Ventas {...props} setShowSearch={setShow} />;
}
