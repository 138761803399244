
import React from 'react';
import { Drawer, Form, Select,  Button, TreeSelect, DatePicker } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';

//Componentes
import SelectEventos from '../Inputs/SelectEventos';
import SelectClientes from '../Inputs/SelectClientes';

const { RangePicker } = DatePicker

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
class FiltrosDrawer extends React.Component {

    /**
     *
     *
     * @export
     * @class FiltrosDrawer
     * @extends {React.Component}
     * 
     */
    state = {
        filtros: null,
        estatus: {
            data: [],
            dictionary: {}
        }
    }

    formFiltros = React.createRef()



    loadEstatus = () => {
        const estatus = {}

        if (this.props.estatus.type !== 'tree') {
            estatus.data = this.props.estatus.data
            estatus.dictionary = {}
            estatus.data.map(d => estatus.dictionary[d._id] = d)
        }

        if (this.props.estatus.type === 'tree') {
            estatus.data = this.props.estatus.data
            estatus.dictionary = {}
            estatus.data.map(d => estatus.dictionary[d.value] = d)
        }

        this.setState({ estatus })
    }

    getParent = (name, element) => {

        let dictionary = this.state[name].dictionary
        let parent = null

        for (let key in dictionary) {
            let { children } = dictionary[key]
            if (!children) continue

            let child = children.find(child => child.value === element)
            if (!child) continue

            parent = {
                ...dictionary[key],
                children: [child]
            }

            break
        }

        return parent
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = ({ clientes, estatus, fechas, asesores, eventos }) => {

        let filtros = []

        //Metodo para añadir al areglo de filtros.
        let addToFilter = (name, objectName, array) => array.map(({value, label}) => filtros.push({
            _id: value,//clasificador,
            name: name,
            objectName,
            label: Array.isArray(label) ? label.join('') : label
        }))

        if (clientes) addToFilter("cliente_id", "clientes", clientes)
        if (estatus) addToFilter("estatus", "estatus", estatus)
        if (eventos) addToFilter("evento_id", "eventos", eventos)

        if (fechas) filtros.push({
            name: "fecha",
            objectName: "fechas",
            filter: {
                range: fechas.map(f => dayjs(f).format("YYYY-MM-DD"))
            }
        })
    
        if (this.props.updateFilters)
            this.props.updateFilters(filtros)
    }


    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method downloadCSV
     */
    downloadCSV = () => {
        const { filters } = this.props.params
        let url = new URL(axios.defaults.baseURL + '/' + this.props.csv)
        if (filters)
            url.searchParams.set('filters[]', filters.map(f => JSON.stringify(f)))
        url.searchParams.set('csv', true)
        url.searchParams.set('Authorization', sessionStorage.getItem('token'))

        if (this.props.hacienda_id)
            url.searchParams.set('hacienda_id', this.props.hacienda_id)

        window.open(url, '_blank');
    }

    render() {

        const { onClose, visible } = this.props
        const { clientes, estatus, asesores, fechas, eventos } = this.props

        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            visible={visible}
            extra={
                (this.props.csv !== undefined) ?
                    <Button
                        onClick={this.downloadCSV}
                        icon={<DownloadOutlined color='#' />}
                    >CSV</Button>
                    : null
            }>


            <Form
                ref={this.formFiltros}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >
                {(fechas) && (
                    <Form.Item label="Rango de Fechas" name="fechas" >
                        <RangePicker placeholder={["Fecha Inicial", "Fecha Final"]}  className='width-100'/>
                    </Form.Item>
                )}

                {(clientes) ?
                    <Form.Item label="Clientes" name="clientes">
                        <SelectClientes
                            mode="multiple"
                            labelInValue={true}
                        />
                    </Form.Item> : null}

                {(eventos) ?
                    <Form.Item label="Eventos" name="eventos">
                        <SelectEventos
                            mode="multiple"
                            labelInValue={true}
                        />
                    </Form.Item> : null}
               
                {(estatus) &&
                    (estatus?.type === "tree" ? (
                        <Form.Item label="Estatus" name="estatus">
                            <TreeSelect
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                treeData={estatus.data}
                                treeDefaultExpandAll
                                multiple
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item label="Estatus" name="estatus">
                            <Select
                                filterOption={false}
                                mode="multiple"
                                labelInValue
                                options={estatus.data.map(o => ({value: o._id, label: o.label}))}
                            />
                        </Form.Item>
                    ))
                }
            </Form>
        </Drawer >)
    }
};


export default React.forwardRef((props, ref) => {
    return <FiltrosDrawer ref={ref} {...props}  />
})