import React, { useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Layout } from "antd";

import Navbar from "../Components/Admin/Navigation/Navbar";
import Sidebar from "../Components/Admin/Navigation/Sidebar";
import Dashboard from "../Components/Admin/Dashboard/Dashboard";
import axios from "axios";

import RouterCRM from "./Admin/RouterCRM";
import RouterRoles from "./Admin/RouterRoles";
import RouterVentas from "./Admin/RouterVentas";
import RouterEventos from "./Admin/RouterEventos";
import RouterBitacora from "./Admin/RouterBitacora";
import RouterUsuarios from "./Admin/RouterUsuarios";
import RouterCategorias from "./Admin/RouterCategorias";
import RouterPlantillas from "./Admin/RouterPlantillas";

import User from "../Hooks/Logged";

import io from "socket.io-client";
import Socket from '../Hooks/Socket';

import { Search } from "../Hooks/useSearch";

import "../Styles/Global/admin.scss";
import RouterSeating from "./Admin/RouterSeating";


const { Content } = Layout;

/**
 *
 * @export
 * @function AdminRoutes
 * @description Router for handling all admin routes
 */
class AdminRoutes extends React.Component {
  static contextType = User;

  constructor(props) {
    super(props);
    this.state = {
      search: null,
      showSearch: true,
      socket: this.getSocket(),
    };
  }

  componentDidMount() {
    this.axiosInterceptor = axios.interceptors.response.use(
      (response) => {
        //console.log("response interceptor", response);
        return response;
      },
      (error) => {
        console.log("error interceptor", error.response?.status);
        if (error.response?.status === 401) this.props.navigate("/login");
        return Promise.reject(error);
      }
    );
  }
  componentWillUnmount() {
    axios.interceptors.request.eject(this.axiosInterceptor);
  }

  setSearch = (search) => this.setState({ search });

  setShowSearch = (showSearch) => this.setState({ showSearch });

  getSocket = () => {
    try {
      this.state.socket?.disconnected();
    } catch (error) {
    }

    let socket = io.connect(process.env.REACT_APP_WEB_SERVICE, {
      extraHeaders: {
        Authorization: sessionStorage.getItem("token"),
      },
    });
    socket.io.on("open", () => {
      socket.io.engine.transport.on("pollComplete", () => {
        socket.io.opts.extraHeaders = {
          Authorization: sessionStorage.getItem("token"),
        };
      });
    });
    return socket;
  };
  render() {

    return (
      <Socket.Provider value={this.state.socket}>
        <Search.Provider
          value={{
            search: this.state.search,
            setSearch: this.setSearch,
            show: this.state.showSearch,
            setShow: this.setShowSearch,
          }}
        >
          <Layout id={"admin"} className="layout-main">
            <Navbar
              showSearch={this.state.showSearch}
              onSearch={this.setSearch}
            />
            <Layout className="layout-main">
              <Sidebar />
              <Content id="admin-content">
                <Routes>
                  <Route
                    path="/dashboard"
                    element={<Dashboard search={this.state.search} />}
                  />
                  <Route
                    path="/crm/*"
                    element={<RouterCRM search={this.state.search} />}
                  />
                  <Route
                    path="/eventos/*"
                    element={<RouterEventos search={this.state.search} />}
                  />
                  <Route
                    path="/plantillas/*"
                    element={<RouterPlantillas search={this.state.search} />}
                  />
                   <Route
                    path="/categorias/*"
                    element={<RouterCategorias search={this.state.search} />}
                  />
                   <Route
                    path="/ventas/*"
                    element={<RouterVentas search={this.state.search} />}
                  />
                  <Route
                    path="/usuarios/*"
                    element={<RouterUsuarios search={this.state.search} />}
                  />
                  <Route
                    path="/roles/*"
                    element={<RouterRoles search={this.state.search} />}
                  />
                  <Route
                    path="/bitacora/*"
                    element={<RouterBitacora search={this.state.search} />}
                  />
                  <Route
                    path="/seating/*"
                    element={<RouterSeating search={this.state.search} />}
                  />
                </Routes>
              </Content>
            </Layout>
          </Layout>
        </Search.Provider>
      </Socket.Provider>
    );
  }
}
export default function (props) {
  const user = useContext(User);
  const navigate = useNavigate();
  return <AdminRoutes {...props} user={user} navigate={navigate} />;
}
