import React, { Component } from "react";
import {
	Row,
	Col,
	Layout,
	Card,
	Typography,
	Form,
	DatePicker,
	Select,
	Result,
	List,

} from "antd";
import ReactECharts from "echarts-for-react";
import axios from "axios";
import {
	CardPorcentajeEventos,
	CardReporteDashboard,
	CardProximoEvento,
	CardVentas,
} from "../../Widgets/Cards";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSearch from "../../../Hooks/useSearch";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import SelectEventos from "../../Widgets/Inputs/SelectEventos";
import dayjs from "dayjs";
import Logged, { User } from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";

const { Content } = Layout;
const { Title, Text } = Typography;
/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Dashboard extends Component {
	back = () => {
		this.props.navigate(-1);
	};

	/**
	 *Creates an instance of Usuarios.
	 * @param {*} props
	 * @memberof Usuarios
	 */
	constructor(props) {
		super(props);
		this.state = {
			filtros: {
				fechas: [],
				evento_id: "",
				frecuencia: 1
			},
			plantas: {
				nombres: [],
				disponibles: [],
				ocupados: [],
			},
			montos: {
				totales: 0,
				mes: 0,
				invertido: 0,
				confirmar: 0,
			},
			ventas: {
				year: {
					meses: [],
					data: [],
				},
				total: [],
			},
			ventas_general: null,
			ventas_linea: null,
			ventas_lugar: null,
			campanas: null,
			monto_general: null,
			monto_linea: null,
			monto_lugar: null,
			porcentaje_vendidos: 0,
			tendencia_boletos: null,
			mesas: {}

		};
	}

	formatFuente = (fuente) => {
		switch (fuente) {
			case 0: return "Local";
			case 1: return "Facebook";
			case 2: return "Instagram";
			case 3: return "Formulario";
			case 4: return "Whatsapp";
			case 5: return "Ads";
			default: return "Otro";
		}
	};

	colores = [
		"#7F2BE9",
		"#4267B2",
		"#E1306C",
		"#7F2BE9",
		"#25D366",
		"#7F2BE9",
	]

	get = ({
		fechas = this.state.filtros.fechas,
		evento_id = this.state.filtros.evento_id,
		frecuencia = this.state.filtros.frecuencia
	} = this.state.filtros) => {
		axios
			.get("/dashboard", {
				params: {
					fechas,
					evento_id,
					frecuencia
				},
			})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({
					filtros: {
						fechas: fechas,
						evento_id: evento_id
					},
					ventas_general: {
						grid: {
							show: false,
						},
						xAxis: {
							type: "category",
							data: data.en_linea.map((f) => dayjs(f.k).format('LL')),
							show: false,
						},
						yAxis: {
							type: "value",
							show: false,
						},
						series: [
							{
								data: data.general.map((f) => f.v),
								type: "line",
								smooth: true,
								color: 'white',
							},
						],
						tooltip: {
							trigger: "axis",
						},
					},
					ventas_linea: {

						grid: {
							show: false,
						},
						xAxis: {
							type: "category",
							data: data.en_linea.map((f) => dayjs(f.k).format('LL')),
							show: false,
						},
						yAxis: {
							type: "value",
							show: false,
						},
						series: [
							{
								data: data.en_linea.map((f) => f.v),
								type: "line",

								smooth: true,
								color: "white",
							},
						],
						tooltip: {
							trigger: "axis",
						},

					},
					ventas_lugar: {
						grid: {
							show: false,
						},
						xAxis: {
							type: "category",
							data: data.en_linea.map((f) => dayjs(f.k).format('LL')),
							show: false,
						},
						yAxis: {
							type: "value",
							show: false,
						},
						series: [
							{
								data: data.del_lugar.map((f) => f.v),
								type: "line",
								smooth: true,
								color: "white",
							},
						],
						tooltip: {
							trigger: "axis",
						},
					},
					monto_general: data.general.map((f) => f.v).reduce((a, b) => a + b, 0),
					monto_linea: data.en_linea.map((f) => f.v).reduce((a, b) => a + b, 0),
					monto_lugar: data.del_lugar.map((f) => f.v).reduce((a, b) => a + b, 0),

					campanas: {
						dataset: {
							source: data.fuentes

						},
						xAxis: {
							type: "category",


						},
						yAxis: {

						},



						series: [
							{
								encode: {
									x: "_id",
									y: "count"
								},
								type: "bar",

								itemStyle: {
									color: (params) => this.colores[params.data._id],
								}
							},
						],
						tooltip: {
							trigger: "axis",
							formatter: (params) => {

								//console.log("params", params);

								return ` ${this.formatFuente(params[0].data._id)}<br/> ${params[0].data.count}`

							}
						},
					},
					porcentaje_vendidos: Number(data.porcentajeVendidos) ?? 0,

					tendencia_boletos: {
						grid: {
							show: false,
						},
						xAxis: {
							type: "category",
							data: data.tendencias.map((f) => dayjs(f.k).format('LL')),
							show: false,
						},
						yAxis: {
							type: "value",
							show: false,
						},
						series: [
							{
								data: data.tendencias.map((f) => f.v),
								type: "line",
								smooth: true,
								color: 'black',
							},
						],
						tooltip: {
							trigger: "axis",
						},
					},

					eventos: data.proximosEventos,
					mesas: data.mesas



				});
				// this.setState(data);
			})
			.catch((error) => {
				console.log("ERR, error", error);
			});
	};

	componentDidMount() {
		this.props.setShowSearch(false);
		axios.defaults.headers.common["Authorization"] =
			sessionStorage.getItem("token");

		this.get();
	}

	genTitle = (fechas) => {

		console.log("fechas", fechas)

		if (fechas.length === 0) return 'Ventas de la semana'

		return `Ventas del ${dayjs(fechas[0]).format('LL')} al ${dayjs(fechas[1]).format('LL')}`

	}

	render() {
		let option = {
			xAxis: {
				type: "category",
				data: this.state.ventas?.total?.map((hacienda) => hacienda.nombre),
				axisLabel: {
					rotate: 90,
				},
			},
			yAxis: {
				type: "value",
			},
			series: [
				{
					data: this.state.ventas?.total?.map((hacienda) => hacienda.monto),
					type: "bar",
				},
			],
		};
		return (
			<>
				<PageHeader
					className="admin-page-header"
					breadcrumb={{
						items: [
							{
								title: "Dashboard",
							},

						]
					}}
					extra={[
						<Form
							layout="inline"
							onValuesChange={(x, values) => this.get(values)}
						>
							<Form.Item name={"fechas"}>
								<DatePicker.RangePicker maxDate={dayjs().endOf("day")}  placeholder={["Fecha Inicio","Fecha Fin"]}/>
							</Form.Item>
							<Form.Item name={"evento_id"}>
								<SelectEventos onChange={() => { }} style={{ width: 250 }} />
							</Form.Item>
						</Form>,
					]}
				/>
				<Content className="admin-content content-bg pd-1">
					<Row gutter={[16, 16]}>
						<Col xs={24} md={12}>
							<Row gutter={[16, 16]}>
								<Col xs={24} xl={12}>
									<CardVentas
										className="bg-pink card-title uppercase"
										titulo={"ventas brutas"}
										showtext={false}
										options={this.state.ventas_general}
										monto={this.state.monto_general}
									/>
								</Col>
								<Col xs={24} xl={12}>
									<CardVentas
										className="bg-orange card-title uppercase"
										titulo={"ventas online"}
										options={this.state.ventas_linea}
										monto={this.state.monto_linea}
									/>
								</Col>
								<Col xs={24} xl={12}>
									<CardVentas
										titulo={"campañas"}
										className="bg-white uppercase"
										options={this.state.campanas}
									/>
								</Col>
								<Col xs={24} xl={12}>
									<CardVentas
										className="bg-green card-title uppercase"
										titulo={"ventas del lugar"}
										options={this.state.ventas_lugar}
										monto={this.state.monto_lugar}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={24} md={12}>
							<Row gutter={[16, 16]}>
								<Col xs={24} xl={12}>
									<CardPorcentajeEventos
										color="#43E794"
										titulo="entradas anticipadas"
										footer={[
											{
												titulo: "General",
												monto: this.state.monto_lugar,
												color: "#43E794",
											},
											{
												titulo: "Online",
												monto: this.state.monto_linea,
												color: "#FDA240",
											},
										]}
									/>
								</Col>
								<Col xs={24} xl={12}>
									<CardPorcentajeEventos
										color="#FDA240"
										titulo="objetivos del evento"
										porcentaje={this.state.porcentaje_vendidos}
										footer={[]}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row gutter={[0, 8]} className="width-100 mt-1">
						<Col xs={24} md={12} lg={16}>
							<Card
								title={<Row>
									<Col span={18}>
										Tendencia de Venta de Boletos
									</Col>
									<Col span={6}>
										<Select className="width-100" defaultValue={1} onChange={(x) => this.get({ frecuencia: x })}>
											<Select.Option value={1}>Dia</Select.Option>
											<Select.Option value={2}>Mes</Select.Option>
											<Select.Option value={3}>Año</Select.Option>
										</Select>
									</Col>
								</Row>}
								className="card-title-custom"
							>
								<ReactECharts
									option={this.state.tendencia_boletos ?? option}
									style={{ width: "100%", maxWidth: 400 }}
								/>
							</Card>
						</Col>
						<Col xs={24} md={12} lg={8}>
							<CardReporteDashboard titulo={this.genTitle(this.state.filtros.fechas)}
								textos={[
									{
										titulo: "Tickets Vendidos",
										monto: this.state.mesas?.occupiedSeats??0,
									},
									{
										titulo: "Tickets en Ventas",
										monto: this.state.mesas?.totalSeats??0,
									},
								]}
							/>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Title level={4}>Próximos Eventos</Title>
						</Col>
						<Col span={24}>
							<List
								grid={{
									gutter: 16,
									xs: 1,
									sm: 1,
									md: 2,
									lg: 2,
									xl: 3,
									xxl: 4,
								}}
								className="width-100"
								itemLayout="horizontal"
								dataSource={this.state.eventos}
								renderItem={(item) => (
									<List.Item>
										<CardProximoEvento 
											description={item.descripcion} 
											fecha={item.fecha} 
											vendidos={item.comprados} 
											total={item.totales} 
											horario={item.horario}
											showTotales={true}
										/>
									</List.Item>


								)}
							/>
						</Col>

					</Row>
				</Content>
			</>
		);
	}
}

export default (props) => {
	const { setShow } = useSearch();
	const navigate = useNavigate();
	const [params, setParams] = useSearchParams();
	const user = React.useContext(User);

	const permisos = usePermissions(user?.rol_id?.permisos, {
		access: ["dashboard"],
	});

	if (permisos.access === false)
		return <Result
			className="mt-3"
			status="warning"
			title="No tienes acceso a esta área"
		/>

	return (
		<Dashboard
			{...props}
			setShowSearch={setShow}
			navigate={navigate}
			params={params}
		/>
	);
};
