import React, { Component } from "react";
import { Row, Col, Layout, Card, Typography, List, Empty } from "antd";
import axios from "axios";
import { CardProximoEvento } from "../Widgets/Cards";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSearch from "../../Hooks/useSearch";
import PageHeader from "../Widgets/PageHeader/PageHeader";
import ModalVentas from "./ModalVentas";
import dayjs from "dayjs";
import { ventaEstatusTag } from "../Utils";

import { User } from "../../Hooks/Logged";
import { ButtonView } from "../Widgets/Buttons";

const { Content } = Layout;
const { Title, Text } = Typography;
/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Dashboard extends Component {
  back = () => {
    this.props.navigate(-1);
  };

  /**
   *Creates an instance of Usuarios.
   * @param {*} props
   * @memberof Usuarios
   */
  constructor(props) {
    super(props);
    this.state = {
      filtros: {
        fechas: [],
        evento_id: "",
      },

      eventos: [],
      ventas: [],
    };
  }

  componentDidMount() {
    this.props.setShowSearch(false);

    this.get();
  }

  get = () => {
    axios.get("/dashboard/cliente").then(({ data }) => {
      this.setState({
        eventos: data.proximosEventos,
        ventas: data.ventas,
      });
    });
  };

  render() {
    return (
      <>
        <PageHeader
          className="admin-page-header"
          breadcrumb={{
            items: [
              {
                title: "Dashboard",
              },
            ],
          }}
        />
        <Content className="admin-content content-bg pd-1">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={3}>
                Bienvenido(a), {this.props.user?.nombre}{" "}
                {this.props.user?.apellidos}
              </Title>
            </Col>

            <Col xs={24} xl={24}>
              <Row>
                <Col span={24}>
                  <Title level={4}>Últimas Compras</Title>
                </Col>
                <Col span={24}>
                  <List
                    className=""
                    itemLayout="vertical"
                    dataSource={this.state.ventas}
                    locale={{
                      emptyText: (
                        <Empty description="Sin compras registradas" />
                      ),
                    }}
                    renderItem={(item) => (
                      <List.Item className="component-list-item">
                        <Card className="card-list">
                          <Row className="width-100 " gutter={[8, 8]}>
                            <Col xs={24} lg={5} className="center">
                              <Text>
                                {dayjs(item.fecha).format("DD/MM/YYYY")}
                              </Text>
                            </Col>

                            <Col xs={24} lg={6} className="center">
                              <Text>{item.evento_id?.nombre}</Text>
                            </Col>
                            <Col xs={24} lg={5} className="center">
                              <Text>
                                $ {item.total ? item.total.toMoney(true) : 0}
                              </Text>
                            </Col>
                            <Col xs={24} lg={6} className="center">
                              <Text>{ventaEstatusTag(item.estatus)}</Text>
                            </Col>
                            <Col xs={24} lg={2} className="center">
                              <ButtonView
                                onClick={() =>
                                  this.setState({
                                    modal_visible: true,
                                    venta_id: item._id,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </Card>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} xl={24}>
              <Row>
                <Col span={24}>
                  <Title level={4}>Próximos Eventos</Title>
                </Col>
                <Col span={24}>
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 2,
                      lg: 2,
                      xl: 3,
                      xxl: 4,
                    }}
                    itemLayout="horizontal"
                    dataSource={this.state.eventos}
                    renderItem={(item) => (
                      <List.Item className="grid">
                        <CardProximoEvento
                          onClick={() =>
                            this.props.navigate("/evento/" + item.url, {
                              state: { evento_id: item._id },
                            })
                          }
                          description={
                            item.descripcion ??
                            "No hay una descripción disponible"
                          }
                          fecha={item.fecha}
                          total={item.totales}
                          vendidos={item.comprados}
                        />
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalVentas
            open={this.state.modal_visible}
            onClose={() => {
              this.setState({ modal_visible: false, venta_id: undefined });
            }}
            venta_id={this.state.venta_id}
          />
        </Content>
      </>
    );
  }
}

export default function View(props) {
  const { setShow } = useSearch();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const user = React.useContext(User);

  return (
    <Dashboard
      {...props}
      user={user}
      setShowSearch={setShow}
      navigate={navigate}
      params={params}
    />
  );
}
