import React, { Component } from "react";
import { Layout, Menu, Button } from "antd";
import { useNavigate, Link } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  AppstoreFilled,
  UserOutlined,
  TagOutlined,
  ClusterOutlined,
  CalendarOutlined,
  ApartmentOutlined,
  UnorderedListOutlined,
  GatewayOutlined,
  UsergroupAddOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";

import { IoTicketOutline } from "react-icons/io5";
import SubMenu from "antd/lib/menu/SubMenu";
import usePermissions from "../../../Hooks/usePermissions";
import User from "../../../Hooks/Logged";
import '../../../Styles/Modules/Admin/sidebar.scss';

const { Sider } = Layout;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  /**
   *
   *
   * @memberof AdminSidebar
   */
  toogle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { props } = this;

    return (
      <Sider
        theme="light"
        width={250}
        collapsedWidth={0}
        breakpoint="lg"
        className="custom-sider sider-width"
        //style={{position: "absolute", height: "100vh"}}
        //collapsed={this.state.collapsed}
        //collapsible
      >
        <Menu
          mode="inline"
          theme="light"
          className="heigth-100 "
          style={{ borderRight: 0 }}
        >
          <Button ghost onClick={this.toogle} className="no-border">
            {this.state.collapsed ? (
              <MenuUnfoldOutlined />
            ) : (
              <MenuFoldOutlined />
            )}
          </Button>

          <Menu.ItemGroup key="navegacion" title="NAVEGACIÓN">
            {props.accessDashboard && (
              <Menu.Item
                key="dashboard"
                icon={<AppstoreFilled />}
                onClick={() =>
                  this.props.navigate("/admin/dashboard", { replace: true })
                }
              >
                Dashboard
              </Menu.Item>
            )}

            {props.accessLayouts && (
              <Menu.Item
                key="plantillas"
                icon={<GatewayOutlined />}
                onClick={() =>
                  this.props.navigate("/admin/plantillas", { replace: true })
                }
              >
                Plantillas
              </Menu.Item>
            )}
            {props.accessEventos && (
              <Menu.Item
                key="eventos"
                icon={<CalendarOutlined />}
                onClick={() =>
                  this.props.navigate("/admin/eventos", { replace: true })
                }
              >
                Eventos
              </Menu.Item>
            )}
            {props.accessCategorias && (
              <SubMenu
                key="categorias"
                icon={<ApartmentOutlined />}
                title={"Categorias"}
              >
                <Menu.Item
                  key="categorias-1"
                  icon={<ScheduleOutlined />}
                  onClick={() =>
                    this.props.navigate("/admin/categorias", { replace: true })
                  }
                >
                  Categorías de boletos
                </Menu.Item>
                <Menu.Item
                  key="categorias-2"
                  icon={<ApartmentOutlined />}
                  onClick={() =>
                    this.props.navigate("/admin/categorias/simples", {
                      replace: true,
                    })
                  }
                >
                  Categorías
                </Menu.Item>
              </SubMenu>
            )}
            {props.accessVentas && (
              <Menu.Item
                key="Ventas"
                icon={<IoTicketOutline />}
                onClick={() =>
                  this.props.navigate("/admin/ventas", { replace: true })
                }
              >
                Ventas
              </Menu.Item>
            )}
            {props.accessCRM && (
              <SubMenu
                key="crm"
                icon={<ClusterOutlined />}
                title={<Link to={"/admin/crm"}>CRM</Link>}
              >
                <Menu.Item
                  key="crm-1"
                  icon={<UsergroupAddOutlined />}
                  onClick={() =>
                    this.props.navigate("/admin/crm/clientes", {
                      replace: true,
                    })
                  }
                >
                  Clientes
                </Menu.Item>
              </SubMenu>
            )}
            {props.accessUsuarios && (
              <Menu.Item
                key="usuarios"
                icon={<UserOutlined />}
                onClick={() =>
                  this.props.navigate("/admin/usuarios", { replace: true })
                }
              >
                Usuarios
              </Menu.Item>
            )}
            {props.accessRoles && (
              <Menu.Item
                key="roles"
                icon={<TagOutlined />}
                onClick={() =>
                  this.props.navigate("/admin/roles", { replace: true })
                }
              >
                Roles
              </Menu.Item>
            )}

            {props.accessBitacora && (
              <Menu.Item
                key="bitacora"
                icon={<UnorderedListOutlined />}
                onClick={() =>
                  this.props.navigate("/admin/bitacora", { replace: true })
                }
              >
                Bitacora
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        </Menu>
      </Sider>
    );
  }
}

export default function View(props) {
  const user = React.useContext(User);
  const permisos = usePermissions(user?.rol_id?.permisos, {
    accessDashboard: ["dashboard"],
    accessUsuarios: ["usuarios", "access"],
    accessRoles: ["roles", "access"],
    accessLayouts: ["layouts", "access"],
    accessCRM: ["crm", "access"],
    accessEventos: ["eventos", "access"],
    accessVentas: ["ventas", "access"],
    accessCategorias: ["categorias", "access"],
    accessBitacora: ["bitacora"],
  });

  return <Sidebar {...props} navigate={useNavigate()} {...permisos} />;
}
