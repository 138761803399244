import React, { Component } from "react";
import {
  Button,
  Layout,
  Row,
  Col,
  Typography,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navigation/Navbar-v2";

import "../../Styles/Modules/Landing/landing.scss";
import BuscadorEventos from "./Buscador";
import ProximosEventos from "./ProximosEventos";
import FooterPublic from "./Navigation/Footer";
import ScrollToHashElement from "../Widgets/ScrollToElement";

import Direccion from "./Sections/Direccion";
import MaiTai from "./Sections/MaiThai";
import Contacto from "./Sections/Contacto";
import Galeria from "./Sections/Galeria";
import Banner from "./Sections/Banner";

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

/**
 *
 * @class Landing
 * @extends {Component}
 * @description Componente Landing page
 */
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = { loading_submit: false };
  }

  formRef = React.createRef();

  componentDidMount() {}


  render() {
    return (
      <Layout className="layout-landing">
        <ScrollToHashElement behavior="smooth" inline="center" />
        <Navbar />
        <section className="image-section">
          <video src="/bg-landing.mp4" autoPlay loop muted className="video" />
        </section>
        <div className="transition-section">
          <Row justify="center">
            <Title
              className="sub-title text-white"
              onClick={() =>
                window
                  .open(
                    "https://www.instagram.com/explore/tags/venavandari/",
                    "_blank"
                  )
                  .focus()
              }
            >
              #VenAVandari
            </Title>
          </Row>
          <Row justify="center">
            <img src="/logo.png" className="title-img"></img>
          </Row>
          <Row justify={"center"}>
            <Paragraph className="description pl-3 pr-3" justify>
              Visita el mejor lugar para presenciar shows musicales, cómicos y
              más
            </Paragraph>
          </Row>
          <BuscadorEventos />
        </div>

        <div className="mt-3" />

        <ProximosEventos limit={12} />
        <Banner />
        <Galeria />
        <Direccion />
        <MaiTai />
        <Contacto />
        <FooterPublic />
      </Layout>
    );
  }
}

export default function Vista(props) {
  const navigate = useNavigate();
  return <Landing {...props} navigate={navigate} />;
}
