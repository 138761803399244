import React, { useState, useEffect } from "react";
import { Input } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

/**
 * @const Quill
 * @description Input de Texto
 *
 * @param String value, valor inicial o actual del numero de telefono
 * @param String onChange, metodo que se ejecuta al realzar una cambio en el valor
 */
const Quill = (props) => {
  const { value, onChange } = props;

  const [inputValue, setInputValue] = useState(value);

  const handleInput = (content, delta, source, editor) => {
    const lines = editor.getText().split("\n").length;
    if (lines > 30) {
      // Si se excede el número de líneas, no actualizar el valor
      setInputValue(value);
      onChange(value);
    } else {
      setInputValue(content);
      onChange(content);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return <ReactQuill theme="snow" value={value} onChange={handleInput} />;
};

export default Quill;
