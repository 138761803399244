import React, { useState, useRef, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Rect, Text, Circle, Layer, Stage, Line, Transformer } from 'react-konva';
import { CloseOutlined } from '@ant-design/icons';
import Konva from 'konva';
import { Button, Tag, Input, Row, Col, Divider, Popover, Select, Space } from 'antd';

import SelectCategoriasBoletos from '../../Widgets/Inputs/SelectCategoriasBoletos';

import "../../../Styles/Modules/Admin/seating.scss";

const { Option } = Select;

function shadeColor(color, percent) {
  // Remove the '#' character if it exists
  color = color.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16);

  // Calculate the new r, g, b values
  r = Math.round(r * (100 + percent) / 100);
  g = Math.round(g * (100 + percent) / 100);
  b = Math.round(b * (100 + percent) / 100);

  // Ensure the new values are within the range [0, 255]
  r = (r < 255) ? r : 255;
  g = (g < 255) ? g : 255;
  b = (b < 255) ? b : 255;

  // Convert the new r, g, b values back to HEX format
  r = ("0" + r.toString(16)).slice(-2);
  g = ("0" + g.toString(16)).slice(-2);
  b = ("0" + b.toString(16)).slice(-2);

  return `#${r}${g}${b}`;
}

const defaultTheme = {
  table: {
    fill: '#F2F2F2',
    stroke: '#B7B7B7',
    textColor: '#000000',
    deleteButtonColor: 'red',
    deleteButtonTextColor: 'white',
  },
  chair: {
    fill: '#D3D3D3',
    stroke: '#B7B7B7',
  },
  blockedArea: {
    fill: 'gray',
    textColor: 'white',
    deleteButtonColor: 'red',
    deleteButtonTextColor: 'white',
  }
};

class PositionManager {
  constructor(stageSize, gridWidth, gridHeight, mesas, blocked) {
    this.stageSize = stageSize;
    this.gridWidth = gridWidth;
    this.gridHeight = gridHeight;
    this.mesas = mesas;
    this.blocked = blocked;
  }

  snapToGrid(x, y) {
    const snappedX = Math.max(0, Math.min(this.stageSize.width / this.gridWidth - 1, Math.round(x / this.gridWidth)));
    const snappedY = Math.max(0, Math.min(this.stageSize.height / this.gridHeight - 1, Math.round(y / this.gridHeight)));
    return { x: snappedX, y: snappedY };
  }

  getOccupiedPoints() {
    const occupied = [];

    // Agregar puntos ocupados por las mesas (3x3)
    this.mesas.forEach((mesa) => {
      for (let dx = 0; dx < 3; dx++) {
        for (let dy = 0; dy < 3; dy++) {
          occupied.push({ x: mesa.x + dx, y: mesa.y + dy });
        }
      }

      // Agregar puntos ocupados por las sillas visibles
      mesa.sillas.forEach((silla) => {
        if (silla.visible) {
          occupied.push({ x: silla.x, y: silla.y });
        }
      });
    });

    // Agregar puntos ocupados por bloques bloqueados
    this.blocked.forEach((block) => {
      for (let dx = 0; dx < block.width; dx++) {
        for (let dy = 0; dy < block.height; dy++) {
          occupied.push({ x: block.x + dx, y: block.y + dy });
        }
      }
    });

    return occupied;
  }

  isCollision(pos, id, sillas) {
    // Verifica colisión con otras mesas y sus sillas
    const mesaCollision = this.mesas.some((mesa) => {
      const mesaYSillas = [
        {
          x: mesa.x,
          y: mesa.y,
          width: 3, // Anchura de la mesa
          height: 3 // Altura de la mesa
        },
        // Agrega las sillas visibles de la mesa
        ...mesa.sillas.filter(silla => silla.visible).map(silla => ({
          x: silla.x,
          y: silla.y,
          width: 1, // Anchura de la silla
          height: 1 // Altura de la silla
        }))
      ];

      // Verifica si alguna parte de la mesa o sus sillas colisiona con la nueva posición
      return mesaYSillas.some(item => (
        item.x < pos.x + 3 &&
        item.x + item.width > pos.x &&
        item.y < pos.y + 3 &&
        item.y + item.height > pos.y &&
        mesa.id !== id // Ignora la mesa actual en movimiento
      ));
    });

    // Verifica colisión con bloques bloqueados
    const blockedCollision = this.blocked.some((block) => {
      // Verifica si el área bloqueada se solapa con la nueva posición
      return (
        block.x < pos.x + 3 && // El área bloqueada empieza antes del final de la posición + 3 en X
        block.x + block.width > pos.x && // El área bloqueada termina después del inicio de la posición en X
        block.y < pos.y + 3 && // El área bloqueada empieza antes del final de la posición + 3 en Y
        block.y + block.height > pos.y // El área bloqueada termina después del inicio de la posición en Y
      );
    });

    // Verifica colisión con otras sillas y elementos bloqueados
    const sillaCollision = sillas.some((silla) => {
      return (
        silla.visible && (
          mesaCollision || // Colisiona con alguna mesa
          blockedCollision || // Colisiona con algún bloque bloqueado
          this.mesas.some((mesa) =>
            mesa.sillas.some((tSilla) =>
              tSilla.id !== silla.id && // Ignora la silla actual en movimiento
              tSilla.visible &&
              tSilla.x === silla.x && // Colisiona con otra silla en la misma posición X
              tSilla.y === silla.y // Colisiona con otra silla en la misma posición Y
            )
          ) ||
          this.blocked.some((block) =>
            block.x <= silla.x && // Colisiona con un bloque en la misma posición X
            block.x + block.width > silla.x &&
            block.y <= silla.y && // Colisiona con un bloque en la misma posición Y
            block.y + block.height > silla.y
          )
        )
      );
    });

    // Devuelve verdadero si hay colisión con mesas, bloques o sillas
    return mesaCollision || blockedCollision || sillaCollision;
  }

  findNextAvailablePosition(pos, sillas) {
    const directions = [
      { x: 0, y: -1 }, // Arriba
      { x: 1, y: 0 }, // Derecha
      { x: 0, y: 1 }, // Abajo
      { x: -1, y: 0 }, // Izquierda
      { x: 1, y: 1 }, // Abajo-Derecha
      { x: -1, y: -1 }, // Arriba-Izquierda
      { x: 1, y: -1 }, // Arriba-Derecha
      { x: -1, y: 1 } // Abajo-Izquierda
    ];

    for (let i = 0; i < directions.length; i++) {
      const newX = pos.x + directions[i].x;
      const newY = pos.y + directions[i].y;
      const newPos = { x: newX, y: newY };

      // Verifica si la nueva posición está libre de colisiones
      if (!this.isCollision(newPos, null, sillas)) {
        return newPos; // Retorna la nueva posición si no hay colisión
      }
    }

    return pos; // Retorna la posición original si no se encuentra una posición disponible
  }

  calculateSnappedPosition(x, y, id = null, sillas = []) {
    let newPos = this.snapToGrid(x, y);
    if (this.isCollision(newPos, id, sillas)) {
      newPos = this.findNextAvailablePosition(newPos, sillas);
    }
    return newPos;
  }
}

class ChairManager {
  constructor(gridWidth, gridHeight) {
    this.gridWidth = gridWidth;
    this.gridHeight = gridHeight;
    this.relativePositions = [
      { x: -1, y: 0 },
      { x: -1, y: 1 },
      { x: -1, y: 2 },
      { x: 0, y: -1 },
      { x: 1, y: -1 },
      { x: 2, y: -1 },
      { x: 3, y: 0 },
      { x: 3, y: 1 },
      { x: 3, y: 2 },
      { x: 0, y: 3 },
      { x: 1, y: 3 },
      { x: 2, y: 3 },
    ];

    this.relativePositions_tipo_1 = [
      { x: -1.5, y: -0.5 },
      { x: -1.5, y: 0.5 },
      { x: -0.5, y: 1.5 },
      { x: -0.5, y: -1.5 },
      { x: 0.5, y: -1.5 },
      { x: 1.5, y: -0.5 },
      { x: 1.5, y: 0.5 },
      { x: 0.5, y: 1.5 },
      
    ];
  }

  generateInitialSillas(x, y, tipo_mesa) {

    if(tipo_mesa === 1){
      return this.relativePositions_tipo_1.map((pos, index) => ({
        id: index + 1,
        x: -2,
        y: y + pos.y,
        width: this.gridWidth,
        height: this.gridHeight,
        visible: true,
      }));
    }


    return this.relativePositions.map((pos, index) => ({
      id: index + 1,
      x: x + pos.x,
      y: y + pos.y,
      width: this.gridWidth,
      height: this.gridHeight,
      visible: true,
    }));
  }

  moveSillas(x, y, sillas, tipo_mesa) {

    if(tipo_mesa === 1){
      return sillas.map((silla, index) => ({
        ...silla,
        x: x + this.relativePositions_tipo_1[index].x,
        y: y + this.relativePositions_tipo_1[index].y,
      }));
    }

    return sillas.map((silla, index) => ({
      ...silla,
      x: x + this.relativePositions[index].x,
      y: y + this.relativePositions[index].y,
    }));
  }

  toggleSillaVisibility(sillas, sillaIndex) {
    // console.log("sillas[sillaIndex]", sillas[sillaIndex])
    // console.log("sillas[sillaIndex]", sillaIndex)
    // console.log("sillas[sillaIndex]", sillas)
    sillas[sillaIndex].disponible = !sillas[sillaIndex].disponible//!sillas[sillaIndex].disponible//false
    sillas[sillaIndex].visible = !sillas[sillaIndex].disponible//!sillas[sillaIndex].visible
    return [...sillas]
  }

  showHiddenSilla(sillas) {
    const hiddenSillaIndex = sillas.findIndex(silla => !silla.visible);
    if (hiddenSillaIndex !== -1) {
      sillas[hiddenSillaIndex].visible = true;
    }
    return sillas;
  }

  getDeleteButtonPosition(x, y, width) {
    return {
      x: x + width,
      y: y
    };
  }
}

class GridManager {
  constructor(gridWidth, gridHeight) {
    this.gridWidth = gridWidth;
    this.gridHeight = gridHeight;
  }

  toGridUnits(x, y) {
    return {
      x: Math.round(x / this.gridWidth),
      y: Math.round(y / this.gridHeight),
    };
  }

  toPixels(x, y) {
    return {
      x: x * this.gridWidth,
      y: y * this.gridHeight,
    };
  }

  snapRectToGrid(x, y, width, height) {
    return {
      x: Math.round(x / this.gridWidth),
      y: Math.round(y / this.gridHeight),
      width: Math.round(width / this.gridWidth),
      height: Math.round(height / this.gridHeight),
    };
  }
}

const Grid = ({ width, height, gridWidth, gridHeight, blocked, onResize, onDragEnd, onDeleteBlocked, updateBlockedAreaPosition, theme }) => {
  const lines = [];

  for (let i = 0; i <= width / gridWidth; i++) {
    lines.push(
      <Line
        key={`v-${i}`}
        points={[i * gridWidth, 0, i * gridWidth, height]}
        stroke="#ECECEC"
        strokeWidth={1}
      />
    );
  }

  for (let i = 0; i <= height / gridHeight; i++) {
    lines.push(
      <Line
        key={`h-${i}`}
        points={[0, i * gridHeight, width, i * gridHeight]}
        stroke="#ECECEC"
        strokeWidth={1}
      />
    );
  }

  return (
    <Layer>
      {lines}
      {blocked.map((block, index) => (
        <BlockedArea
          updateBlockedAreaPosition={updateBlockedAreaPosition}
          theme={theme}
          key={index}
          block={block}
          index={index}
          gridWidth={gridWidth}
          gridHeight={gridHeight}
          onResize={onResize}
          onDragEnd={onDragEnd}
          onDeleteBlocked={onDeleteBlocked}
        />
      ))}
    </Layer>
  );
};



const Mesa = ({ mesaIndex, id, x, y, containerWidth, width, height, categoria, onClick, positionManager, chairManager, onDelete, theme, updateMesaPosition, updateSillasState, mesa }) => {


  const mesaRef = useRef();
  const textRef = useRef();
  const [sillas, setSillas] = useState(mesa.sillas ?? chairManager.generateInitialSillas(x, y, mesa.tipo_mesa));
  const [deleteButtonPos, setDeleteButtonPos] = useState(chairManager.getDeleteButtonPosition(x, y, width));
  const [mesaNumberPos, setMesaNumberPos] = useState({
    x: (x + width / 2) * positionManager.gridWidth - 5,
    y: (y + height / 2) * positionManager.gridHeight - 5
  });

  useEffect(() => {
    if (Array.isArray(mesa.sillas)) {
      setSillas(chairManager.moveSillas(mesa.x, mesa.y, mesa.sillas, mesa.tipo_mesa));
    }
  }, [mesa.sillas, mesa.x, mesa.y, mesa.tipo_mesa]);


  let mesaPadding = (containerWidth * 7) / 500
  let mesaCornerRadius = 10; // Redondeo de la mesa

  let sillaPadding = (containerWidth * 3) / 800; // Padding para la silla
  let sillaCornerRadius = 4; // Redondeo de la silla

  let deleteButtonRadius = 10; // Tamaño del botón de eliminar
  
  if(mesa.tipo_mesa === 1){
    mesaPadding = (100 * 7) / 500;
    mesaCornerRadius = 5
  }

  useEffect(() => {
    setDeleteButtonPos(chairManager.getDeleteButtonPosition(x, y, width));
    setMesaNumberPos({
      x: (x + width / 2) * positionManager.gridWidth - 5,
      y: (y + height / 2) * positionManager.gridHeight - 5
    });
  }, [x, y, width]);

  const handleDragMove = (e) => {
    const newPos = positionManager.calculateSnappedPosition(e.target.x() - mesaPadding, e.target.y() - mesaPadding, id, sillas);
    const snappedPos = new GridManager(positionManager.gridWidth, positionManager.gridHeight).toPixels(newPos.x, newPos.y);

    e.target.position({ x: snappedPos.x + mesaPadding, y: snappedPos.y + mesaPadding });
    setSillas(chairManager.moveSillas(newPos.x, newPos.y, sillas, mesa.tipo_mesa));
    setDeleteButtonPos(chairManager.getDeleteButtonPosition(newPos.x, newPos.y, width));
    setMesaNumberPos({
      x: (newPos.x + width / 2) * positionManager.gridWidth - 5,
      y: (newPos.y + height / 2) * positionManager.gridHeight - 5
    });
  };

  const handleDragEnd = (e) => {
    const newPosition = positionManager.calculateSnappedPosition(e.target.x() - mesaPadding, e.target.y() - mesaPadding, id, sillas);
    const snappedPos = new GridManager(positionManager.gridWidth, positionManager.gridHeight).toPixels(newPosition.x, newPosition.y);

    mesaRef.current.to({
      x: snappedPos.x + mesaPadding,
      y: snappedPos.y + mesaPadding,
      duration: 0.2,
      onFinish: () => {
        onClick(id, newPosition.x, newPosition.y);
        setSillas(chairManager.moveSillas(newPosition.x, newPosition.y, sillas, mesa.tipo_mesa));
        setDeleteButtonPos(chairManager.getDeleteButtonPosition(newPosition.x, newPosition.y, width));
        setMesaNumberPos({
          x: (newPosition.x + width / 2) * positionManager.gridWidth - 5,
          y: (newPosition.y + height / 2) * positionManager.gridHeight - 5
        });
        updateMesaPosition(id, newPosition.x, newPosition.y); // Actualiza la posición en el estado
      }
    });

    textRef.current.to({
      x: (newPosition.x + width / 2) * positionManager.gridWidth - 5 + mesaPadding,
      y: (newPosition.y + height / 2) * positionManager.gridHeight - 5 + mesaPadding,
      duration: 0.2
    });
  };

  const handleSillaClick = (sillaId) => {
    let newSillas = chairManager.toggleSillaVisibility(sillas, sillaId)
    setSillas(newSillas);
    updateSillasState(mesaIndex, newSillas)
  };

  const handleMesaClick = () => {
    let newSillas = chairManager.showHiddenSilla(sillas)
    setSillas(newSillas);
    updateSillasState(mesaIndex, newSillas)
  };

  // console.log('categoria', categoria)
  const mesaColor = categoria ? categoria.color : '#F2F2F2';
  const sillaColor = categoria ? categoria.color : '#D3D3D3';
  const strokeColor = categoria ? categoria.color : '#B7B7B7';

  return (
    <React.Fragment>
      <Rect
        ref={mesaRef}
        x={x * positionManager.gridWidth + mesaPadding}
        y={y * positionManager.gridHeight + mesaPadding}
        width={width * positionManager.gridWidth - mesaPadding * 2}
        height={height * positionManager.gridHeight - mesaPadding * 2}
        fill={mesaColor}
        stroke={strokeColor}
        strokeWidth={3}
        draggable
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        cornerRadius={mesaCornerRadius}
        onClick={handleMesaClick}
      />
      <Text
        ref={textRef}
        x={mesaNumberPos.x + mesaPadding - 4}
        y={mesaNumberPos.y + mesaPadding - 4}
        text={id.toString()}
        fontSize={15}
        fill={theme.table.textColor}
        onClick={handleMesaClick}
        listening={false}
      />
      {sillas.map((silla, index) => (
        (
          <Rect
            opacity={silla.visible ? 1: 0.4}
            key={silla.id}
            x={silla.x * positionManager.gridWidth + sillaPadding}
            y={silla.y * positionManager.gridHeight + sillaPadding}
            width={silla.width - sillaPadding * 2}
            height={silla.height - sillaPadding * 2}
            fill={sillaColor}
            stroke={strokeColor}
            strokeWidth={2}
            cornerRadius={sillaCornerRadius}
            onClick={() => handleSillaClick(index)}
          />
        )
      ))}
      <Circle
        x={deleteButtonPos.x * positionManager.gridWidth}
        y={deleteButtonPos.y * positionManager.gridHeight}
        radius={deleteButtonRadius}
        fill={theme.table.deleteButtonColor}
        onClick={() => onDelete(id)}
        style={{ cursor: 'pointer' }}
      />
      <CloseOutlined />
      <Text
        text="X"
        fontSize={12}
        fill={theme.table.deleteButtonTextColor}
        x={deleteButtonPos.x * positionManager.gridWidth - deleteButtonRadius / 2}
        y={deleteButtonPos.y * positionManager.gridHeight - deleteButtonRadius / 2}
        onClick={() => onDelete(id)}
        style={{ cursor: 'pointer' }}
      />
    </React.Fragment>
  );
};

const BlockedArea = ({ block, index, gridWidth, gridHeight, onResize, onDragEnd, onDeleteBlocked, theme, updateBlockedAreaPosition }) => {
  const [isSelected, setIsSelected] = useState(false);
  const gridManager = new GridManager(gridWidth, gridHeight);
  const rectRef = useRef(null);
  const transformerRef = useRef(null);

  useEffect(() => {
    if (isSelected && rectRef.current && transformerRef.current) {
      transformerRef.current.nodes([rectRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Rect
        ref={rectRef}
        x={gridManager.toPixels(block.x, block.y).x}
        y={gridManager.toPixels(block.x, block.y).y}
        width={block.width * gridWidth}
        height={block.height * gridHeight}
        fill={theme.blockedArea.fill}
        draggable
        onClick={() => setIsSelected(!isSelected)}
        onDragEnd={(e) => {
          const node = e.target;
          const newAttrs = gridManager.snapRectToGrid(
            node.x(),
            node.y(),
            node.width(),
            node.height()
          );
          node.position(gridManager.toPixels(newAttrs.x, newAttrs.y));
          onDragEnd(index, newAttrs);
          updateBlockedAreaPosition(index, newAttrs); // Actualiza la posición en el estado
        }}
        onTransform={(e) => {
          const node = e.target;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          const snappedWidth = Math.round(node.width() * scaleX / gridWidth) * gridWidth;
          const snappedHeight = Math.round(node.height() * scaleY / gridHeight) * gridHeight;

          node.width(snappedWidth);
          node.height(snappedHeight);

          node.scaleX(1);
          node.scaleY(1);
        }}
        onTransformEnd={(e) => {
          const node = e.target;
          const newAttrs = gridManager.snapRectToGrid(
            node.x(),
            node.y(),
            node.width(),
            node.height()
          );
          node.position(gridManager.toPixels(newAttrs.x, newAttrs.y));
          node.size({ width: newAttrs.width * gridWidth, height: newAttrs.height * gridHeight });
          onResize(index, newAttrs);
          updateBlockedAreaPosition(index, newAttrs); // Actualiza la posición en el estado
        }}
      />
      <Text
        x={gridManager.toPixels(block.x, block.y).x + 5}
        y={gridManager.toPixels(block.x, block.y).y + 5}
        text={block.text}
        fontSize={16}
        fill={theme.blockedArea.textColor}
      />
      {isSelected && <Transformer ref={transformerRef} />}
      <Circle
        x={gridManager.toPixels(block.x, block.y).x + block.width * gridWidth - 10}
        y={gridManager.toPixels(block.x, block.y).y - 10}
        radius={10}
        fill={theme.blockedArea.deleteButtonColor}
        onClick={() => onDeleteBlocked(index)}
        style={{ cursor: 'pointer' }}
      />
      <Text
        text="X"
        fontSize={12}
        fill={theme.blockedArea.deleteButtonTextColor}
        x={gridManager.toPixels(block.x, block.y).x + block.width * gridWidth - 15}
        y={gridManager.toPixels(block.x, block.y).y - 15}
        onClick={() => onDeleteBlocked(index)}
        style={{ cursor: 'pointer' }}
      />
    </>
  );
};

const TableEditor = forwardRef(({ value, width = 1000, height = 500, columns = 33, rows = 42, theme = defaultTheme, onChangeCategoria = () => {} }, ref) => {
  height = (rows * width) / columns;
  const containerRef = useRef(null);
  const [mesas, setMesas] = useState([]);
  const [stageSize, setStageSize] = useState({ width, height });
  const [gridWidth, setGridWidth] = useState(width / columns);
  const [gridHeight, setGridHeight] = useState(height / rows);
  const [blocked, setBlocked] = useState([{ x: 4, y: 4, width: 2, height: 2, text: 'Stage' }]);
  const [editingTag, setEditingTag] = useState(null);
  const [categories, setCategories] = useState([
    { id: 1, name: 'Default', mesaColor: '#F2F2F2', sillaColor: '#D3D3D3', stroke: '#B7B7B7' },
    { id: 2, name: 'VIP', mesaColor: '#FFD700', sillaColor: '#FFA500', stroke: '#FF8C00' },
    { id: 3, name: 'Standard', mesaColor: '#87CEEB', sillaColor: '#4682B4', stroke: '#1E90FF' },
  ]);
  const [tipoMesa, setTipoMesa] = useState(0);

  const stageRef = useRef();

  //SCALING STAGE
  //const [scale, setScale] = useState(1);

  //useEffect(() => {
    // let tempWidth = width;
    // let tempHeight = height;
    // if (containerRef.current.offsetWidth < width && containerRef.current.offsetWidth > 1000 ) {tempWidth = containerRef?.current?.offsetWidth ?? width;};
    // if (containerRef.current.offsetWidth < width && containerRef.current.offsetHeight > 500 ) {tempHeight = containerRef?.current?.offsetHeight ?? height;};
    // setStageSize({ width: tempWidth, height: tempHeight });
    // console.log("scale", (containerRef.current.offsetWidth / width))
    // if (containerRef.current.offsetWidth < width) setScale(containerRef.current.offsetWidth / width);
    // else setScale(1);
    //setScale(1);
  //},[width, height]);



  const rebuildStage = useCallback(() => {
    // let tempWidth = width;
    // let tempHeight = height;
    // if (containerRef.current.offsetWidth < width && containerRef.current.offsetWidth > 1000 ) {tempWidth = containerRef?.current?.offsetWidth ?? width;};
    // if (containerRef.current.offsetWidth < width && containerRef.current.offsetHeight > 500 ) {tempHeight = containerRef?.current?.offsetHeight ?? height;};
    // if (containerRef.current.offsetWidth < width) setScale(containerRef.current.offsetWidth / width);
    // else setScale(1);

    setGridWidth(width / columns);
    setGridHeight(height / rows);
    // setStageSize({ width: tempWidth, height: tempHeight });
    setStageSize({ width, height });
  }, [width, height, columns, rows]);

  useEffect(() => {
    const handleResize = () => {
      rebuildStage();
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [rebuildStage]);

  useEffect(() => {
    if (value && value != null) {
      if (Array.isArray(value.mesas)){
        setMesas(value.mesas)
      }

      if (Array.isArray(value.blocked))
        setBlocked(value.blocked)

    }
  }, [value]);

  const addMesa = ({x, y, width, height, category = categories[0], tipo_mesa = 0}) => {
    const positionManager = new PositionManager(stageSize, gridWidth, gridHeight, mesas, blocked);
    const chairManager = new ChairManager(gridWidth, gridHeight);
    const newMesa = {
      id: mesas.length + 1,
      ...positionManager.calculateSnappedPosition(x, y, null, chairManager.generateInitialSillas(x, y, tipo_mesa)),
      width,
      height,
      category,
      tipo_mesa,
      sillas: chairManager.generateInitialSillas(x, y, tipo_mesa)
    };

    setMesas([...mesas, newMesa]);
  };

  const handleDelete = (id) => {
    let updatedMesas = mesas.filter((mesa) => mesa.id !== id);

    // Reasigna los IDs de las mesas restantes basados en su nueva posición en el array
    updatedMesas = updatedMesas.map((mesa, index) => ({
      ...mesa,
      sillas: mesa.sillas,
      id: (index + 1).toString()
    }));

    setMesas(updatedMesas);
  };

  const handleStageClick = (e) => {
    if (e.target === e.target.getStage()) {
      const { x, y } = e.target.getPointerPosition();
      if(tipoMesa === 1)
        addMesa({x, y, width: 1, height:1, tipo_mesa: tipoMesa});
      else
        addMesa({x, y, width: 3, height:3});
    }
  };

  const addBlockedArea = () => {
    setBlocked([...blocked, { x: 1, y: 1, width: 2, height: 2, text: `Bloqueado ${blocked.length + 1}` }]);
  };

  const updateBlockedArea = (index, newAttrs) => {
    const updatedBlocked = blocked.slice();
    updatedBlocked[index] = { ...updatedBlocked[index], ...newAttrs };
    setBlocked(updatedBlocked);
  };

  const handleResize = (index, newAttrs) => {
    updateBlockedArea(index, newAttrs);
  };

  const handleDragEnd = (index, newAttrs) => {
    updateBlockedArea(index, newAttrs);
  };

  const removeBlockedArea = (index) => {
    const updatedBlocked = blocked.slice();
    updatedBlocked.splice(index, 1);
    setBlocked(updatedBlocked);
  };

  const handleTagChange = (index, value) => {
    const updatedBlocked = blocked.slice();
    updatedBlocked[index].text = value;
    setBlocked(updatedBlocked);
  };

  const handleTagEdit = (index) => {
    setEditingTag(index);
  };

  const handleTagBlur = () => {
    setEditingTag(null);
  };

  const handleDeleteBlocked = (index) => {
    removeBlockedArea(index);
  };


  const updateMesaPosition = (id, x, y) => {
    const newMesas = mesas.map(mesa => {
      if (mesa.id === id) {
        return { ...mesa, x, y };
      }
      return mesa;
    });
    setMesas(newMesas);
  };

  const updateSillasState = (id, sillas) => {
    const newMesas = mesas.map((mesa, index) => {
      if (index === id) {
        return { ...mesa, sillas };
      }
      return mesa;
    })
    setMesas(newMesas);
  };

  const updateBlockedAreaPosition = (index, newAttrs) => {
    const newBlocked = blocked.map((block, i) => {
      if (i === index) {
        return { ...block, ...newAttrs };
      }
      return block;
    });
    setBlocked(newBlocked);
  };

  useImperativeHandle(ref, () => ({
    setValues: (newMesas, newBlocked) => {
      setMesas(newMesas);
      setBlocked(newBlocked);
    },
    getValues: () => {
      return { mesas, blocked };
    }
  }));

  return (
    <div ref={containerRef}>
      <Row style={{ width: "100%", gap: "10px", marginBottom: "1em", justifyContent: "space-between" }}>
        <Row style={{ gap: "10px" }}>
          {blocked.map((block, index) => (
            <Tag key={index} closable onClose={() => removeBlockedArea(index)}>
              {editingTag === index ? (
                <Input
                  size="small"
                  value={block.text}
                  onChange={(e) => handleTagChange(index, e.target.value)}

                  onBlur={handleTagBlur}
                  autoFocus
                />
              ) : (
                <span onDoubleClick={() => handleTagEdit(index)}>
                  {block.text}
                </span>
              )}
            </Tag>
          ))}
        </Row>
        <Button type="primary" size='small' onClick={addBlockedArea}>
          Agregar Área Bloqueada
        </Button>
      </Row>
      <Divider style={{}} />
      <Row style={{ width: "100%", gap: "10px", marginBottom: "1em", justifyContent: "space-between" }}>
        <Row gutter={[16]}className="width-100 ">
          <Col span={20} className="">
            <Space wrap>
              {mesas.map((mesa, index) => (
                <Popover
                  content={
                    <SelectCategoriasBoletos
                      onChange={(_id, item) => {
                        const mesasX = mesas
                        mesasX[index].categoria = item
                        setMesas([...mesasX])
                        if(onChangeCategoria){
                          onChangeCategoria(item)
                        }
                      }}
                    />
                  }
                  title="Seleccionar Categoría"
                  trigger="click"
                  key={index}
                >
                  <Tag closable onClose={() => handleDelete(index)}>
                    <span onDoubleClick={() => handleTagEdit(index)}>
                      Mesa {mesa.id} - {mesa?.categoria?.nombre}
                    </span>
                  </Tag>
                </Popover>
              ))}
            </Space>
          </Col>
          <Col span={4} className="flex-right " >
            <Space direction="vertical" align="end">
              <Button type="primary" size='small' onClick={() => {
                  if(tipoMesa === 1)
                    addMesa({x: 10, y: 10, width: 1, height: 1, tipo_mesa: tipoMesa})
                  else
                    addMesa({x: 1, y: 1, width: 3, height: 3, tipo_mesa: tipoMesa})
                }}>
                Agregar una Mesa
              </Button>
              <Select 
                style={{minWidth: "150px"}}
                defaultValue={tipoMesa}
                onSelect={(value)=>setTipoMesa(value)}
                options={[
                  {
                    value: 0,
                    label: "Mesa Normal",
                  },
                  {
                    value: 1,
                    label: 'Periquera',
                  },
                ]}
              />
            </Space>
          </Col>
        </Row>
      </Row>
      <Divider />
      <div style={{ width: '100%' }} className="center">
        {/* <Stage width={stageSize.width} height={stageSize.height} ref={stageRef} onClick={handleStageClick} scale={{ x: scale, y: scale }}> */}
        <Stage width={stageSize.width} height={stageSize.height} ref={stageRef} onClick={handleStageClick}>

          <Grid
            theme={theme}
            // width={stageSize.width * (1 + scale)}
            // height={stageSize.height * (1 + scale)}
            width={stageSize.width}
            height={stageSize.height}
            gridWidth={gridWidth}
            gridHeight={gridHeight}
            blocked={blocked}
            onResize={handleResize}
            onDragEnd={handleDragEnd}
            onDeleteBlocked={handleDeleteBlocked}
            updateBlockedAreaPosition={updateBlockedAreaPosition}
          />
          <Layer>
            {mesas.map((mesa, mesaIndex) => (
              <Mesa
                theme={theme}
                mesaIndex={mesaIndex}
                key={mesa.id}
                id={mesa.id}
                x={mesa.x}
                y={mesa.y}
                width={mesa.width}
                height={mesa.height}
                mesa={mesa}
                containerWidth={width}
                color={mesa.color}
                categoria={mesa.categoria}
                onClick={() => { }}
                positionManager={new PositionManager(
                  stageSize,
                  gridWidth,
                  gridHeight,
                  mesas,
                  blocked
                )}
                chairManager={new ChairManager(gridWidth, gridHeight)}
                onDelete={handleDelete}
                updateMesaPosition={updateMesaPosition}
                updateSillasState={updateSillasState}

              />
            ))}
          </Layer>
        </Stage>
      </div>
    </div>
  );
});
export default TableEditor;
