import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from "axios"

const { Option } = Select 

/**
 * @const Selectcategorias
 * @description Select para los categorias registrados en el sistema
 */
const SelectCategorias = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione la categoría", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        allowClear = true, 
        onClear = () => {},
        mode = null,
        labelInValue,
        landing = false,
        size,
    } = props

    const [ categorias, setCategorias ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getcategorias
     * @description Obitiene los usaurios
     */
    const getcategorias = ({
        page, 
        limit, 
        search, 
    } = categorias) => {

        axios.get('/categorias', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setCategorias(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los categorias')
        })
    }

    const getcategoriasPublic = ({
        page, 
        limit, 
        search, 
    } = categorias) => {

        axios.get('/public/categorias', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setCategorias(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los categorias')
        })
    }

    //componentDidMount
    useEffect(() => {

        if(landing) {
            getcategoriasPublic()
            return
        }

        getcategorias()

    }, [])


    return (
        <Select
            size={size}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search)=> getcategorias({search})}
            onSelect={(cliente)=> {
                if(mode === null && !labelInValue){
                    onChange(cliente.value)
                    onSelect(cliente.value)
                }
                if(mode === null && labelInValue){
                    onChange(cliente)
                    onSelect(cliente)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple" && !labelInValue){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }

                if(mode === "multiple" && labelInValue){
                    onChange(values)
                    onSelect(values)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                categorias?.data?.map(({ _id, nombre,  }) => <Option value={_id} key={_id}>{nombre ?? ""} </Option>)
            }
        </Select>
    );
}



export default SelectCategorias;