import React, { Component, useContext, forwardRef } from "react";
import {
  Row,
  Col,
  Upload,
  Form,
  Button,
  message,
  Space,
  Input,
  Mentions,
  Image,
  Popover,
} from "antd";
import axios from "axios";
import EmojiPicker from "emoji-picker-react";
import { SendOutlined } from "@ant-design/icons";
import { PaperClipOutlined, SmileOutlined } from "@ant-design/icons";

import FilesBox from "./FilesView";
import MessagesList from "./MessageList";
import { User } from "../../../../Hooks/Logged";
import SocketContext from "../../../../Hooks/Socket";
import { getResponseError } from "../../../Utils";

/**
 * @class Chat
 * @extends {Component}
 */
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      cliente_id: this.props.cliente_id,
      comment: "",
      mensajes: {
        data: [],
        page: 1,
        pages: 1,
        limit: 20,
        total: 0,
      },
      templates: {
        items: [],
        count: 2,
        offset: 1,
        limit: 50,
        totalCount: 20,
      },
      filesUploaded: [],
      fileList: [],
      template_selected: {},
    };
  }

  formRef = React.createRef();
  messagesListRef = React.createRef();

  componentDidMount() {
    this.getTemplates();
    if (this.props.cliente_id) {
      this.startConversation();
      this.props.socket.emit(
        "/admin/messagebird/chat/join",
        this.props.cliente_id
      );
    }

    this.props.socket.on("/mb/new-message", this.addMessage);
    this.props.socket.on("mb_successful", () => console.log("joined"));
  }

  componentWillUnmount() {
    this.props.socket.emit(
      "/admin/messagebird/chat/leave",
      this.props.cliente_id
    );

    // Desuscribirse de eventos del socket
    this.props.socket.off("/mb/new-message", this.addMessage);
    this.props.socket.off("mb_successful");
  }

  /**
   * @methodOf  Chat
   * @method addMessage
   *
   * @description recibimos la informacion de un mensaje para añadirlo a la lista de mensajes
   * */
  addMessage = (data) => {
    console.log("addMessage", data);

    this.setState(
      (prevState) => {
        // Comprueba si el mensaje ya existe en el array
        const mensajeExistente = prevState.mensajes?.data?.some(
          (mensaje) => mensaje.id === data.id
        );

        // Si el mensaje no existe, agrégalo al array
        if (!mensajeExistente) {
          return {
            mensajes: {
              ...prevState.mensajes,
              data: [...prevState.mensajes?.data, data],
            },
          };
        }

        // Si el mensaje ya existe, no hagas nada
        return null;
      },
      () => this.messagesListRef.current.scrollToBottom()
    );
  };

  /**
   * @methodOf  Chat
   * @method startConversation
   *
   * @description Si el cliente no tiene no tiene una conversacion inciada, la inicia, para despues obetner los mensajes
   * nuevos o anteriores segun sea el caso
   * */
  startConversation = () => {
    this.setState({ loading: true });
    axios
      .post("/mb/messages/start", {
        cliente_id: this.props.cliente_id,
        evento_id: this.props.evento_id,
      })
      .then(({ data }) => {
        console.log("startConversation", data);
        this.setState(
          {
            mensajes: {
              ...this.state.mensajes,
              data: data.items?.reverse(),
              total: data.totalCount,
            },
          },
          () => this.messagesListRef.current.scrollToBottom()
        );
      })
      .catch((error) => {
        console.log("error", error);
        message.info(getResponseError(error.response));
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @methodOf  Chat
   * @method getMessages
   *
   * @description Obtiene la lista de mensajes enviados en la conversacion
   * */
  getMessages = ({ page = this.state.mensajes.page } = this.state.mensajes) => {
    if (this.state.mensajes.total <= this.state.mensajes.data.length) return;

    this.setState({ loading: true });
    axios
      .get("/mb/messages", {
        params: {
          cliente_id: this.props.cliente_id,
          page: page,
        },
      })
      .then(({ data }) => {
        console.log("getMessages", data);
        this.setState({
          mensajes: {
            ...this.state.mensajes,
            page,
            data: [...data.items?.reverse(), ...this.state.mensajes?.data],
            total: data.totalCount,
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  getTemplates = () => {
    this.setState({ loading_templates: true });
    axios
      .get("/mb/templates", {})
      .then(({ data }) => {
        console.log("getTemplates", data);
        this.setState((state) => {
          state.templates = data;
          state.templates.data = data.items.map((t) => {
            return { key: t.id, value: t.name, label: t.name };
          });
          return state;
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => this.setState({ loading_templates: false }));
  };

  selectTemplate = (e) => {
    let templates = this.state.templates.items;

    let selected = templates.find((t) => t.id == e.key);

    this.setState({ template_selected: selected });
  };
  /**
   * @methodOf  Chat
   * @method sendMessage
   *
   * @description Envia un mensaje al cliente para despues devolverlo y agregarlo a la lista de mensajes
   * */
  sendMessage = (values) => {
    this.setState({ loading: true });
    axios
      .post("/mb/messages/reply", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        this.addMessage(response.data);
        this.formRef.current.setFieldsValue({
          text: "",
          adjuntos: null,
        });
        this.setState({ fileList: [], template_selected: {} });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @methodOf  Chat
   * @method submit
   *
   * @description Se ejecuta la hecer click en el boton de enviar
   * */
  submit = (values) => {
    try {
      const formData = new FormData();

      formData.appendMultiple({
        ...values,
        cliente_id: this.props.cliente_id,
        adjuntos: undefined,
        template: undefined,
      });

      if (this.state.fileList) {
        // Validar que los adjuntos solo puedan ser imágenes o PDFs
        const validMimeTypes = [
          "image/png",
          "image/jpeg",
          "image/gif",
          "image/webp",
          "image/svg+xml",
          "image/jpg",
          "application/pdf",
        ];

        this.state.fileList?.forEach((img) => {
          if (
            img instanceof File ||
            (img instanceof Blob && validMimeTypes.includes(img.type))
          ) {
            formData.append("adjuntos", img, img.name);
          } else {
            console.error("El adjunto no es un archivo válido:", img);
            message.error("Formato de archivo no válido");
            return;
          }
        });
      }

      if (Object.keys(this.state.template_selected).length > 0) {
        let t = JSON.stringify(this.state.template_selected);
        formData.append("template", t);
      }
      if (
        (values.text && values.text != "") ||
        this.state.fileList?.length > 0
      ) {
        this.sendMessage(formData);
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  /**
   * @methodOf  Chat
   * @method normFile
   *
   * @description Retorna el filelist al subir un archivo
   * */
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    e.file.status = "done";
    return e?.fileList;
  };

  uploadFile = (file) => {
    this.setState({ fileList: [file.file] });
  };

  deleteFile = (file, index) => {
    this.setState({ fileList: [] });
  };

  selectEmoji = (emoji) => {
    console.log("emoji", emoji);
    let text = this.formRef.current.getFieldValue("text");
    console.log("text", text);
    if (!text) text = "";

    text += emoji.emoji;

    this.formRef.current.setFieldsValue({ text });
  };

  render() {
    console.log("state", this.state);
    return (
      <>
        <div className="container-chat width-100 ">
          <div className="message-box">
            <MessagesList
              ref={this.messagesListRef}
              data={this.state.mensajes.data}
              loading={this.state.loading}
              className={this.props.className}
              cliente_id={this.props.cliente_id}
              loadMore={this.loadMore}
              push={this.state.push}
              deleteMessage={this.deleteMessage}
              getMessages={() =>
                this.getMessages({ page: this.state.mensajes.page + 1 })
              }
            />
          </div>
          <div className="files-box">
            <FilesBox
              files={this.state.fileList}
              deleteFile={this.deleteFile}
              id={this.props.cliente_id}
              edit={true}
            />
          </div>
          <div className="bottom-content width-100">
            <Form
              ref={this.formRef}
              onFinish={this.submit}
              className="form-comment"
              layout="inline"
            >
              <Form.Item name="_id" noStyle>
                <Input type="hidden" />
              </Form.Item>
              <Row className="width-100">
                <Col className="center" span={2}>
                  <Popover
                    content={
                      <EmojiPicker
                        onEmojiClick={(emoji) => this.selectEmoji(emoji)}
                      />
                    }
                    trigger="click"
                    className="popover-emoji"
                  >
                    <SmileOutlined style={{ fontSize: "18px" }} />
                  </Popover>
                </Col>
                <Col className="center" span={16}>
                  <Form.Item
                    name="text"
                    style={{ width: "100%", maxWidth: "90%" }}
                    className="m-0"
                  >
                    <Mentions
                      onSelect={(e) => this.selectTemplate(e)}
                      loading={this.state.loading_templates}
                      options={this.state.templates.data}
                      className="width-100"
                      placeholder="Escribe un mensaje"
                    />
                  </Form.Item>
                </Col>
                <Col className="center" span={6}>
                  <Space className="width-100" align="center">
                    <Form.Item
                      noStyle={true}
                      className="m-0"
                      name="adjuntos"
                      valuePropName="fileList"
                      getValueFromEvent={this.normFile}
                    >
                      <Upload
                        customRequest={this.uploadFile}
                        maxCount={1}
                        showUploadList={false}
                      >
                        <Button
                          icon={
                            <PaperClipOutlined
                              style={{ color: "black", fontSize: "18px" }}
                            />
                          }
                          type="ghost"
                        />
                      </Upload>
                    </Form.Item>
                    <Form.Item className="m-0" noStyle={true}>
                      <Button
                        loading={this.state.loading}
                        type="ghost"
                        htmlType="submit"
                      >
                        <SendOutlined
                          style={{ color: "black", fontSize: "18px" }}
                        />
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 s*/
const Acts = forwardRef((props, ref) => (
  <Chat
    {...props}
    ref={ref}
    user={useContext(User)}
    socket={useContext(SocketContext)}
  />
));

export default Acts;
