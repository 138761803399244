import React from "react";
import { Layout, Row, Col, Typography, Space } from "antd";
import { useNavigate, Link } from "react-router-dom";

import "../../../Styles/Modules/Landing/footer.scss";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitchOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

const { Footer } = Layout;
const { Text } = Typography;

const FooterPublic = () => {
  const navigate = useNavigate();

  return (
    <Footer className="footer-public">
      <Row gutter={[16, 16]}>
        <Col lg={12} xs={24}>
          <Row justify={"center"}>
            <Space direction="horizontal">
              <Text>¡Síguenos!</Text>
              <a href="https://www.instagram.com/vandarimusichall/" target="_blank"><InstagramOutlined className="text-white" /></a>
              <a href="https://twitter.com/vandarimusic" target="_blank"><TwitterOutlined className="text-white" /></a>
              <a href="https://www.facebook.com/AstatlRecordsOficial" target="_blank"><FacebookOutlined className="text-white" /></a>
              <a href="https://www.youtube.com/channel/UCUiHb9RMB31kmjwptCqigZQ" target="_blank"><YoutubeOutlined className="text-white" /></a>
            </Space>
          </Row>
          <Row  justify={"center"} className="mt-2">
            <img src="/logo.png" alt="Logo" className="logo" />
          </Row>
        </Col>
        <Col lg={12} xs={24}>
          <Row gutter={[8, 8]}>
            <Col lg={8} md={12} sm={24}>
              <Row className="mb-1">
                <Text strong>Enlaces rápidos</Text>
              </Row>
              <Row>
                <Link to="/eventos">
                  <Text>Explorar por categorías</Text>
                </Link>
              </Row>
              <Row>
                <Link to="/eventos">
                  <Text>Eventos por venir</Text>
                </Link>
              </Row>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Row className="mb-1">
                <Text strong>Compañia</Text>
              </Row>
              <Row>
                <Link to="/eventos">
                  <Text>Eventos</Text>
                </Link>
              </Row>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Row className="mb-1">
                <Text strong>Soporte</Text>
              </Row>
              {/* <Row>
                <Link to="/faq">
                  <Text>FAQ</Text>
                </Link>
              </Row> */}
              <Row>
                <Link to="/aviso-privacidad">
                  <Text>Aviso de Privacidad</Text>
                </Link>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row align={"end"} className="mt-2">
        <Text>Vandari © 2024. Todos los derechos reservados.</Text>
      </Row>
    </Footer>
  );
};

export default FooterPublic;
