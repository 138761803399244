import React from 'react'
import { Route, Routes } from "react-router-dom";

import PlantillaEventos from '../../Components/Admin/PlantillasEventos/PlantillaEventos';
import PlantillaEvento from '../../Components/Admin/PlantillasEventos/PlantillaEvento';

/**
 * 
 * @export
 * @function RouterVentas
 * @description Router for Eventos routes 
 */
function RouterVentas(props) {
  return (
    <Routes>
      <Route path="" element={<PlantillaEventos {...props} />} />
      <Route path="/crear" element={<PlantillaEvento {...props} />} />
      <Route path="/editar/:plantilla_evento_id" element={<PlantillaEvento {...props} />} />
    </Routes>
  )
}

export default RouterVentas