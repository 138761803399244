
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { User, SetUser } from './Hooks/Logged';
import axios from 'axios'
import Routes from './Routes';
import { ConfigProvider } from 'antd';


axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
axios.defaults.withCredentials = true
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.response.use(function (response) {
  if (response?.headers?.authorization) {
    sessionStorage.setItem("token", response?.headers?.authorization)
  }
  return response
}, function (error) {
  return Promise.reject(error);
})

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = sessionStorage.getItem("token")
  return config;
})



/**
 * 
 * @class App
 * @extends {Component}
 */
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: 0,
    }
  }


  componentDidMount() {
    axios.get('/user/logged', {
      headers: { Authorization: sessionStorage.getItem('token') }
    }).then(({ data }) => {
      this.setUser(data.data)
    }).catch((error) => {
      console.log('error', error.response)
    })
  }

  setUser = (user) => this.setState({ user })


  render() {
    const { setUser } = this;
    const { user } = this.state;
    return (
      <ConfigProvider
        theme={{
          cssVar:true,
          token: {
            colorPrimary: '#7F63F4',
          },
          components:{
            Layout:{
              bodyBg:'#F3F6F9'
            },
            Card:{
              colorBorderSecondary:'transparent',
              padding:12,
              paddingLG:16,
            }
          }
        }}
      >
        <BrowserRouter>
          <User.Provider value={user}>
            <SetUser.Provider value={setUser}>
              <Routes />
            </SetUser.Provider>
          </User.Provider>
        </BrowserRouter>
      </ConfigProvider>
    )
  }
}

export default function (props) {

  return <App {...props} />

}