import React from 'react'
import { Route, Routes } from "react-router-dom";

import CRM from '../../Components/Admin/CRM/Clientes';
import ClientesList from '../../Components/Admin/CRM/ClientesList';
import DetalleCliente from '../../Components/Admin/CRM/DetalleCliente/DetalleCliente';

/**
 * 
 * @export
 * @function RouterClientes
 * @description Router for Prospectos routes 
 */
function RouterClientes(props) {
  return (
    <Routes>
      <Route path="" element={<CRM {...props} />} />
      <Route path="/clientes" element={<ClientesList {...props} />} />
      <Route path="/cliente/:cliente_id" element={<DetalleCliente {...props} />} />
    </Routes>
  )
}

export default RouterClientes