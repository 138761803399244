import React, { useState, useRef, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Rect, Text, Circle, Layer, Stage, Line, Transformer } from 'react-konva';
import { CloseOutlined } from '@ant-design/icons';
import Konva from 'konva';
import { Button, Tag, Input, Row, Divider, Popover, Select } from 'antd';

import SelectCategoriasBoletos from '../../Widgets/Inputs/SelectCategoriasBoletos';

import "../../../Styles/Modules/Admin/seating.scss";

const { Option } = Select;

function shadeColor(color, percent) {
    // Remove the '#' character if it exists
    color = color.replace(/^#/, '');

    // Parse the r, g, b values
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);

    // Calculate the new r, g, b values
    r = Math.round(r * (100 + percent) / 100);
    g = Math.round(g * (100 + percent) / 100);
    b = Math.round(b * (100 + percent) / 100);

    // Ensure the new values are within the range [0, 255]
    r = (r < 255) ? r : 255;
    g = (g < 255) ? g : 255;
    b = (b < 255) ? b : 255;

    // Convert the new r, g, b values back to HEX format
    r = ("0" + r.toString(16)).slice(-2);
    g = ("0" + g.toString(16)).slice(-2);
    b = ("0" + b.toString(16)).slice(-2);

    return `#${r}${g}${b}`;
}

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

function getContrastYIQ(hexColor, tipo_mesa, marcar_sentado) {

  if(marcar_sentado) return "black"

  if(tipo_mesa === 1) return "white"

  // Convertir el color hexadecimal a valores RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calcular el brillo usando la fórmula de luminancia relativa
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  // Retornar 'black' para colores claros y 'white' para colores oscuros
  return (yiq >= 128) ? 'black' : 'white';
}

const defaultTheme = {
  table: {
    fill: '#F2F2F2',
    stroke: '#B7B7B7',
    textColor: 'white',
    deleteButtonColor: 'red',
    deleteButtonTextColor: 'white',
  },
  chair: {
    fill: '#D3D3D3',
    stroke: '#B7B7B7',
  },
  blockedArea: {
    fill: 'gray',
    textColor: 'white',
    deleteButtonColor: 'red',
    deleteButtonTextColor: 'white',
  }
};

class PositionManager {
  constructor(stageSize, gridWidth, gridHeight, mesas, blocked) {
    this.stageSize = stageSize;
    this.gridWidth = gridWidth;
    this.gridHeight = gridHeight;
    this.mesas = mesas;
    this.blocked = blocked;
  }

  snapToGrid(x, y) {
    const snappedX = Math.max(0, Math.min(this.stageSize.width / this.gridWidth - 1, Math.round(x / this.gridWidth)));
    const snappedY = Math.max(0, Math.min(this.stageSize.height / this.gridHeight - 1, Math.round(y / this.gridHeight)));
    return { x: snappedX, y: snappedY };
  }

  getOccupiedPoints() {
    const occupied = [];

    // Agregar puntos ocupados por las mesas (3x3)
    this.mesas.forEach((mesa) => {
      for (let dx = 0; dx < 3; dx++) {
        for (let dy = 0; dy < 3; dy++) {
          occupied.push({ x: mesa.x + dx, y: mesa.y + dy });
        }
      }

      // Agregar puntos ocupados por las sillas visibles
      mesa.sillas.forEach((silla) => {
        if (silla.visible) {
          occupied.push({ x: silla.x, y: silla.y });
        }
      });
    });

    // Agregar puntos ocupados por bloques bloqueados
    this.blocked.forEach((block) => {
      for (let dx = 0; dx < block.width; dx++) {
        for (let dy = 0; dy < block.height; dy++) {
          occupied.push({ x: block.x + dx, y: block.y + dy });
        }
      }
    });

    return occupied;
  }

  isCollision(pos, id, sillas) {
    // Verifica colisión con otras mesas y sus sillas
    const mesaCollision = this.mesas.some((mesa) => {
      const mesaYSillas = [
        {
          x: mesa.x,
          y: mesa.y,
          width: 3, // Anchura de la mesa
          height: 3 // Altura de la mesa
        },
        // Agrega las sillas visibles de la mesa
        ...mesa.sillas.filter(silla => silla.visible).map(silla => ({
          x: silla.x,
          y: silla.y,
          width: 1, // Anchura de la silla
          height: 1 // Altura de la silla
        }))
      ];

      // Verifica si alguna parte de la mesa o sus sillas colisiona con la nueva posición
      return mesaYSillas.some(item => (
        item.x < pos.x + 3 &&
        item.x + item.width > pos.x &&
        item.y < pos.y + 3 &&
        item.y + item.height > pos.y &&
        mesa.id !== id // Ignora la mesa actual en movimiento
      ));
    });

    // Verifica colisión con bloques bloqueados
    const blockedCollision = this.blocked.some((block) => {
      // Verifica si el área bloqueada se solapa con la nueva posición
      return (
        block.x < pos.x + 3 && // El área bloqueada empieza antes del final de la posición + 3 en X
        block.x + block.width > pos.x && // El área bloqueada termina después del inicio de la posición en X
        block.y < pos.y + 3 && // El área bloqueada empieza antes del final de la posición + 3 en Y
        block.y + block.height > pos.y // El área bloqueada termina después del inicio de la posición en Y
      );
    });

    // Verifica colisión con otras sillas y elementos bloqueados
    const sillaCollision = sillas.some((silla) => {
      return (
        silla.visible && (
          mesaCollision || // Colisiona con alguna mesa
          blockedCollision || // Colisiona con algún bloque bloqueado
          this.mesas.some((mesa) =>
            mesa.sillas.some((tSilla) =>
              tSilla.id !== silla.id && // Ignora la silla actual en movimiento
              tSilla.visible &&
              tSilla.x === silla.x && // Colisiona con otra silla en la misma posición X
              tSilla.y === silla.y // Colisiona con otra silla en la misma posición Y
            )
          ) ||
          this.blocked.some((block) =>
            block.x <= silla.x && // Colisiona con un bloque en la misma posición X
            block.x + block.width > silla.x &&
            block.y <= silla.y && // Colisiona con un bloque en la misma posición Y
            block.y + block.height > silla.y
          )
        )
      );
    });

    // Devuelve verdadero si hay colisión con mesas, bloques o sillas
    return mesaCollision || blockedCollision || sillaCollision;
  }

  findNextAvailablePosition(pos, sillas) {
    const directions = [
      { x: 0, y: -1 }, // Arriba
      { x: 1, y: 0 }, // Derecha
      { x: 0, y: 1 }, // Abajo
      { x: -1, y: 0 }, // Izquierda
      { x: 1, y: 1 }, // Abajo-Derecha
      { x: -1, y: -1 }, // Arriba-Izquierda
      { x: 1, y: -1 }, // Arriba-Derecha
      { x: -1, y: 1 } // Abajo-Izquierda
    ];

    for (let i = 0; i < directions.length; i++) {
      const newX = pos.x + directions[i].x;
      const newY = pos.y + directions[i].y;
      const newPos = { x: newX, y: newY };

      // Verifica si la nueva posición está libre de colisiones
      if (!this.isCollision(newPos, null, sillas)) {
        return newPos; // Retorna la nueva posición si no hay colisión
      }
    }

    return pos; // Retorna la posición original si no se encuentra una posición disponible
  }

  calculateSnappedPosition(x, y, id = null, sillas = []) {
    let newPos = this.snapToGrid(x, y);
    if (this.isCollision(newPos, id, sillas)) {
      newPos = this.findNextAvailablePosition(newPos, sillas);
    }
    return newPos;
  }
}

class ChairManager {
  constructor(gridWidth, gridHeight) {
    this.gridWidth = gridWidth;
    this.gridHeight = gridHeight;
    this.relativePositions = [
      { x: -1, y: 0 },
      { x: -1, y: 1 },
      { x: -1, y: 2 },
      { x: 0, y: -1 },
      { x: 1, y: -1 },
      { x: 2, y: -1 },
      { x: 3, y: 0 },
      { x: 3, y: 1 },
      { x: 3, y: 2 },
      { x: 0, y: 3 },
      { x: 1, y: 3 },
      { x: 2, y: 3 },
    ];

    this.relativePositions_tipo_1 = [
      { x: -1.5, y: -0.5 },
      { x: -1.5, y: 0.5 },
      { x: -0.5, y: 1.5 },
      { x: -0.5, y: -1.5 },
      { x: 0.5, y: -1.5 },
      { x: 1.5, y: -0.5 },
      { x: 1.5, y: 0.5 },
      { x: 0.5, y: 1.5 },
    ];
  }

 generateInitialSillas(x, y, tipo_mesa) {

    if(tipo_mesa === 1){
      return this.relativePositions_tipo_1.map((pos, index) => ({
        id: index + 1,
        x: -2,
        y: y + pos.y,
        width: this.gridWidth,
        height: this.gridHeight,
        visible: true,
      }));
    }


    return this.relativePositions.map((pos, index) => ({
      id: index + 1,
      x: x + pos.x,
      y: y + pos.y,
      width: this.gridWidth,
      height: this.gridHeight,
      visible: true,
    }));
  }

  moveSillas(x, y, sillas, tipo_mesa) {

    if(tipo_mesa === 1){
      return sillas.map((silla, index) => ({
        ...silla,
        x: x + this.relativePositions_tipo_1[index].x,
        y: y + this.relativePositions_tipo_1[index].y,
      }));
    }

    return sillas.map((silla, index) => ({
      ...silla,
      x: x + this.relativePositions[index].x,
      y: y + this.relativePositions[index].y,
    }));
  }

  toggleSillaVisibility(sillas, sillaId) {
    return sillas.map(silla => (
      silla.id === sillaId ? { ...silla, visible: !silla.visible } : silla
    ));
  }

  showHiddenSilla(sillas) {
    const hiddenSillaIndex = sillas.findIndex(silla => !silla.visible);
    if (hiddenSillaIndex !== -1) {
      sillas[hiddenSillaIndex].visible = true;
    }
    return sillas;
  }

  getDeleteButtonPosition(x, y, width) {
    return {
      x: x + width,
      y: y
    };
  }
}

class GridManager {
  constructor(gridWidth, gridHeight) {
    this.gridWidth = gridWidth;
    this.gridHeight = gridHeight;
  }

  toGridUnits(x, y) {
    return {
      x: Math.round(x / this.gridWidth),
      y: Math.round(y / this.gridHeight),
    };
  }

  toPixels(x, y) {
    return {
      x: x * this.gridWidth,
      y: y * this.gridHeight,
    };
  }

  snapRectToGrid(x, y, width, height) {
    return {
      x: Math.round(x / this.gridWidth),
      y: Math.round(y / this.gridHeight),
      width: Math.round(width / this.gridWidth),
      height: Math.round(height / this.gridHeight),
    };
  }
}

const Grid = ({ width, height, gridWidth, gridHeight, blocked, onResize, onDragEnd, onDeleteBlocked, updateBlockedAreaPosition, theme }) => {
  const lines = [];

  for (let i = 0; i <= width / gridWidth; i++) {
    lines.push(
      <Line
        key={`v-${i}`}
        points={[i * gridWidth, 0, i * gridWidth, height]}
        stroke="#ECECEC"
        strokeWidth={1}
      />
    );
  }

  for (let i = 0; i <= height / gridHeight; i++) {
    lines.push(
      <Line
        key={`h-${i}`}
        points={[0, i * gridHeight, width, i * gridHeight]}
        stroke="#ECECEC"
        strokeWidth={1}
      />
    );
  }

  return (
    <Layer>
      {/* {lines} */}
      {blocked.map((block, index) => (
        <BlockedArea
        updateBlockedAreaPosition={updateBlockedAreaPosition}
          theme={theme}
          key={index}
          block={block}
          index={index}
          gridWidth={gridWidth}
          gridHeight={gridHeight}
          onResize={onResize}
          onDragEnd={onDragEnd}
          onDeleteBlocked={onDeleteBlocked}
        />
      ))}
    </Layer>
  );
};

const Mesa = (props) => {

	let { _id, id, x, y, containerWidth, width, height, categoria, onClick, positionManager, chairManager, onDelete, theme, updateMesaPosition, mesa, selectedSeats } = props	

  	const mesaRef = useRef();
  	const textRef = useRef();
  	const [sillas, setSillas] = useState(props.sillas ?? []);
  	const [mesaNumberPos, setMesaNumberPos] = useState({
    	x: (x + width / 2) * positionManager.gridWidth - 5,
    	y: (y + height / 2) * positionManager.gridHeight - 5
  	});

    //Actualiza la posicion de las sillas, al cambiar la seccion
  	useEffect(()=> {
	    if (Array.isArray(props.sillas))
	     	setSillas(chairManager.moveSillas(mesa.x + 0.1, mesa.y +0.1, props.sillas, mesa.tipo_mesa));
	  }, [props.sillas])

    //Actualiza la posicion de los textos
    useEffect(() => {
      setMesaNumberPos({
          x: (x + width / 2) * positionManager.gridWidth - 5,
          y: (y + height / 2) * positionManager.gridHeight - 5
      });
    }, [x, y, width]);
  
  	let mesaPadding = (containerWidth * 7) / 500
  	let mesaCornerRadius = 10; // Redondeo de la mesa

  	let sillaPadding = (containerWidth * 3) / 800; // Padding para la silla
  	let sillaCornerRadius = 4; // Redondeo de la silla

  	if(mesa.tipo_mesa === 1){
      mesaPadding = (100 * 7) / 500;
      mesaCornerRadius = 5
    }

    const [tooltip, setTooltip] = useState({
      visible: false,
      x: 0,
      y: 0,
      text: ''
    });

    /**
     * se ejecuta al hacer click en un silla, pasa los valores al padre
     * */
  	const handleSillaClick = (silla) => {

      if(props.marcar_sentado){
        props.handleSillaClick({ silla_id: silla._id, mesa_id: mesa._id, seccion_id: mesa.seccion_id })
        return
      }
  		  
      if(!mesa?.categoria || !mesa?.categoria?._id) return

  		if((mesa.apartada_por) && mesa.apartada_por !== props.carrito_id) return;

  		if(!silla.disponible) return;

  		if(props.limit <= Object.keys(selectedSeats).length && !selectedSeats[silla._id]) return

  		props.handleSillaClick({ silla_id: silla._id, mesa_id: mesa._id, seccion_id: mesa.seccion_id })
  	};

    /**
     * Retorna el color de la silla, dependiendo su categoria o si esta apartada
     * */
  	const getSillaColor = (silla) => {

      if(props.marcar_sentado && selectedSeats[silla._id]){
        return "red";
      }


  		if(!silla.disponible){
  			return "gray"
  		}else if(selectedSeats[silla._id]){
        if(props.marcar_sentado) return "red"
  			return hexToRgba(categoria?.color ?? '#F2F2F2', 0.5)
  		}else if (silla.apartada && mesa.apartada_por !== props.carrito_id){
  			return "gray"
  		}else{
  			return  categoria?.color ?? "#91eb79"
  		}
  	}

    /**
     * Retorna el color de la mesa, dependiendo su categoria o si esta apartada
     * */
  	const getMesaColor = () => {
  		if(mesa.apartada_por){
  			return hexToRgba(categoria.color ?? '#F2F2F2', 0.5)
  		}else{
  			return categoria?.color ?? '#F2F2F2'
  		}
  	}

    /**
     * Cuando el mouse entra en el area de una silla, activa el tooltip
     * */
    const handleSillaMouseEnter = (silla, e) => {
      const stage = e.target.getStage();
      const mousePos = stage.getPointerPosition();
      setTooltip({
        visible: true,
        x: mousePos.x,
        y: mousePos.y - 20, 
        text: `Silla ${silla?.id} - $ ${mesa?.categoria?.precio_asiento?.toMoney(true) ?? "-"} MXN`
      });
    };

    /**
     * Cuando el mouse sale en el area de una silla, desactiva el tooltip
     * */
    const handleSillaMouseLeave = () => {
      setTooltip({
        visible: false,
        x: 0,
        y: 0,
        text: ''
      });
    };

    const setSillasPositionX = (x_cord) => {
      let silla_x = x_cord * positionManager.gridWidth + sillaPadding

      let limit_x = x * positionManager.gridWidth + mesaPadding + 25

      if(silla_x > (limit_x) + 5) silla_x -=4 
      else if(silla_x < limit_x - 20) silla_x +=4 
      return silla_x
    }

    const setSillasPositionY = (y_cord) => {
      let silla_y = y_cord * positionManager.gridHeight + sillaPadding

      let limit_y = y * positionManager.gridWidth + mesaPadding + 25

      if(silla_y > (limit_y) + 5) silla_y -=4 
      else if(silla_y < limit_y - 20) silla_y +=4 
      return silla_y
    }

  	return (
    	<React.Fragment>
      		<Rect //MESA
		        ref={mesaRef}
		        x={x * positionManager.gridWidth + mesaPadding + 2}
		        y={y * positionManager.gridHeight + mesaPadding + 2}
		        width={width * positionManager.gridWidth - mesaPadding * 2}
		        height={height * positionManager.gridHeight - mesaPadding * 2}
		        fill={getMesaColor()}
		        stroke={getMesaColor()}
		        strokeWidth={3}
		        cornerRadius={mesaCornerRadius}
		      />
      		<Text
		        x={mesaNumberPos.x - ( mesa.tipo_mesa === 1 ? 15 : 18)}
		        y={mesaNumberPos.y - ( mesa.tipo_mesa === 1 ? 50 : 10)}
		        text={`Mesa: ${id.toString()}`}
		        fontSize={12}
		        fill={getContrastYIQ(getMesaColor(), mesa.tipo_mesa, props.marcar_sentado ?? props.venta_admin)}
            //fontStyle="bold"
            fontFamily="Poppins"
		      />
          <Text
            x={mesaNumberPos.x - ( mesa.tipo_mesa === 1 ? 10 : 15)}
            y={mesaNumberPos.y + ( mesa.tipo_mesa === 1 ? 60 : 10) }
            text={`$${mesa.categoria?.precio_asiento?.toMoney() ?? "-"}`}
            fontSize={12}
            fill={getContrastYIQ(getMesaColor(), mesa.tipo_mesa, props.marcar_sentado ?? props.venta_admin)}
            //fontStyle="bold"
            fontFamily="Poppins"
          />
          
		    {sillas.map((silla) => (
		        silla.visible && (
              <>
		          	<Rect
			            key={silla.id}
			            x={setSillasPositionX(silla.x)}
			            y={setSillasPositionY(silla.y)}
			            width={silla.width - sillaPadding * 2}
			            height={silla.height - sillaPadding * 2}
			            fill={getSillaColor(silla)}
			            stroke={getSillaColor(silla)}
			            strokeWidth={2}
			            cornerRadius={sillaCornerRadius}
			            onClick={() => handleSillaClick(silla)}

                  onMouseEnter={(e) => handleSillaMouseEnter(silla, e)}
                  onMouseLeave={handleSillaMouseLeave}
		          	/>
                { props.marcar_sentado ?? props.venta_admin ? <Text
                  x={setSillasPositionX(silla.x) + 3}
                  y={setSillasPositionY(silla.y) + 3}
                  text={`${silla.id}`}
                  fontSize={12}
                  listening={false}
                  fill={getContrastYIQ(getMesaColor(), mesa.tipo_mesa)}
                  fontFamily="Poppins"
                /> : null }
              </>
		        )
		     ))}
        {tooltip.visible && (
          <React.Fragment>
            <Rect
              x={tooltip.x - (tooltip.text.length * 5) - 5}
              y={tooltip.y - 25} // Ajustar para que esté encima del texto
              width={tooltip.text.length * 8} // Ancho dinámico
              height={20}
              fill="#7f63f4"
              cornerRadius={5}
              stroke="#310f71"
            />
            <Text
              x={tooltip.x - (tooltip.text.length * 5)}
              y={tooltip.y - 20} // Ajustar para que esté encima del rectángulo
              text={tooltip.text}
              fontSize={14}
              fill="white"
              fontStyle="bold"
              fontFamily="Poppins"
            />
          </React.Fragment>
        )}
    	</React.Fragment>
  	);
};

const BlockedArea = ({ block, index, gridWidth, gridHeight, theme }) => {

  	const gridManager = new GridManager(gridWidth, gridHeight);
  	return (
    	<>
      		<Rect
        		x={gridManager.toPixels(block.x, block.y).x}
        		y={gridManager.toPixels(block.x, block.y).y}
        		width={block.width * gridWidth}
        		height={block.height * gridHeight}
        		fill={theme.blockedArea.fill}
      		/>
      		<Text
        		x={gridManager.toPixels(block.x, block.y).x + 5}
        		y={gridManager.toPixels(block.x, block.y).y + 5}
        		text={block.text}
        		fontSize={16}
        		fill={theme.blockedArea.textColor}
      		/>
    	</>
  	);
};

const TableEditor = forwardRef((props, ref) => {

	let { 
		value, 
		width = 1000, 
		height = 500, 
		columns = 33, 
		rows = 42, 
		theme = defaultTheme,
	} = props;

 	height = (rows * width) / columns;

  const [mesas, setMesas] = useState([]);
  const [stageSize, setStageSize] = useState({ width, height });
  const [gridWidth, setGridWidth] = useState(width / columns);
  const [gridHeight, setGridHeight] = useState(height / rows);
  const [blocked, setBlocked] = useState([]); //Area bloqueada
	const [selectedSeats ,setSelectedSeats] = useState({}) //Objeto con las sillas seleccionadas	

  const stageRefContainer = useRef();
  const [scale, setScale] = useState(1);

  const rebuildStage = useCallback(() => {
    setGridWidth(width / columns);
    setGridHeight(height / rows);
    setStageSize({ width, height });
  }, [width, height, columns, rows]);

  /*useEffect(() => {
    const handleResize = () => {
      rebuildStage();
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [rebuildStage]);*/


	//carga las mesas y las areas bloquedas en el mapa
	useEffect(() => {
  	if (value && value != null) {
    		if (Array.isArray(value.mesas)){
      		setMesas(value.mesas)
    		}
    		if (Array.isArray(value.blocked))
      		setBlocked(value.blocked)
  	}
	}, [value]);

	//carga las mesas y las areas bloquedas en el mapa
	useEffect(() => {
		setSelectedSeats(props.selectedSeats?.reduce((acc, item) => {
  	  acc[item.silla_id] = item;
  	  return acc;
  	}, {}))
	}, [props.selectedSeats]);

  const fitStageIntoParentContainer = () => {
    if (stageRefContainer.current) {
      const containerWidth = stageRefContainer.current.offsetWidth;

      // Limitar el ancho máximo a 1000 píxeles
      const effectiveWidth = Math.min(containerWidth, 1000);

       // Calcula la nueva escala en base al ancho del contenedor
      const newScale = effectiveWidth / 1000;

      setScale(newScale);

      setStageSize({
        width: 1000 * newScale,
        height: (rows * effectiveWidth) / columns,
      });
    }
  };

  useEffect(() => {
    fitStageIntoParentContainer();
    window.addEventListener('resize', fitStageIntoParentContainer);
    return () => window.removeEventListener('resize', fitStageIntoParentContainer);
  }, []);

	/**
	 * guarda la silla seleccionada en el state y lo manda la info a la clase padre
	 * */
	const handleSillaClick = (values) => {

    if(props.interactive === false) return; 
    
    if(props.marcar_sentado){
      if (selectedSeats[values.silla_id]) {
          props.deleteSeat(values)
      } else {
          props.addSeat(values)
      }
      return;
    }

  	setSelectedSeats(prevSelectedSeats => {
    	const sillas_seleccionadas = { ...prevSelectedSeats };
    	if (sillas_seleccionadas[values.silla_id]) {
      		delete sillas_seleccionadas[values.silla_id];
      		props.deleteSeat(values)
    	} else {
      		sillas_seleccionadas[values.silla_id] = values;
      		props.addSeat(values)
    	}
    	return sillas_seleccionadas;
  	});
  };

  const handleStageClick = (e) => {
  	//console.log("e", e);
  };

  const handleDelete = (id) => {
    const updatedMesas = mesas.filter((mesa) => mesa.id !== id);
    setMesas(updatedMesas);
  };

  const addBlockedArea = () => {
    setBlocked([...blocked, { x: 1, y: 1, width: 2, height: 2, text: `Blocked ${blocked.length + 1}` }]);
  };

  const updateBlockedArea = (index, newAttrs) => {
    const updatedBlocked = blocked.slice();
    updatedBlocked[index] = { ...updatedBlocked[index], ...newAttrs };
    setBlocked(updatedBlocked);
  };

  const handleResize = (index, newAttrs) => {
    updateBlockedArea(index, newAttrs);
  };

  const handleDragEnd = (index, newAttrs) => {
    updateBlockedArea(index, newAttrs);
  };

  const removeBlockedArea = (index) => {
    const updatedBlocked = blocked.slice();
    updatedBlocked.splice(index, 1);
    setBlocked(updatedBlocked);
  };

  const handleTagChange = (index, value) => {
    const updatedBlocked = blocked.slice();
    updatedBlocked[index].text = value;
    setBlocked(updatedBlocked);
  };

  const handleDeleteBlocked = (index) => {
    removeBlockedArea(index);
  };

  const updateMesaPosition = (id, x, y) => {
    /*const newMesas = mesas.map(mesa => {
      if (mesa.id === id) {
        return { ...mesa, x, y };
      }
      return mesa;
    });
    setMesas(newMesas);*/
  };

  const updateBlockedAreaPosition = (index, newAttrs) => {
    const newBlocked = blocked.map((block, i) => {
      if (i === index) {
        return { ...block, ...newAttrs };
      }
      return block;
    });
    setBlocked(newBlocked);
  };

	useImperativeHandle(ref, () => ({
  	setValues: (newMesas, newBlocked) => {
    		setMesas(newMesas);
    		setBlocked(newBlocked);
  	},
  	getValues: () => {
    		return { mesas, blocked };
  	},
  	clearSeats: () => {
  		setSelectedSeats({})
  	}
	}));

  	return (
    	
    	<div ref={stageRefContainer} style={{ width: '100%', border: "solid 1px lightgray", borderRadius: "4px" }} className="hover">
      	<Stage width={stageSize.width} height={stageSize.height} onClick={handleStageClick} scaleX={scale} scaleY={scale}>
        		<Grid
          		theme={theme}
          		width={stageSize.width}
          		height={stageSize.height}
          		gridWidth={gridWidth}
          		gridHeight={gridHeight}
          		blocked={blocked}
          		onResize={handleResize}
          		onDragEnd={handleDragEnd}
          		onDeleteBlocked={handleDeleteBlocked}
          		updateBlockedAreaPosition={updateBlockedAreaPosition}
        		/>
        		<Layer>
          		{mesas.map((mesa, mesaIndex) => {
            			return <Mesa
		                key={mesa.id}
		                id={mesa.id}
		                x={mesa.x}
		                y={mesa.y}
		                width={mesa.width}
		                height={mesa.height}
		                mesaIndex={mesaIndex}
		                mesa={mesa}
		                theme={theme}
		                color={mesa.color}
		                sillas={mesa.sillas}
		                categoria={mesa.categoria}
            				carrito_id={props.carrito_id}
                    marcar_sentado={props.marcar_sentado}
                    venta_admin={props.venta_admin}
            				limit={props.limit}
		                containerWidth={width}
		                positionManager={new PositionManager(
		                 	stageSize,
		                  	gridWidth,
		                  	gridHeight,
		                  	mesas,
		                 	blocked
		                )}
		                chairManager={new ChairManager(gridWidth, gridHeight)}
		                handleSillaClick={handleSillaClick}
		                selectedSeats={selectedSeats}
		                updateMesaPosition={updateMesaPosition} // Pasa la función de actualización de posición
            			/>
          		})}
        		</Layer>
      	</Stage>
    	</div>
    
  );
});


export default TableEditor;