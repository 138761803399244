import React, { Component } from "react";
import {
  Row,
  Col,
  InputNumber,
  Form,
  Input,
  message,
  Spin,
  Layout,
  Button,
  Affix,
  Space,
  ColorPicker
} from "antd";

import axios from "axios";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { getResponseError } from "../../Utils";

const { Content } = Layout;
/**
 *
 *
 * @class Categoria
 * @extends {Component}
 */
class Categoria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      categoria: {
        mesas: [],
      },
    };
  }
  back = () => {
    this.props.navigate('/admin/categorias');
}
  formRef = React.createRef();

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");

    if (this.props.params.categoria_id !== undefined) {
      this.get();
    }
  }

  /**
   * @memberof ModalCategorias
   * @method onFinish
   * @description Se ejecuta al dar enter al formulario
   */
  onFinish = (values) => {
    let data = this.formRef?.current?.getFieldsValue();
    data.color = typeof data.color == "object" ? "#"+data.color.toHex() : null;
    if (this.props.params.categoria_id) {
      this.update(data);
    } else {
      this.add(data);
    }
  };

  /**
   * @memberof ModalCategorias
   * @method add
   * @description Añade una categoria a la BD
   */
  add = (values) => {
    this.setState({ loading: true }, () => {
      axios
        .post("/categoria-boleto", { ...values, })
        .then((response) => {
          message.success("Categoría creada");
          this.back()
        })
        .catch((error) => {
          console.log(error);
          message.error("Error al crear categoría.");
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  /**
   * @memberof ModalCategorias
   * @method getArea
   * @description Obtiene una area de la DB
   */
  get = () => {
    this.setState({ loading: true });

    axios
      .get("/categoria-boleto/" + this.props.params.categoria_id)
      .then(({ data }) => {
        console.log("categoria", data);
        this.setState({
          categoria: data,
        });

        this.formRef?.current?.setFieldsValue({
          ...data,
        });
      })
      .catch((error) => {
        message.error(
          getResponseError(error.response, "Error al traer categoría")
        );
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @memberof ModalCategorias
   * @method updateArea
   * @description Actualiza la información de una area
   */
  update = (values) => {
    this.setState({ loading: true });
    axios
      .put("/categoria-boleto", {
        ...values,
        id: this.props.params.categoria_id,
      })
      .then(({ data }) => {
        message.success("Categoría actualizada");
        this.back()

      })
      .catch((error) => {
        message.error("Error al actualizar categoría");
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };
  
  render() {
    return (
      <Spin spinning={this.state.loading}>
        <PageHeader
          className="admin-page-header"
          breadcrumb={{
            items: [
              {
                key: "0",
                title: "Categoría",
                onClick: () => this.props.navigate("/admin/categorias"),
                className: "hover",
              },
              {
                key: "1",
                title: this.props.params.categoria_id
                  ? "Editar Categoría "
                  : "Crear Categoría ",
              },
            ],
          }}
        ></PageHeader>
        <Content className="admin-content content-bg pd-1">
          <Form
            layout="vertical"
            name="form-categorias"
            className="pd-3 bg-white width-100"
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={24}>
                <Form.Item
                  label="Nombre"
                  name="nombre"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese el nombre",
                    },
                  ]}
                >
                  <Input placeholder="Nombre"></Input>
                </Form.Item>
              </Col>
              <Col xs={24} lg={24}>
                <Form.Item
                  label="Descripción"
                  name="descripcion"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese la descripción",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder="Descripción de la categoría"
                  ></Input.TextArea>
                </Form.Item>
              </Col>
              <Col xs={24} lg={24}>
                <Form.Item
                  label="SKU"
                  name="sku"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese el sku",
                    },
                  ]}
                >
                  <Input placeholder="sku"></Input>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Precio Mesa"
                  name="precio_mesa"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese el precio",
                    },
                  ]}
                >
                  <InputNumber
                    className="width-100"
                    min={1}
                    prefix={"$"}
                    placeholder="0.00"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Precio Asiento"
                  name="precio_asiento"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese el precio",
                    },
                  ]}
                >
                  <InputNumber
                    className="width-100"
                    min={1}
                    prefix={"$"}
                    placeholder="0.00"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Color"
                  name="color"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingrese el color",
                    },
                  ]}
                >
                  <ColorPicker
                    disabledAlpha
                    defaultFormat="hex"
                    arrow={false}
                    showText
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Affix className="fixed-buttons" offsetTop={50} style={{ marginTop: '1em' }}>
                <Space>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"

                    loading={this.state.loading}
                  >
                    Guardar
                  </Button>
                </Space>
              </Affix>
            </Row>
            
          </Form>
        </Content>
      </Spin>
    );
  }
}

export default function Vista(props) {
  return <Categoria {...props} params={useParams()} navigate={useNavigate()} />;
}
