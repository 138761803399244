import React, { useState } from "react";
import { Form, Input,  Row, Col, Button, } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";


import "../../Styles/Modules/Landing/buscador.scss"


const BuscadorEventos = () => {
    const navigate = useNavigate();
    const onFinish = (values) => {
        const params = new URLSearchParams();
        params.append("search", values.search);
        navigate(`/eventos?${params.toString()}`)

    };

    return (
        <div className="buscador-eventos">
            <Form
                onFinish={onFinish}
                className="form"
            >
                <Row className="width-100" justify={"center"}>
                    <Col xs={16} md={16} lg={10}>
                        <Form.Item name={"search"}>
                            <Input prefix={<SearchOutlined />} placeholder="Busca un evento" className="text-white"/>
                        </Form.Item>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Buscar
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>
        </div>
    )
}

export default BuscadorEventos