import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import axios from "axios";

const { Option } = Select;

/**
 * @const SelectPlantillas
 * @description Select para los categorias registrados en el sistema
 */
const SelectPlantillas = (props) => {
    const { 
        value, 
        onChange, 
        placeholder = "Seleccione la plantilla", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        allowClear = true, 
        onClear = () => {},
        mode = null,
        labelInValue,
        landing = false
    } = props;

    const [ categorias, setCategorias ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    });

    /**
     * @const getCategorias
     * @description Obtiene las categorías
     */
    const getCategorias = ({
        page, 
        limit, 
        search, 
    } = categorias) => {
        axios.get('/plantillas-eventos', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setCategorias(data);
        }).catch(error => {
            console.log("ERR, error", error);
            message.error(error?.response?.data?.message ?? 'Error al obtener las categorías');
        });
    };

    // componentDidMount
    useEffect(() => {
        getCategorias();
    }, []);

    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue={labelInValue}
            mode={mode}
            onSearch={(search) => getCategorias({ search })}
            onSelect={(cliente) => {
                // El comportamiento específico de onSelect se puede definir aquí
            }}
            onChange={(values) => {
                if (mode === null && !labelInValue) {
                    const selectedItem = categorias.data.find(item => item._id === values);
                    onChange(values, selectedItem);
                } else if (mode === null && labelInValue) {
                    const selectedItem = categorias.data.find(item => item._id === values.value);
                    onChange(values.value, selectedItem);
                } else if (mode !== null) {
                    const selectedItems = values.map(value => {
                        return {
                            key: value,
                            item: categorias.data.find(item => item._id === value)
                        };
                    });
                    onChange(selectedItems.map(si => si.key), selectedItems.map(si => si.item));
                }
            }}
            value={value}
            onClear={() => {
                onClear();
                onChange(undefined, undefined);
                onSelect(undefined, undefined);
            }}
        >
            {
                categorias?.data?.map(({ _id, nombre, ...rest }) => (
                    <Option item={{ ...rest, nombre, _id }} value={_id} key={_id}>
                        {nombre ?? ""}
                    </Option>
                ))
            }
        </Select>
    );
}

export default SelectPlantillas;
