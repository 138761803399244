import React, { Component } from "react";
import { Row, Col, Typography, List, Modal, message, Spin } from "antd";
import axios from "axios";
import dayjs from "dayjs";

// Componentes
import { ventaEstatusTag } from "../Utils";

// scss
import "../../Styles/Modules/CRM/Modal.scss";

const { Text } = Typography;

class ModalVentas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			venta: {
				total: 0,
				subtotal: 0,
				detalles: [],
			},
		};
	}

	componentDidMount() {
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");

		if (this.props.venta_id !== undefined) {
			this.getVenta();
		}
	}

	/**
	 * @memberof ModalVentas
	 * @method getVenta
	 * @description Obtiene una venta de la DB
	 */
	getVenta = () => {
		this.setState({ loading: true });

		axios.get("/customer/ventas/" + this.props.venta_id)
			.then(({ data }) => {
				this.setState({ venta: data });
			})
			.catch((error) => {
				console.log(error?.response);
				message.error(error?.response?.data?.message ?? "Error al traer la venta");
			})
			.finally(() => this.setState({ loading: false }));
	};

	render() {
		const { venta } = this.state;
		return (
			<Spin spinning={this.state.loading}>
				<Row gutter={[16, 16]}>
					<Col span={12} className="flex-left-column">
						<Text strong>Estatus</Text>
						<div>{ventaEstatusTag(venta.estatus)}</div>
					</Col>
					<Col span={12} className="flex-left-column">
						<Text strong>Fecha de Compra</Text>
						<div>{dayjs(venta.fecha).format("DD/MM/YYYY HH:mm")}</div>
					</Col>
					<Col span={14} className="flex-left-column">
			            <Text strong>Folio</Text>
			            <div>{venta.folio}</div>
			        </Col>
					<Col span={24} className="flex-left-column">
						<Text strong>Datos del Evento</Text>
						<div><Text strong className="text-grey" style={{ display: "inline-block", width: "120px" }}>Nombre:</Text> {venta.evento_id?.nombre ?? "-"}</div>
						<div><Text strong className="text-grey" style={{ display: "inline-block", width: "120px" }}>Fecha:</Text> {dayjs(venta.evento_id?.fecha).format("LL")}</div>
						<div><Text strong className="text-grey" style={{ display: "inline-block", width: "120px" }}>Hora de Inicio:</Text> {dayjs(venta.evento_id?.horario).format("LT a")}</div>
					</Col>
					<Col span={24} className="flex-left-column">
						<Text strong>Detalles</Text>
						<List
							itemLayout="horizontal"
							className="resume-list width-100"
							dataSource={venta.detalles}
							header={
								<Row className="component-list-header width-100 pr-1 pl-1">
									<Col span={12} className="center">
										<Text strong>BOLETO</Text>
									</Col>
									<Col span={12} className="center">
										<Text strong>PRECIO</Text>
									</Col>
								</Row>
							}
							renderItem={(item, index) => (
								<List.Item>
									<Row
										className="width-100 pl-1 pr-1"
										align={"middle"}
										justify={"space-between"}
									>
										<Col span={12} className="center">
											<Text>{item.boleto_id?.folio}</Text>
										</Col>
										<Col span={12} className="center">
											<Text>${item.total?.toMoney(true)} <small>MXN</small></Text>
										</Col>
									</Row>
								</List.Item>
							)}
							footer={
								<Row justify={"end"} className="">
									<Col span={24}>
										<Row>
											<Col span={10} className="flex-right">
												<Text className="item-costo">Subtotal</Text>
											</Col>
											<Col span={14} className="center">
												<Text className="item-costo">${venta?.subtotal?.toMoney(true)} <small>MXN</small></Text>
											</Col>
										</Row>
										
										<Row>
											<Col span={10} className="flex-right">
												<Text className="total">Total</Text>
											</Col>
											<Col span={14} className="center">
												<Text className="total">${venta?.total?.toMoney(true)} <small>MXN</small></Text>
											</Col>
										</Row>
									</Col>
								</Row>
							}
						/>
					</Col>
				</Row>
			</Spin>
		);
	}
}

export default function Vista(props) {
	const { open = false, onClose = () => {}, venta_id } = props;

	return (
		<Modal
			open={open}
			onCancel={onClose}
			destroyOnClose={true}
			zIndex={1000}
			title={"Detalle Compra"}
			closable={true}
			cancelText="Cancelar"
			okText="Aceptar"
			onOk={onClose}
		>
			<ModalVentas {...props} />
		</Modal>
	);
}
