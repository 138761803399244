import React, { useState, useEffect } from "react";
import Prospecto from "./Prospecto";
import { Droppable } from "react-beautiful-dnd";

import { CardEventoProspectos } from "../../../Widgets/Cards";

const Column = ({
  col: { list, id, color, evento_id, banner },
  onSelect = () => {},
  onCopy = () => {},
  prospectos_selected = {},
  onPaste = () => {},
  openModal = () => {},
}) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleRightClick = (event) => {
    event.preventDefault();
    setShowSubMenu(true);
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
  };

  const closeSubMenu = () => {
    setShowSubMenu(false);
  };

  return (
    <div style={{ minWidth: "320px" }} className="ml-1">
      <Droppable droppableId={id}>
        {(provided) => (
          <>
            <div
            //onContextMenu={handleRightClick}
            //style={{ cursor: 'context-menu' }}
            >
              <CardEventoProspectos
                titulo={id}
                color={color}
                total={list?.length ?? 0}
                banner={banner}
                evento_id={evento_id}
              />
            </div>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className=" mt-1"
              style={{ minHeight: "100px" }}
            >
              {list.map((prospecto, index) => (
                <Prospecto
                  showMenu={true}
                  key={prospecto._id}
                  item_id={prospecto._id}
                  prospecto={prospecto.cliente}
                  index={index}
                  orden={prospecto.orden}
                  //onClick={()=>onSelect({...prospecto, evento: id})}
                  onCopy={() => onCopy({ ...prospecto, evento: id })}
                  openModal={() => openModal(prospecto.cliente._id, evento_id)}
                  prospecto_selected={prospectos_selected[prospecto._id]}
                  mensajes_nuevos={
                    prospecto.cliente.messagebird_mensajes_contador
                  }
                />
              ))}
              {provided.placeholder}
            </div>
          </>
        )}
      </Droppable>
      {showSubMenu && (
        <SubMenu
          position={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
          onClose={closeSubMenu}
          onPaste={() => onPaste(evento_id)}
        />
      )}
    </div>
  );
};

const SubMenu = ({ position, onClose, onPaste }) => {
  const { top, left } = position;

  const submenuRef = React.useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div ref={submenuRef} className="sub-menu-float" style={{ top, left }}>
      <ul>
        <li
          style={{ cursor: "pointer" }}
          onClick={() => {
            onPaste();
            onClose();
          }}
        >
          Pegar
        </li>
      </ul>
    </div>
  );
};

export default Column;
