import React from "react";
import { Typography, Layout, Row, Col } from "antd";
import FooterPublic from "../Navigation/Footer";
import ScrollToHashElement from "../../Widgets/ScrollToElement";
import Navbar from "../Navigation/Navbar-v2";

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;
const AvisoPrivacidad = () => {
  return (
    <Layout className="layout-landing ">
      <ScrollToHashElement behavior="smooth" inline="center" />
      <Navbar />
      <Content className="mx-auto">
        <Typography>
          <Title className="text-white" level={2}>
            Aviso de Privacidad Clientes Integral
          </Title>
          <Paragraph className="text-white">
            En cumplimiento a la Ley Federal de Protección de Datos Personales
            en Posesión de los Particulares (la{" "}
            <Text strong className="text-white">
              'Ley'
            </Text>
            ), ASTATL RECORDS, S.A. de C.V., protegemos y salvaguardamos sus
            datos personales para evitar el daño, pérdida, destrucción, robo,
            extravío, alteración, así como el tratamiento no autorizado de sus
            datos personales. Informamos a usted, los términos y condiciones del
            Aviso de Privacidad de Datos Personales (
            <Text strong className="text-white">
              'Aviso de Privacidad'
            </Text>
            ) ASTATL RECORDS S.A DE C.V (nombre comercial: VANDARIMUSICHALL) con
            domicilio en Sendero de La Alameda 22, Fracc. Rancho Blanco,
            Jilotzingo, Estado de México, C.P.54570.
          </Paragraph>
          <Title className="text-white" level={3}>
            ¿Cuál es el área responsable del manejo y administración de los
            datos personales?
          </Title>
          <Paragraph className="text-white">
            El área responsable del manejo y la administración de los datos
            personales es: el Departamento de Privacidad, a quien puede
            contactar mediante el correo electrónico
            haidee.rojas@astatlrecords.com. Para atender cualquier solicitud
            acerca del ejercicio de sus derechos ARCO, para la revocación del
            consentimiento que nos haya otorgado del tratamiento de sus datos
            personales, para limitar el uso o divulgación de sus datos, o acerca
            de las finalidades para el tratamiento de sus datos personales. Para
            poder comunicarnos con usted, necesitamos los siguientes datos
            personales y de contacto en su solicitud:
          </Paragraph>
          <Paragraph className="text-white">
            • Nombre del titular <br />
            • Carta de autorización en caso de que la solicitud la haga otra
            persona que no sea el titular <br />
            • Copia de identificación del titular <br />
            • Correo electrónico <br />
            • Teléfono <br />
            Después de recibir su solicitud, recibirá nuestra respuesta en un
            plazo máximo de veinte días hábiles por los medios de contacto que
            nos proporcione.
          </Paragraph>
          <Title className="text-white" level={3}>
            ¿Para qué fines utilizaremos sus datos personales?
          </Title>
          <Paragraph className="text-white">
            Los datos personales que recabamos de usted; los clientes que nos
            visitan o contratan los accesos al centro de espectáculos y grupos
            que se presenta en el mismo, los destinamos de manera enunciativa
            mas no limitativa para los siguientes propósitos que son necesarias
            para el servicio que solicita:
          </Paragraph>
          <Paragraph className="text-white">
            • Para fines de contacto <br />
            • Para facturación y cobro <br />
            De manera adicional, utilizaremos su información personal para las
            siguientes finalidades secundarias que no son necesarias para el
            servicio solicitado, pero que nos permiten y facilitan brindarle
            mejor atención:
          </Paragraph>
          <Paragraph className="text-white">
            • Para envío de publicidad <br />
            En la recolección y tratamiento de datos personales que usted nos
            proporcione, cumplimos todos los principios que marca la Ley
            (artículo 6): licitud, calidad, consentimiento, información,
            finalidad, lealtad, proporcionalidad y responsabilidad. En caso de
            que no desee que sus datos personales sean tratados para estos fines
            adicionales, lo podrá indicar en el medio a través del cual nos
            proporcione sus datos personales, seleccionando la opción
            correspondiente. La negativa para el uso de sus datos personales
            para estas finalidades adicionales no podrá ser un motivo para que
            le neguemos los servicios que solicita o contrata con nosotros.
          </Paragraph>
          <Title className="text-white" level={3}>
            ¿Qué datos personales recopilamos y utilizaremos para estos fines?
          </Title>
          <Paragraph className="text-white">
            Los datos personales que usted libre y voluntariamente proporcione a
            La Empresa a través de este website (
            <Text strong className="text-white">
              'Portal'
            </Text>
            ), por escrito, vía telefónica y/o por otros medios distintos,
            podrán incluir de manera enunciativa más no limitativa:
          </Paragraph>
          <Paragraph className="text-white">
            • Datos de contacto <br />
            • Datos de identificación <br />Y datos sensibles que marca la Ley
            (articulo 3). Usted manifiesta su consentimiento expreso a que sus
            datos personales sean tratados de acuerdo a los términos y
            condiciones de este Aviso de Privacidad.
          </Paragraph>
          <Title className="text-white" level={3}>
            ¿Con quién compartimos su información?
          </Title>
          <Paragraph className="text-white">
            La Empresa no comparte sus datos con terceros. Los datos que se
            recopilan son usados exclusivamente para los fines señalados. Los
            datos personales que usted proporcione a la Empresa, podrán
            compilarse y fijarse en una base de datos propiedad exclusiva de la
            Empresa. Si los datos dejan de ser necesarios serán cancelados. Las
            ligas a sitios externos de este portal, no son responsabilidad de La
            Empresa, por lo que no asumimos responsabilidad alguna con respecto
            al contenido y políticas de privacidad en dichos sitios.
          </Paragraph>
          <Title className="text-white" level={3}>
            ¿Qué medidas de seguridad y control utilizamos para la protección de
            sus datos personales?
          </Title>
          <Paragraph className="text-white">
            La Empresa tiene implementadas medidas de seguridad administrativas,
            técnicas y físicas para proteger sus datos personales.
          </Paragraph>
          <Title className="text-white" level={3}>
            ¿Cómo puede usted ejercer sus derechos ARCO y revocar el
            consentimiento?
          </Title>
          <Paragraph className="text-white">
            En términos de la LFPDPPP, a partir del 6 de enero de 2012, usted
            podrá iniciar el procedimiento de protección de derechos ARCO ante
            el Instituto Federal de Acceso a la Información y Protección de
            Datos Personales (IFAI) dentro de los 15 días siguientes a la fecha
            en que La Empresa de respuesta a su solicitud o, si transcurrido el
            término que señala la Ley, La Empresa no diera respuesta a su
            solicitud. Usted tiene derecho a conocer qué datos personales
            tenemos de usted, para qué los utilizamos y las condiciones del uso
            que les damos (
            <Text strong className="text-white">
              Acceso
            </Text>
            ). Asimismo, es su derecho solicitar la corrección de su información
            personal en caso de que esté desactualizada, sea inexacta o
            incompleta (
            <Text strong className="text-white">
              Rectificación
            </Text>
            ); que la eliminemos de nuestros registros o bases de datos cuando
            considere que la misma no está siendo utilizada conforme a los
            principios, deberes y obligaciones previstas en la normativa (
            <Text strong className="text-white">
              Cancelación
            </Text>
            ); así como oponerse al uso de sus datos personales para fines
            específicos (
            <Text strong className="text-white">
              Oposición
            </Text>
            ). Estos derechos se conocen como derechos ARCO. Como titular de
            datos personales usted podrá ejercer sus derechos ARCO, (acceso,
            cancelación, rectificación y oposición al tratamiento de sus datos
            personales), o bien, revocar el consentimiento que usted haya
            otorgado a la empresa, para el tratamiento de sus datos personales,
            enviando directamente su solicitud al Departamento de Privacidad a
            través de los medios especificados en el presente aviso. Dicha
            solicitud deberá contener por lo menos: (a) nombre y domicilio u
            otro medio para comunicarle la respuesta a su solicitud; (b) los
            documentos que acrediten su identidad o, en su caso, la
            representación legal; (c) la descripción clara y precisa de los
            datos personales respecto de los que se solicita ejercer alguno de
            los derechos ARCO, (d) la manifestación expresa para revocar su
            consentimiento al tratamiento de sus datos personales y por tanto,
            para que no se usen; (d) cualquier otro elemento que facilite la
            localización de los datos personales. Es importante que tenga en
            cuenta que no en todos los casos podremos atender su solicitud o
            concluir el uso de forma inmediata, ya que es posible que por alguna
            obligación legal requiramos seguir tratando sus datos personales.
            Asimismo, usted deberá considerar qué para ciertos fines, la
            revocación de su consentimiento implicará que no le podamos seguir
            prestando el servicio que nos solicitó, o la conclusión de su
            relación con nosotros.
          </Paragraph>
          <Title className="text-white" level={3}>
            ¿Cómo puede limitar el uso o divulgación de su información personal?
          </Title>
          <Paragraph className="text-white">
            Si usted desea limitar el uso o divulgación de su información
            personal podrá solicitarlo a nuestro Departamento de Privacidad a
            través de los medios especificados en el presente aviso.
            Adicionalmente, podemos poner a su disposición procedimientos y
            mecanismos específicos mediante los cuales puede limitar el uso de
            su información personal. Estos procedimientos y mecanismos
            específicos se informarán a través de los medios que utilicemos para
            comunicarnos con usted u otros que consideremos adecuados.
          </Paragraph>
          <Title className="text-white" level={3}>
            ¿Cómo puede conocer los cambios a este Aviso de Privacidad?
          </Title>
          <Paragraph className="text-white">
            El presente Aviso de Privacidad puede sufrir modificaciones, cambios
            o actualizaciones derivadas de nuevos requerimientos legales; de
            nuestras propias necesidades por los servicios que ofrecemos; de
            nuestras prácticas de privacidad; de cambios en nuestro modelo de
            negocio, o por otras causas. Los cambios al Aviso de Privacidad se
            informarán a través de nuestro sitio web en la siguiente dirección:
            www.vandarimusichall.com
          </Paragraph>
        </Typography>
      </Content>
      <FooterPublic/>
    </Layout>
  );
};

export default AvisoPrivacidad;
