import React from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Button,
  Tag,
  Form,
  Select,
  DatePicker,
  Divider,
  Upload,
  message,
  Typography,
} from "antd";

import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import { getResponseError } from "../../Utils";

//Componentes
import Chat from "./Chat";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import SelectUsuario from "../../Widgets/Inputs/SelectUsuarios";
import SelectClientes from "../../Widgets/Inputs/SelectClientes";

import "../../../Styles/Modules/CRM/Modal.scss";
import "../../../Styles/Modules/CRM/chat.scss";
import dayjs from "dayjs";
import axios from "axios";
const { Option } = Select;
const { Title } = Typography;

/**
 *
 *
 * @export
 * @class FormCliente
 * @extends {React.Component}
 *
 * @description Modal para la informacion y chat del prospecto
 */
class FormCliente extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      fileList: [],
      cliente: {},
      eventos: {
        data: [],
        page: 1,
        total: 0,
        search: null,
        loading: false,
      },
      estatuses: [
        {
          label: "Creado",
          nombre: "Creado",
          color: "#4194F6",
          _id: 0,
          value: 0,
        },
        {
          label: "Interesado",
          nombre: "Interesado",
          color: "#ADFF45",
          _id: 1,
          value: 1,
        },
        {
          label: "Descartado",
          nombre: "Descartado",
          color: "#EB5757",
          _id: 2,
          value: 2,
        },
      ],
      id: this.props.cliente_id,
    };
  }

  formRef = React.createRef();
  refClientes = React.createRef();

  /**
   *
   *
   * @memberof FormCliente
   * @methodof componentDidMount
   * @description Obtenemos todas las listas
   */
  componentDidMount() {
    this.getEventos();
    if (this.props.cliente_id) this.getCliente();


    console.log('props',this.props)
  }

  getCliente = () => {
    return axios
      .get("/clientes/" + this.props.cliente_id)
      .then(({ data }) => {
        let adjuntos = data.adjuntos?.map((f) => ({
          uid: f.file,
          name: f.name,
          status: "done",
          fromDB: true,
          url:
            axios.defaults.baseURL +
            "/clientes/" +
            this.props.cliente_id +
            "?imagen=" +
            f.file +
            "&Authorization=" +
            sessionStorage.getItem("token"),
        }));
        this.setState({
          cliente: data,
          fileList: adjuntos,
        });
        this.formRef.current.setFieldsValue({
          ...data,
          fecha: dayjs(data.fecha),
          estatus: data.estatus,
          evento_id: data.evento_id
            ? {
                label: data.evento_id?.nombre,
                value: data.evento_id?._id,
              }
            : null,
          asignado_a:
            Array.isArray(data.asignado_a) && data.asignado_a[0]
              ? {
                  label: data.asignado_a[0]?.nombre,
                  value: data.asignado_a[0]?._id,
                }
              : null,
          adjuntos: adjuntos,
        });
      })
      .catch((e) => {
        console.log("e", e);
        message.error(
          e.response?.data?.message ?? "Error al obtener el cliente"
        );
      });
  };

  getEventos = (
    {
      page = this.state.eventos.page,
      limit = this.state.eventos.limit,
      search = this.state.eventos.search,
    } = this.state.eventos
  ) => {
    return axios
      .get("/eventos", {
        params: {
          page,
          limit,
          search,
        },
      })
      .then(({ data }) => {
        this.setState({
          eventos: data,
        });
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  /**
   * @memberof FormCliente
   * @method add
   * @description Crea un nuevo clienet en bd
   */
  add = (values) => {
    this.setState({ loading: true });
    axios
      .post("/clientes", { ...values })
      .then((response) => {
        message.success("Cliente creado");
        this.props.onClose([response.data.prospecto]);
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Error al crear el Cliente.");
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @memberof FormCliente
   * @method update
   * @description Actualiza la información de un rol
   */
  update = (values) => {
    console.log("values", values);
    this.setState({ loading: true });

    axios
      .put("/clientes", {
        ...values,
        cliente_id: this.props.cliente_id,
      })
      .then(({ data }) => {
        console.log("update=>", data);
        let c = data.data;
        this.setState({ cliente: c });
        message.success("Cliente actualizado");
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Error al actualizar el Cliente");
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @memberof FormCliente
   * @method onFinish
   * @description Se ejecuta al dar enter al formulario
   */
  onFinish = (values) => {
    console.log("values", values);

    if (values.asignado_a) {
      values.asignado_a =
        typeof values.asignado_a == "object"
          ? values.asignado_a.value
          : values.asignado_a;
    }

    values.estatus =
      typeof values.estatus == "object" ? values.estatus.value : values.estatus;

    values.evento_id = values.evento_id?.value ?? values.evento_id;

    console.log(" new", values);

    if (this.props.cliente_id) this.update(values);
    else this.add(values);
  };

  /**
   * @memberof FormCliente
   * @method onFinishFormCRM
   * @description Crea nuevos prospectos de los clientes selccioandos
   */
  onFinishFormCRM = (values) => {
    this.setState({ loading: true });
    let prospectos = {};

    values.prospectos.forEach((e) => {
      prospectos[e] = { cliente_id: e };
    });

    axios
      .post("/prospectos", { prospectos })
      .then(({ data }) => {
        message.success("Prospectos creados");
        this.props.onClose(data.prospectos);
      })
      .catch((error) => {
        message.error("Error al añadir los clientes");
      });
  };

  /**
   * @memberof FormCliente
   * @method handleUpload
   * @description Metodo que se encarga de subir el archivo al server y relacionarlo en el cliente
   */
  handleUpload = async ({ file, onSuccess, onError }) => {
    console.log("file", file);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("cliente_id", this.props.cliente_id);

    try {
      const response = await axios.put("/clientes", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onSuccess(response.data);
      message.success("Archivo subido exitosamente");
    } catch (error) {
      onError(error);
      message.error("Error al subir el archivo");
    }
  };

  /**
   * @memberof FormCliente
   * @method handleRemove
   * @description Metodo que se encarga de eliminar el archivo al server y quitarlo del cliente
   */
  handleRemove = async (file) => {
    if (!file.fromDB) return;

    try {
      const response = await axios.put("/clientes", {
        delete_file: file.uid,
        cliente_id: this.props.cliente_id,
      });
      message.success("Archivo eliminado exitosamente");
    } catch (error) {
      console.log("error", error);
      message.error("Error al eliminar el archivo");
    }
  };

  /**
   * @memberof FormCliente
   * @method normFile
   * @description
   */
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  render() {
    console.log("state", this.state);
    return (
      <Row>
        <Col span={this.props.cliente_id ? 12 : 24}>
          {this.props.crm && this.props.not_add !== true ? (
            <>
              <Form
                layout={"vertical"}
                onFinish={this.onFinishFormCRM}
                className="form-borderless pd-1"
              >
                <Title level={4} className="center">
                  {" "}
                  Agregar clientes{" "}
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="prospectos"
                      rules={[
                        {
                          required: true,
                          message: "Por favor seleccione a los clientes",
                        },
                      ]}
                    >
                      <SelectClientes mode={"multiple"} />
                    </Form.Item>
                  </Col>
                  <Col span={18}></Col>
                  <Col span={6}>
                    <Form.Item className="flex-end">
                      <Button block htmlType="submit" type="primary">
                        Agregar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>

                <Divider>O crea un nuevo cliente</Divider>
              </Form>
            </>
          ) : null}

          <Form
            layout={"vertical"}
            ref={this.formRef}
            onFinish={this.onFinish}
            className="form-borderless pd-1"
            requiredMark={this.props.cliente_id ? false : true}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="estatus">
                  <Select
                    placeholder="Estatus"
                    className="estatus-select"
                    labelInValue
                    suffixIcon={null}
                  >
                    {this.state.estatuses?.map((e) => (
                      <Option value={e._id} key={e._id}>
                        <Tag
                          className="tag tag-select"
                          color={e.color}
                          key={e.nombre}
                        >
                          {e?.nombre?.toUpperCase()}
                        </Tag>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}></Col>
              <Col span={12}>
                <Form.Item name="asignado_a">
                  <SelectUsuario
                    suffixIcon={null}
                    bordered={false}
                    rol={true}
                    placeholder="Seleccione al Asesor"
                    renderCustomValue={(value, usuarios) => {
                      const usuario = usuarios.data.find(
                        (user) => user._id === value.value
                      );
                      return usuario ? (
                        <span style={{ fontWeight: "bold" }}>
                          <CustomAvatar
                            size="large"
                            name={`${usuario.nombre ?? ""} ${
                              usuario.apellidos ?? ""
                            }`}
                          />{" "}
                          {usuario.nombre ?? ""} {usuario.apellidos ?? ""}
                        </span>
                      ) : null;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Nombre"
                  name="nombre"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa un nombre",
                    },
                  ]}
                >
                  <Input
                    variant={"borderless"}
                    placeholder="Nombre del prospecto"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Evento" name="evento_id">
                  <Select
                    suffixIcon={null}
                    placeholder="Seleccione el evento"
                    labelInValue
                    variant={"borderless"}
                  >
                    {this.state.eventos?.data.map((e) => (
                      <Option value={e._id} key={e._id}>
                        {e.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="descripcion"
                  label={"Descripción"}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa una descripcion",
                    },
                  ]}
                >
                  <Input.TextArea
                    className="width-100"
                    placeholder="Descripción"
                    variant={"borderless"}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item name="fecha" label="Fecha">
                  <DatePicker
                    variant={"borderless"}
                    className="width-100"
                    suffixIcon={null}
                    placeholder="Seleccione la Fecha"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label={"Teléfono"}
                  name="telefono"
                  extra="Incluir el código del país (e.g. +51, +52)"
                  rules={[
                    {
                      pattern:
                        /^\+52\d{10}$|^(\d{2,3})?\d{7}$|^(\d{3}-)?\d{4}-\d{4}$/,
                      message: "El formato no coincide",
                    },
                    {
                      pattern: /\+/,
                      message: "Falta el + o el código del país en el teléfono",
                    },
                    {
                      min: 10,
                      message: "min. 10 caracteres",
                    },
                    {
                      max: 13,
                      message: "max. 13 caracteres",
                    },
                  ]}
                >
                  <Input
                    placeholder="+52551231233"
                    className="width-100"
                    type="tel"
                    variant={"borderless"}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="adjuntos"
                  valuePropName="fileList"
                  getValueFromEvent={this.normFile}
                >
                  <Upload
                    customRequest={this.handleUpload}
                    onRemove={this.handleRemove}
                    fileList={this.state.fileList}
                  >
                    <Button icon={<UploadOutlined />}>Subir Archivos</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Divider className="divider-blue" />
            <Form.Item label="Información y Notas" name="notas">
              <Input.TextArea
                placeholder="Escribir notas"
                className="width-100"
                variant={"borderless"}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
            <Form.Item className="flex-end">
              <Button block htmlType="submit" type="primary">
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Col>
        {this.props.cliente_id ? (
          <Col span={12}>
            <Chat
              cliente_id={this.props.cliente_id}
              evento_id={this.props.evento_id}
              className="message-inner"
              disabled={!this.state.cliente.telefono}
              ref={this.refClientes}
            />
          </Col>
        ) : null}
      </Row>
    );
  }
}

/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function Vista(props) {
  const { open = false, onClose = () => {} } = props;

  return (
    <Modal
      open={open}
      onCancel={onClose}
      closable={true}
      destroyOnClose={true}
      zIndex={1000}
      width={props.cliente_id ? 1000 : 600}
      footer={null}
      className={props.cliente_id ? "modal-cliente-chat" : null}
    >
      <FormCliente {...props} />
    </Modal>
  );
}
