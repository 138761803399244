import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  message,
  Button,
  DatePicker,
  TimePicker,
  ColorPicker,
  Checkbox,
  Space,
  Typography,
  Image,
  Upload,
  Card,
  Divider,
  Affix,
} from "antd";

import { ImagesUploader } from "../../../Widgets/Uploaders";
import axios from "axios";
import { getResponseError } from "../../../Utils";

import Quill from "../../../Widgets/Inputs/Quill";

import dayjs from "dayjs";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";

import SelectCategorias from "../../../Widgets/Inputs/SelectCategorias";
const { RangePicker } = DatePicker;
const { Text } = Typography;
export default class FormEvento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      evento: {
        descripcion: "",
        contenido: "",
        imagenes: [],
        banner_name: undefined,
        mesas: undefined,
      },
      fileList: [],
      linkcard_selected: false,

      key: 1,
    };
  }
  formRef = React.createRef();

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");

    if (this.props.evento_id) {
      this.get();
    }
  }

  /**
   * @method get
   * @description Obtiene el evento de la bd
   */
  get = () => {
    this.setState({ loading: true });
    axios
      .get("/eventos/" + this.props.params.evento_id)
      .then(({ data }) => {
        let imagenes = data.imagenes
          ? this.formatImages(data.imagenes, data._id)
          : null;
        let banner = data.banner
          ? this.formatImages([data.banner], data._id)
          : null;

        let mensaje_predefinido_archivo = data.mensaje_predefinido?.archivo
          ? this.formatImages(
              [data.mensaje_predefinido.archivo],
              data._id,
              "archivo"
            )
          : null;
        this.setState({ evento: data, fileList: imagenes });

        console.log(
          "mensaje_predefinido_archivo to file",
          mensaje_predefinido_archivo
        );
        console.log(
          "mensaje_predefinido_archivo object",
          data.mensaje_predefinido
        );

        this.formRef.current.setFieldsValue({
          ...data,
          horario: dayjs(data.horario),
          color: data.color,
          fecha: dayjs(data.fecha),
          hora_llegada: data.hora_llegada ? dayjs(data.hora_llegada) : null,
          imagenes: imagenes,
          banner: banner,
          url: data.url,
          banner_name: banner?.name,
          mensaje_predefinido_archivo: mensaje_predefinido_archivo,
          mensaje_predefinido: data.mensaje_predefinido?.mensaje,
        });
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Error al obtener el evento");
      })
      .finally(() => this.setState({ loading: false }));
  };

  handleCheckboxChange = (file, checked) => {
    let imagenes = this.state.fileList;
    let evento = this.state.evento;

    let index_finded = imagenes.findIndex((img) => img.name === file.name);
    let finded = imagenes[index_finded];

    //si lo encuentro y esta seleccionado
    if (index_finded != -1 && checked) {
      evento.banner_name = finded.name;
    }
    //si lo encuentro y esta deseleccionado
    if (index_finded != -1 && !checked) {
      evento.banner_name = undefined;
    }

    let banner_selected = evento.banner_name ? true : false;

    this.setState({
      evento,
      linkcard_selected: banner_selected,
    });
  };

  handleRemove = (file) => {
    this.setState((prevState) => ({
      fileList: prevState.fileList.filter((item) => item.uid !== file.uid), // Eliminar archivo de la lista
    }));
  };

  onChangeFiles = ({ fileList }) => {
    const updatedFileList = fileList.map((file) => {
      if (!file.url && !file.preview) {
        file.preview = URL.createObjectURL(file.originFileObj); // Crear la URL para mostrar
      }
      return file;
    });
    this.setState({ fileList: updatedFileList }); // Actualizar el estado con la nueva lista de archivos
  };

  onRemoveFile = (file) => {
    let fileList = this.state.fileList;

    if (file.fromDB) {
      let exist = fileList.findIndex((img) => img.name === file.name);
      let is_banner = exist != -1;
      axios.put("/eventos", {
        id: this.props.params.evento_id,
        delete_image: file.name,
        delete_banner: is_banner,
      });
    }

    let img_delete = fileList.findIndex((f) => f.name === file.name);
    if (img_delete != -1) fileList.splice(img_delete, 1);
    this.setState({ fileList: fileList }); // Actualizar el estado con la nueva lista de archivos
  };

  onSubmit = (values) => {
    const { submitAction } = this.state;

    switch (submitAction) {
      case "onSave":
        this.onSave(values);
        break;
      case "onSaveAndNew":
        this.onSaveAndNew(values);
        break;
      case "onSaveBack":
        this.onSaveBack(values);
        break;
      default:
        console.error("Desconocido");
    }
  };

  onSave = (values) => {
    this.onFinish(values, (evento_id) => {
      this.props.navigate(`/admin/eventos/editar/${evento_id}`, {
        replace: true,
      });
    });
  };

  onSaveAndNew = (values) => {
    this.onFinish(values, () => {
      this.formRef?.current?.resetFields();
      this.setState({
        fileList: [],
        linkcard_selected: false,
        key: 1,
      });
      this.props.navigate("/admin/eventos/crear", {
        replace: true,
      });
    });
  };

  onSaveBack = (values) => {
    this.onFinish(values, () => {
      this.formRef?.current?.resetFields();
      this.props.navigate(-1);
    });
  };

  formatImages = (images, id, value = "imagen") => {
    let format_images;

    if (Array.isArray(images))
      format_images = images.map((f) => ({
        uid: f.file,
        name: f.name,
        status: "done",
        fromDB: true,
        url:
          axios.defaults.baseURL +
          "/eventos/" +
          id +
          "?" +
          value +
          "=" +
          f.file +
          "&Authorization=" +
          sessionStorage.getItem("token"),
      }));
    else {
      format_images = {
        uid: images.file,
        name: images.name,
        status: "done",
        fromDB: true,
        url:
          axios.defaults.baseURL +
          "/eventos/" +
          id +
          "?imagen=" +
          images.file +
          "&Authorization=" +
          sessionStorage.getItem("token"),
      };
    }

    return format_images;
  };

  /**
   * @method add
   * @description Añade un nuevo registro de evento
   */
  add = (values, callback = () => {}) => {
    axios
      .post("/eventos", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        this.setState({ evento: data });
        callback(data._id);
        this.props.setKey(2);
        message.success("Evento creado con éxito");
      })
      .catch((e) => {
        console.log("e", e);
        message.error(getResponseError(e.response));
      });
  };

  /**
   * @method update
   * @description Actualiza un nuevo registro de evento
   */
  update = (values, callback = () => {}) => {
    axios
      .put("/eventos", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        message.success("Evento actualizado con éxito");
        callback(data._id);
      })
      .catch((e) => {
        console.log("e", e);
        message.error(getResponseError(e.response));
      });
  };

  /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
  onFinish = (values, callback) => {
    const formData = new FormData();
    let data = this.formRef?.current?.getFieldsValue();
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        data.color = typeof data.color == "object" ? data.color.toHex() : null;

        formData.appendMultiple({
          ...data,
          id: this.props.params.evento_id,
          imagenes: undefined,
          banner: undefined,
          banner_name: this.state.evento.banner_name,
          mensaje_predefinido_archivo: null,
        });

        let imagenes = this.state.fileList;
        imagenes.forEach((img) => {
          if (!img.fromDB)
            formData.append("imagenes", img.originFileObj, img.name);
        });

        //si subi una imagen y no seleccione ninguna de las imagenes previas
        if (
          data.banner &&
          data.banner[0] &&
          data.banner[0] instanceof File &&
          !this.state.linkcard_selected
        ) {
          formData.append("banner", data.banner[0], data.banner[0].name);
        }
        if (this.props.params.evento_id) {
          this.update(formData, callback);
        } else {
          this.add(formData, callback);
        }

        if (data.mensaje_predefinido_archivo) {
          formData.append(
            "mensaje_predefinido_archivo",
            data.mensaje_predefinido_archivo[0],
            data.mensaje_predefinido_archivo[0].name
          );
        }
      })
      .catch((errorInfo) => {
        console.log("Errors:", errorInfo.errorFields);
      });
  };

  render() {
    return (
      <Form
        id="form-evento"
        layout={"vertical"}
        className="pd-3 bg-white width-100"
        scrollToFirstError
        onValuesChange={(changedValues, allValues) =>
          this.setState({ evento: allValues })
        }
        ref={this.formRef}
        onFinish={this.onSubmit}
        initialValues={{
          color: this.state.evento.color ?? "#FFF",
        }}
      >
        <Affix className="fixed-buttons" offsetTop={50}>
          <Space>
            <Button
              htmlType="submit"
              type="dashed"
              size="large"
              onClick={() => this.setState({ submitAction: "onSaveAndNew" })}
              loading={this.state.loading}
            >
              Guardar y Nuevo
            </Button>
            <Button
              htmlType="submit"
              type="dashed"
              size="large"
              onClick={() => this.setState({ submitAction: "onSaveBack" })}
              loading={this.state.loading}
            >
              {`Guardar e ir a ${
                this.props.return_to_crm ? "el CRM" : "la lista"
              }`}
            </Button>
          </Space>
        </Affix>
        <Row gutter={[16, 32]} className="width-100">
          <Col span={24}>
            <Row gutter={[32, 16]} className="width-100">
              <Divider orientation="left">Datos Generales</Divider>
              <Col xs={24} md={8}>
                <Form.Item
                  name="nombre"
                  label="Nombre"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese el nombre",
                    },
                  ]}
                >
                  <Input
                    onChange={(event) => {
                      this.formRef.current.setFieldsValue({
                        url: event.target.value
                          .replace(/\s+/g, "-")
                          .toLowerCase()
                          .replace(/[^a-z0-9ñ\-]+/g, "", ""),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={16}>
                <Form.Item label="URL" name="url">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Categoría" name="categoria_id">
                  <SelectCategorias params={{ tipo: 0 }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="fecha"
                  label="Fecha del Evento"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese la fecha",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Seleccionar Fecha"
                    className="width-100"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={4}>
                <Form.Item
                  name="color"
                  label="Color"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione un color para identificar al evento",
                    },
                  ]}
                >
                  <ColorPicker
                    disabledAlpha
                    defaultFormat="hex"
                    arrow={false}
                    showText
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={24}>
            <Row gutter={[32, 16]} className="width-100">
              <Divider orientation="left">Horarios</Divider>
              <Col xs={24} md={8}>
                <Form.Item
                  name="horario"
                  label="Horario del Evento"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese la hora",
                    },
                  ]}
                >
                  <TimePicker
                    className="width-100"
                    format={"HH:mm"}
                    placeholder="Horario"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="hora_llegada"
                  label="Hora de llegada al Evento"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese la hora de llegada",
                    },
                  ]}
                >
                  <TimePicker
                    className="width-100"
                    format={"HH:mm"}
                    placeholder="Hora de Llegada"
                    onChange={(hora) =>
                      this.formRef?.current?.setFieldsValue({
                        horario: dayjs(hora).add(1, "hour"),
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/**Imagenes */}
          <Row className="width-100">
            <Col span={24}>
              <p className="text-gray"></p>
              <Form.Item
                name="imagenes"
                label={
                  <Space direction="vertical">
                    <Text>Banner del Evento</Text>
                    <Text className="text-gray">
                      Puede seleccionar una imagen para usarla como LinkCard
                    </Text>
                  </Space>
                }
              >
                <Upload.Dragger
                  action={null}
                  multiple={true}
                  onChange={this.onChangeFiles}
                  fileList={this.state.fileList}
                  beforeUpload={() => false}
                  showUploadList={false}
                >
                  <div>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Haga click o arrastre las imagenes del evento
                    </p>
                  </div>
                </Upload.Dragger>
              </Form.Item>
              <Row
                className="width-100"
                justify={"start"}
                align={"start"}
                gutter={[16, 16]}
              >
                {this.state.fileList?.map((file) => (
                  <Col>
                    <Card
                      style={{ width: 150, height: 150 }}
                      className="image-list-element card-picture"
                      cover={
                        <>
                          <Image
                            preview={false}
                            src={file.fromDB ? file.url : file.preview}
                            alt={file.name}
                          />
                          <div className="options-overlay">
                            <Button
                              type="ghost"
                              icon={<DeleteOutlined />}
                              className="flex flex-center m-auto"
                              onClick={() => this.onRemoveFile(file)}
                            />
                          </div>
                        </>
                      }
                    >
                      <Checkbox
                        onChange={(e) =>
                          this.handleCheckboxChange(file, e.target.checked)
                        }
                        defaultChecked={
                          this.state.evento.banner?.name == file.name
                        }
                        disabled={
                          this.state.evento.banner_name != file.name &&
                          this.state.linkcard_selected
                        }
                        className="checkbox-images"
                      ></Checkbox>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col span={24}>
              <Form.Item name="banner" label="Link Card">
                <ImagesUploader
                  listType="picture-card"
                  disabled={this.state.linkcard_selected}
                  text="Haga click o arrastre la imagen"
                  className="upload-list-inline"
                  imageCrop={true}
                  maxCount={1}
                  onRemove={({ name, fromDB }) => {
                    if (fromDB)
                      axios.put("/eventos", {
                        id: this.props.params.evento_id,
                        delete_banner: true,
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="width-100">
            <Col span={24}>
              <Form.Item label="Descripción del Evento" name="descripcion">
                <Input.TextArea rows={5} maxLength="128"></Input.TextArea>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Contenido del Evento" name="contenido">
                <Quill />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">
            Mensajes para enviar a prospectos por WhatsApp
          </Divider>
          <Row className="width-100 ">
            <Col span={24}>
              <Form.Item name="mensaje_predefinido" label="Mensaje predefinido">
                <Input.TextArea className="width-100" rows={4} />
              </Form.Item>
              <Form.Item
                name="mensaje_predefinido_archivo"
                label="Imagen Adjunta"
              >
                <ImagesUploader
                  listType="picture-card"
                  text="Haga click o arrastre la imagen"
                  className="upload-list-inline"
                  multiple={false}
                  imageCrop={true}
                  maxCount={1}
                  onRemove={({ name, fromDB }) => {
                    if (fromDB)
                      axios.put("/eventos", {
                        id: this.props.params.evento_id,
                        delete_archivo: true,
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </Form>
    );
  }
}
