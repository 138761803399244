import React, { Component, useContext } from "react";
import { Layout, Row, Col, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navigation/Navbar-v2";

import "../../Styles/Modules/Landing/landing.scss";
import FooterPublic from "./Navigation/Footer";
import ScrollToHashElement from "../Widgets/ScrollToElement";
import Direccion from "./Sections/Direccion";

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

/**
 *
 * @class Landing
 * @extends {Component}
 * @description Componente Landing page
 */
class MaiTai extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  goTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  componentDidMount() {
    this.goTop();
  }

  render() {
    return (
      <Layout className="layout-landing">
        <ScrollToHashElement behavior="smooth" inline="center" />
        <Navbar />
        <div className="image-section">
          <img src="/img/maitai-bg.png" className="video" alt="maitai" />
        </div>
        <div className="transition-section">
          <Row justify="center">
            <Title
              className="sub-title text-white"
              onClick={() =>
                window
                  .open(
                    "https://www.instagram.com/explore/tags/venavandari/",
                    "_blank"
                  )
                  .focus()
              }
            >
              #VenAVandari
            </Title>
          </Row>
          <Row justify="center">
            <img src="/img/logo3.png" className="title-img"></img>
          </Row>
          <Row justify={"center"}>
            <Paragraph className="description pl-3 pr-3" justify>
              <strong> Espacio </strong>de desarrollo <strong>creativo </strong>{" "}
              el cual cuenta con lo último en equipos de audio y grabación, y
              lujos a disposición del cliente en cuestión de comodidad y
              entretenimiento.
            </Paragraph>
          </Row>
        </div>

        <section className="scroll-background">
          <Row
            justify={"center"}
            align={"start"}
            className="card-text bb-para"
            id="para1"
            style={{
              // backgroundImage: "url(/img/control-room.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundColor: "#171921",
              backgroundImage:
                'linear-gradient(rgb(0 0 255 / 64%), rgb(255 255 0 / 64%)), url(/img/wood.jpg)',
            }}
          >
            <div className="box">
              <Title className="title center">Misión</Title>
              <Paragraph className="description text-center mx-auto ">
                Nuestra misión en Mai Tai es crear una experiencia que rompa
                expectativas, brindando siempre un servicio de calidad a través
                de la misma producción musical y comodidad a quien venga
                acompañando a el/los artista(s).
              </Paragraph>
            </div>
            <div class="scroll-divider"></div>
          </Row>
        </section>

        <section className="section">
          <Row>
            <Title className="sub-title">¿Qué ofrecemos?</Title>
          </Row>
          <Row>
            <Title className="title m-0">EQUIPO DISPONIBLE</Title>
          </Row>
        </section>
        <section>
          <Row gutter={[0, 0]} className="mt-3">
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 12, order: 1 }}
              className="flex-center bg-gradient h-full "
            >
              <Row justify={"center"} align={"start"} className="card-text">
                <Col span={24}>
                  <Title
                    level={2}
                    className="text-white uppercase title-2 "
                    strong
                  >
                    Control Room
                  </Title>

                  <Title className="text-2">
                    <ul className="landing-list">
                      <li>
                        Slate Digital’s VMS <br />
                        <span className="sub-list">
                          (Micrófono, preamp y condensador de micrófono)
                        </span>
                      </li>
                      <li>
                        Native Instruments’ Komplete 12 <br />
                        <span className="sub-list">
                          (Controlador y software)
                        </span>
                      </li>
                      <li>
                        Universal Audio’s LA-610 MKII
                        <br />
                        <span className="sub-list">(Compresor/preamp)</span>
                      </li>
                      <li>
                        Universal Audio’s Apollo X8P
                        <br />
                        <span className="sub-list">(Interfaz de audio)</span>
                      </li>
                      <li>
                        Apple’s Macbook Pro 15
                        <br />
                        <span className="sub-list">(Computadora)</span>
                      </li>
                      <li>
                        Samsung’s 55” Monitor 4K
                        <br />
                        <span className="sub-list">(Monitor visual)</span>
                      </li>
                      <li>
                        ADAM Audio’s A7x Monitors
                        <br />
                        <span className="sub-list">(Monitores de estudio)</span>
                      </li>
                      <li>
                        König & Meyer’s 26720 Monitor Stand
                        <br />
                        <span className="sub-list">(Bases de monitor)</span>
                      </li>
                      <li>
                        ADAM Audio’s Sub8
                        <br />
                        <span className="sub-list">(Subwoofer de estudio)</span>
                      </li>
                    </ul>
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col
              lg={{ span: 12, order: 2 }}
              md={{ span: 24, order: 2 }}
              xs={{ span: 24, order: 2 }}
              className="flex-center bg-gradient h-full"
              style={{ backgroundImage: "url(/img/control-room.png)" }}
            ></Col>

            <Col
              lg={{ order: 3, span: 12 }}
              md={{ order: 4, span: 24 }}
              xs={{ order: 4, span: 24 }}
              className="cover h-full"
              style={{ backgroundImage: "url(/img/ensayos.png)" }}
            ></Col>
            <Col
              lg={{ span: 12, order: 4 }}
              md={{ span: 24, order: 3 }}
              xs={{ span: 24, order: 3 }}
              className="flex-center bg-gradient h-full "
            >
              <Row justify={"center"} align={"start"} className="card-text">
                <Col span={24}>
                  <Title
                    level={2}
                    className="text-white uppercase title-2 "
                    strong
                  >
                    Cuarto de ensayos
                  </Title>

                  <Title className="text-2">
                    <ul className="landing-list">
                      <li>
                        Novation’s UltraNova
                        <br />
                        <span className="sub-list"> (Sintetizador)</span>
                      </li>
                      <li>
                        Fender’s Vintage Modified Jazz Bass
                        <br />
                        <span className="sub-list"> (Bajo eléctrico)</span>
                      </li>
                      <li>
                        Fender’s American Elite Stratocaster
                        <br />
                        <span className="sub-list"> (Guitarra eléctrica)</span>
                      </li>
                      <li>
                        Taylor’s DN3
                        <br />
                        <span className="sub-list"> (Guitarra acústica)</span>
                      </li>
                      <li>
                        Pearl’s Drumset
                        <br />
                        <span className="sub-list"> (Batería)</span>
                      </li>
                    </ul>
                  </Title>
                </Col>
              </Row>
            </Col>

            <Col
              lg={{ span: 12, order: 5 }}
              md={{ span: 24, order: 5 }}
              xs={{ span: 24, order: 5 }}
              className="flex-center bg-gradient h-full "
            >
              <Row justify={"center"} align={"start"} className="card-text">
                <Col span={24}>
                  <Title
                    level={2}
                    className="text-white uppercase title-2 "
                    strong
                  >
                    Lujos
                  </Title>
                  <Title className="text-2">
                    <ul className="landing-list">
                      <li>Salas lounge</li>
                      <li>Videojuegos</li>
                      <li>Servicio de frigobar</li>
                      <li>Sofácama</li>

                      <li>Menu de alimentos</li>
                      <li>Área para fumar</li>
                      <li>Conexion Wifi</li>
                    </ul>
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col
              lg={{ span: 12, order: 6 }}
              md={{ span: 24, order: 6 }}
              xs={{ span: 24, order: 6 }}
              className="cover h-full"
              style={{ backgroundImage: "url(/img/lounge.jpg)" }}
            ></Col>
          </Row>
        </section>
        <Direccion />
        <FooterPublic />
      </Layout>
    );
  }
}

export default function Vista(props) {
  return <MaiTai {...props} />;
}
