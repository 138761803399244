import React from "react";
import { Draggable } from "react-beautiful-dnd";

import { CardEventoProspecto } from "../../../Widgets/Cards";

const Item = ({
  item_id,
  index,
  prospecto,
  onClick = () => {},
  onCopy = () => {},
  prospecto_selected,
  openModal = () => {},
  showMenu,
  orden,
  mensajes_nuevos
}) => {

  //console.log('mensajes nuevos',mensajes_nuevos, prospecto.nombre)
  return (
    <Draggable draggableId={item_id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={onClick}
        >
          <CardEventoProspecto
            showMenu={showMenu}
            cliente_id={prospecto?._id}
            nombre={prospecto?.nombre}
            fuente={prospecto?.fuente}
            telefono={prospecto?.telefono}
            prospecto_selected={prospecto_selected}
            openModal={openModal}
            onCopy={onCopy}
            mensajes_nuevos={mensajes_nuevos}
          />
        </div>
      )}
    </Draggable>
  );
};

export default Item;
