import React, { Component } from "react";
import { Row, Col, Card, Layout, message, Space, List, Button, Typography, Tag, Segmented,} from "antd";
import axios from "axios";

//componentes
import { useNavigate } from "react-router-dom";
import useSearch from "../../../Hooks/useSearch";
import Logged, { User } from "../../../Hooks/Logged";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import { ButtonDelete, ButtonEdit } from "../../Widgets/Buttons";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalCategorias from "./ModalCategorias";
import ModalCategoriasSimple from "./ModalCategoriaSimple";

const { Text } = Typography;
const { Content } = Layout;

/**
 * @export
 * @class Categorias
 * @extends {Component}
 * @description Vista de Categorias
 */
class Categorias extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			modal_visible: false,

			categoria_id: undefined,
			categorias: {
				data: [],
				page: 1,
				total: 0,
				limit:10
			},

			filtroSearch: "",
			searching: true,
		};
	}

	componentDidMount() {
		this.props.setShowSearch(true);

		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
		this.getCategorias();
	}

	componentDidUpdate(prevProps){
	    if(prevProps.search != this.props.search){
	      this.getCategorias({page: 1, search: this.props.search})
	    }
	}

	/**
	 *
	 *
	 * @param {*} [page=this.state.page] Numero de la pagina a obtener
	 * @memberof Areas
	 * @method getCategorias
	 * @description Obtiene el listado de areas
	 */
	getCategorias = (
		{
			page = this.state.categorias.page,
			limit = this.state.categorias.limit,
			search = this.props.search,
		} = this.state.categorias
	) => {
		this.setState({ loading: true });
		axios
			.get("/categorias", {
				params: {
					page,
					limit,
					search,
				},
			})
			.then(({ data }) => {
				this.setState({ categorias: data });
			})
			.catch((error) => {
				console.log(error);
				message.error("Error al traer las categorías");
			})
			.finally(() => this.setState({ loading: false }));
	};

	render() {
		return (
			<>
				<PageHeader
					className="admin-page-header"
					breadcrumb={{
						items: [
							{
								key: "1",
								title: "Categorías",
							},
						],
					}}
				></PageHeader>
				<Content className="admin-content content-bg pd-1">
					<List
						loading={this.state.loading}
						className="component-list"
						itemLayout="horizontal"
						locale={{ emptyText: "Sin categorías " }}
						dataSource={this.state.categorias.data}
						pagination={{
							current: this.state.categorias.page,
							pageSize: this.state.categorias.limit,
							total: this.state.categorias.total,
							position: "bottom",
							className: "flex-left",
							onChange: (page, limit) => this.getCategorias({ page, limit }),
						}}
						header={
							<Row className="component-list-header width-100 pr-1 pl-1">
								<Col span={2} className="center">
									
								</Col>
								<Col span={5} className="center">
									<Text strong>Nombre</Text>
								</Col>
								<Col span={13} className="center">
									<Text strong>Descripción</Text>
								</Col>
								
							</Row>
						}
						renderItem={(item) => (
							<List.Item className="component-list-item">
								<Card className="card-list">
									<Row className="width-100 " gutter={[8, 8]}>
										<Col span={2}>
											<CustomAvatar
												name={item.nombre}
												url={
													item.imagen
														? {
																url:
																	axios.defaults.baseURL +
																	"/categorias/" +
																	item._id,
																imagen: item.imagen?.name,
																logo: item.imagen?.file,
															}
														: null
												}
											/>
										</Col>
										<Col span={5} className="center">
											<Text>{item.nombre}</Text>
										</Col>
										<Col span={13} className="center">
											<Text>{item.descripcion}</Text>
										</Col>
										<Col span={4} className="center">
											<Space size="small" direction="horizontal">
												<ButtonEdit
													disabled={!this.props.canEdit}
													onClick={() =>
															this.setState({
																	modal_visible_categoria: true,
																	categoria_id: item._id,
																})
													}
												/>
												<ButtonDelete
													disabled={!this.props.canDelete}
													onConfirm={() =>
														axios
															.delete("/categorias/", {
																params: { id: item._id },
															})
															.then((response) => {
																message.success(response?.data?.message);
																this.getCategorias();
															})
															.catch((error) => {
																message.error(error?.response?.data?.message);
																this.getCategorias();
															})
													}
												/>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Content>
				<FloatingButton
					title="Nuevo registro"
					onClick={() => this.setState({ modal_visible_categoria: true })}
				/>

				<ModalCategorias
					open={this.state.modal_visible_evento}
					onClose={() => {
						this.setState({
							modal_visible_evento: false,
							categoria_id: undefined,
						});
						this.getCategorias();
					}}
					categoria_id={this.state.categoria_id}
				/>
				<ModalCategoriasSimple
					open={this.state.modal_visible_categoria}
					onClose={() => {
						this.setState({
							modal_visible_categoria: false,
							categoria_id: undefined,
						});
						this.getCategorias();
					}}
					categoria_id={this.state.categoria_id}
				/>
			</>
		);
	}
}

export default function Vista(props) {
	const user = React.useContext(User);
	const { setShow } = useSearch();

	const permisos = usePermissions(user?.rol_id?.permisos, {
		canEdit: ["categorias", "edit"],
		canDelete: ["categorias", "delete"],
		canCreate: ["categorias", "create"],
	});

	return (
		<Categorias
			{...props}
			setShowSearch={setShow}
			{...permisos}
			navigate={useNavigate()}
		/>
	);
}
