import React, { Component, useContext, useRef, useState } from 'react';
import { Row, Col, message, Spin, Button, Card, Typography } from 'antd';

import axios from 'axios';
import { useCookies } from "react-cookie";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

//css
import './Stripe.css';

const { Title } = Typography;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: 'es' });
const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: 'black',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#e5edf5' },
            '::placeholder': { color: '#bfc0d2' },
        },
        invalid: {
            iconColor: '#bfc0d2',
            color: 'black',
        },
    },
};


/**
 * @const CheckoutForm
 * @description Formulario para realizar un pago por medio de Stripe
 */
const CheckoutForm = (props) => {


    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(["cart", "carrito_id"]);

    /**
     * @const handleSubmit
     * @description Se ejecuta al realizar el pago correspondiente
     */
    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        })
            console.log("paymentMethod", paymentMethod);

        //Si no hay error
        if (!error) {

            axios.post("/payment",{
                carrito_id: props.carrito_id,
                stripe_id: paymentMethod.id,
            }).then(response => {
                console.log("response", response.data);
                message.success("Compra completada")
                removeCookie("cart",{ path: '/',});
                removeCookie("carrito_id",{ path: '/',});
                navigate(`/carrito/pago/${response.data?.venta?._id}`)

            }).catch((error)=> {
                console.log("error", error);
                if(error?.response?.status === 406){
                    message.error(error?.response?.data?.message ?? "Error (406)")
                    removeCookie("cart",{ path: '/',});
                    removeCookie("carrito_id",{ path: '/',});
                    navigate("/")
                }

                message.error(error?.response?.data?.message ?? "Error al registrar la venta")
            }).finally(()=>setLoading(false))
        } else {
            console.log(error)
            message.error('Error producido por Stripe')
        }
        
    }

    return (
        <Spin spinning={loading}>
            <form onSubmit={handleSubmit}>
                <Title level={4} style={{ marginBottom: '2em', textAlign: "center" }}>
                    Ingresa tu información de pago.
                </Title>
                <fieldset className="FormGroup">
                    <div className="FormRow">
                        <CardElement
                            options={CARD_OPTIONS}
                            id="card-element"
                        />
                    </div>
                </fieldset>
                <Row>
                    <Col span={24} className="center">
                        <Button htmlType='submit' block className="stripe-btn" >
                            Pagar
                        </Button>
                    </Col>
                    <Col span={24} style={{marginTop: "8px"}}>
                        <img src="/img/powered-stripe.png" width="150"/>
                    </Col>
                </Row>
            </form>
        </Spin>
    );
}

export default function App(props) {
    return (
        <Card className="card-stripe">
            <Elements stripe={stripePromise}>
                <CheckoutForm {...props}/>
            </Elements>
        </Card>
    );
};
