import React, { Component } from "react";
import { Row, Col, Card, Layout, message, Space, List, Typography, Button, Result, Tooltip } from "antd";
import axios from "axios";
import { FilterOutlined, FilePdfOutlined, MailOutlined } from "@ant-design/icons";


//componentes
import { ventaEstatusTag } from "../../../../Utils";
import useSearch from "../../../../../Hooks/useSearch";
import Logged, { User } from "../../../../../Hooks/Logged";
import usePermissions from "../../../../../Hooks/usePermissions";
import PageHeader from "../../../../Widgets/PageHeader/PageHeader";
import ClienteSubMenu from "../../../../Widgets/ClienteMenuItem/ClienteSubMenu";
import FloatingButton from "../../../../Widgets/Floating Button/FloatingButton";
import { ButtonDelete, ButtonEdit, ButtonView } from "../../../../Widgets/Buttons";

import ModalVentas from "../../../Ventas/ModalVentas"

import dayjs from "dayjs";

const { Text } = Typography;
const { Content } = Layout;

/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista de Areas
 */
class Ventas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			modal_visible: false,

			venta_id: undefined,
			ventas: {
				data: [],
				page: 1,
				total: 0,
				filters: []
			},

			filtroSearch: "",
			searching: true,
		};
	}

	componentDidMount() {
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
		this.getVentas();
	}


	componentDidUpdate(prevProps) {
		if (this.props.search != prevProps.search) {
			this.getVentas()
		}
	}


	/**
	 * @memberof VEntas
	 * @method getVentas
	 * @description Obtiene el listado de ventas
	 */
	getVentas = ({
		page = this.state.ventas.page,
		limit = this.state.ventas.limit,
		filters = this.state.ventas.filters,
		search = this.props.search,
	} = this.state.ventas) => {
	this.setState({ loading: true });
		axios
			.get("/ventas", {
				params: {
					page,
					limit,
					filters,
					search,
					cliente_id: this.props.cliente_id
				},
			})
			.then(({ data }) => {
				this.setState({ ventas: {...data, filters} });
			})
			.catch((error) => {
				console.log(error);
				message.error("Error al traer las ventas");
			})
			.finally(() => this.setState({ loading: false }));
	};

	/**
	 *
	 * @memberof Compras
	 * 
	 * @method getPDF
	 * @description Generamos el PDF de la venta realizda
	 */
	getPDF = (venta_id, send = false) => {
		this.setState({loading: true})
		axios.get('/venta/pdf',{
			params:{
				venta_id: venta_id,
				send: send
			},
			responseType: 'blob'
		}).then(response => {
			if(send){
				message.success("Correo enviado")
			}else{
				const blob = new Blob([response.data], { type: 'application/pdf' });
				const pdfUrl = URL.createObjectURL(blob);
				window.open(pdfUrl, '_blank');
			}

		}).catch(error => {
			message.error(send ? "Error al enviar el PDF" : "Error al obtener el PDF")
		}).finally(()=>this.setState({loading: false}))
	}


	render() {
		return (
			<>
				<Content className="admin-content content-bg pd-1">
					<List
						loading={this.state.loading}
						className="component-list"
						itemLayout="horizontal"
						locale={{ emptyText: "Sin ventas " }}
						dataSource={this.state.ventas.data}
						pagination={{
							current: this.state.ventas.page,
							pageSize: this.state.ventas.limit,
							total: this.state.ventas.total,
							position: "bottom",
							className: "flex-left",
							onChange: (page, limit) => this.getVentas({ page, limit }),
						}}
						header={
							<Row className="component-list-header width-100 pr-1 pl-1">
								<Col xs={24} lg={5} className="center">
									<Text strong>fecha</Text>
								</Col>
								<Col xs={24} lg={5} className="center">
									<Text strong>evento</Text>
								</Col>
								<Col xs={24} lg={5} className="center">
									<Text strong>monto</Text>
								</Col>
								<Col xs={24} lg={5} className="center">
									<Text strong>estatus</Text>
								</Col>
							</Row>
						}
						renderItem={(item) => (
							<List.Item className="component-list-item">
								<Card className="card-list">
									<Row className="width-100 " gutter={[8, 8]}>
										<Col xs={24} lg={5} className="center">
											<Text>{dayjs(item.fecha).format("DD/MM/YYYY")}</Text>
										</Col>
										<Col xs={24} lg={5} className="center">
											<Text>{item.evento_id?.nombre}</Text>
										</Col>
										<Col xs={24} lg={5} className="center">
											<Text>$ {item.total ? item.total.toMoney(true) : 0}</Text>
										</Col>
										<Col xs={24} lg={5} className="center">
											<Text>{ventaEstatusTag(item.estatus)}</Text>
										</Col>
										<Col xs={24} lg={4} className="center">
											<Space size="small" direction="horizontal">
												<Tooltip placement="topRight" title={"Enviar boletos al cliente"}>
													<Button
														icon={<MailOutlined />}
														onClick={()=>this.getPDF(item._id, true)}
													/>
												</Tooltip>
												<Tooltip placement="topRight" title={"Ver boletos del cliente"}>
													<Button
														icon={<FilePdfOutlined />}
														onClick={()=>this.getPDF(item._id)}
													/>
												</Tooltip>
												<ButtonView
													disabled={!this.props.canView}
													onClick={() =>
														this.setState({
															modal_visible: true,
															venta_id: item._id,
														})
													}
												/>
												<ButtonEdit
													disabled={!this.props.canEdit}
													onClick={() =>
														this.setState({
															modal_visible: true,
															venta_id: item._id,
														})
													}
												/>
												<ButtonDelete
													disabled={!this.props.canDelete}
													onConfirm={() =>
														axios
															.delete("/ventas/", {
																params: { venta_id: item._id },
															})
															.then((response) => {
																message.success(response?.data?.message);
																this.getVentas();
															})
															.catch((error) => {
																message.error(error?.response?.data?.message);
																this.getVentas();
															})
													}
												/>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Content>
				<FloatingButton
					title="Nuevo registro"
					onClick={() => this.setState({ modal_visible: true, venta_id: undefined })}
				/>

				<ModalVentas
					open={this.state.modal_visible}
					onClose={() => {
						this.setState({ modal_visible: false, venta_id: undefined });
						 this.getVentas();
					}}
					venta_id={this.state.venta_id}
				/>
			</>
		);
	}
}

export default function Vista(props) {
	const user = React.useContext(User);
	const { setShow } = useSearch();

	const permisos = usePermissions(user?.rol_id?.permisos, {
		canEdit: ["ventas", "edit"],
		canDelete: ["ventas", "delete"],
		canCreate: ["ventas", "create"],
		canView: ["ventas", "access"],
	});

	if(permisos.canView === false)
		return <Result
			className="mt-3"
			status="warning"
			title="No tienes acceso a este módulo"
		/>  

	return <Ventas {...props} setShowSearch={setShow} {...permisos} />;
}
