import React, { Component } from "react";
import {
  message,
  Layout,
  Button,
  Col,
  Form,
  Row,
  Divider,
  Space,
  Input,
  Affix,
} from "antd";

import axios from "axios";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import Logged, { User } from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import useSearch from "../../../Hooks/useSearch";

import Seating from "../Seating/Sections";
import TextArea from "antd/es/input/TextArea";

const { Content } = Layout;

/**
 * @class PlantillaEvento
 * @description Seccion para el crear - modificar los PlantillaEventos
 */
class PlantillaEvento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      plantilla: {
        descripcion: "",
        contenido: "",
        imagenes: [],
        mesas: undefined,
      },
      mesas: undefined,
      key: 1,
    };
  }

  back = () => {
    this.props.navigate('/admin/plantillas');
  }

  formSeating = React.createRef()

  componentDidMount() {
    if (this.props.params.plantilla_evento_id) {
      this.get();
    }
  }

  /**
   * @method get
   * @description Obtiene el evento de la bd
   */
  get = () => {
    this.setState({ loading: true });
    axios
      .get("/plantilla-evento/" + this.props.params.plantilla_evento_id)
      .then(({ data }) => {
        this.formRef.setFieldsValue(data);
        this.seatRef.setValues(data.secciones ?? []);
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Error al obtener plantilla");
      })
      .finally(() => this.setState({ loading: false }));
  }

  onSave = (values) => {
    this.setState({ loading: true });
    let saveTemplates = this.seatRef.getValues();

    if (this.props.params.plantilla_evento_id) {
      this.update({ ...values, secciones: saveTemplates });
    } else {
      this.create({ ...values, secciones: saveTemplates });
    }
  }

  create = (values) => {
    this.setState({ loading: true });

    axios
      .post("/plantilla-evento", values)
      .then(({ data }) => {
        console.log("data", data);
        message.success("Plantilla Guardada");
        this.back()
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Error al obtener la plantilla");
      })
      .finally(() => this.setState({ loading: false }));
  };

  update = (values) => {
    this.setState({ loading: true });
    axios
      .put("/plantilla-evento/" + this.props.params.plantilla_evento_id, values)
      .then(({ data }) => {
        console.log("data", data);
        message.success("Plantilla Guardada");
        this.back()

      })
      .catch((error) => {
        console.log("error", error);
        message.error("Error al obtener la plantilla");
      })
      .finally(() => this.setState({ loading: false }));
  };

  seatRef = React.createRef();

  render() {
    return (
      <>
        <PageHeader
          className="admin-page-header"
          breadcrumb={{
            items: [
              {
                key: "0",
                title: "Plantillas de Evento",
                onClick: () => this.props.navigate("/admin/plantillas"),
                className: "hover",
              },
              {
                key: "1",
                title: this.props.params.plantilla_evento_id
                  ? "Editar Plantilla de Evento "
                  : "Crear Plantilla de Evento ",
              },
            ],
          }}
        ></PageHeader>
        <Content className="admin-content content-bg pd-1">
          <Form
            id="form-evento"
            layout={"vertical"}
            className="pd-3 bg-white width-100"
            scrollToFirstError
            onValuesChange={(changedValues, allValues) => {
              console.log("allValues", allValues);
              this.setState({ evento: allValues });
            }}
            onFinish={this.onSave}
            ref={(e) => (this.formRef = e)}
          >
            <Affix
              className="fixed-buttons"
              offsetTop={50}
            >
              <Space>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  className="ant-btn "
                  loading={this.state.loading}
                >
                  Guardar
                </Button>
              </Space>
            </Affix>
            <Row gutter={[16, 32]} className="width-100">

              <Col span={24}>
                <Row gutter={[32, 16]} className="width-100">
                  <Divider orientation="left">Datos Generales</Divider>
                  <Col span={24}>
                    <Form.Item
                      name="nombre"
                      label="Nombre"
                      rules={[
                        {
                          required: true,
                          message: "Ingrese el nombre",
                        },
                      ]}
                    >
                      <Input placeholder="Nombre de la Plantilla" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="descripcion"
                      label="Descripción"
                      rules={[
                        {
                          required: true,
                          message: "Ingrese el nombre",
                        },
                      ]}
                    >
                      <TextArea placeholder="Descripción de la Plantilla" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Seating
                      ref={(ref) => {
                        this.seatRef = ref;
                        window.ref = this.seatRef;
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Content>
      </>
    );
  }
}

export default function Vista(props) {
  const user = React.useContext(User);
  const { setShow } = useSearch();

  const permisos = usePermissions(user?.rol_id?.permisos, {
    canEdit: ["layouts", "edit"],
    canDelete: ["layouts", "delete"],
    canCreate: ["layouts", "create"],
  });

  return (
    <PlantillaEvento
      {...props}
      setShowSearch={setShow}
      {...permisos}
      navigate={useNavigate()}
      params={useParams()}
    />
  );
}
