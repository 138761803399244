import { Tag } from "antd";
import axios from "axios";

import { WhatsAppOutlined, UserOutlined } from "@ant-design/icons";
import { FaFacebookSquare, FaInstagram, FaAdversal } from "react-icons/fa";
/**
 * @param {*} estatus
 * @description Renderiza el Tag con el estatus de la inversion
 */
const renderEstatusCliente = ({ estatus = 0 }) => {
  let steps = {
    0: <Tag className="tag cancelado">Cancelado</Tag>,
    1: <Tag className="tag registrado">Registrado</Tag>,
    2: <Tag className="tag contactado">Contactado</Tag>,
    3: <Tag className="tag compro">Otro</Tag>,
    4: <Tag className="tag pagado">Pagado</Tag>,
  };

  return estatus != undefined ? steps[estatus] : "N/A";
};

const ventaEstatusTag = (estatus = 0) => {
  let status = {
    0: <Tag className="tag cancelado">Cancelado</Tag>,
    1: <Tag className="tag pendiente">Pendiente</Tag>,
    2: <Tag className="tag parcial">Pago Parcial</Tag>,
    3: <Tag className="tag pagado">Pagado</Tag>,
  };

  return estatus != undefined ? status[estatus] : "N/A";
};
/**
 *
 * @param {*} response
 * @param {String} si_falla_text
 * @returns {String}
 */
const getResponseError = (response, si_falla_text) => {
  if(!response) return "Error"

  if (typeof response.data === "string") return response.data;

  if (typeof response.data.message === "string") return response.data.message;

  if (typeof response.data.message.data === "string")
    return response.data.message.data;

  return si_falla_text;
};

const FuenteImage = (element) => {
  const images = [
    {
      icon: <UserOutlined />,
      style: { backgroundColor: "#c2c2c2" },
    },
    {
      icon: <FaFacebookSquare />,
      style: { backgroundColor: "#4267b2" },
    },
    {
      icon: <FaInstagram />,
      style: { backgroundColor: "#EC0088" },
    },
    {
      icon: <WhatsAppOutlined />,
      style: { backgroundColor: "#57EB57" },
    },
  ];

  return images[element] || images[0];
};

const getImageURL = (id, imagen, link = "") => {
  console.log("imagen>>>>>", imagen);
  let image_url = "/img/fallback.jpg";

  if (imagen)
    image_url =
      axios.defaults.baseURL +
      `/${link}/` +
      id +
      "?imagen=" +
      imagen.file +
      "&Authorization=" +
      sessionStorage.getItem("token");

  console.log("image_url", image_url);
  return image_url;
};

const getBanner = (item, id, link = "") => {
  let image_url = "/img/fallback.jpg";
  if (item)
    image_url =
      axios.defaults.baseURL +
      `/${link}/` +
      id +
      "?imagen=" +
      item?.file +
      "&Authorization=" +
      sessionStorage.getItem("token");

  return image_url;
};

const getBannerPublic = (item, id, link = "") => {
  let image_url = "/img/fallback.jpg";
  if (item)
    image_url =
      axios.defaults.baseURL + `/${link}/` + id + "?imagen=" + item?.file;

  return image_url;
};

const getTipoColor = tipo => {
    const colors = [
        '#36F905',
        '#EB5757',
        '#1890FF',
        '#000000'
    ]

    return colors[tipo - 1]
}
export {
  renderEstatusCliente,
  getResponseError,
  FuenteImage,
  ventaEstatusTag,
  getImageURL,
  getBanner,
  getBannerPublic,
  getTipoColor
};
