import { Button, Popover } from "antd";

import './floatingbutton.css'
const IconPlus = () => (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.903 24.0949C16.7132 24.0949 16.5699 24.0949 16.4266 24.0949C12.2208 24.0949 8.01512 24.0956 3.8094 24.0949C2.5497 24.0949 1.50265 23.6602 0.702553 22.6476C-0.388915 21.2668 -0.184349 19.0215 1.14735 17.8541C1.92389 17.1732 2.82829 16.9033 3.83766 16.904C8.03194 16.9047 12.2269 16.9047 16.4212 16.9047C16.5652 16.9047 16.7085 16.9047 16.903 16.9047C16.903 16.7331 16.903 16.5925 16.903 16.4512C16.903 12.2458 16.9037 8.0404 16.903 3.83501C16.903 2.83783 17.1701 1.94494 17.8356 1.17182C19.3638 -0.604535 22.4081 -0.313186 23.5702 1.71953C23.9511 2.38566 24.0958 3.09419 24.0964 3.84443C24.0985 8.03838 24.0971 12.233 24.0971 16.427C24.0971 16.5703 24.0971 16.7136 24.0971 16.904C24.2721 16.904 24.4134 16.904 24.5554 16.904C28.7725 16.904 32.989 16.9033 37.2062 16.9047C38.4531 16.9054 39.4914 17.3367 40.2861 18.3372C41.385 19.7213 41.1925 21.9599 39.8655 23.1313C39.0924 23.8143 38.1913 24.0956 37.1799 24.0956C32.9742 24.0949 28.7685 24.0956 24.5628 24.0956C24.4201 24.0956 24.2775 24.0956 24.0944 24.0956C24.0944 24.2779 24.0944 24.4199 24.0944 24.5619C24.0944 28.7672 24.0944 32.9726 24.0944 37.178C24.0944 38.4363 23.6698 39.4866 22.6557 40.288C21.0596 41.5509 18.4857 41.0759 17.4548 39.3204C17.0611 38.6496 16.9037 37.9323 16.903 37.1693C16.9016 32.9753 16.9023 28.7807 16.9023 24.5867C16.903 24.4414 16.903 24.2967 16.903 24.0949Z" fill="white" />
    </svg>
);
/**
 * @const FloatingButton
 * @description Boton flotante inferior derecho
 * @param function  onClick Accion a ejecutar al presionar el boton
 * @param String title, titulo del boton
 * @param String className , clases para el boton
 */
const FloatingButton = ({
    onClick = () => { },
    title,
    content = null,
    buttonStyle = {
        width: "80px",
        height: "80px",
        bottom: "30px",
        right: "30px"
    },
    icon = <IconPlus />,
    disabled=false
}) => {

    
    if(content)
        return <Popover content={content} title={null} >
            <Button className="button-floating " title={title} onClick={onClick} >
                {icon}
            </Button>
        </Popover>
    else
        return <Button className="button-floating " title={title} onClick={onClick} style={buttonStyle} disabled={disabled}>
                {icon}
            </Button>

}

export default FloatingButton;