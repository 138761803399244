import React, { Component, useContext, createRef } from "react";
import { Layout, Row, Col, Typography, Card, Divider, message, List,  Space,  } from "antd";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//Componentes
import CheckoutForm from "../Stripe";
import Socket from "../../../Hooks/Socket";
import Navbar from "./../Navigation/Navbar-v2";
import FooterPublic from "./../Navigation/Footer";
import { Carrito, SetCarrito } from "../../../Hooks/Carrito";

//scss
import "react-credit-cards/es/styles-compiled.css";

const Decimal = require('decimal.js');
const { Title, Text, Paragraph } = Typography;

/**
 * @description Vista de pago de boletos para un evento
 */
class Payment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			evento: {},
			imgUrl: "",
			evento_id: this.props.params.id,
			pagado: false,
			venta: {},
			boletos: [],
			subtotal: 0,
			total: 0,
		};
	}
	componentDidMount() {
		console.log(this.props.cookies)
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
		//this.getEvento();
		if(this.props.cookies?.carrito_id){
			this.getCarrito()
		}else{
			message.error("Carrito vencido o no existente (101)")
			this.props.removeCookie("cart",{ path: '/',});
			this.props.removeCookie("carrito_id",{ path: '/',});
			this.props.navigate("/")
		}
	}

	payment = React.createRef();

	/**
	 * @memberOf Payment
	 * @method getEvento
	 * @description Obtiene la informacion del evento
	 */
	getEvento = () => {
		this.setState({loading: true})
		axios.get("/evento/public/" + this.state.evento_id)
			.then(({ data }) => {
				data.imagen = this.formatImages(data.imagenes, data._id);
				this.setState({
					evento: data,
					imgUrl: data.imagen[0].url,
				});
			})
			.catch((error) => {
				message.error("Error al obtener el evento");
			})
			.finally(() => this.setState({ loading: false }));
	};

	/**
	 * @memberOf Payment
	 * @method formatImages
	 * @description Formate la url de las imagen para que cargen
	 */
	formatImages = (images, id) => {
		let format_images;

		if (Array.isArray(images))
			format_images = images.map((f) => ({
				url:
					axios.defaults.baseURL + "/evento/public/" + id + "?imagen=" + f.file,
			}));
		else {
			format_images = {
				url:
					axios.defaults.baseURL +
					"/evento/public/" +
					id +
					"?imagen=" +
					images.file,
			};
		}

		return format_images;
	};

	/**
	 * @memberOf Payment
	 * @method getCarrito
	 * @description Obtiene la informacion del carrito de Compra
	 */
	getCarrito = () => {
		axios.get("/carrito",{
			params: {
				carrito_id: this.props.cookies?.carrito_id
			}
		}).then(({data}) => {
			console.log("data", data);

			let total = 0, subtotal = 0;

			for (let silla of data.sillas){
				subtotal = Decimal(subtotal).add(silla.categoria?.precio ?? 0).toDecimalPlaces(2).toNumber()
				total = Decimal(subtotal).toDecimalPlaces(2).toNumber()
			}

			this.setState({
				carrito: data,
				detalles_compra: data.sillas,
				subtotal,
				total,
			})
		}).catch((error) => {
			console.log("error", error);
			if(error?.response?.status === 406){
				message.error(error?.response?.data?.message ?? "Error (406)")
				this.props.removeCookie("cart",{ path: '/',});
				this.props.removeCookie("carrito_id",{ path: '/',});
				this.props.navigate("/")
			}

		})
	}

	render() {

		let { subtotal,total } = this.state;

		return (
			<Layout className="layout-landing payment">
				<Navbar />
				{/*<section
					className="image-section"
					style={{
						...(this.state.imgUrl
							? { backgroundImage: `url(${this.state.imgUrl})` }
							: {}),
					}}
				></section>
				<section className="transition-section">
					<Row justify="center">
						<Title className="sub-title">#VenAVandari</Title>
					</Row>
					<Row justify="start">
						<Text className="title pl-3">{this.state.evento?.nombre}</Text>
					</Row>
				</section>*/}
				<section>
					<Row
						justify={"space-around"}
						className="width-100 pd-3"
						gutter={[16, 16]}
						style={{maxWidth: "1600px", margin: "auto"}}
					>
						
						<Col span={24} className="center mb-2">
							<Space direction="vertical">
								<Title className="text-white center">Paga tu(s) boleto(s)</Title>
								<Text className="title text-white center">
									Ingresa tus datos o inicia sesión en tu cuenta si ya tienes una para proceder con tu compra
								</Text>
							</Space>
						</Col>
						<Col lg={12} md={24} className="">
							<Card
								className="resume"
								classNames={{
									header: "resume-header",
									body: "resume-body",
								}}
								title="Carrito"
							>
								<List
									itemLayout="horizontal"
									className="resume-list"
									dataSource={this.state.detalles_compra}
									header={<Row className="component-list-header width-100 pr-1 pl-1">
										<Col span={6} className="center">
			                                <Text className="text-white" strong>SECCIÓN</Text>
			                            </Col>
			                            <Col span={6} className="center">
			                                <Text className="text-white" strong>MESA</Text>
			                            </Col>
			                            <Col span={6} className="center">
			                                <Text className="text-white" strong>SILLA</Text>
			                            </Col>
			                            <Col span={6} className="center">
			                                <Text className="text-white" strong>PRECIO</Text>
			                            </Col>
			                        </Row>}
									renderItem={(item, index) => (
										<List.Item>
											<Row className="width-100 pl-1 pr-1" align={"middle"} justify={"space-between"} >
												<Col className="center" span={6}>{item.seccion ?? "-"}</Col>
												<Col className="center" span={6}>{item.mesa ?? "-"}</Col>
												<Col className="center" span={6}>{item.silla ?? "-"}</Col>
												<Col className="center" span={6}>${item.categoria?.precio.toMoney(true)}&nbsp;<small>MXN</small></Col>
											</Row>
										</List.Item>
									)}
									footer={
										<div className="bg-purple-deg">
											<Divider className="resume-list-footer-divider"/>
											<Row>
												<Col span={16} className="item-costo">Subtotal</Col>
												<Col span={8} className="item-costo">${subtotal?.toMoney(true)} <small>MXN</small></Col>
											</Row>
											<Row>
												<Col span={16} className="total">Total</Col>
												<Col span={8} className="total">${total?.toMoney(true)} <small>MXN</small></Col>
											</Row>
										</div>
									}
								/>
							</Card>
						</Col>
						<Col lg={12} md={24}>
							<CheckoutForm
								{...this.props}
								carrito_id={this.props.cookies?.carrito_id}
								setVenta={(venta)=>this.setState({venta, pagado: true})}
							/>
						</Col>
					</Row>
				</section>
			 
				<FooterPublic />
			</Layout>
		);
	}
}

export default function Vista(props) {
	let cart = useContext(Carrito);
	let setCart = useContext(SetCarrito);
	const [cookies, setCookie, removeCookie] = useCookies(["cart", "carrito_id"]);
	return (
		<Payment
			{...props}
			params={useParams()}
			navigate={useNavigate()}
			location={useLocation()}
			socket={useContext(Socket)}
			cart={cart}
			setCart={setCart}
			cookies={cookies}
			setCookie={setCookie}
			removeCookie={removeCookie}
		/>
	);
}
