import React from 'react'
import { Route, Routes } from "react-router-dom";


import Evento from '../../Components/Admin/Eventos/Evento';
import Eventos from '../../Components/Admin/Eventos/Eventos';
import DetallesEvento from '../../Components/Admin/Eventos/DetallesEvento';

/**
 * 
 * @export
 * @function RouterEventos
 * @description Router for Eventos routes 
 */
function RouterEventos(props) {
  return (
    <Routes>
      <Route path="" element={<Eventos {...props} />} />
      <Route path="/crear" element={<Evento {...props} />} />
      <Route path="/editar/:evento_id" element={<Evento {...props} />} />
      <Route path="/detalle/:evento_id" element={<DetallesEvento {...props} />} />
    </Routes>
  )
}

export default RouterEventos