import React, { Component, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Form,
  Input,
  List,
  Select,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import axios from "axios";

import Navbar from "./Navigation/Navbar-v2";
import FooterPublic from "./Navigation/Footer";
import SelectCategorias from "../Widgets/Inputs/SelectCategorias";
import { EventoCard } from "../Widgets/Cards";
const { Option } = Select;
const { Title } = Typography;

class Eventos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      eventos: {
        data: [],
        limit: 12,
        page: 1,
        total: 0,
        pages: 0,
        filters: [],
        sort: "1",
        fechas: [],
        search: "",
      },
      modal_visible: null,
    };
  }

  filtrosRef = React.createRef();

  componentDidMount() {
    let params = new URL(window.location).searchParams;

    console.log("search", params.get("search"));
    console.log("categorias", params.get("categorias"));
    console.log("sort", params.get("sort"));

    let fechas = params.get("fechas") ? JSON.parse(params.get("fechas")) : null;

    if (Array.isArray(fechas) && fechas.length > 0) {
      fechas = fechas.map((f) => dayjs(f));
    }

    if (
      params.get("search") ||
      params.get("fechas") ||
      params.get("categorias") ||
      params.get("sort")
    ) {
      this.filtrosRef.current.setFieldsValue({
        search: params.get("search"),
        categorias: params.get("categorias"),
        sort: params.get("sort"),
        fechas: fechas,
      });
      this.get({
        search: params.get("search"),
        categorias: params.get("categorias"),
        sort: params.get("sort"),
        fechas: fechas,
      });
    } else {
      this.get();
    }

    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.disableScroll, { passive: false });
  }

 

  /**
   * Obtiene los eventos
   * */
  get = (
    {
      page = this.state.eventos.page,
      limit = this.state.eventos.limit,
      search = this.state.eventos.search,
      sort = this.state.eventos.sort,
      fechas = this.state.eventos.fechas,
      categorias = this.state.eventos.categorias,
    } = this.state.eventos
  ) => {
    this.setState({ loading: true });
    axios
      .get("/public/eventos", {
        params: {
          limit,
          page,
          search,
          sort,
          fechas,
          disponibles: true,
          categorias,
        },
      })
      .then((response) => {
        this.setState({
          loading: false,
          eventos: {
            ...this.state.eventos,
            ...response.data,
            search: search,
            fechas: fechas,
            sort: sort,
            categorias: categorias,
          },
        });

        let newURL = new URLSearchParams(window.location.search);
        newURL.set("search", search);
        newURL.set("fechas", fechas ? JSON.stringify(fechas) : null);
        if (sort) {
          newURL.set("sort", sort);
        }
        if (categorias) {
          newURL.set("categorias", categorias);
        }
        window.history.replaceState(null, null, "?" + newURL.toString());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  disableScroll = (event) => {
    event.preventDefault();
  };

  getNext = (
    {
      page = this.state.eventos.page,
      limit = this.state.eventos.limit,
      search = this.state.eventos.search,
      sort = this.state.eventos.sort,
      fechas = this.state.eventos.fechas,
      categorias = this.state.eventos.categorias,
    } = this.state.eventos
  ) => {
    this.setState({ loading: true });

    console.log("fechas", fechas);
    console.log("search", search);
    console.log("sort", sort);

    if (this.state.eventos.page === this.state.eventos.pages) {
      console.log("Ya no hay mas eventos");
      this.setState({ loading: false });
      return;
    }

    axios
      .get("/public/eventos", {
        params: {
          limit,
          page: page + 1,
          search,
          sort,
          fechas,
          categorias,
        },
      })
      .then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i].imagen = this.formatImages(
            response.data.data[i].imagenes,
            response.data.data[i]._id
          );
        }
        this.setState({
          loading: false,
          eventos: {
            ...this.state.eventos,
            ...response.data,
            data: [...this.state.eventos.data, ...response.data.data],
            search: search,
            fechas: fechas,
            sort: sort,
            categorias: categorias,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({ loading: false });
  };

  /**
   * Cambia el orden de los eventos
   * */
  filters = (e, allValues) => {
    if (!allValues.categorias) allValues.categorias = null;
    this.get({ ...allValues, sort: allValues.order });
  };

  handleScroll = () => {
    if (
      !this.state.loading &&
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
        document.documentElement.scrollHeight
    ) {
      this.getNext();
    }
  };

  // Disable past dates
  disabledDate = (current) => {
    return dayjs().add(-1, "days") >= current;
  };

  render() {
    return (
      <Layout className="layout-landing" >
        <Navbar />
        <Row justify="center">
          <section className="listado-eventos mt-2">
            <Row align={"middle"}>
              <Title className="title m-0">Nuestros Eventos</Title>
            </Row>
          </section>
        </Row>
        <Row justify={"center"}>
          <section
            className="mt-2 mb-2 width-100"
            style={{ maxWidth: "1300px" }}
          >
            <Row gutter={[16, 16]} className="width-100">
              <Col xs={24} className="mt-2 width-100">
                <Form
                  className="filters"
                  onValuesChange={this.filters}
                  ref={this.filtrosRef}
                  layout="vertical"
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item className="width-100 item-light" name="search">
                          <Input placeholder="Buscar eventos" />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} sm={24} className="mt-1">
                      <Form.Item
                        label="Ordenar por"
                        className="width-100 item-light "
                        name="order"
                      >
                        <Select defaultValue={1} className="width-100">
                          <Option value={1}>Fecha (Asc)</Option>
                          <Option value={2}>Fecha (Desc)</Option>
                          <Option value={3}>Costo (Asc)</Option>
                          <Option value={4}>Costo (Desc)</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col lg={8} md={12} sm={24} className="mt-1 width-100" >
                      <Form.Item
                        label="Fechas"
                        className="width-100 "
                        name="fechas"
                      >
                        <DatePicker.RangePicker
                          allowClear={true}
                          disabledDate={this.disabledDate}
                          className="width-100"
                          placeholder={["Fecha inicio", "Fecha Fin"]}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} sm={24} className="mt-1">
                      <Form.Item
                        label="Categorías"
                        className="width-100"
                        name={"categorias"}
                      >
                        <SelectCategorias
                          className="item-light"
                          landing={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col xs={24}>
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                  }}
                  dataSource={this.state.eventos.data}
                  renderItem={(item) => <EventoCard item={item} />}
                  loading={this.state.loading}
                  locale={{
                    emptyText: (
                      <Title
                        className="text-white"
                        style={{ marginTop: "10rem", marginBottom: "10rem" }}
                      >
                        No se encontraron eventos
                      </Title>
                    ),
                  }}
                  className="width-100"
                />
              </Col>
            </Row>
          </section>
        </Row>
        <FooterPublic />
      </Layout>
    );
  }
}

export default function Vista(props) {
  return <Eventos {...props} />;
}
