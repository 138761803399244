import React, { Component } from "react";
import { Avatar, Card, Carousel, Divider, Flex, Layout, List, Spin, Typography, message, Row,  Col, Space, Result } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { getBanner } from "../../Utils";
import { useNavigate } from "react-router-dom";

//Compoenntes
import useSearch from "../../../Hooks/useSearch";
import Logged, { User } from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { ButtonDelete, ButtonEdit, ButtonView } from "../../Widgets/Buttons";

//css
import "../../../Styles/Modules/Admin/eventos.scss";

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;

/**
 *
 *
 * @export
 * @class Eventos
 * @extends {Component}
 * @description Vista donde se listan todos los eventos
 */
class Eventos extends Component {
	static contextType = Logged;

	back = () => {
		this.props.history.goBack();
	};

	/**
	 *Creates an instance of Eventos.
	 * @param {*} props
	 * @memberof Eventos
	 */
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			eventos: {
				data: [],
				limit: 8,
				page: 1,
				total: 0,
				pages: 0,
				filters: [],
			},
			modal_visible: null,
		};
	}

	componentDidMount() {
		this.props.setShowSearch(true);
		this.getEventos();
	}

	componentDidUpdate(prevProps) {
		if (this.props.search != prevProps.search) {
			this.getEventos();
		}
	}

	/**
	 *
	 * @memberof Eventos
	 * @method getEventos
	 * @description Obtiene la informacion de los eventos
	 */
	getEventos = async (
		{
			page = this.state.eventos.page,
			limit = this.state.eventos.limit,
			search = this.props.search,
		} = this.state.eventos
	) => {
		this.setState({ loading: true });
		axios
			.get("/eventos", {
				params: {
					limit,
					page,
					search,
				},
			})
			.then(({ data }) => {
				this.setState({ eventos: data });
			})
			.catch((res) => {
				message.error(res.response.data.message);
			})
			.finally(() => this.setState({ loading: false }));
	};

	render() {
		return (
			<>
				<Spin spinning={this.state.loading}>
					<PageHeader
						className="admin-page-header"
						breadcrumb={{
							items: [
								{
									title: "Eventos",
								},
							],
						}}
					/>

					<Content
						id={"eventos-content"}
						className="admin-content content-bg pd-1"
					>
						<List
							grid={{
								gutter: 12,
								xs: 1,
								sm: 1,
								md: 2,
								lg: 2,
								xl: 3,
								xxl: 5,
							}}
							className="list-eventos"
							locale={{ emptyText: "Sin Eventos" }}
							dataSource={this.state.eventos.data}
							pagination={{
								current: this.state.eventos.page,
								pageSize: this.state.eventos.limit,
								total: this.state.eventos.total,
								hideOnSinglePage: false,
								position: "bottom",
								align: "start",
								onChange: (page, limit) => this.getEventos({ page, limit }),
							}}
							renderItem={(item) => (
								<List.Item className="list-item">
									<Card
										className="card-evento"
										classNames={{
											cover: "p-0 m-0",
										}}
										cover={
											<>
												<Carousel
													autoplay
													dots={false}
													arrows={false}
													className="carousel-cover"
												>
													{item.imagenes?.length > 0 ? item.imagenes?.map((img) => {
														return (
															<img
																alt="evento-imagen"
																src={getBanner(img, item._id, "eventos")}
																className="image"
															/>
														);
													}): (
														<img
															alt="evento-imagen"
															src={"/img/fallback.jpg"}
															className="image"
														/>
													)}
												</Carousel>
												<span className="tag">
													{item.comprados ?? 0} Participantes
												</span>
											</>
										}
									>
										<Row gutter={[0,8]} className="width-100 pb-1">
											<Col span={24}>
												<Title className="m-0" level={4}>{item.nombre}</Title>
												<Paragraph
													className="description"
													ellipsis={{ rows: 5 }}
												>
													{item.descripcion ?? "Sin descripción"}
												</Paragraph>
											</Col>
											<Col span={24} className="center">
												<Space>
													<Flex gap={3} wrap align="center">
														<Avatar
															size={"small"}
															shape="circle"
															className="bg-black"
														>
															V
														</Avatar>
														<Text className="text-gray subtitle">Vandari HM</Text>
													</Flex>
													<Divider className="divisor" type="vertical" />
													<Text className="text-gray subtitle">
														{dayjs(item.fecha).format("DD/MM/YY")}
														{" - "}
														{dayjs(item.horario).format("HH:mm A")}
													</Text>
												</Space>
											</Col>
											<Col span={24} className="center">
												<Space>
													<ButtonView
														size="small"
														disabled={!this.props.canView}
														onClick={() => this.props.navigate("detalle/" + item._id)}
													/>
													<ButtonEdit
														size="small"
														disabled={!this.props.canEdit}
														onClick={() =>
															this.props.navigate("editar/" + item._id)
														}
													/>
													<ButtonDelete
														size="small"
														disabled={!this.props.canDelete}
														onConfirm={() =>
															axios
																.delete("/eventos/", {
																	params: { evento_id: item._id },
																})
																.then((response) => {
																	message.success(response?.data?.message);
																	this.getEventos();
																})
																.catch((error) => {
																	message.error(error?.response?.data?.message);
																	this.getEventos();
																})
														}
													/>
												</Space>
											</Col>
										</Row>
									</Card>
								</List.Item>
							)}
						/>
					</Content>

					{this.props.canCreate && (
						<FloatingButton
							title="Nuevo registro"
							onClick={() => this.props.navigate("crear")}
						/>
					)}
				</Spin>
			</>
		);
	}
}

export default function Vista(props) {
	const user = React.useContext(User);
	const { setShow } = useSearch();

	let navigate = useNavigate()

	const permisos = usePermissions(user?.rol_id?.permisos, {
		canEdit: ["eventos", "edit"],
		canDelete: ["eventos", "delete"],
		canCreate: ["eventos", "create"],
		canView: ["eventos", "access"],
	});

	if(permisos.canView === false)
	    return <Result
	      className="mt-3"
	      status="warning"
	      title="No tienes acceso a este módulo"
	    /> 

	return (
		<Eventos
			{...props}
			setShowSearch={setShow}
			{...permisos}
			navigate={navigate}
		/>
	);
}
