import React, { Component, useRef } from "react";
import { Modal, Spin, message, Space, Button, Drawer, Row, Radio, Col, Typography } from "antd";
import axios from "axios";
import Seating from "../Seating/PublicSeating";
// import Seating from "../Admin/Seating/PublicSeating";
const { Title } = Typography;
/**
 *
 *
 * @class FormAsientos
 * @extends {Component}
 */
class FormAsientos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			evento: {
				secciones: [],
			},
			venta: {
				fecha: new Date(),
				estatus: 1,
				total: 0,
				subtotal: 0,
				iva: 0,
				detalles: [],
			},

			// selectedSeats: this.props.detalles, //obj de las sillas seleccioandas
			seats_selectedIds: this.props.detalles, //ids de las sillas seleccionadas

			seccion: null
		}
	}

	formRef = React.createRef();

	componentDidMount() {
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
		this.getEvento();
	}

	componentDidUpdate(prevProps) {

	}

	/**
	 * @memberof FormAsientos
	 * @method getEvento
	 * @description Obtiene la informacion de un evento
	 */
	getEvento = () => {
		this.setState({ loading: true });
		axios.get("/eventos/" + this.props.evento_id)
			.then(({ data }) => {
				console.log("getEvento", data);
				this.setState({
					evento: data
				}, () => {
					console.log("data?.secciones", data?.secciones?.length)
					if (data?.secciones?.length > 0) {
						this.setSection(0)
					}
				})
			})
			.catch((error) => {
				console.log("error", error);
				message.error("Error al obtener el evento");
			})
			.finally(() => this.setState({ loading: false }));
	};



	/**
	 * @memberof FormAsientos
	 * @method addSeat
	 * @description añade el objeto del asiento seleccionado a la lista en el state, solamante si es nuevo
	 */
	addSeat = (_seat) => {
		const { seccion } = this.state
		const { silla_id, mesa_id, } = _seat
		this.setState(state => {
			state.seats_selectedIds.push({
				seccion_id: seccion._id,
				silla_id,
				mesa_id
			})
			return state
		})
	}

	/**
	 * @memberof FormAsientos
	 * @method addSeat
	 * @description añade el objeto del asiento seleccionado a la lista en el state, solamante si es nuevo
	 */
	deleteSeat = (_seat) => {
		this.setState((prevState) => {
			const selectedSeats = prevState.seats_selectedIds.filter((boleto) => (boleto.silla_id?.toString() !== _seat.silla_id?.toString()))
			return { seats_selectedIds: selectedSeats }
		});
	};

	onSave = () => {
		this.props.setBoletos(this.state.selectedSeats);
		this.props.onClose();
	}

	setSection = (value) => this.setState({
		seccion: this.state.evento.secciones[value],
		seccion_index: value
	})

	getNumberAsientos = () => {
		const data = [...this.state.seats_selectedIds];

		// Crear conjuntos para almacenar mesas y sillas únicas
		const uniqueMesas = new Set();
		const uniqueSillas = new Set();

		data.forEach(item => {
		  uniqueMesas.add(item.mesa_id);
		  uniqueSillas.add(item.silla_id);
		});

		// Contar la cantidad de mesas y sillas únicas
		const totalMesas = uniqueMesas.size;
		const totalSillas = uniqueSillas.size;

		return `${totalMesas} Mesas - ${totalSillas} asientos seleccionados`
	}

	render() {
		console.log("this.state", this.state.seats_selectedIds)

		return (
			<>
				<Row style={{ justifyContent: "center", marginBottom: "1em" }}>
					<Col span={12} className="center">
						<Title level={4}>{this.getNumberAsientos()}</Title>
					</Col>
				</Row>
				<Row style={{ justifyContent: "center", marginBottom: "1em" }}>
					<Radio.Group
						options={
							this.state.evento?.secciones?.map((value, index) => {
								const { nombre, _id, data } = value
								return ({
									label: nombre,
									value: index,
									title: nombre,
									item: value
								})
							})
						}
						optionType="button"
						buttonStyle="solid"
						onChange={(event) => this.setSection(event.target.value)}
						value={this.state.seccion_index}
					/>
				</Row>
				<Row style={{ justifyContent: "center", marginbottom: "1em" }}>
					<div>
						<Seating
							ref={this.refStage}
							value={this.state.seccion?.data}
							addSeat={this.addSeat}
							deleteSeat={this.deleteSeat}
							selectedSeats={this.state.seats_selectedIds}
							venta_admin={true}
							limit={1000}
							columns={41}
	              			rows={65}
						></Seating>
					</div>
				</Row>
			</>
		);
	}
}

export default function Vista(props) {
	const { open = false, onClose = () => { }, venta_id } = props;


	const ref = useRef()

	return (
		<Drawer
			open={open}
			onCancel={onClose}
			onClose={onClose}
			destroyOnClose={true}
			zIndex={1000}
			title={venta_id ? "Editar Asientos" : "Seleccionar Asientos"}
			closable={true}
			// width={800}
			style={{ height: "90vh" }}
			height="90vh"
			placement="bottom"
			extra={<>
				<Space>
					<Button
						onClick={() => {
							// console.log("ref", ref.current.state.seats_selectedIds)
							onClose(ref.current?.state?.seats_selectedIds)
						}}
					>
						Aceptar
					</Button>
				</Space>
			</>}
		>
			<FormAsientos ref={ref} {...props} />
		</Drawer>
	);
}
