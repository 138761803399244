import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Affix,
  Layout,
  Menu,
  Row,
  Col,
  Badge,
  Button,
  Dropdown,
  Avatar,
  Drawer,
  message,
  Space,
} from "antd";
import { BsPersonFill, BsCartFill } from "react-icons/bs";
import { IoMenu } from "react-icons/io5";
import axios from "axios";
import { useCookies } from "react-cookie";
import "../../../Styles/Modules/Landing/headerv2.scss";
import { User, SetUser } from "../../../Hooks/Logged";

const { Header } = Layout;

const PublicHeader = (props) => {
  // Estados
  const [isAffixed, setIsAffixed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [responsiveMenu, setResponsiveMenu] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(["cart"]);
  //Hooks
  const navigate = useNavigate();

  //Contextos
  const user = useContext(User);
  const setUser = useContext(SetUser);

  const goTop = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onClick = (item) => {
    navigate(item.key);
  };
  // Variables

  const items = [
    {
      label: "Inicio",
      key: "/",
      onClick: () => goTop(),
    },
    {
      label: "Eventos",
      key: "/#eventos",
    },
    {
      label: "Galeria",
      key: "/#galeria",
    },
    {
      label: "Nosotros",
      key: "/#nosotros",
    },
    {
      label: "Mai Tai",
      key: "/#mai-tai",
    },
    {
      label: (
        <Button
          type="primary"
          className="btn-gradiente-morado"
          onClick={onClick}
        >
          Contacto
        </Button>
      ),
      key: "/#contacto",
    },
  ];

  const redirect = () => {
    console.log("user?.rol_id", user?.rol_id);
    if (!user?.rol_id) navigate("/customer/dashboard");
    if (user?.rol_id) navigate("/admin/dashboard");

    navigate("/login");
  };

  /**
   * @const cerrarSesion
   * @description Cierra la sesion
   */
  const cerrarSesion = () => {
    removeCookie("cart", { path: "/" });
    removeCookie("carrito_id", { path: "/" });
    setUser(undefined);
    sessionStorage.clear();
    navigate("/");
  };

  const getCarrito = () => {
    axios
      .get("/carrito", {
        params: {
          carrito_id: cookies?.carrito_id,
        },
      })
      .then(({ data }) => {
        setCookie(
          "cart",
          data.sillas.map((e) => ({
            silla_id: e.silla_id,
            mesa_id: e.mesa_id,
          })),
          { path: "/" }
        );
        setCookie("carrito_id", data._id, { path: "/" });
      })
      .catch((error) => {
        if (error?.response?.status === 406) {
          removeCookie("cart", { path: "/" });
          removeCookie("carrito_id", { path: "/" });
        }
      });
  };

  useEffect(() => {
    getCarrito();
  }, []);

  return (
    <Affix onChange={setIsAffixed} style={{ zIndex: 999 }}>
      <Header className={"navbar-public" + (isAffixed ? " affixed" : "")}>
        <Row
          justify="space-between"
          className="width-100"
          style={{ margin: "auto" }}
        >
          <Col xs={5}>
            <Row justify={"center"} align={"middle"} className="height-100">
              <a onClick={goTop} href="#">
                <img src="/logo.png" alt="Logo" className="logo" />
              </a>
            </Row>
          </Col>
          <Col xs={0} lg={15}>
            <Menu
              onClick={onClick}
              mode="horizontal"
              items={items}
              className="width-100 transparent"
            />
          </Col>
          <Col xs={0} lg={4} className="">
            {user ? (
              <Space>
                <Dropdown
                  placement="bottomRight"
                  className="menu-login"
                  arrow={true}
                  overlay={
                    <Menu>
                      <Menu.Item key="0" onClick={redirect}>
                        Dashboard
                      </Menu.Item>
                      <Menu.Item key="0" onClick={() => cerrarSesion()}>
                        Cerrar Sesión
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Button className="btn-logged" type="ghost">
                    {user?.nombre}
                  </Button>
                </Dropdown>
                <Badge count={cookies.cart?.length ?? 0}>
                  <Button
                    type="ghost"
                    onClick={() => {
                      if (cookies.cart?.length > 0) {
                        navigate("/carrito/pago");
                      }
                    }}
                    style={{ fontSize: "16px" }}
                    icon={<BsCartFill className="text-white" />}
                  ></Button>
                </Badge>
              </Space>
            ) : (
              <Button
                className="btn-gradiente-morado"
                type="primary"
                onClick={() => navigate("/login")}
              >
                Iniciar Sesión
              </Button>
            )}
          </Col>
          <Col xs={4} lg={0}>
            <Button
              type="link"
              onClick={() => setResponsiveMenu(true)}
              icon={<IoMenu className="text-white" />}
            ></Button>
          </Col>
        </Row>
        <Drawer
          placement="right"
          closable={false}
          onClose={() => setResponsiveMenu(false)}
          visible={responsiveMenu}
          width={300}
          bodyStyle={{ zIndex: 1000 }}
          className="navbar-public affixed"
        >
          <div className="drawer-header"></div>

          <Menu
            onClick={onClick}
            mode="vertical"
            items={items}
            className="width-100 transparent"
          />
          <div className="mt-1 ml-1">
            {user ? (
              <>
                <Dropdown
                  className="menu-login"
                  arrow={true}
                  overlay={
                    <Menu>
                      <Menu.Item key="0" onClick={redirect}>
                        Dashboard
                      </Menu.Item>
                      <Menu.Item key="1" onClick={cerrarSesion}>
                        Cerrar Sesión
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Link
                    onClick={(e) => e.preventDefault()}
                    className="user-menu"
                  >
                    {user?.nombre} <Avatar src={<BsPersonFill />} />
                  </Link>
                </Dropdown>
              </>
            ) : (
              <Button
                type="link"
                size="small"
                icon={<BsPersonFill className="text-white" />}
                onClick={() => navigate("/login")}
              ></Button>
            )}
            <Badge count={props.cart?.length ?? 0} className="ml-1">
              <Button
                type="link"
                size="small"
                onClick={setIsVisible}
                icon={<BsCartFill className="text-white" />}
              ></Button>
            </Badge>
          </div>
        </Drawer>
      </Header>
    </Affix>
  );
};

export default PublicHeader;
