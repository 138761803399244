import React, { Component } from "react";
import { Row, Col, Typography, Spin, Space, message, List, Card, Result } from "antd";
import axios from "axios";
import PageHeader from "../../Widgets/PageHeader/PageHeader";

import Logged from "../../../Hooks/Logged";
import Avatar from "../../Widgets/Avatar/Avatar";

import { getResponseError } from "../../Utils";
import dayjs from "dayjs";
import usePermissions from "../../../Hooks/usePermissions";

const { Text } = Typography;

/**
 *
 *
 * @export
 * @class Bitacora
 * @extends {Component}
 * @description Vista donde se listan todos los bitacora
 */
class Bitacora extends Component {
  static contextType = Logged;

  /**
   *Creates an instance of Bitacora.
   * @param {*} props
   * @memberof Bitacora
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      bitacora: {
        data: [],
        limit: 20,
        page: 1,

        total: 0,
        pages: 0,
      },
    };
  }

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    this.getBitacora();
  }

  /**
   *
   * @memberof Bitacora
   * @method getBitacora
   * @description Obtiene la informacion de los Bitacora y actualiza los valores locales de la lista.
   */
  getBitacora = async (
    {
      page = this.state.bitacora.page,
      limit = this.state.bitacora.limit,
    } = this.state.bitacora
  ) => {
    this.setState({
      loading: true,
      bitacora: { ...this.state.bitacora, page, limit },
    });

    axios
      .get("/bitacora", {
        params: { limit, page },
      })
      .then(({ data }) => {
        this.setState({ bitacora: data });
      })
      .catch((res) => {
        message.error(getResponseError(res.response));
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <PageHeader
          className="admin-page-header"
          breadcrumb={{
            items: [
              {
                title: "Bitacora",
              },
            ],
          }}
        />
        <List
          className="component-list"
          itemLayout="horizontal"
          locale={{ emptyText: "Sin Bitacora" }}
          dataSource={this.state.bitacora.data}
          pagination={{
            current: this.state.bitacora.page,
            pageSize: this.state.bitacora.limit,
            total: this.state.bitacora.total,
            hideOnSinglePage: false,
            position: "bottom",
            onChange: (page, limit) => this.getBitacora({ page, limit }),
          }}
          header={
            <Row className="header-list width-100 pr-1 pl-1">
              <Col span={14} className="center">
                <Text strong>Descripción</Text>
              </Col>
              <Col span={5} className="center">
                <Text strong>Usuario</Text>
              </Col>
              <Col span={5} className="center">
                <Text strong>Fecha</Text>
              </Col>
            </Row>
          }
          renderItem={(item) => (
            <List.Item className="component-list-item">
              <Card className="card-list">
                <Row className="width-100 ">
                  <Col span={14} className="center">
                    <Text>{item.descripcion}</Text>
                  </Col>
                  <Col span={5} className="center">
                    <Space>
                      <Avatar
                        size={35}
                        color={item.usuario?.color}
                        name={item.usuario?.nombre}
                        url={
                          item.usuario?.avatar
                            ? {
                                url:
                                  axios.defaults.baseURL +
                                  "/usuarios/" +
                                  item.usuario?._id,
                                avatar: item.usuario?.avatar?.name,
                              }
                            : null
                        }
                      />
                      <Text>{item.usuario?.nombre}</Text>
                    </Space>
                  </Col>
                  <Col span={5} className="center">
                    <Text>
                      {dayjs(item.createdAt).format("DD/MM/YYYY HH:mm")}
                    </Text>
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </Spin>
    );
  }
}

export default function Vista(props) {

  let user = React.useContext(Logged);

  const permisos = usePermissions(user?.rol_id?.permisos, {
      canView: ["bitacora"],
  });

  if(permisos.canView === false)
      return <Result
        className="mt-3"
        status="warning"
        title="No tienes acceso a este modulo"
      />

  return <Bitacora {...props} />;
}
