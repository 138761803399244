import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Collapse, Button, Input, Row } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import TableEditor from './Seating'; // Asegúrate de importar correctamente el TableEditor

const { Panel } = Collapse;

const MultiSectionTableEditor = forwardRef((props, ref) => {
  const [sections, setSections] = useState([{ id: 1, nombre: 'Sección Inicial', data: { mesas: [], blocked: [] } }]);
  const sectionRefs = useRef([]);

  useImperativeHandle(ref, () => ({
    getValues: () => {
      return sections.map((section, index) => ({
        id: section.id,
        nombre: section.nombre,
        data: sectionRefs.current[index]?.getValues(),
      }));
    },
    setValues: (newSections) => {
      setSections(newSections);
    }
  }));

  useEffect(() => {
    sections?.forEach((section, index) => {
      // if (sectionRefs.current[index]) {
      //   sectionRefs.current[index].setValues(section.data.mesas, section.data.blocked);
      // }
    });
  }, [sections]);

  const addSection = () => {
    const newSection = {
      id: sections.length + 1,
      nombre: `Sección ${sections.length + 1}`,
      data: { mesas: [], blocked: [] },
    };
    setSections([...sections, newSection]);
  };

  const removeSection = (id) => {
    setSections(sections.filter(section => section.id !== id));
  };

  const updateSectionnombre = (index, nombre) => {

    let updatedSections = sections;

    updatedSections[index].nombre = nombre
    // let updatedSections = sections.map(section =>
    //   section.id === id ? { ...section, nombre } : section
    // );
    setSections([...updatedSections]);
  };

  return (
    <div>
      <Collapse accordion>
        {sections?.map((section, index) => (
          <Panel
            forceRender={true}
            header={
              <Input
                value={section.nombre}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={(e) => updateSectionnombre(index, e.target.value)}
                style={{ width: 'calc(100% - 40px)', marginRight: 8 }}
              />
            }
            key={section.id}
            extra={<CloseOutlined onClick={() => removeSection(section.id)} />}
          >
            <TableEditor
              value={section.data}
              ref={el => sectionRefs.current[index] = el}
              width={1000}
              columns={41}
              rows={65}
              onChangeCategoria={props.onChangeCategoria}
            />
          </Panel>
        ))}
      </Collapse>
      <Row style={{ marginTop: 16 }}>
        <Button type="dashed" onClick={addSection} icon={<PlusOutlined style={{color: "black"}}/>}>
          Agregar Sección
        </Button>
      </Row>
    </div>
  );
});

export default MultiSectionTableEditor;
