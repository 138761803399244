import React from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Space,
  Statistic,
  Progress,
  Button,
  Badge,
  Flex,
  Select,
  Carousel,
  Dropdown,
} from "antd";
import "./cards.scss";
import dayjs from "dayjs";
import axios from "axios";
import convert from "color-convert";
import ReactECharts from "echarts-for-react";
import { IoCalendar } from "react-icons/io5";
import CustomAvatar from "./Avatar/Avatar";

import { MoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { FuenteImage, getBannerPublic } from "../Utils";
const { Text  } = Typography;

/**
 *
 *
 * @const CardProximoEvento
 * @extends {React.Component}
 * @description Card para eventos proximos del dashboard principal
 */
const CardProximoEvento = ({
  description = "",
  total = 0,
  vendidos = 10,
  fecha = new Date(),
  horario,
  onClick,
  showTotales = false,
}) => {
  return (
    <Card
      bordered={false}
      className="card card-statistics"
      style={{ minHeight: "200px" }}
      onClick={onClick}
      hoverable
    >
      <Row className="card-header width-100 ">
        <Col xs={12} sm={6} xl={6} className="center ">
          <Text className="date">{dayjs(fecha).format("D")}</Text>
        </Col>
        <Col xs={12} sm={10} xl={12} className="">
          <Space direction="vertical" size={0}>
            <Text className="date-text dark">
              <span>{dayjs(fecha).format("MMMM")}</span>{" "}
              {dayjs(fecha).format("YYYY")}
            </Text>
            <Text className="date-text">
              {dayjs(horario).format("h:mm A")}
            </Text>
          </Space>
        </Col>
      </Row>
      <Card.Grid
        hoverable={false}
        className="grid"
        style={{ minHeight: "76px" }}
      >
        <Text className="description">
          {description ? description : "Sin Descripción"}
        </Text>
      </Card.Grid>
      <Card.Grid hoverable={false} className="grid">
        {showTotales ? (
          <Text className="footer">{`${vendidos} de ${total} boletos vendidos`}</Text>
        ) : null}
      </Card.Grid>
    </Card>
  );
};

const CardVentas = ({
  titulo = "",
  className = "",
  monto = 0,
  children = null,
  classNameStatistics = "statics-light",
  showtext = true,
  color = "#fff",
  filtrar = false,
  options = {},
}) => {
  if (!options || Object.keys(options).length == 0)
    options = {
      grid: {
        show: false,
      },
      xAxis: {
        type: "category",
        show: false,
      },
      yAxis: {
        type: "value",
        show: false,
      },
      series: [
        {
          data: [
            820, 300, 501, 934, 334, 544, 134, 1290, 430, 1320, 4, 232, 290,
          ],
          type: "line",
          smooth: true,
          color: color,
        },
      ],
      tooltip: {
        trigger: "axis",
      },
    };

  return (
    <Card
      title={titulo}
      className={`card card-md-4  ${className}`}
      extra={
        filtrar ? (
          <Select
            placeholder="Periodo"
            className="width-100 select-borderless"
            options={[
              {
                label: "Semanal",
                value: "week",
              },
              {
                label: "Mensual",
                value: "month",
              },
              {
                label: "Anual",
                value: "year",
              },
            ]}
          />
        ) : null
      }
    >
      {children ? (
        children
      ) : (
        <>
          <Statistic
            title={showtext ? "venta aproximada" : null}
            className={`flex-between ${classNameStatistics} capitalize`}
            prefix={"$"}
            value={monto}
          />
          <ReactECharts
            option={options}
            style={{ width: "100%", maxWidth: 350, maxHeight: 100 }}
          />
        </>
      )}
    </Card>
  );
};
/**
 *
 *
 * @const CardReporteDashboard
 * @extends {React.Component}
 * @description Card para reporte de texto de tickets del dashboard principal
 */
const CardReporteDashboard = ({
  titulo = "Reporte de 30 Días",
  textos = [
    {
      titulo: "Tickets Vendidos",
      monto: 40,
    },
    {
      titulo: "TIckets en Ventas",
      monto: 1000,
    },
  ],
  description = "",
}) => {
  return (
    <Card className="card  card-report card-h-full bg-purple flex-around center-content">
      <Card.Grid hoverable={false} className="grid">
        <Text className="title capitalize text-white ">{titulo}</Text>
      </Card.Grid>

      {textos &&
        textos.map(({ titulo, monto }) => (
          <Card.Grid hoverable={false} className="grid">
            <Text className="description capitalize text-white">{titulo}</Text>
            <Text className="amount text-white" strong>
              {monto}
            </Text>
          </Card.Grid>
        ))}
      <Card.Grid hoverable={false} className="grid">
        <Text className="description text-white">{description}</Text>
      </Card.Grid>
    </Card>
  );
};

/**
 *
 *
 * @const CardPorcentajeEventos
 * @extends {React.Component}
 * @description Card para reporte de texto de tickets del dashboard principal
 */
const CardPorcentajeEventos = ({
  titulo = "title",
  porcentaje = 0,
  color = "#43E794",
  footer = [
    {
      titulo: "General",
      monto: 2450,
      color: "#43E794",
    },
    {
      titulo: "Online",
      monto: 7550,
      color: "#FDA240",
    },
  ],
}) => {
  return (
    <Card
      title={titulo}
      className="card card-title-custom card-h-full capitalize"
    >
      <Progress
        type="circle"
        percent={porcentaje}
        strokeColor={color}
        className="center pd-1"
      />
      <Space direction="horizontal" className="center flex-around bottom-fixed">
        {footer &&
          footer.map(({ titulo, monto, color }) => (
            <Statistic title={titulo} value={monto} prefix="$" />
          ))}
      </Space>
    </Card>
  );
};

/**
 * @const CardEventoProspectos
 * @description Card de la descripcion del step del project manager
 * @param {*} titulo Object con la informacion del step
 * @param String color Color del step
 */
const CardEventoProspectos = ({
  titulo = "Evento Random ",
  total = 10,
  color = "#BABABA",
  banner = null,
  evento_id = null
}) => {

  let url = null
  if(banner && evento_id){
    url =  axios.defaults.baseURL + "/eventos/" + evento_id + "?imagen=" + banner.file + "&Authorization=" + sessionStorage.getItem("token");
  }

  return (
    <Card
      className="card-evento-prospecto"
      cover={<span className="color" style={{ backgroundColor: color }} />}
      title={
        <Space direction="horizontal" className="flex-between">
          <CustomAvatar 
            shape="square" 
            color={color} 
            image={url}
          />
          <Text ellipsis style={{ color: color }}>
            {titulo}
          </Text>
          <Text style={{ color: color }}>{total}</Text>
        </Space>
      }
      bodyStyle={{ display: "none" }}
    ></Card>
  );
};
/**
 * @const CardEventoProspectos
 * @description Card de la descripcion del step del project manager
 * @param {*} titulo Object con la informacion del step
 * @param String color Color del step
 */
const CardEventoProspecto = ({
  cliente_id,
  nombre = "",
  telefono = "",
  fuente = 3,
  color = "#DADADA",
  onClick = () => {},
  onCopy = () => {},
  openModal = () => {},
  prospecto_selected = null,
  showMenu = false,
  mensajes_nuevos = 0,
}) => {
  return (
    <Card
      className={`card-prospecto-item ${prospecto_selected ? "selected" : ""}`}
      onClick={onClick}
      hoverable
    >
      <Flex justify="space-between">
        <Flex direction={"row"} flex={1} align="center" justify="center">
          <div
            className="container-prospectos-card"
            onClick={() => openModal(cliente_id)}
          >
            <Badge count={mensajes_nuevos} className="bagde">
              <CustomAvatar size="" name={nombre} />
            </Badge>
            <Button
              rootClassName="icon "
              className=" text-white"
              icon={FuenteImage(fuente).icon}
              style={FuenteImage(fuente).style}
            />
          </div>
        </Flex>
        <Flex
          direction={"row"}
          flex={3}
          align="center"
          justify="start"
          gap="middle"
        >
          <div className="flex-left-column">
            <Text className="capitalize">{nombre}</Text>
            <small className="text-gray">{telefono}</small>
          </div>
        </Flex>
        <Flex
          direction={"row"}
          flex={1}
          align="center"
          justify="center"
          gap="middle"
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              size=""
              icon={<IoCalendar />}
              style={{
                color: color,
                background: `rgba(${convert.hex.rgb(color)},0.3)`,
                border: `2px solid ${color}`,
              }}
            ></Button>
            {showMenu ? (
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "1",
                      label: "Copiar",
                      onClick: () => {
                        onCopy();
                      },
                    },
                  ],
                }}
                placement="bottomLeft"
              >
                <MoreOutlined />
              </Dropdown>
            ) : null}
          </Space>
        </Flex>
      </Flex>
    </Card>
  );
};
/**
 * @const CardResumenVentas
 * @description Card del resumen de ventas del detalle del evento
 * @param {*} title titulo de card
 * @param String color Color del step
 */
const CardResumenVentas = ({
  title = "Resumen de Ventas",
  legend_data = [],
  data_series = [],
  onSelect = () => {},
}) => {
  const options = {
    tooltip: {},
    legend: {},
    grid: {
      show: false,
    },
    xAxis: {
      data: legend_data,
      type: "category",
      show: true,
    },
    yAxis: {},
    series: [
      {
        name: "Ventas",
        type: "bar",
        data: data_series,
        itemStyle: {
          borderRadius: [50, 50, 0, 0],
          color: "#FB3E7A",
        },
        barWidth: 10,
      },
    ],
  };
  return (
    <Card
      title={title}
      extra={
        <Select
          defaultValue={"week"}
          placeholder="Esta semana"
          options={[
            { label: "Semanal", value: "week" },
            { label: "Mensual", value: "month" },
          ]}
          onSelect={(value) => onSelect(value)}
        />
      }
    >
      <Flex vertical gap={28}>
        {/* Grafica */}
        <ReactECharts option={options} style={{ width: "100%" }} />
      </Flex>
    </Card>
  );
};

const CardGraficaTickets = ({
  cardClass = "",
  titulo,
  legend_data = [],
  series_data = [],
}) => {
  const options = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left:"auto",
      data: legend_data,
    },
    series: [
      {
        type: "pie",
        data: series_data,
      },
    ],
  };
  return (
    <Card className={`${cardClass}`} title={titulo}>
      <ReactECharts
        option={options}
        style={{ maxHeight: 170, maxWidth: 500 }}
      />
    </Card>
  );
};

/**
 * @const CardTag
 * @description Card en forma de tag para expecificar icono, texto y contenido con estilos especificos
 * @param {Component} icon
 * @param {String} titulo
 * @param {Component} content
 * @returns
 */
const CardTag = ({ icon = <></>, titulo = "", content = <></> }) => {
  return (
    <Flex gap={24} className="card-tag" align="center">
      {icon}
      <Flex className="card-tag-data" vertical>
        <Text className="title">{titulo}</Text>
        {content}
      </Flex>
    </Flex>
  );
};

const EventoCard = (props) => {
  const navigate = useNavigate();

  const ver = () => {
    navigate(`/evento/${props.item?.url}`, {
      state: { evento_id: props.item._id },
    });
  };

  return (
    <Card
      className="event-card"
      actions={[
        <Button type="primary" className="ver-evento" onClick={ver}>
          Ver Tickets
        </Button>,
      ]}
      cover={
        <>
          <Carousel autoplay dots={false} arrows={false}>
            {props.item.imagenes?.length === 0 ? (
              <img
                alt={props.item.nombre}
                src={"/img/fallback.jpg"}
                className=" pd-1"
                fallback="/img/fallback.jpg"
                preview={false}
              />
            ) : null}
            {props.item.imagenes?.map((img) => {
              return (
                <img
                  alt={props.item.nombre}
                  src={getBannerPublic(img, props.item._id, "evento/public")}
                  className=" pd-1"
                  fallback="/img/fallback.jpg"
                  preview={false}
                />
              );
            })}
          </Carousel>
        </>
      }
    >
      <Row className="h-50">
        <Col span={24}>
          <Text className="fecha">
            {dayjs(props.item?.fecha).format("DD [de] MMMM [del] YYYY")}
          </Text>
        </Col>
        <Col span={24}>
          <Text className="nombre">{props.item?.nombre}</Text>
        </Col>
        <Col span={24}>
          <Text className="costo">
            Desde $ {props.item.menor_precio?.toMoney(true) ?? "0"} MXN
          </Text>
        </Col>
      </Row>
    </Card>
  );
};
export {
  CardProximoEvento,
  CardReporteDashboard,
  CardPorcentajeEventos,
  CardVentas,
  CardEventoProspectos,
  CardEventoProspecto,
  CardResumenVentas,
  CardGraficaTickets,
  CardTag,
  EventoCard,
};
