import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
} from "antd";

import "../../../Styles/Modules/Landing/landing.scss";

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;


const Galeria = ()=>{

    return(
        <section
        className="mt-3 section"
        id="galeria"
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
      >
        <div className="galeria">
          <Row>
            <Title className="sub-title">Galería</Title>
          </Row>
          <Row align={"middle"}>
            <Title className="title m-0">Efecto Vandari</Title>
          </Row>
          <Row
            className="mt-1 width-100"
            gutter={{
              lg: 64,
              md: 32,
              sm: 16,
            }}
          >
            <Col xs={24} md={12} className="flex">
              <img
                className="width-100 mx-auto"
                src="/img/galeria.png"
                alt="Galeria"
              />
            </Col>
            <Col xs={24} md={12}>
              <Row align={"middle"} style={{ height: "100%" }}>
                <Col span={24}>
                  <Title className="title-2">
                  Astatl Records - Mai Tai 
                  </Title>
                  <Paragraph className="description">
                  Mai Tai de Astatl Records es más que un estudio musical, es un espacio de desarrollo creativo con lo útlimo en equipos de audio y grabación.
                  </Paragraph>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row justify={"center"} className="mt-3 mb-2">
          <iframe
            className="video"
            src="https://www.youtube-nocookie.com/embed/-cU6tIZOgHE?si=lbygENNgiTjOkiRV"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Row>
      </section>
    )
}
export default Galeria