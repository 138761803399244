import React, { useRef } from "react";
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Input,
  List,
  Form,
  Select,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

import { EventoCard } from "../Widgets/Cards";
import SelectCategorias from "../Widgets/Inputs/SelectCategorias";

const localizedFormat = require("dayjs/plugin/localizedFormat");
const isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const ProximosEventos = (props) => {
  const filtrosRef = useRef(null);
  const [data, setData] = React.useState([]);
  const [date, setDate] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [openCalendar, setOpenCalendar] = React.useState(false);
  const [filters, setFilters] = React.useState({
    sort: "1",
    categorias: null,
    fechas: null,
  });

  const navigate = useNavigate();

  const disabledDate = (current) => {
    return dayjs().add(-1, "days") >= current;
  };


  const get = ({
    sort = filters.sort,
    categorias = filters.categorias,
    fechas = filters.fechas,
  } = filters) => {
    setLoading(true);
    axios
      .get("/public/eventos", {
        params: {
          limit: props.limit ?? 12,
          disponibles: true,
          sort,
          fechas,
          categorias,
        },
      })
      .then(({ data }) => {
        console.log("data", data.data);
        setData(data.data);
        setFilters({ ...filters, fechas, sort, categorias });
      })
      .catch((error) => {
        console.log("ERR, error", error);
      })
      .finally(() => setLoading(false));
  };

  const genURL = () => {
    let newURL = new URLSearchParams();
    if (filters.categorias) newURL.set("categorias", filters.categorias);
    if (filters.fechas) newURL.set("fechas", JSON.stringify(filters.fechas));
    if (filters.sort) newURL.set("sort", filters.sort);
    return newURL.toString();
  };

  /**
	Cambia el orden de los eventos
	*/
  const handleFilters = (e, allValues) => {
    if (!allValues.categorias) allValues.categorias = null;
    get({ ...allValues, sort: allValues.order });
  };

  React.useEffect(() => {
    get();
  }, []);

  return (
    <section className="proximos-eventos section" id="eventos">
      <Row align={"middle"} gutter={[16, 16]}>
        <Col xs={24} lg={15}>
          <Title className="title m-0">Encuentra Tu Próximo Evento</Title>
        </Col>
      </Row>
      <Row className="mt-2 width-100">
        <Col xs={24} className="width-100">
          <Form
            className="filters"
            onValuesChange={handleFilters}
            ref={filtrosRef}
            layout="vertical"
          >
            <Row gutter={[16, 16]} className="width-100">
              <Col lg={8} md={12} sm={24} className="mt-1 width-100">
                <Form.Item
                  label="Ordenar por"
                  className="width-100 item-light "
                  name="order"
                >
                  <Select defaultValue={1} className="width-100 ">
                    <Option value={1}>Fecha (Asc)</Option>
                    <Option value={2}>Fecha (Desc)</Option>
                    <Option value={3}>Costo (Asc)</Option>
                    <Option value={4}>Costo (Desc)</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} className="mt-1 width-100">
                <Form.Item label="Fechas" className="width-100 " name="fechas">
                  <DatePicker.RangePicker
                    allowClear={true}
                    disabledDate={disabledDate}
                    className="width-100 text-white"
                    placeholder={["Fecha inicio", "Fecha Fin"]}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} className="mt-1 width-100">
                <Form.Item
                  label="Categorías"
                  className="width-100"
                  name={"categorias"}
                >
                  <SelectCategorias
                    className="item-light selector width-100"
                    landing={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          dataSource={data}
          renderItem={(item) => <EventoCard item={item} />}
          className="width-100"
          loading={loading}
          locale={{
            emptyText: (
              <Title
                className="text-white"
                style={{ marginTop: "10rem", marginBottom: "10rem" }}
              >
                No hay Eventos disponibles
              </Title>
            ),
          }}
        />
      </Row>
      <Row justify={"center"}>
        <Button
          type="primary"
          className="btn-gradiente-morado mt-3  mb-3"
          onClick={() => navigate(`/eventos?${genURL()}`)}
        >
          Ver todos
        </Button>
      </Row>
    </section>
  );
};

export default ProximosEventos;
