import React, { Component } from "react";
import {   Button,Row, Col, Typography, } from "antd";
import { useNavigate } from "react-router-dom";

import "../../../Styles/Modules/Landing/landing.scss";
const { Text } = Typography;

const MaiTai = (props) => {
  const navigate = useNavigate();
  
  return(
  <section
    className="mt-3 mb-2 section"
    id="mai-tai"
    style={{ paddingTop: "2rem", paddingBottom: "5rem" }}
  >
    <div>
      <Row className="mb-2" gutter={[16, 64]}>
        <Col className="center" xs={24} xl={10}>
          <img src="/img/maitai.png" alt="Maitai" className="logo-maitai" />
        </Col>
        <Col className="center" xs={24} xl={14}>
          <img src="/img/logo3.png" alt="Maitai" className="logo-maitai" />
        </Col>
      </Row>
      <Text className="info-maitai center">
        Mai Tai de Astatl Records es más que un estudio musical, es un espacio
        de desarrollo creativo con lo último en equipos de audio y grabación.
      </Text>
      <Row justify={"center"}>
        <Button
          type="primary"
          className="center mt-3 btn-gradiente-morado"
          onClick={() => navigate("/mai-tai")}
        >
          Conoce más
        </Button>
      </Row>
    </div>
  </section>
)}

export default MaiTai;
