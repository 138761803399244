import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from "axios"

const { Option } = Select 

/**
 * @const SelectEventos
 * @description Select para los eventos registrados en el sistema
 */
const SelectEventos = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el evento", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        mode = null,
        labelInValue,
        style = null
    } = props

    const [ eventos, setEventos ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getEventos
     * @description Obitiene los usaurios
     */
    const getEventos = ({
        page, 
        limit, 
        search, 
    } = eventos) => {

        axios.get('/eventos', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setEventos(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los eventos')
        })
    }

    //componentDidMount
    useEffect(() => {
        getEventos()
    }, [])


    return (
        <Select
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search)=> getEventos({search})}
            onSelect={(cliente)=> {
                if(mode === null && !labelInValue){
                    onChange(cliente.value)
                    onSelect(cliente.value)
                }
                if(mode === null && labelInValue){
                    onChange(cliente)
                    onSelect(cliente)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple" && !labelInValue){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }

                if(mode === "multiple" && labelInValue){
                    onChange(values)
                    onSelect(values)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            style={style}
        >
            {
                eventos?.data?.map(({ _id, nombre,  }) => <Option value={_id} key={_id}>{nombre ?? ""} </Option>)
            }
        </Select>
    );
}



export default SelectEventos;