import React, { Component, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Modal,
  Checkbox,
} from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { User, SetUser } from "../../Hooks/Logged";
import Cookies from "universal-cookie";
//css
import "../../Styles/Global/auth.scss";

const { Title, Text } = Typography;

/**
 *
 * @class Login
 * @extends {Component}
 * @description Componente Login para inicio de sesion
 */
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * @memberof Login
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al usuario
   *
   * @returns response (array)
   **/
  handleSubmit = (values) => {
    this.setState({ loading: true });
    axios
      .post("/login", {
        email: values.email,
        password: values.password,
      })
      .then(({ data, headers }) => {
        console.log("data", data);
        const { setUser } = this.props;
        if (values.recuerdame === true) {
          const cookies = new Cookies();
          cookies.set("token", headers.authorization, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
        }
        axios.defaults.headers.post["Authorization"] = headers.authorization;

        sessionStorage.setItem("token", headers.authorization);
        axios.defaults.headers.common["Authorization"] =
          sessionStorage.getItem("token");

        setUser(data.user);
        if(data.user.rol_id)
          this.props.navigate("/admin/dashboard");
        else
          this.props.navigate("/customer/dashboard")
      })
      .catch((error) => {
        Modal.error({ title: "Credenciales incorrectas" });
        this.setState({ loading: false });
      });
  };

  cerrarSesion = () => {
    this.props.setUser(undefined);
    sessionStorage.clear();
    this.props.navigate("/");

    if (window.FB) {
      window.FB.logout(function (response) {
        console.log("response", response);
      });
    }
  };

  render() {
    return (
      <Row className="layout-login">
        <Col xs={24} lg={10} className="col-welcome">
          <Row>
            <div className="flex-column-right h-100">
              <img
                width="400"
                src="/logo.png"
                alt="Logo"
                className="logo-login"
              />
            </div>
          </Row>
        </Col>
        <Col xs={24} lg={14}>
          {this.props.user ? (
            <div className="login-form-container">
              <div className="login-form">
                <Title level={3} className="h3-title">
                  Bienvenido de vuelta!
                </Title>
                <Title align="center">
                  {this.props?.user?.nombre} {this.props?.user?.apellidos}
                </Title>
                <Title level={4} align="center">
                  {this.props?.user?.email}
                </Title>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  onClick={() =>
                    this.props.navigate(!this.props.user?.rol_id ? "/customer/dashboard" : "/admin/dashboard"
                    )
                  }
                >
                  Continuar
                </Button>
                <Button
                  type="link"
                  htmlType="submit"
                  block
                  size="large"
                  onClick={() => this.cerrarSesion()}
                >
                  Cerrar Sesión
                </Button>
              </div>
            </div>
          ) : (
            <div className="login-form-container">
              <Form
                onFinish={this.handleSubmit}
                layout={"vertical"}
                requiredMark={false}
                className="login-form"
              >
                <Title level={3} className="h3-title">
                  ¡Bienvenido de Vuelta!
                </Title>
                <Form.Item
                  className="input-email"
                  name="email"
                  label="Correo electrónico"
                  rules={[
                    { required: true, message: "Ingrese su correo" },
                    {
                      type: "email",
                      message: "Email no válido",
                    },
                  ]}
                >
                  <Input placeholder="ejemplo@mail.com" size="large" className="width-100" />
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[{ required: true, message: "Ingrese su contraseña" }]}
                >
                  <Input.Password placeholder="********" size="large" className="width-100" />
                </Form.Item>
                <Form.Item name="recuerdame" valuePropName="checked" noStyle >
                  <Checkbox className="mb-1">Mantener la sesión iniciada</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block size="large" className="width-100">
                    Iniciar sesión
                  </Button>
                </Form.Item>

                <Text className="mt-1 ">
                  ¿No tienes cuenta? <Link to="/register">Registrate</Link>
                </Text>
              </Form>
            </div>
          )}
        </Col>
        {!this.props.user && (
          <div className="link-password-recovery ">
            ¿Olvido su contraseña?{" "}
            <Link
              className="link"
              style={{ color: "#790165" }}
              to="/password/recovery"
            >
              Restaurar aquí
            </Link>
          </div>
        )}
      </Row>
    );
  }
}

export default function View(props) {
  let user = useContext(User);
  const setUser = useContext(SetUser);

  return (
    <Login {...props} user={user} setUser={setUser} navigate={useNavigate()} />
  );
}
