import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import axios from "axios";

import Login from "../Components/Auth/Login";
import Landing from "../Components/Public/Landing";
import Recovery from "../Components/Auth/Recovery";
import UpdatePassword from "../Components/Auth/UpdatePassword";
import Register from "../Components/Auth/Register";
import Evento from "../Components/Public/Evento";
import Payment from "../Components/Public/Carrito/Pago";
import Resumen from "../Components/Public/Carrito/Resumen";
import MaiTai from "../Components/Public/Mai-Tai";
import Eventos from "../Components/Public/Eventos";
import FAQ from "../Components/Public/Sections/FAQ";

import io from "socket.io-client";
import Socket from "../Hooks/Socket";
import User from "../Hooks/Logged";
import { CookiesProvider } from "react-cookie";

import "../Styles/Global/global.scss";
import AvisoPrivacidad from "../Components/Public/Sections/AvisoPrivacidad";
/**
 *
 * @export
 * @function PublicRoutes
 * @description Router for handling all public routes
 */
class PublicRoutes extends React.Component {
  static contextType = User;

  constructor(props) {
    super(props);
    this.state = {
      socket: this.getSocket(),
    };
  }

  componentDidMount() {
    this.axiosInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.log("error interceptor", error.response?.status);
        if (error.response?.status === 401) this.props.navigate("/login");
        return Promise.reject(error);
      }
    );
  }
  componentWillUnmount() {
    axios.interceptors.request.eject(this.axiosInterceptor);
  }

  getSocket = () => {
    try {
      this.state?.socket?.disconnected();
    } catch (error) {
      console.log("e", error);
    }

    let socket = io.connect(process.env.REACT_APP_WEB_SERVICE, {
      extraHeaders: {
        Authorization: sessionStorage.getItem("token"),
      },
    });
    socket.io.on("open", () => {
      socket.io.engine.transport.on("pollComplete", () => {
        socket.io.opts.extraHeaders = {
          Authorization: sessionStorage.getItem("token"),
        };
      });
    });
    return socket;
  };
  render() {
    return (
      <CookiesProvider>
        <Socket.Provider value={this.state.socket}>
          <Layout className="heigth-100">
            <Routes>
              <Route path="" element={<Landing />} />
              <Route path="mai-tai" element={<MaiTai />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="aviso-privacidad" element={<AvisoPrivacidad />} />

              <Route path="login" element={<Login />} />
              <Route path="password/recovery" element={<Recovery />} />

              <Route path="recovery/:token" element={<UpdatePassword />} />
              <Route path="register/:token" element={<UpdatePassword />} />
              <Route path="register" element={<Register />} />
              <Route path="/evento/:id" element={<Evento />} />
              <Route path="/evento/:url" element={<Evento />} />

              <Route path="/carrito/pago" element={<Payment />} />
              <Route path="/carrito/pago/:venta_id" element={<Resumen />} />
              <Route path="/evento/:url/pago" element={<Evento />} />

              <Route path="/evento/:url/pago/resumen" element={<Evento />} />


              <Route path="/eventos" element={<Eventos />} />
            </Routes>
          </Layout>
        </Socket.Provider>
      </CookiesProvider>
    );
  }
}
export default function View(props) {


  const navigate = useNavigate();

  return <PublicRoutes {...props} />;
}
