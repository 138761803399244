import React, { useState } from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined } from '@ant-design/icons';
import {  message, Space, Button, Result } from "antd";
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

//Componentes
import Column from './Board/Column'
import SocketContext from '../../../Hooks/Socket';
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import Logged, { User } from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";

//Modales
import ModalCliente from "./ModalCliente";

import "../../../Styles/Modules/CRM/board.scss";


class CRMBoard extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			loading: false,
			showDelete: false,
			prospectos: {
				limit: 40,
				page: 1
			},
			columna_principal : {
				
			},
			columnas: {
				disponible: {
					color: "#4194F6",
					id: 'disponible',
					list: []
				},
			},
			prospectos_selected: {},
			prospectos_to_copy: {},
			evento_id:null,
			cliente_id:null
		}

	}


	//variables temporales
	columnas_temp = {}


	componentDidMount(){
		this.getProspectos()

		document.addEventListener('keydown', this.handleKeyDown);
		document.addEventListener('keyup', this.handleKeyUp);

		this.props.socket.emit("/admin/crm/join")
		this.props.socket.on("/admin/crm/joined", this.onJoined)
		this.props.socket.on("/admin/crm/prospecto/created", this.onAddNewProspecto)
		this.props.socket.on("/admin/crm/prospecto/deleted", this.onDeletedProspecto)
	}

	componentWillUnmount() {

		this.props.socket.emit("/admin/crm/leave")

		// Eliminar event listeners
		document.removeEventListener('keydown', this.handleKeyDown);
		document.removeEventListener('keyup', this.handleKeyUp);

		// Desuscribirse de eventos del socket
		this.props.socket.off("/admin/crm/joined", this.onJoined);
		this.props.socket.off("/admin/crm/prospecto/created", this.onAddNewProspecto);
		this.props.socket.off("/admin/crm/prospecto/deleted", this.onDeletedProspecto)
	}

	handleKeyDown = (event) => {
			if (event.ctrlKey) {
				event.preventDefault()
				this.setState({up: true, prospectos_to_copy: {}})
			}
	};

	handleKeyUp = (event) => {
		if (event.key === "Control" && this.state.up === true) {
			this.setState({up: false})
		}
	};

	/**
	 * @memberof Clientes
	 * @method joined
	 * @description Indica si se conecto correctamente a la sala de board_crm
	 */
	onJoined = (data) => {
		console.log("data", data);
	}

	onAddNewProspecto = ({prospectos, evento}) => {

		if(!evento?.nombre) return;

		const destino = this.state.columnas[evento?.nombre]

		const newEndList = Array.from(destino.list);

		newEndList.push(...prospectos)

		// Crear una nueva columna de destino
		const newEndCol = {
			...destino,
			list: newEndList
		}

		this.setState(state => { 
			state.columnas = {
				...state.columnas, 
				[newEndCol.id]: newEndCol
			}
			return state;
		})

	}

	onDeletedProspecto = (prospectos) => {
		
		let columnas = { ...this.state.columnas }

		for (let prospecto of prospectos) {
					const evento_nombre = prospecto?.evento_id?.nombre || 'disponible';

					if (columnas[evento_nombre]) {
						columnas[evento_nombre] = {
								...columnas[evento_nombre],
								list: columnas[evento_nombre].list.filter(e => e._id.toString() !== prospecto._id.toString())
						};
					}
			}

		this.setState(state => {
			state.columnas = columnas;
			return state;
		})

	} 


	/**
	 * @memberof Clientes
	 * @method getProspectos
	 * @description Obtiene los prospectos disponibles
	 */
	getProspectos = async ({
		page = this.state.prospectos.page,
		limit = this.state.prospectos.limit,
	} = this.state.prospectos ) => {

			this.setState({ loading: true });
		axios.get("/prospectos", {
			params: {
				disponible: true,
				limit,
				page,
			},
		}).then(({ data }) => {

			console.clear()
			console.log('data',data)
			this.setState(state => { 
				state.columnas = {
					disponible: {
						...state.columnas.disponible,
						list: data.disponibles
					},
					...data.eventos_prospectos
				}

				return state;
			},()=>console.log(this.state.columnas))
		}).catch((res) => {
				message.error(res.response.data.message);
		}).finally(() => this.setState({ loading: false }));
	};

	/**
	 * @memberof Clientes
	 * @method onDragEnd
	 * @description Maneja el evento de finalización del arrastre de un item.
	 * Actualiza las listas con los nuevos elementos
	 */
	onDragEnd = async ({ source, destination, droppableId}) => {

		// Asegurarse de que realmente estamos moviendo el elemento
		if (!destination || (source.droppableId === destination.droppableId && destination.index === source.index)){
			this.state.showDelete = false;
			return null
		}

		if(destination.droppableId === "delete"){
			this.deleteProspecto(source)

			return;
		}


		//obtenemos las columnas de origen y destino
		const start = this.state.columnas[source.droppableId]
		const end = this.state.columnas[destination.droppableId]

		// Si el origen  es el mismo que el destino, estamos en la misma columna
		if (start === end) {
			// Mover el elemento dentro de la lista
					// Empezar haciendo una nueva lista sin el elemento arrastrado
			const newList = Array.from(start.list);
			//eliminamos el item del lista
					const [movedItem] = newList.splice(source.index, 1);

			// Luego insertar el item en la ubicación correcta
			newList.splice(destination.index, 0, start.list[source.index])

			// Luego crear una nueva copia del objeto columna
			const newCol = {
				...start,
				list: newList
			}

			this.setState(state => {
				state.columnas = { 
					...state.columnas, 
					[newCol.id]: newCol 
				}
				state.showDelete = false;
				return state;
			})

		} else {
			// Si el origen es diferente del destino, necesitamos actualizar múltiples columnas
			// Filtrar la lista de origen
			let newStartList = Array.from(start.list);
			let movedItem = newStartList[source.index];


			let { evento_id } = end;
			let { cliente_id } = movedItem

			if(Object.keys(this.state.prospectos_to_copy).length > 0){
				this.onPaste(evento_id, this.state.prospectos_to_copy, "prospectos_to_copy")
				return
			}

			/*if(Object.keys(this.state.prospectos_selected).length > 0){
				this.onPaste(evento_id, this.state.prospectos_selected, "prospectos_selected")
				return
			}*/


			newStartList.splice(source.index, 1)
			this.deleteProspecto(source)

			// Crear una nueva columna de origen
			const newStartCol = {
				...start,
				list: newStartList
			}

			let prospecto = await this.createProspecto({evento_id, cliente_id, order: destination.index})

			// Hacer una nueva lista de destino
			const newEndList = Array.from(end.list);

			// Insertar el elemento en la lista de fin
			newEndList.splice(destination.index, 0, prospecto)

			// Crear una nueva columna de destino
			const newEndCol = {
				...end,
				list: newEndList
			}

			this.setState(state => { 
				state.columnas = {
					...state.columnas, 
					[newStartCol.id]: newStartCol,
					[newEndCol.id]: newEndCol
				}
				state.showDelete = false;
				return state;
			})

			this.props.socket.emit("/admin/crm/prospecto/created", { prospectos: [prospecto], evento_id })
		}
	}

	/**
	 * @memberof Clientes
	 * @method createProspecto
	 * @description crea el prospecto para algun evento
	 */
	createProspecto = async (values) => {
		let response = await axios.post("/prospectos", { ...values })
		return response.data
	}

	/**
	 * @memberof Clientes
	 * @method deleteProspecto
	 * @description Elimina un prospecto de la db
	 */
	deleteProspecto = (source) => {

		let prospectos_ids = []

		if(Object.values(this.state.prospectos_selected).length > 0){

			let columnas = { ...this.state.columnas }

			for (let prospecto of Object.values(this.state.prospectos_selected)) {

				if (columnas[prospecto?.evento]) {
					columnas[prospecto?.evento] = {
						...columnas[prospecto?.evento],
						list: columnas[prospecto?.evento].list.filter(e => e._id.toString() !== prospecto._id.toString())
					};
				}

				prospectos_ids.push(prospecto._id)
					
			}

			this.setState(state => {
				state.columnas = columnas;
				state.showDelete = false;
				state.prospectos_selected = {};
				return state;
			})

		}else{

			const start = this.state.columnas[source.droppableId]

			const newStartList = Array.from(start.list);
			const [movedItem] = newStartList.splice(source.index, 1);

			prospectos_ids.push(movedItem._id)

			// Crear una nueva columna de origen
			const newStartCol = {
				...start,
				list: newStartList
			}

			this.setState(state => { 
				state.columnas = {
					...state.columnas, 
					[newStartCol.id]: newStartCol,
				}
				state.showDelete = false;
				return state;
			})
		}


		axios.delete("/prospectos",{
			params:{
				prospectos_ids
			}
		})
		.then(({data}) => {
			//message.success("Eliminado con éxito") 
			this.props.socket.emit("/admin/crm/prospecto/deleted", data)
		})
		.catch(() => { message.error("Error") })
	}


	/**
	 * @memberof Clientes
	 * @method addProspecto
	 * @description Añade el prospecto/cliente recien creado en el modal del cliente a la columna de diponibles
	 */
	addProspecto = (prospectos) => {

		if (prospectos.length === 1 && prospectos[0].evento_id) {
		    let columnas = { ...this.state.columnas };

		    for (const evento_nombre in columnas) {
		      	if (columnas.hasOwnProperty(evento_nombre)) {
		        	if (columnas[evento_nombre].evento_id === prospectos[0].evento_id) {
		          		columnas = {
		            		...columnas,
		            		[evento_nombre]: {
		              			...columnas[evento_nombre],
		              			list: [...columnas[evento_nombre].list, ...prospectos]
		            		}
		          		};

		         		this.setState({ columnas });
		          		break;
		        	}
		      	}
		    }
		    return; 
		}

		this.setState(state => { 
			state.columnas = {
				...state.columnas,
				disponible: {
					...state.columnas.disponible,
					list: [...state.columnas.disponible.list, ...prospectos],
				},
			}

			return state;
		})
	}

	/**
	 * @memberof Clientes
	 * @method onDragStart
	 * @description Mientras se arrastra un elemento se muestra el cuadro para eliminar
	 */
	onDragStart = ({source, draggableId}) => {
		this.setState({showDelete: true})

		//Vemos si se esta arrastrando uno de los prospecto que estan seleccionados
		if(!this.state.prospectos_selected[draggableId]){
			this.setState(state => {
				state.prospectos_selected = {}
				return state
			})
		}
	} 

	/**
	 * @memberof Clientes
	 * @method onSelectProspecto
	 * @description Permite seleccionar prospectos haciendo click sobre ellos solo si se esta precionando el boton CTRL
	 */
	onSelectProspecto = (prospecto) => {

		if(!this.state.up) return

		let temp_prospectos_selected = {...this.state.prospectos_selected}
				const { _id } = prospecto;

				if (temp_prospectos_selected[_id]) {
						delete temp_prospectos_selected[_id]
				} else {
						temp_prospectos_selected[_id] = prospecto
				}
		this.setState({ prospectos_selected: temp_prospectos_selected })
	}

	/**
	 * @memberof Clientes
	 * @method onCopyProspecto
	 * @description Permite seleccionar prospectos haciendo click sobre ellos solo si se esta precionando el boton CTRL
	 */
	onCopyProspecto = (prospecto) => {
		console.log("prospecto", prospecto);

		let temp_prospectos_copy = {...this.state.prospectos_to_copy}
				const { _id } = prospecto;

				if (temp_prospectos_copy[_id]) {
						delete temp_prospectos_copy[_id]
				} else {
						temp_prospectos_copy[_id] = prospecto
				}
		this.setState({ prospectos_to_copy: temp_prospectos_copy })
	}

	onPaste = (evento_id, prospectos, tipo_prospectos) => {
		this.state.showDelete = false
		axios.post("/prospectos", { 
			evento_id, 
			prospectos: prospectos
		}).then(({data}) => {
			console.log("onPaste", data);

			const destino = this.state.columnas[data.evento?.nombre]

			let newEndList = Array.from(destino.list);

			newEndList.push(...data.prospectos)

			// Crear una nueva columna de destino
			const newEndCol = {
				...destino,
				list: newEndList
			}

			this.setState(state => { 
				state.columnas = {
					...state.columnas, 
					[newEndCol.id]: newEndCol
				}
				state[tipo_prospectos] = {}
				return state;
			})

			this.setState({up: false, prospectos_selected: {}, showDelete: false  })

			this.props.socket.emit("/admin/crm/prospecto/created", { prospectos: data.prospectos, evento_id })

		}).catch(error => {
			console.log("error", error);

		})
	}

	openModal = ( cliente_id,evento_id ) => {
		this.setState({cliente_id,evento_id,  modal_cliente_visible: true})
	}

	render(){

		let { columnas, columna_principal, prospectos_selected } = this.state
		return (
			<>
				<PageHeader
					className="admin-page-header"
					breadcrumb={{
						items: [
							{
								title: "CRM",
							},
						],
					}}
					extra={<Space>
						<Button type="primary" onClick={()=>this.setState({modal_cliente_visible: true})}>Nuevo Prospecto</Button>
						<Button type="primary" onClick={()=>this.props.navigate("/admin/eventos/crear?crm=true")}>Nuevo Evento</Button>
						{/*<Button>Nuevo Evento</Button>*/}
					</Space>}
				/>
					<DragDropContext 
						onDragStart={this.onDragStart}
						onDragEnd={this.onDragEnd}
					>
						<div className={`width-100 pd-1 ${this.state.showDelete ? "dragging" : null}`} style={{display: "flex", maxWidth: "100%", overflowX:"auto", minHeight:"75vh", maxHeight: "75vh"}}>
							{Object.values(columnas).map(col => (

								console.log('col',col),
								<Column 
									col={col} 
									key={col.id}
									onSelect={(item) => this.onSelectProspecto(item)}
									onCopy={this.onCopyProspecto}
									prospectos_selected={Object.keys(this.state.prospectos_selected).length > 0 ? this.state.prospectos_selected : this.state.prospectos_to_copy}
									onPaste={this.onPaste}
									openModal={this.openModal}
								/>
							))}
						</div>
						<div style={{minWidth: "320px", display: "box", height: "100px"}} className="ml-1 mb-2">
							<Droppable droppableId={"delete"} className="">
								{provided => (<>
									<div {...provided.droppableProps} 
										ref={provided.innerRef} 
										className="center"
										style={{
											padding: this.state.showDelete ? "1rem" : "0",
											margin: "auto", 
											width: "15%",
											minWidth: "330px", 
											height: this.state.showDelete ? "100px" : "0px",
											background: "#f74444",
											borderRadius: "8px",
											overflow: "hidden",
											color: "white",
											fontSize: "48px",

										}}>
										<DeleteOutlined />
									</div>
								</>)}
							</Droppable>
						</div>
					</DragDropContext>
				<ModalCliente
					crm={this.state.cliente_id ? false : true}
					open={this.state.modal_cliente_visible}
					cliente_id={this.state.cliente_id}
					evento_id={this.state.evento_id}
					onClose={(prospectos)=>{
						this.setState({modal_cliente_visible: false, cliente_id: undefined})
						if(Array.isArray(prospectos) && prospectos.length > 0){
							this.addProspecto(prospectos)
						}
					}}
				/>
			</>
		)
	}
}

export default function (props) {

	const user = React.useContext(User);

	const permisos = usePermissions(user?.rol_id?.permisos, {
	    canView: ["crm","access"],
	});

	const navigate = useNavigate()

	if(permisos?.canView === false)
	    return <Result
	      className="mt-3"
	      status="warning"
	      title="No tienes acceso a esta área"
	    />
	else
		return <CRMBoard socket={React.useContext(SocketContext)} navigate={navigate} />
};
