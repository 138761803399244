import React from "react";
import { List, Col, Row, Typography, Card, Space, message, Select, Modal } from "antd";
import axios from "axios";
import dayjs from "dayjs";


//Compoenntes
import { ventaEstatusTag } from "../../../Utils";
import SocketContext from '../../../../Hooks/Socket';
import ModalSillaApartada from "./ModalSillaApartada";
import Logged, { User } from "../../../../Hooks/Logged";
import PublicSeating from "../../Seating/PublicSeating";
import usePermissions from "../../../../Hooks/usePermissions";



const { Text, Title } = Typography;

class TabMapa extends React.Component {
  	constructor(props) {
    	super(props);
    	this.state = {
      		loading: false,
      		evento:{
      			secciones: []
      		},
      		ventas: {
				data: [],
				page: 1,
				limit: 20,
				total: 0,
				filters: []
			},
      		mesas:[],
      		blocked: [],
      		seccion_selected: 0,
      		clientes_sentados: [],
      		venta: null
    	};
 	}

  	componentDidMount() {

  		this.props.socket.emit("/admin/evento-mesas/join",this.props.evento_id)

  		this.props.socket.on("/admin/evento-mesas/updated", this.clientesSentadosUpdate)
  		this.props.socket.on("/admin/evento-mesas/respuesta-verificacion", this.respuetaVerificacion)

  		if(this.props.evento_id){
  			this.getEvento()
  			this.getVentas()
  		}
  	}

  	componentWillUnmount(){
  		this.props.socket.emit("/admin/evento-mesas/leave")
  		this.props.socket.off("/admin/evento-mesas/updated", this.clientesSentadosUpdate)
  		this.props.socket.off("/admin/evento-mesas/respuesta-verificacion", this.respuetaVerificacion)
  	}

  	/**
	 * @method getEvento
	 * @description Obtiene el evento de la bd
	 */
	getEvento = () => {
	    this.setState({ loading: true });
	    axios.get("/eventos/" + this.props.evento_id)
	    .then(({ data }) => {
	        this.setState({
	        	evento: data,
	          	mesas: data.secciones[this.state.seccion_selected].data.mesas.map((m) => ({ ...m, seccion_id: data.secciones[this.state.seccion_selected]._id})),
          		blocked: data.secciones[this.state.seccion_selected].data.blocked,
          		clientes_sentados: data.clientes_sentados.map(e => ({silla_id: e})),
	        });
	    }).catch((error) => {
	        message.error(error?.response?.data?.message ?? "Error al obtener el evento");
	    }).finally(() => this.setState({ loading: false }));
	};

	/**
	 * @memberof VEntas
	 * @method getVentas
	 * @description Obtiene el listado de ventas
	 */
	getVentas = ({
	    page = this.state.ventas.page,
	    limit = this.state.ventas.limit,
	} = this.state.ventas) => {
	    axios.get("/ventas", {
	        params: {
	         	page,
	         	limit,
	         	evento_id: this.props.evento_id
	        },
	    }).then(({ data }) => {
	    	console.clear()
	    	console.log("data", data.data);
	        this.setState({ ventas: data });
	    }).catch((error) => {
	        console.log(error);
	        message.error("Error al traer las ventas");
	    })
	};

	clientesSentadosUpdate = (clientes_sentados) => {
		this.setState({
	  		clientes_sentados: clientes_sentados.map(e => ({silla_id: e})),
	    });
	}

	handleChangeSeccion = (seccion_index) => {
    	this.setState({ seccion_selected: seccion_index }, () => this.getEvento());
  	};

  	addSeat = ({ silla_id }) => {

  		//Verificamos si la silla seleccionada fue comprada por alguien
  		this.props.socket.emit("/admin/evento-mesas/verificar", { evento_id:this.props.evento_id, silla_id  })

  		//this.props.socket.emit("/admin/evento-mesas/sentar", { evento_id:this.props.evento_id, silla_id  })
  	}

  	deleteSeat = ({ silla_id }) => {
  		this.props.socket.emit("/admin/evento-mesas/levantar", { evento_id:this.props.evento_id, silla_id  })
  	}

  	respuetaVerificacion = ({ venta, silla_id }) => {
  		if(venta)
  			this.setState({modalAsiento: true, venta})
  		else if(silla_id){
  			Modal.confirm({
  				title: "Marcar como sentado",
				content: "Este asiento no esta relacionado a ninguna venta, ¿Quieres marcar como sentado?",
				okText: "Aceptar",
				cancelText: "Cancelar",
				onOk: () =>{
					this.props.socket.emit("/admin/evento-mesas/sentar", { evento_id:this.props.evento_id, silla_id  })
				}
  			})
  		}
  	}

  	addMultipleSeats = (sillas_ids) => {
  		this.props.socket.emit("/admin/evento-mesas/sentar-multiple", { evento_id:this.props.evento_id, sillas_ids  })
  		this.setState({modalAsiento: false, venta: null})
  	}

  	render() {
    	return (
      		<>
        		<Row gutter={[16,16]}>
        			<Col span={24} className="center">
        				<Space>
        					<Title level={4} className="m-0">
        						Sección:
        					</Title>
	        				<Select
	                			defaultValue={0}
	                			style={{ minWidth: 220 }}
	                			options={this.state.evento?.secciones?.map((elem, index) => ({
	                  				label: elem.nombre,
	                  				value: index,
	               	 			}))}
	                			onChange={this.handleChangeSeccion}
	              			/>
              			</Space>
        			</Col>
        			<Col xs={24} lg={16} className="mb-2">
        				<PublicSeating
				            value={{
				              	mesas: this.state.mesas,
				              	blocked: this.state.blocked,
				            }}
				            marcar_sentado={true} //permite al usaurio marcar asientos como sentados
				            interactive={this.props.owner || (this.props.marcarSentado ?? false)}
				            selectedSeats={this.state.clientes_sentados}
				            addSeat={this.addSeat}
            				deleteSeat={this.deleteSeat}
            				width={1000}
            				columns={41}
            				rows={65}
				        />
        			</Col>
        			<Col xs={24} lg={8} className="">
        				<Title level={4}>Ventas del Eventos</Title>
        				<List
				          	itemLayout="horizontal"
				          	locale={{ emptyText: "Sin ventas " }}
				          	dataSource={this.state.ventas.data}
				          	pagination={{
				            	current: this.state.ventas.page,
				            	pageSize: this.state.ventas.limit,
				            	total: this.state.ventas.total,
				            	position: "bottom",
				            	className: "flex-left",
				            	onChange: (page, limit) => this.getVentas({ page, limit }),
				          	}}
				          	renderItem={(item) => (
			              		<Card className="width-100" style={{borderBottom: "solid 2px lightgray"}}>
				                	<Row gutter={[8,8]}>
				                		<Col span={12}>
				                			<Text strong>{item.folio}</Text>
				                		</Col>
				                		<Col span={12} className="flex-right">
				                			<Text>Boletos ({item?.detalles?.length})</Text>
				                		</Col>
				                		<Col span={24}>
				                			<Text className="text-grey">{item?.cliente_id?.nombre ?? item?.invitado?.nombre}</Text>
				                		</Col>
				                		<Col span={12}>
				                			{ventaEstatusTag(item.estatus)}
				                		</Col>
				                		<Col span={12} className="flex-right">
				                			<Text strong>$ {item?.total.toMoney(true)} MXN</Text>
				                		</Col>
				                	</Row>
			              		</Card>
				          	)}
				        />
        			</Col>
        		</Row>
        		<ModalSillaApartada
        			open={this.state.modalAsiento}
        			onClose={()=>this.setState({
        				modalAsiento: false,
        				venta: null,
        			})}
        			venta={this.state.venta}
        			addMultipleSeats={this.addMultipleSeats}
        		/>
      		</>
    	);
  	}
}

export default function SubVista(props) {
  	const user = React.useContext(User);

  	const permisos = usePermissions(user?.rol_id?.permisos, {
    	marcarSentado: ["eventos", "marcar_sentado"],
  	});

  	return <TabMapa 
  		socket={React.useContext(SocketContext)} 
  		owner={user?.rol_id.tipo === 1} 
  		{...props}
  		{...permisos}
  	/>;
}
