import React, { Component } from "react";
import { Row, Col, Card, Layout, message, Space, List, Typography, Button, Result, Tooltip, Modal } from "antd";
import axios from "axios";

//Componentes
import Sort from "../../Widgets/Sort"
import ModalVentas from "./ModalVentas";
import { ventaEstatusTag } from "../../Utils";
import useSearch from "../../../Hooks/useSearch";
import Logged, { User } from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import DrawerFiltros from "../../Widgets/DrawerFiltros/DrawerFiltros";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ClienteSubMenu from "../../Widgets/ClienteMenuItem/ClienteSubMenu";
import { ButtonDelete, ButtonEdit, ButtonView } from "../../Widgets/Buttons";
import { FilterOutlined, FilePdfOutlined, MailOutlined } from "@ant-design/icons";



import dayjs from "dayjs";

const { Text } = Typography;
const { Content } = Layout;

/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista de Areas
 */
class Ventas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			modal_visible: false,

			venta_id: undefined,
			ventas: {
				data: [],
				page: 1,
				total: 0,
        filters: [],
        sort:{
          fecha: -1
        }
			},

			filtroSearch: "",
			searching: true,
		};
	}

	componentDidMount() {
		this.props.setShowSearch(true);
    axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
    this.getVentas();
  }


  componentDidUpdate(prevProps) {
    if (this.props.search != prevProps.search) {
      this.getVentas()
    }
  }


  /**
   * @memberof VEntas
   * @method getVentas
   * @description Obtiene el listado de ventas
   */
  getVentas = ({
    page = this.state.ventas.page,
    limit = this.state.ventas.limit,
    filters = this.state.ventas.filters,
    sort = this.state.ventas.sort,
    search = this.props.search,
  } = this.state.ventas) => {
  this.setState({ loading: true });
  axios
    .get("/ventas", {
      params: {
        page,
        limit,
        filters,
        search,
        sort,
      },
    })
    .then(({ data }) => {
      this.setState({ ventas: {...data, filters, sort} });
    })
    .catch((error) => {
      console.log(error);
      message.error("Error al traer las ventas");
    })
    .finally(() => this.setState({ loading: false }));
  };

  /**
   *
   * @memberof Compras
   * 
   * @method getPDF
   * @description Generamos el PDF de la venta realizda
   */
  getPDF = (venta_id, send = false) => {
    this.setState({loading: true})
    axios.get('/venta/pdf',{
      params:{
        venta_id: venta_id,
        send: send
      },
      responseType: 'blob'
    }).then(response => {
      if(send){
        message.success("Correo enviado")
      }else{
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
      }

    }).catch(error => {
      message.error(send ? "Error al enviar el PDF" : "Error al obtener el PDF")
    }).finally(()=>this.setState({loading: false}))
  }

  updateFilters = (filters) => {
    console.log("filters", filters);
    this.getVentas({page: 1, filters})
  }

  deleteVenta = (venta_id) => {
    axios.delete("/ventas/", {
      params: { venta_id: venta_id },
    })
    .then((response) => {
      message.success(response?.data?.message);
      this.getVentas();
    })
    .catch((error) => {
      message.error(error?.response?.data?.message);
      this.getVentas();
    })
  }


  setSort = (key) => {
    let value;
    switch (this.state.ventas.sort[key]) {
      case 1:
        value = -1
      break;
      case -1:
        value = undefined
      break;
      default:
        value = 1
      break;
    }

    let sort = {
      [key]: value
    }

    if(value === undefined && key !== "fecha") sort = { fecha: -1 }

    this.getVentas({
      sort
    })
  }

  render() {
    return (
      <>
        <PageHeader
          className="admin-page-header"
          breadcrumb={{
            items: [
              {
                title: "Ventas",
              },
            ],
          }}
          extra={
            <Space>
              <Button
                onClick={() => this.setState({ drawer_visible: true })}
                className="btn-filter"
                icon={<FilterOutlined />}
              />
            </Space>
          }
        ></PageHeader>
        <Content className="admin-content content-bg pd-1">
          <List
            loading={this.state.loading}
            className="component-list"
            itemLayout="horizontal"
            locale={{ emptyText: "Sin ventas " }}
            dataSource={this.state.ventas.data}
            pagination={{
              current: this.state.ventas.page,
              pageSize: this.state.ventas.limit,
              total: this.state.ventas.total,
              position: "bottom",
              className: "flex-left",
              onChange: (page, limit) => this.getVentas({ page, limit }),
            }}
            header={
              <Row className="component-list-header width-100 pr-1 pl-1">
                <Col span={3} className="center">
                  <Sort
                    sort={this.state.ventas?.sort && this.state.ventas?.sort['folio_number']} 
                    onClick={() => this.setSort("folio_number")} 
                  >
                    <Text strong>Folio</Text>
                  </Sort> &nbsp;/ &nbsp;
                  <Sort
                    sort={this.state.ventas?.sort && this.state.ventas?.sort['fecha']} 
                    onClick={() => this.setSort("fecha")} 
                  >
                    <Text strong>Fecha</Text>
                  </Sort>
                </Col>
                <Col span={6} className="center">
                  <Sort
                    sort={this.state.ventas?.sort && this.state.ventas?.sort['nombre']} 
                    onClick={() => this.setSort("nombre")} 
                  >
                    <Text strong>Cliente</Text>
                  </Sort>
                </Col>
                <Col span={4} className="center">
                  <Sort
                    sort={this.state.ventas?.sort && this.state.ventas?.sort['evento_nombre']} 
                    onClick={() => this.setSort("evento_nombre")} 
                  >
                    <Text strong>Evento</Text>
                  </Sort>
                </Col>
                <Col span={4} className="center">
                  <Sort
                    sort={this.state.ventas?.sort && this.state.ventas?.sort['total']} 
                    onClick={() => this.setSort("total")} 
                  >
                    <Text strong>Monto</Text>
                  </Sort>
                </Col>
                <Col span={4} className="center">
                  <Sort
                    sort={this.state.ventas?.sort && this.state.ventas?.sort['estatus']} 
                    onClick={() => this.setSort("estatus")} 
                  >
                    <Text strong>Estatus</Text>
                  </Sort>
                </Col>
              </Row>
            }
            renderItem={(item) => (
              <List.Item className="component-list-item">
                <Card className="card-list">
                  <Row className="width-100 " gutter={[8, 8]}>
                    <Col span={3} className="flex-column">
                      <Text>{item.folio ?? "-"}</Text><br/>
                      <small>{dayjs(item.fecha).format("DD/MM/YYYY")}</small>
                    </Col>
                    <Col span={6} className="center">
                      <ClienteSubMenu cliente={item.cliente_id ?? item.invitado} invitado={item.invitado} />
                    </Col>
                    <Col span={4} className="center">
                      <Text>{item.evento_id?.nombre}</Text>
                    </Col>
                    <Col span={4} className="center">
                      <Text>$ {item.total ? item.total.toMoney(true) : 0}</Text>
                    </Col>
                    <Col span={3} className="center">
                      <Text>{ventaEstatusTag(item.estatus)}</Text>
                    </Col>
                    <Col span={4} className="center">
                      <Space size="small" direction="horizontal">
                        <Tooltip placement="topRight" title={"Enviar boletos al cliente"}>
                          <Button
                            size="small"
                            icon={<MailOutlined />}
                            onClick={()=>this.getPDF(item._id, true)}
                          />
                        </Tooltip>
                        <Tooltip placement="topRight" title={"Ver boletos del cliente"}>
                          <Button
                            size="small"
                            icon={<FilePdfOutlined />}
                            onClick={()=>this.getPDF(item._id)}
                          />
                        </Tooltip>
                        <ButtonView
                          size="small"
                          disabled={!this.props.canView}
                          onClick={() =>
                            this.setState({
                              modal_visible: true,
                              venta_id: item._id,
                            })
                          }
                        />
                        <ButtonEdit
                          size="small"
                          disabled={!this.props.canEdit}
                          onClick={() =>
                            this.setState({
                              modal_visible: true,
                              venta_id: item._id,
                            })
                          }
                        />
                        <ButtonDelete
                          size="small"
                          disabled={!this.props.canDelete}
                          onConfirm={() =>{
                            Modal.confirm({
                              title: '¡Cuidado!',
                              content: "Al eliminar la venta, se eliminarán los boletos relacionados a la misma y, a su vez, los asientos del evento estarán disponibles de nuevo.",
                              onOk: () => this.deleteVenta(item._id)
                            })
                          }}
                        />
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </Content>
        <FloatingButton
          title="Nuevo registro"
          onClick={() => this.setState({ modal_visible: true, venta_id: undefined })}
        />

        <ModalVentas
          open={this.state.modal_visible}
          onClose={() => {
            this.setState({ modal_visible: false, venta_id: undefined });
             this.getVentas();
          }}
          venta_id={this.state.venta_id}
        />

        <DrawerFiltros
          updateFilters={this.updateFilters}
          visible={this.state.drawer_visible}
          onClose={()=>this.setState({drawer_visible: false})}
          fechas={true}
          clientes={true}
          eventos={true}
          estatus={{
            data: [
            {
              _id: 0,
              label: "Cancelado"
            },{
              _id: 1,
              label: "Pendiente"
            },{
              _id: 2,
              label: "Pago Parcial"
            },{
              _id: 3,
              label: "Pagado"
            }
          ]}}
        />
      </>
    );
  }
}

export default function Vista(props) {
	const user = React.useContext(User);
	const { setShow } = useSearch();

	const permisos = usePermissions(user?.rol_id?.permisos, {
		canEdit: ["ventas", "edit"],
		canDelete: ["ventas", "delete"],
		canCreate: ["ventas", "create"],
		canView: ["ventas", "access"],
	});

	if(permisos.canView === false)
		return <Result
			className="mt-3"
			status="warning"
			title="No tienes acceso a este módulo"
		/>  

	return <Ventas {...props} setShowSearch={setShow} {...permisos} />;
}
