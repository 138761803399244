import React, { Component } from "react";
import { Layout, Menu, Button } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { MenuUnfoldOutlined, MenuFoldOutlined, AppstoreFilled } from "@ant-design/icons";
import { IoTicketOutline } from "react-icons/io5";

//Componentes
import User from "../../../Hooks/Logged";

const { Sider } = Layout;

class Sidebar extends Component {
 	
 	constructor(props) {
    	super(props);
    	this.state = {
      		collapsed: false,
    	};
  	}

  	/**
   	* @memberof AdminSidebar
   	*/
  	toogle = () => {
    	this.setState({
      		collapsed: !this.state.collapsed,
    	});
  	};

  	render() {
    	const { props } = this;

    	return (
      		<Sider
        		theme="light"
        		width={250}
        		collapsedWidth={0}
        		breakpoint="lg"
        		className="custom-sider sider-width"
      		>
        		<Menu
          			mode="inline"
          			theme="light"
          			className="heigth-100 "
          			style={{ borderRight: 0 }}
        		>
          			<Button ghost onClick={this.toogle} className="no-border">
            			{this.state.collapsed ? (<MenuUnfoldOutlined />) : (<MenuFoldOutlined />)}
          			</Button>

          			<Menu.ItemGroup key="navegacion" title="NAVEGACIÓN">
              			<Menu.Item
               	 			key="dashboard"
                			icon={<AppstoreFilled />}
                			onClick={() =>
                  				this.props.navigate("/customer/dashboard", { replace: true })
                			}
              			>
                			Dashboard
              			</Menu.Item>
                    <Menu.Item
                      key="compras"
                      icon={<IoTicketOutline />}
                      onClick={() =>
                          this.props.navigate("/customer/compras", { replace: true })
                      }
                    >
                      Compras
                    </Menu.Item>
						 
          			</Menu.ItemGroup>
    			</Menu>
    		</Sider>
    	);
  	}
}

export default function View(props) {
  const user = React.useContext(User);
  return <Sidebar {...props} navigate={useNavigate()}  />;
}
