import React, { Component, useState } from "react";
import { Row, Col, InputNumber, Modal, Form, Input, message, Spin, Typography, Divider } from "antd";

import axios from "axios";
import PhoneInput from '../../Widgets/Inputs/PhoneInput';

const { Title, Text, Paragraph } = Typography;

export default function Vista(props) {
	
	const { open = false, onClose = () => {}, onComprarInvitado = () => {} } = props;

	const [confirmLoading, setConfirmLoading] = useState(false);

	const submit = (values) => {
		setConfirmLoading(true)
		onComprarInvitado(values)
	}

	return (
		<Modal
			open={open}
			onCancel={onClose}
			destroyOnClose={true}
			zIndex={1000}
			title={"Compra de Boletos"}
			closable={true}
			cancelText="Cancelar"
			okText="Aceptar"
			confirmLoading={confirmLoading}
			okButtonProps={{
		        form: "form-invitado",
		        key: "form-invitado",
		        htmlType: "submit",
		    }}
			
		>
			<Row gutter={[16, 16]} justify="center" align="middle">
		        <Col span={24} style={{ textAlign: 'center' }}>
		          <Title level={2}>Inicio de Sesión Requerido</Title>
		        </Col>
		        <Col span={24}>
		          	<Paragraph style={{textAlign: "center"}}>
		            	Para poder comprar boletos, por favor <Text strong underline style={{color: "#7f63f4"}}> <a href="/login" style={{color: "#7f63f4"}}>Inicia Sesión</a></Text>{" "}
		            	o <Text strong underline style={{color: "#7f63f4"}}> <a href="/register" style={{color: "#7f63f4"}}>Registrarte</a></Text> en caso de no tener cuenta,
		           		para consultar las compras realizadas.
		          	</Paragraph>
		        	<Paragraph style={{ textAlign: "center",}}>Tambien puedes comprar como <Text strong style={{color: "#7f63f4"}}>Invitado</Text> llenando el siguiente fomulario</Paragraph>
		        </Col>
		        <Col span={16}>
		        	<Form
		        		layout="vertical"
		        		name="form-invitado"
		        		onFinish={submit}
		        	>
		        		<Form.Item
		        			name="nombre"
		        			label="Nombre completo"
		        			rules={[
								{ required: true, message: 'Ingrese su Nombre' },
								{
									max: 50,
									message: "Máximo 50 caracteres"
								}
							]}
		        		>
		        			<Input/>
		        		</Form.Item>
		        		<Form.Item
		        			name="telefono"
		        			label="Teléfono"
		        			rules={[
								{
									required: true,
									message: 'Ingrese el Teléfono',
								}
							]}
		        		>
		        			<PhoneInput/>
		        		</Form.Item>
		        		<Form.Item
		        			name="email"
		        			label="Correo electrónico"
		        			rules={[
								{
									type: 'email',
									message: 'Email no válido',
								},
								{
									required: true,
									message: 'Ingrese el Email',
								}, {
									max: 50,
									message: "Máximo 50 caracteres"
								}
							]}
		        		>
		        			<Input/>
		        		</Form.Item>
		        	</Form>
		        </Col>
	      	</Row>
		</Modal>
	);
}
