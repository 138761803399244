import React from "react";
import { List, Col, Row, Typography, Card, Space, message } from "antd";
import Logged, { User } from "../../../../Hooks/Logged";
import { ButtonDelete, ButtonEdit } from "../../../Widgets/Buttons";
import ClienteSubMenu from "../../../Widgets/ClienteMenuItem/ClienteSubMenu";
import { ventaEstatusTag } from "../../../Utils";
import usePermissions from "../../../../Hooks/usePermissions";
import axios from "axios";
import dayjs from "dayjs";
import ModalVentas from "../../Ventas/ModalVentas";
const { Text } = Typography;

class TabVentas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ventas: {
        data: [],
        limit: 10,
        page: 1,
        total: 0,
      },
      loading: false,
    };
  }

  componentDidMount() {
    this.getVentas()
  }

  getVentas = (
    {
      page = this.state.ventas.page,
      limit = this.state.ventas.limit,
      search = this.props.search,
    } = this.state.ventas
  ) => {
    this.setState({ loading: true });
    axios
      .get("/ventas", {
        params: {
          page,
          limit,
          search,
          evento_id: this.props.evento_id
        },
      })
      .then(({ data }) => {
        this.setState({ ventas: data });
      })
      .catch((error) => {
        console.log(error);
        message.error("Error al traer las ventas");
      })
      .finally(() => this.setState({ loading: false }));
  };
  render() {
    return (
      <>
        <List
          loading={this.state.loading}
          className="component-list"
          itemLayout="horizontal"
          locale={{ emptyText: "Sin ventas " }}
          dataSource={this.state.ventas.data}
          pagination={{
            current: this.state.ventas.page,
            pageSize: this.state.ventas.limit,
            total: this.state.ventas.total,
            position: "bottom",
            className: "flex-left",
            onChange: (page, limit) => this.getVentas({ page, limit }),
          }}
          header={
            <Row className="component-list-header width-100 pr-1 pl-1">
              <Col span={3} className="center">
                <Text strong>fecha</Text>
              </Col>
              <Col span={6} className="center">
                <Text strong>cliente</Text>
              </Col>
              <Col span={3} className="center">
                <Text strong>evento</Text>
              </Col>
              <Col span={4} className="center">
                <Text strong>monto</Text>
              </Col>
              <Col span={4} className="center">
                <Text strong>estatus</Text>
              </Col>
            </Row>
          }
          renderItem={(item) => (
            <List.Item className="component-list-item">
              <Card className="card-list">
                <Row className="width-100 " gutter={[8, 8]}>
                  <Col span={3} className="center">
                    <Text>{dayjs(item.fecha).format("DD/MM/YYYY")}</Text>
                  </Col>
                  <Col span={6} className="center">
                  <ClienteSubMenu cliente={item.cliente_id ?? item.invitado} invitado={item.invitado} />
                  </Col>
                  <Col span={3} className="center">
                    <Text>{item.evento_id?.nombre}</Text>
                  </Col>
                  <Col span={4} className="center">
                    <Text>$ {item.total ? item.total.toMoney(true) : 0}</Text>
                  </Col>
                  <Col span={4} className="center">
                    <Text>{ventaEstatusTag(item.estatus)}</Text>
                  </Col>
                  <Col span={4} className="center">
                    <Space size="small" direction="horizontal">
                      <ButtonEdit
                        disabled={!this.props.canEdit}
                        onClick={() =>
                          this.setState({
                            modal_visible: true,
                            venta_id: item._id,
                          })
                        }
                      />
                      <ButtonDelete
                        disabled={!this.props.canDelete}
                        onConfirm={() =>
                          axios
                            .delete("/ventas/", {
                              params: { venta_id: item._id },
                            })
                            .then((response) => {
                              message.success(response?.data?.message);
                              this.getVentas();
                            })
                            .catch((error) => {
                              message.error(error?.response?.data?.message);
                              this.getVentas();
                            })
                        }
                      />
                    </Space>
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
        <ModalVentas
          open={this.state.modal_visible}
          onClose={() => {
            this.setState({ modal_visible: false, venta_id: undefined });
             this.getVentas();
          }}
          venta_id={this.state.venta_id}
        />
      </>
    );
  }
}

export default function SubVista(props) {
  const user = React.useContext(User);
  const permisos = usePermissions(user?.rol_id?.permisos, {
    canEdit: ["eventos", "edit"],
    canView: ["eventos", "access"],
    canDelete: ["eventos", "delete"],
  });
  return <TabVentas {...props} {...permisos} />;
}
