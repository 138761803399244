import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Dropdown, Button,  message} from "antd"
import { ExclamationCircleFilled, CopyOutlined } from "@ant-design/icons";


const { Text } = Typography;

export default function CSM({ cliente = {}, shopify_cliente, delete_order = false, invitado = false }) {

    let { nombre, apellido_paterno } = cliente

    const navigate = useNavigate()

    let items = []

    if(cliente.nombre){
        items = [
            {
                label: <div className="flex-between"><Text>{cliente.email ?? "Sin Email"}</Text><CopyOutlined className="ml-1"/></div>,
                key: '0',
                onClick: () => {
                    navigator.clipboard.writeText(cliente.email ?? "")
                    .then(() => {
                        message.info('Correo copiado al portapapeles');
                    })
                        
                }
            },
            {
                label: <div className="flex-between"><Text>{cliente.telefono ?? "Sin Teléfono"}</Text><CopyOutlined className="ml-1"/></div>,
                key: '1',
                onClick: () => {
                    navigator.clipboard.writeText(cliente.telefono ?? "")
                    .then(() => {
                        message.info('Teléfono copiado al portapapeles');
                    })
                        
                }
            },
        ];

        if(invitado === false){
            items.push(
                {
                    label: <Button block style={{minWidth: "230px"}} onClick={()=>navigate("/admin/crm/cliente/"+cliente?._id)}>Ver Detalle</Button>,
                    key: "2",
                },
            )
        }else{
            items.push(
                {
                    label: <Button block style={{minWidth: "230px"}}>Cliente Invitado</Button>,
                    key: "2",
                },
            )
        }
    }

    return <>
        <Dropdown menu={{ items }} trigger={['click']} placement="bottom"  >
            <Text className="hover-cursor" >{nombre ?? '-'} {apellido_paterno ?? ""}</Text>
        </Dropdown>
    </>
}