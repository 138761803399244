import React, { useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";
import { Layout } from "antd";

//Componentes
import User from "../Hooks/Logged";
import { Search } from "../Hooks/useSearch";
import Dashboard from "../Components/Customer/Dashboard";
import Navbar from "../Components/Admin/Navigation/Navbar";
import Sidebar from "../Components/Customer/Navigation/Sidebar";

//Routers
import RouterVentas from "./Customer/RouterVentas";

//css
import "../Styles/Global/admin.scss";

const { Content } = Layout;

/**
 *
 * @export
 * @function CustomerRoutes
 * @description Router for handling all admin routes
 */
class CustomerRoutes extends React.Component {
 	
 	static contextType = User;

  	constructor(props) {
    	super(props);
    	this.state = {
      		search: null,
      		showSearch: true,
    	};
  	}

  	componentDidMount() {
    	this.axiosInterceptor = axios.interceptors.response.use(
      		(response) => {
        		return response;
      		},(error) => {
        		if (error.response?.status === 401) this.props.navigate("/login");
        		return Promise.reject(error);
      		}
    	);
  	}

  	componentWillUnmount() {
   		axios.interceptors.request.eject(this.axiosInterceptor);
  	}

  	setSearch = (search) => this.setState({ search });

  	setShowSearch = (showSearch) => this.setState({ showSearch });


  	render() {
    	return (
	        <Search.Provider
	          	value={{
	            	search: this.state.search,
	            	setSearch: this.setSearch,
	            	show: this.state.showSearch,
	            	setShow: this.setShowSearch,
	          	}}
	        >
          		<Layout id={"admin"} className="layout-main">
	            	<Navbar
	            		showSearch={this.state.showSearch}
	            		onSearch={this.setSearch}
	            	/>
	            	<Layout className="layout-main">
	              		<Sidebar />
	          			<Content>
	            			<Routes>
	              				<Route
	                				path="/dashboard"
	                				element={<Dashboard search={this.state.search} />}
	              				/>
	              				<Route
	                				path="/compras"
	                				element={<RouterVentas search={this.state.search} />}
	              				/>
	            			</Routes>
	          			</Content>
	            	</Layout>
	          	</Layout>
        	</Search.Provider>
    	);
  	}
}

export default function (props) {
  const user = useContext(User);
  const navigate = useNavigate();
  return <CustomerRoutes {...props} user={user} navigate={navigate} />;
}
