import React, { Component } from "react";
import { Row, Col, Card, Layout, message, Space, List, Typography } from "antd";
import axios from "axios";

//componentes
import { useNavigate } from "react-router-dom";
import useSearch from "../../../Hooks/useSearch";
import Logged, { User } from "../../../Hooks/Logged";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from "../../Widgets/PageHeader/PageHeader";
import { ButtonDelete, ButtonEdit } from "../../Widgets/Buttons";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

const { Text } = Typography;
const { Content } = Layout;

/**
 * @export
 * @class PlantillaEvento
 * @extends {Component}
 * @description Vista de PlantillaEvento
 */
class PlantillaEvento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal_visible: false,

      tipo: null,
      plantilla_id: undefined,
      plantillas: {
        data: [],
        page: 1,
        total: 0,
        limit: 10,
      },

      filtroSearch: "",
      searching: true,
    };
  }

  componentDidMount() {
    this.props.setShowSearch(true); 
    axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
    this.getPlantillas();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search != this.props.search) {
      this.getPlantillas({ page: 1, search: this.props.search });
    }
  }

  /**
   *
   *
   * @param {*} [page=this.state.page] Numero de la pagina a obtener
   * @memberof Areas
   * @method getPlantillas
   * @description Obtiene el listado de plantillas
   */
  getPlantillas = (
    {
      page = this.state.plantillas.page,
      limit = this.state.plantillas.limit,
      search = this.props.search,
    } = this.state.plantillas
  ) => {
    this.setState({ loading: true });
    axios
      .get("/plantillas-eventos", {
        params: {
          page,
          limit,
          search,
        },
      })
      .then(({ data }) => {
        this.setState({ plantillas: data });
      })
      .catch((error) => {
        console.log(error);
        message.error("Error al traer las plantillas");
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <>
        <PageHeader
          className="admin-page-header"
          breadcrumb={{
            items: [
              {
                key: "1",
                title: "Plantillas",
              },
            ],
          }}
        ></PageHeader>
        <Content className="admin-content content-bg pd-1">
          <List
            loading={this.state.loading}
            className="component-list"
            itemLayout="horizontal"
            locale={{ emptyText: "Sin plantillas " }}
            dataSource={this.state.plantillas.data}
            pagination={{
              current: this.state.plantillas.page,
              pageSize: this.state.plantillas.limit,
              total: this.state.plantillas.total,
              position: "bottom",
              className: "flex-left",
              onChange: (page, limit) => this.getPlantillas({ page, limit }),
            }}
            header={
              <Row className="component-list-header width-100 pr-1 pl-1">
                <Col span={2} className="center"></Col>
                <Col span={5} className="center">
                  <Text strong>Nombre</Text>
                </Col>
                <Col span={13} className="center">
                  <Text strong>Descripción</Text>
                </Col>
              </Row>
            }
            renderItem={(item) => (
              <List.Item className="component-list-item">
                <Card className="card-list">
                  <Row className="width-100 " gutter={[8, 8]}>
                    <Col span={2}>
                      <CustomAvatar
                        name={item.nombre}
                        url={
                          item.imagen
                            ? {
                                url:
                                  axios.defaults.baseURL +
                                  "/categorias/" +
                                  item._id,
                                imagen: item.imagen?.name,
                                logo: item.imagen?.file,
                              }
                            : null
                        }
                      />
                    </Col>
                    <Col span={5} className="center">
                      <Text>{item.nombre}</Text>
                    </Col>
                    <Col span={13} className="center">
                      <Text>{item.descripcion}</Text>
                    </Col>
                    <Col span={4} className="center">
                      <Space size="small" direction="horizontal">
                        <ButtonEdit
                          disabled={!this.props.canEdit}
                          onClick={() =>
                            this.props.navigate(
                              "/admin/plantillas/editar/" + item._id
                            )
                          }
                        />
                        <ButtonDelete
                          disabled={!this.props.canDelete}
                          onConfirm={() =>
                            axios
                              .delete("/plantilla-evento", {
                                params: { id: item._id },
                              })
                              .then((response) => {
                                message.success(response?.data?.message);
                                this.getPlantillas();
                              })
                              .catch((error) => {
                                message.error(error?.response?.data?.message);
                                this.getPlantillas();
                              })
                          }
                        />
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </Content>
        <FloatingButton
          title="Nuevo registro"
          onClick={() => this.props.navigate("crear", { replace: true })}
        />
      </>
    );
  }
}

export default function Vista(props) {
  const user = React.useContext(User);
  const { setShow } = useSearch();

  const permisos = usePermissions(user?.rol_id?.permisos, {
    canEdit: ["layouts", "edit"],
    canDelete: ["layouts", "delete"],
    canCreate: ["layouts", "create"],
  });

  return (
    <PlantillaEvento
      {...props}
      setShowSearch={setShow}
      {...permisos}
      navigate={useNavigate()}
    />
  );
}
