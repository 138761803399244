import React, { Component } from "react";
import { Row, Col, InputNumber, Modal, Form, Input, message, Spin } from "antd";

import axios from "axios";
import { AvatarLoader } from "../../Widgets/Uploaders";

/**
 *
 *
 * @class FormCategoriasTag
 * @extends {Component}
 */
class FormCategoriasTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");

    if (this.props.categoria_id !== undefined) {
      this.get();
    }
  }

  /**
   * @memberof ModalCategorias
   * @method onFinish
   * @description Se ejecuta al dar enter al formulario
   */
  onFinish = (values) => {
    
    if (this.props.categoria_id) {
      this.update(values);
    } else {
      this.add(values);
    }
  };

  /**
   * @memberof ModalCategorias
   * @method add
   * @description Añade una categoria a la BD
   */
  add = (values) => {
    this.setState({ loading: true }, () => {
      axios
        .post(
          "/categorias",
          {
            ...values,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          message.success("Categoría creada");
          this.props.onClose();
        })
        .catch((error) => {
          console.log(error);
          message.error("Error al crear categoría.");
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  /**
   * @memberof ModalCategorias
   * @method get
   * @description Obtiene una area de la DB
   */
  get = () => {
    this.setState({ loading: true });

    axios
      .get("/categorias/" + this.props.categoria_id)
      .then(({ data }) => {
        console.log("categoria", data);
        this.setState({
          categoria: data,
        });

        this.formRef.current.setFieldsValue({
          ...data,
          imagen: data.imagen
            ? {
                uid: data.imagen.file,
                name: data.imagen.name,
                status: "done",
                fromDB: true,
                url:
                  axios.defaults.baseURL +
                  "/categorias/" +
                  data._id +
                  "?imagen=" +
                  data.imagen.file +
                  "&Authorization=" +
                  sessionStorage.getItem("token"),
              }
            : null,
        });
      })
      .catch((error) => {
        message.error("Error al traer categoria");
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @memberof ModalCategorias
   * @method updateArea
   * @description Actualiza la información de una area
   */
  update = (values) => {
    this.setState({ loading: true });
    axios
      .put("/categorias", {
        ...values,
        id: this.props.categoria_id,
      },{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        message.success("Categoria actualizada");
        this.props.onClose();
      })
      .catch((error) => {
        message.error("Error al actualizar categoria");
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Form
        layout="vertical"
        name="form-categorias-simple"
        ref={this.formRef}
        onFinish={this.onFinish}
      >
        <Spin spinning={this.state.loading}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} lg={24}>
              <Form.Item
                label="Nombre"
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese el nombre",
                  },
                ]}
              >
                <Input placeholder="Nombre"></Input>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                label="Descripción"
                name="descripcion"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese la descripción",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Descripción de la categoría"
                ></Input.TextArea>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item name="imagen">
                <AvatarLoader
                  imageCrop={true}
                  onRemove={() => {
                    axios.put("/categorias/", {
                      id: this.props.categoria_id,
                      delete_image: true,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    );
  }
}
export default function Vista(props) {
  const { open = false, onClose = () => {}, categoria_id } = props;

  return (
    <Modal
      open={open}
      onCancel={onClose}
      destroyOnClose={true}
      zIndex={1000}
      title={categoria_id ? "Editar Categoría" : "Crear Categoría"}
      closable={true}
      cancelText="Cancelar"
      okText="Guardar"
      okButtonProps={{
        form: "form-categorias-simple",
        key: "form-categorias-simple",
        htmlType: "submit",
      }}
    >
      <FormCategoriasTag {...props} />
    </Modal>
  );
}
