import React, { Component } from "react";
import { Form, message,  Layout, Affix, Space, Button, Collapse, Col, Input, Row, InputNumber, Typography } from "antd";

import axios from "axios";
import { getResponseError } from "../../../Utils";
import SelectPlantillas from "../../../Widgets/Inputs/SelectPlantillas";
import Sections from "../../Seating/Sections";

const { Content } = Layout;
const { Text } = Typography;


export default class FormSeating extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			evento: {
				descripcion: "",
				contenido: "",
				imagenes: [],
				banner_name: undefined,
				mesas: undefined,
				
			},
			categorias_mesas: [],
			mesas: undefined,
		};
	}
	formRef = React.createRef();

	componentDidMount() {
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");

		if (this.props.evento_id) {
			this.getEvento();
		}
	}

	/**
	 * @method getEvento
	 * @description Obtiene el evento de la bd
	 */
	getEvento = () => {
		this.setState({ loading: true });
		axios.get("/eventos/" + this.props.params.evento_id)
		.then(({ data }) => {
			this.formRef.current.setFieldsValue({
				...data,
			});
			let saveTemplates = this.seatRef.setValues(data.secciones);
			this.setState({categorias_mesas: data.categorias_mesas})

		})
		.catch((error) => {
			message.error("Error al obtener el evento");
		})
		.finally(() => this.setState({ loading: false }));
	};

	/**
	 * @method onFinish
	 * @description Guarda o actualiza la informacion del evento
	 */
	onFinish = (values) => {
		this.setState({ loading: true })
		let saveTemplates = this.seatRef.getValues();
		if (this.props.params.evento_id) {
			this.update({ ...values, secciones: saveTemplates })
		} else {
			this.create({ ...values, secciones: saveTemplates })
		}
	}

	/**
	 * @method create
	 * @description Crea un nuevo evento
	 */
	create = (values) => {
		this.setState({ loading: true })
		axios.post("/eventos/", values)
		.then(({ data }) => {
			message.success("Evento Guardado");
		})
		.catch((error) => {
			message.error("Error al obtener el evento");
		})
		.finally(() => this.setState({ loading: false }));
	}

	/**
	 * @method update
	 * @description Actualia la informacion de un evento
	 */
	update = (data) => {
		this.setState({ loading: true })
		axios.put("/eventos", {
			id: this.props.params.evento_id || this.state.evento._id,
			...data
		})
		.then(({ data }) => {
			this.setState({ evento: data });
			message.success("¡Mesas del evento actualizadas!");
			this.props.navigate("/admin/eventos")
		})
		.catch((e) => {
			message.error(getResponseError(e.response));
		})
		.finally(() => this.setState({ loading: false }));

	};

	/**
	 * @method getCategorias
	 * @description Actualia la informacion de un evento
	 */
	getCategorias = (secciones) => {
	    // Crear un conjunto para rastrear las categorías presentes en las secciones
	    let categoriasPresentes = new Set();

	    // Crear una copia del estado actual de categorias_mesas
	    let categorias_mesas = [...this.state.categorias_mesas];

	    // Iterar sobre las secciones y mesas para actualizar el conjunto y añadir nuevas categorías
	    for (let seccion of secciones) {
	        for (let mesa of seccion.data.mesas) {
	            const categoriaId = mesa?.categoria?._id?.toString();
	            if (categoriaId) {
	                categoriasPresentes.add(categoriaId);
	                let index = categorias_mesas.findIndex(e => e.categoria_id?.toString() === categoriaId);
	                if (index === -1) {
	                    categorias_mesas.push({
	                        categoria_id: categoriaId,
	                        nombre: mesa?.categoria?.nombre,
	                        precio: mesa?.categoria?.precio_asiento
	                    });
	                }
	            }
	        }
	    }

	    // Filtrar categorias_mesas para eliminar las categorías que no están presentes en las secciones
	    categorias_mesas = categorias_mesas.filter(categoria => categoriasPresentes.has(categoria.categoria_id?.toString()));

	    // Actualizar el estado y los valores del formulario
	    this.setState({ categorias_mesas });
	    this.formRef.current.setFieldsValue({
	        categorias_mesas,
	    });
	}


	onChangeCategoria = (categoria) => {
		let secciones = this.seatRef.getValues();
		this.getCategorias(secciones)

	} 

	render() {
		return (
			<Content className="admin-content content-bg pd-1">
				<Form
					id="form-mesas"
					className="form-seating"
					layout={"vertical"}
					onFinish={this.onFinish}
					ref={this.formRef}
				>
					<Form.Item
						label="Plantilla"
						name="plantilla_evento_id"
					>
						<SelectPlantillas
							params={{ tipo: 1 }}
							onChange={(_id, item) => {
								if (item && Array.isArray(item.secciones)){
									this.seatRef.setValues(item.secciones)
									this.getCategorias(item.secciones)
								}
								else
									this.seatRef.setValues([])
							}}
						/>
					</Form.Item>
					<Collapse
						className="mb-1"
						size="small"
						items={[
							{
							    key: '1',
							    label: 'Categorías seleccionadas',
							    children: <Row gutter={[16,16]}>
							    	<Col span={4}>
							    		<Text>Categoría</Text>
							    	</Col>
							    	<Col span={4}>
							    		<Text>Precio</Text>
							    	</Col>
							    	<Col span={24}>
							    		<Form.List name="categorias_mesas">
									    	{(fields, { add, remove }) => (
									        	<>
									          		{fields.map(({ key, name, ...restField }) => (
									            		<Space
									              			key={key}
									              			style={{ display: 'flex', marginBottom: 8 }}
									              			align="baseline"
									            		>
									              			<Form.Item
									                			{...restField}
									                			name={[name, 'nombre']}
									                			rules={[
									                  				{
																		required: true,
																		message: 'Requerido',
									                  				},
									                			]}
									              			>
									              				<Input placeholder="Nombre" disabled/>
									              			</Form.Item>

									              			<Form.Item
									              				{...restField}
									              				name={[name, 'precio']}
									              				rules={[
									              					{
									              						required: true,
									              						message: 'Ingrese el precio de la categoria',
									              					},
									              				]}
									              			>
									              				<InputNumber
									              					formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                   			 						parser={value => value.replace(/\$\s?|(,*)/g, '')}
									              					style={{minWidth: "150px"}}
									              					placeholder="Precio" 
									              					min={0}
									              				/>
									              			</Form.Item>
									              		</Space>
									              	))}
									        	</>
									      	)}
									    </Form.List>
							    	</Col>
							    </Row>,
							  },
						]}
					/>
					<Sections
						ref={e => {
							this.seatRef = e
							window.ref = e
						}}
						onChangeCategoria={this.onChangeCategoria}
					/>
					<Affix className="fixed-buttons" offsetTop={50} style={{ marginTop: '1em' }}>
						<Space>
							<Button
								htmlType="submit"
								type="primary"
								size="large"

								loading={this.state.loading}
							>
								Guardar
							</Button>
						</Space>
					</Affix>
				</Form>
			</Content>

		);
	}
}
