import React from 'react'
import { Route, Routes } from "react-router-dom";

import Categorias from '../../Components/Admin/Categorias/Categorias';
import Categoria from '../../Components/Admin/Categorias/Categoria';
import CategoriaSimples from '../../Components/Admin/Categorias/CategoriasSimples';

/**
 * 
 * @export
 * @function RouterEventos
 * @description Router for Eventos routes 
 */
function RouterEventos(props) {
  return (
    <Routes>
      <Route path="" element={<Categorias {...props} />} />
      <Route path="/simples" element={<CategoriaSimples {...props} />} />
      <Route path="/crear" element={<Categoria {...props} />} />
      <Route path="/editar/:categoria_id" element={<Categoria {...props} />} />
    </Routes>
  )
}

export default RouterEventos