import React, { Component } from "react";
import { Row, Col, InputNumber, Modal, Form, Input, message, Spin, Typography, List, Divider } from "antd";

import axios from "axios";
import { ventaEstatusTag } from "../../../Utils";


const { Title, Text, Paragraph } = Typography;
/**
 *
 *
 * @class FormCategoriasTag
 * @extends {Component}
 */
class FormCategoriasTag extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	formRef = React.createRef();

	componentDidMount() {
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
	}

	render() {

		let { venta } = this.props;

		return (
			<Row gutter={[16,16]}>
				<Col span={24}>
					<Title className="text-center" level={4}>Marcar como sentado</Title>
					<Paragraph className="text-center">
						Este asiento ha sido comprado por el cliente.<br/>
						¿Quieres marcar todos los asientos como sentado?
					</Paragraph>
				</Col>
				<Col span={24} className="pl-2">
					Cliente: &nbsp;<Text strong> {venta?.cliente_id?.nombre ?? venta?.invitado?.nombre} - {venta?.cliente_id?.email ?? venta?.invitado?.email} </Text>
				</Col>
				<Col span={12} className="pl-2">
					Folio:&nbsp; <Text strong> {venta.folio}</Text>
				</Col>
				<Col span={12} className="center">
					<Text>{(ventaEstatusTag(venta.estatus))}</Text>
				</Col>
				<Col span={24}>
					<List
						itemLayout="horizontal"
						className="resume-list"
						dataSource={venta?.boletos}
						header={<Row className="component-list-header width-100 pr-1 pl-1">
							<Col span={8} className="center">
                                <Text strong>SECCIÓN</Text>
                            </Col>
                            <Col span={8} className="center">
                                <Text strong>MESA</Text>
                            </Col>
                            <Col span={8} className="center">
                                <Text strong>SILLA</Text>
                            </Col>
                        </Row>}
						renderItem={(item, index) => (
							<List.Item>
								<Row className="width-100 pl-1 pr-1" align={"middle"} justify={"space-between"} >
									<Col className="center" span={6}>{item.seccion ?? "-"}</Col>
									<Col className="center" span={6}>{item.mesa ?? "-"}</Col>
									<Col className="center" span={6}>{item.silla ?? "-"}</Col>
								</Row>
							</List.Item>
						)}
					/>
							
				</Col>
				<Divider className="m-0"/>
				<Col span={22} className="flex-right">
					<Paragraph>
						Total: <Text strong>$ { venta.total.toMoney(true) } MXN</Text>
					</Paragraph>
				</Col>
			</Row>
		);
	}
}
export default function Vista(props) {
	const { open = false, onClose = () => {}, categoria_id } = props;

	return (
		<Modal
			open={open}
			onCancel={onClose}
			destroyOnClose={true}
			zIndex={1000}
			title={"Detalle Venta"}
			closable={true}
			cancelText="Cancelar"
			okText="Aceptar"
			onOk={()=>props.addMultipleSeats(props.venta?.boletos.map(e => e.seat_id))}
		>
			<FormCategoriasTag {...props} />
		</Modal>
	);
}
