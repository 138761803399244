import React, { Component } from 'react';
import { Row, Col, Typography, Button, Layout, message, Card, Space, Popconfirm, Tabs } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { DollarOutlined, WhatsAppOutlined } from '@ant-design/icons';

//componentes
import usePermissions from "../../../../Hooks/usePermissions";
import PageHeader from '../../../Widgets/PageHeader/PageHeader';

//Tabs
import Ventas from "./Tabs/Ventas" 



const { TabPane } = Tabs;
const { Content } = Layout;
const { Title, Text } = Typography;

const moment = require('moment')
moment.locale('es');



/**
 *
 *
 * @export
 * @class ClientesDetalles
 * @extends {Component}
 * @description Vista donde se muestra la informacion de un cliente
 */
class ClientesDetalles extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_clasificar: false,
            cliente_id: this.props.params.cliente_id,
            cliente: {

            },

        }
    }
  ordenesRef = React.createRef(null);

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.params.cliente_id) {
            this.getCliente()
        }
    }


    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getCliente
     * @description Obtiene informacion del Cliente
     */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/clientes/' + this.state.cliente_id, {
            params: {
                pais_id: true,
                estado_id: true,
                cuentas: true,
            }
        }).then(response => {
            console.log("response", response)
            this.setState({
                cliente: response.data,

            })

        }).catch(error => {
            console.log("error", error);
            message.error("Error al obtener la información del cliente")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof ClientesDetalles
     * 
     * @method getPDF
     * @description Generamos el PDF del estado de cuenta del cliente
     */
    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/crm/estado-cuenta');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('cliente_id', this.state.cliente_id)
        return urlPDF.href
    }


    /**
    *
    *
    * @memberof MatrizCostos
    *
    * @method sendPDF
    * @description Enviamos la cotizacion al cliente
    */
    sendPDF = () => {
        this.setState({ loading_mail: true });
        return axios
            .get("/crm/estado-cuenta", {
                params: {
                    cliente_id: this.state.cliente_id,
                    enviar: true,
                },
            })
            .then((response) => {
                message.success("Recibo Enviado");
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 403)
                    message.error(error.response.data.message)
                else
                    message.error("Error al enviar el recibo");
            })
            .finally(() => {
                this.setState({ loading_mail: false });
            });
    };

    /**
    * @memberof DetalleCliente
    *
    * @method renderMontoVendido
    * @description renderiza los montos 
    */
    renderMontoVendido = () => {
        const { monto_total, monto_total_dolar, monto_pesos, monto_dolar } = this.state;

        let amountInPesos, amountInDollars, size = 17;
        if (monto_pesos > 0) {
            amountInPesos = `$ ${monto_pesos.toMoney(true)} MXN`;
        }
        if (monto_dolar > 0) {
            amountInDollars = `$ ${monto_dolar.toMoney(true)} USD`;
        }

        if (monto_dolar > 0 && monto_pesos > 0) {
            amountInPesos = `$ ${monto_total.toMoney(true)} MXN`;
            amountInDollars = `$ ${monto_total_dolar.toMoney(true)} USD`;
            size = 14
        }

        if (!amountInPesos && !amountInDollars) return <Text ellipsis className="text-info"> $ 0 MXN </Text>;

        return <Text ellipsis className="text-info" style={{ fontSize: size }}> {amountInPesos} {amountInPesos && amountInDollars ? " / " : ""} {amountInDollars}</Text>;
    }


    /**
     * @memberof DetalleCliente
     *
     * @method renderMontos
     * @description renderiza los montos 
     */
    renderMontos = ({ monto_pesos, monto_dolar, color = "info" }) => {

        let amountInPesos, amountInDollars, size = 17;
        if (monto_pesos > 0) {
            amountInPesos = `$ ${monto_pesos.toMoney(true)} MXN`;
        }
        if (monto_dolar > 0) {
            amountInDollars = `$ ${monto_dolar.toMoney(true)} USD`;
        }

        if (monto_pesos > 0 && monto_dolar > 0) {
            size = 14
        }

        if (!amountInPesos && !amountInDollars) return <Text ellipsis className={`text-${color}`}> $ 0 MXN </Text>;

        return <Text ellipsis className={`text-${color}`} style={{ fontSize: size }}> {amountInPesos} {amountInPesos && amountInDollars ? " / " : ""} {amountInDollars}</Text>;

    }

    render() {

        const { cliente, cuentas } = this.state

        console.log('ordenesref',this.ordenesRef?.current)
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    breadcrumb={{
                        items: [
                            {
                                key: "0",
                                title: "Clientes",
                                onClick: () => this.props.navigate("/admin/crm/clientes/listado"),
                                className: "hover"
                            }, {
                                key: "1",
                                title: "Detalle cliente",
                            }, {
                                key: "2",
                                title: cliente?.nombre,
                            }
                        ]
                    }}
                    backIcon={true}
                />
                <Content className="pd-1">
                    <Card className="card-cliente">
                        <Row gutter={[16, 16]}>
                            <Col xs={16} xl={8}>
                                <Title className="text-title" level={3}>Información del Cliente</Title>
                            </Col>
                            <Col xs={8} xl={8} className="flex-left">
                               
                                <div className="flex-left-column">
                                    <Text className="text-date ml-1">Cliente desde {moment(cliente?.createdAt).format("DD-MM-YYYY")}</Text>
                                    
                                </div>
                            </Col>
                            <Col xs={24} xl={8} className="flex-right">
                                <Space>

                                    
                                </Space>
                            </Col>
                            <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Nombre Completo</Text>
                                <Text ellipsis className="text-info">{cliente?.nombre} {cliente?.apellido_paterno} {cliente?.apellido_materno ?? ''}</Text>
                            </Col>

                            {cliente.telefono ? <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Teléfono</Text>
                                <Space direction='horizontal'>
                                    <Text className="text-info" copyable>{cliente?.telefono}</Text>
                                    {cliente.telefono ? <a target='_blank' href={`https://wa.me/${cliente.telefono.replace(/[\s()\-]/g, '')}`}></a> : null}
                                </Space>
                            </Col> : null}

                            {cliente.email ? <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Correo</Text>
                                <Text ellipsis className="text-info">{cliente?.email}</Text>
                            </Col> : null}

                            {cliente.fecha_nacimiento ? <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Fecha Nacimiento</Text>
                                <Text ellipsis className="text-info">{moment(cliente?.fecha_nacimiento).format("DD/MM/YYYY")}</Text>
                            </Col> : null}

                           

                            {cliente.asesor_id !== undefined ? <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Asesor</Text>
                                <Text ellipsis className="text-info">{cliente?.asesor_id?.nombre} {cliente?.asesor_id?.apellidos}</Text>
                            </Col> : null}

                        </Row>
                    </Card>

                    <Tabs
                        defaultActiveKey="1"
                        destroyInactiveTabPane={true}
                        items={[
                            {
                                label: 'Ventas',
                                key: '1',
                                children: <Ventas cliente_id={this.state.cliente_id}/>,
                            },
                        ]}
                    />
                </Content>
                
            </>
        )
    }
}


export default function (props) {

    const params = useParams()
    const navigate = useNavigate()


    return <ClientesDetalles  {...props} params={params} navigate={navigate}
        {...usePermissions(null, {
            editarCliente: ["clientes", "edit"],
            deleteCliente: ["clientes", "delete"],
            createCliente: ["clientes", "create"],
        })}
    />
}