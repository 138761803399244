import React, { Component } from "react";
import { Row, Col, InputNumber, Modal, Form, Input, message, Spin } from "antd";

import axios from "axios";

/**
 *
 *
 * @class FormCategorias
 * @extends {Component}
 */
class FormCategorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");

    if (this.props.categoria_id !== undefined) {
      this.get();
    }
  }

  /**
   * @memberof ModalCategorias
   * @method onFinish
   * @description Se ejecuta al dar enter al formulario
   */
  onFinish = (values) => {
    if (this.props.categoria_id) {
      this.update(values);
    } else {
      this.add(values);
    }
  };

  /**
   * @memberof ModalCategorias
   * @method add
   * @description Añade una categoria a la BD
   */
  add = (values) => {
    this.setState({ loading: true }, () => {
      axios
        .post("/categorias", {
          ...values,
        })
        .then((response) => {
          message.success("Categoría creada");
          this.props.onClose();
        })
        .catch((error) => {
          console.log(error);
          message.error("Error al crear categoría.");
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  /**
   * @memberof ModalCategorias
   * @method getArea
   * @description Obtiene una area de la DB
   */
  get = () => {
    this.setState({ loading: true });

    axios
      .get("/categorias/" + this.props.categoria_id)
      .then(({ data }) => {
        console.log("categoria", data);
        this.setState({
          categoria: data,
        });

        this.formRef.current.setFieldsValue({
          ...data,
        });
      })
      .catch((error) => {
        message.error("Error al traer categoría");
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @memberof ModalCategorias
   * @method updateArea
   * @description Actualiza la información de una area
   */
  update = (values) => {
    this.setState({ loading: true });
    axios
      .put("/categorias", {
        ...values,
        id: this.props.categoria_id,
      })
      .then(({ data }) => {
        message.success("Categoría actualizada");
        this.props.onClose();
      })
      .catch((error) => {
        message.error("Error al actualizar categoria");
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Form
        layout="vertical"
        name="form-categorias"
        ref={this.formRef}
        onFinish={this.onFinish}
      >
        <Spin spinning={this.state.loading}>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={24}>
              <Form.Item
                label="Nombre"
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese el nombre",
                  },
                ]}
              >
                <Input placeholder="Nombre"></Input>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                label="Descripción"
                name="descripcion"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese la descripción",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Descripción de la categoría"
                ></Input.TextArea>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                label="SKU"
                name="sku"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese el sku",
                  },
                ]}
              >
                <Input placeholder="sku"></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Precio"
                name="precio"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese el precio",
                  },
                ]}
              >
                <InputNumber
                  className="width-100"
                  min={1}
                  prefix={"$"}
                  placeholder="0.00"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="IVA"
                name="iva"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese el iva",
                  },
                ]}
              >
                <InputNumber className="width-100" min={1} prefix={"%"} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Capacidad"
                name="capacidad"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese el capacidad",
                  },
                ]}
              >
                <InputNumber className="width-100" min={1} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Disponibles"
                name="disponibles"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingrese el disponibles",
                  },
                ]}
              >
                <InputNumber className="width-100" min={1} placeholder="0" />
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    );
  }
}

export default function Vista(props) {
  const { open = false, onClose = () => {}, categoria_id } = props;

  return (
    <Modal
      open={open}
      onCancel={onClose}
      destroyOnClose={true}
      zIndex={1000}
      title={categoria_id ? "Editar Categoría" : "Crear Categoría"}
      closable={true}
      cancelText="Cancelar"
      okText="Guardar"
      okButtonProps={{
        form: "form-categorias",
        key: "form-categorias",
        htmlType: "submit",
      }}
    >
      <FormCategorias {...props} />
    </Modal>
  );
}
