import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Input, Dropdown, Menu, } from 'antd';
import { User, SetUser } from '../../../Hooks/Logged';
import Profile from './Profile'
import '../../../Styles/Global/navbar.scss'


const { Header } = Layout;
const { Search } = Input;

let myRef = React.createRef();
/**
 * @const Navbar
 * @description Header del sistema
 */
const Navbar = (props) => {

	const navigate = useNavigate()

	
	const [redirect, setRedirect] = useState(false)


	// NOTIFICACIONES
    let user = useContext(User)
	let setUser = React.useContext(SetUser)
	const [loading, setLoading] = useState(false)
	const [notificaciones, setNotificaciones] = useState([])
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(1)
	const [search, setSearch] = useState('')

	const [modalVisible, setModalVisible] = useState(false)

	/**
	 * @const cerrarSesion
	 * @description Cierra la sesion
	 */
	const cerrarSesion = () => {
		setUser(undefined);
		sessionStorage.clear();
		navigate("/")

		if(window.FB){
        	window.FB.logout(function(response) {
        		console.log("response", response);
			});
        }

	};
	


	useEffect( () => {
        setSearch('')
    }, [props.clear])



	
	return (

		<Header className="header-admin">
			<Row className="width-100 content" justify='space-between'>
				<Col className="logo-header">
					<img src={'/logo.png'} alt="logo" className='logo' onClick={()=>navigate("/")}/>
				</Col>
				<Col className="content" xxl={{ flex: 2 }} xl={{ span: 12 }} lg={{ span: 8 }} md={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 16 }} className="flex-left">
					{props.showSearch ? <Search
						placeholder="Buscar"
						onSearch={(e) => { props.onSearch(e) }}
						enterButton
						allowClear
						value={search}
						onChange={(e) => { setSearch(e.target.value) }}
						ref={myRef}
						className="input-global-search flex-left"
					/> : null}
				</Col>
				<Col className="content" xxl={{ flex: 1 }} xl={{ span: 6 }} lg={{ span: 8 }} md={{ span: 6 }} sm={{ span: 6 }} xs={{ span: 8 }} >
					<Row className="width-100 header-options">
						<Col span={8} className="center">
							
						</Col>
						<Col span={16} className="center">
							<Dropdown
								arrow={true}
								overlay={
									<Menu>
										<Menu.Item key="1" onClick={() => {setModalVisible(true)}}>
											Ajustes de Usuario
										</Menu.Item>
										<Menu.Item key="0" onClick={cerrarSesion}>
											Cerrar Sesión
										</Menu.Item>
									</Menu>
								} trigger={['click']}>
								<div >
									<Link onClick={e => e.preventDefault()}
										style={{ marginLeft: '2rem' }}>
										{user?.nombre}
										 <DownOutlined />
									</Link>
								</div>
							</Dropdown>
						</Col>
					</Row>

				</Col>
			</Row>
			<Profile
				visible={modalVisible}
				usuario_id={user?._id}
				onClose={() => {setModalVisible(false)}}
			/>
		</Header>

	)
}

export default Navbar;