import React from 'react'

let Carrito = React.createContext(null);
let SetCarrito = React.createContext(() => {});

export {
    Carrito,
    SetCarrito
}

export default Carrito;