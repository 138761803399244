import React, { Component, useContext, createRef } from "react";
import { Layout, Row, Col, Typography, Result, Divider, Button, List, message, Space } from "antd";
import Navbar from "./../Navigation/Navbar-v2";
import FooterPublic from "./../Navigation/Footer";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Carrito, SetCarrito } from "../../../Hooks/Carrito";
import { useCookies } from "react-cookie";

const { Title, Text, Paragraph } = Typography;

class ResumenVenta extends Component {
	constructor(props) {
		super(props);
		this.state = {
			evento: {},
			imgUrl: "",
			pago: {
				cvc: "123",
				expiration_date: "12/25",
				nombre: "John Smith",
				card_number: "1111111111111111",
			},
			venta: this.props.venta ?? {},
		};
	}
	componentDidMount() {

		if(this.props.params.venta_id){
			this.getVenta()
		}
	}

	getVenta = () => {
		axios.get("/public/venta",{
			params: {
				venta_id:this.props.params.venta_id
			}
		})
	      	.then(({ data }) => {
	      		console.log("data", data);
	        	this.setState({venta: data})
	      	})
	      	.catch((error) => {
	        	message.error("Error al obtener el evento");
	      	})
	      	.finally(() => this.setState({ loading: false }));
	}

	formatImages = (images, id) => {
	    let format_images;

	    if (Array.isArray(images))
	     	format_images = images.map((f) => ({ url: axios.defaults.baseURL + "/evento/public/" + id + "?imagen=" + f.file }));
	    else {
	      format_images = {
	        url:
	          axios.defaults.baseURL +
	          "/evento/public/" +
	          id +
	          "?imagen=" +
	          images.file,
	      };
	    }

	    return format_images;
	};


	downloadPDF=()=>{

		console.log('venta',this.state)
		let venta_id =this.state.venta._id;
		let urlPDF = new URL(axios.defaults.baseURL + '/venta/boletos/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('venta_id', venta_id)
        window.open(urlPDF.href, '_blank');
	}
	render() {
		return (
			<Layout className="layout-landing informacion">
        		<Navbar />
				<Row justify={"center"} className="width-100 ">
					<Col lg={16} md={24} >
						<Result
							className="result"
							status="success"
							title="¡Gracias por tu Compra!"
							subTitle={<div>Agradecemos tu compra de boletos. Podrás ver los boletos comprados en tu cuenta.<br/> 
							En caso de haber realizado la compra siendo invitado se te enviarán los boletos a tu correo <br/> 
							<br/> ¡Disfruta de tu evento!
							<br/><strong>Folio: {this.state.venta.folio ?? "-"}</strong></div>}
							extra={[
								<Button type="secondary" style={{border: "solid 1px white", height: "42px"}} onClick={()=>this.downloadPDF()}>
									Descargar PDF
								</Button>,
								<Button type="primary" className="btn-gradiente-morado" onClick={()=>this.props.navigate("/")}>
									Finalizar
								</Button>,
							]}
						/>
					</Col>
				</Row>
				<Row
					justify={"space-around"}
					className="width-100 pd-3"
					gutter={[16, 16]}
				>
					<Col xs={24} md={12} lg={8} >
						<div className="bg-purple-deg resume">
							<List
								itemLayout="horizontal"
								className="resume-list "
								dataSource={this.state.venta.detalles}
								header={<Row className="component-list-header width-100 pr-1 pl-1">
		                            <Col span={12} className="center">
		                                <Text className="text-white" strong>BOLETO</Text>
		                            </Col>
		                            <Col span={12} className="center">
		                                <Text className="text-white" strong>PRECIO</Text>
		                            </Col>
		                        </Row>}
								renderItem={(item, index) => (
									<List.Item>
										<Row
											className="width-100 pl-1 pr-1"
											align={"middle"}
											justify={"space-between"}
										>
											<Col span={12} className="center">
												<Text>{item.boleto_id?.folio}</Text>
											</Col>
											<Col span={12} className="center">
												<Text>${item.total?.toMoney(true)} <small>MXN</small></Text>
											</Col>
										</Row>
									</List.Item>
								)}
								footer={
									<Row justify={"end"} className="">
										<Divider className="bg-purple-deg-divider" />
										<Col span={24}>
											<Divider className="resume-list-footer-divider"></Divider>
											<Row>
												<Col span={10} className="flex-right">
													<Text  className="item-costo text-white">Subtotal</Text>
												</Col>
												<Col span={10} className="flex-right">
													<Text className="item-costo text-white">${this.state.venta?.subtotal?.toMoney(true)} <small>MXN</small></Text>
												</Col>
											</Row>
											<Row>
												<Col span={10} className="flex-right">
													<Text className="total text-white">Total</Text>
												</Col>
												<Col span={10} className="flex-right">
													<Text className="total text-white">${this.state.venta?.total?.toMoney(true)} <small>MXN</small></Text>
												</Col>
											</Row>
										</Col>
									</Row>
								}
							/>
						</div>
					</Col>
				</Row>
				<FooterPublic />
			</Layout>
		);
	}
}

export default function Vista(props) {
	let cart = useContext(Carrito);
	let setCart = useContext(SetCarrito);
	const [cookies, setCookie] = useCookies(["cart"]);
	return (
		<ResumenVenta
			{...props}
			params={useParams()}
			cart={cart}
			setCart={setCart}
			cookies={cookies}
			navigate={useNavigate()}
		/>
	);
}
