import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select } from 'antd';

import { AvatarLoader } from "../../Widgets/Uploaders";
import axios from "axios"
import { getResponseError } from "../../Utils";
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.usuario_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/customer/usuarios/' + this.props.usuario_id)
            .then(({ data }) => {
                console.log("data", data);
                this.formRef.current.setFieldsValue({
                    ...data,


                })
                this.setState({ cliente: data.cliente_id })
            }).catch(error => {
                message.error('An error occurred while loading user information')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method updateUser
    * @description Añade un nuevo registro de usuario
    */
    update = (values) => {

        console.log('update', values)
        this.setState({ loading: true })
        axios.put('/customer/usuarios', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('User updated successfully')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'An error occurred while updating the user'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            id: this.props.usuario_id,
        })

        if (this.props.usuario_id) {
            this.update(formData)
        }
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-usuario"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">
                        <Col span={24}>
                            <Form.Item
                                name="nombre"
                                label="Nombre (s)"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor, ingrese su(s) nombre(s) ',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="apellidos"
                                label="Apellidos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor, ingrese sus apellidos',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                label="Correo Electrónico"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Correo no válido',
                                    },
                                    {
                                        required: true,
                                        message: 'Por favor, ingrese un correo',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>





                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Editar mi información"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        okButtonProps={{ form: 'form-usuario', key: 'submit', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}