import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import axios from "axios";

const { Option } = Select;

/**
 * @const Selectcategorias
 * @description Select para los categorias registrados en el sistema
 */
const SelectCategorias = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione la categoria", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        allowClear = true, 
        onClear = () => {},
        mode = null,
        labelInValue,
        landing = false
    } = props;

    const [ categorias, setCategorias ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    });

    /**
     * @const getCategorias
     * @description Obtiene los usuarios
     */
    const getCategorias = ({
        page, 
        limit, 
        search, 
    } = categorias) => {

        axios.get('/categorias-boletos', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setCategorias(data);
        }).catch(error => {
            console.log("ERR, error", error);
            message.error(error?.response?.data?.message ?? 'Error al obtener los categorias');
        });
    };

    // componentDidMount
    useEffect(() => {
        getCategorias();
    }, []);

    return (
        <Select
            style={{minWidth: "180px"}}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search) => getCategorias({ search })}
            onSelect={(cliente) => {
                const selectedItem = categorias.data.find(item => item._id === cliente.value);
                onChange(cliente.value, selectedItem);
                onSelect(cliente.value, selectedItem);
            }}
            onChange={(values) => {
                if (mode === "multiple" && !labelInValue) {
                    let arr = values.map(e => {
                        const item = categorias.data.find(cat => cat._id === e);
                        return item;
                    });
                    onChange(values, arr);
                    onSelect(values, arr);
                }

                if (mode === "multiple" && labelInValue) {
                    let arr = values.map(e => {
                        const item = categorias.data.find(cat => cat._id === e.value);
                        return item;
                    });
                    onChange(values.map(v => v.value), arr);
                    onSelect(values.map(v => v.value), arr);
                }

                if (mode === null && !labelInValue) {
                    const selectedItem = categorias.data.find(item => item._id === values);
                    onChange(values, selectedItem);
                    onSelect(values, selectedItem);
                }

                if (mode === null && labelInValue) {
                    const selectedItem = categorias.data.find(item => item._id === values.value);
                    onChange(values.value, selectedItem);
                    onSelect(values.value, selectedItem);
                }
            }}
            value={value}
            onClear={() => {
                onClear();
                onChange(undefined, undefined);
                onSelect(undefined, undefined);
            }}
        >
            {
                categorias?.data?.map(({ _id, nombre, ...rest }) => (
                    <Option item={{ ...rest, nombre, _id }} value={_id} key={_id}>
                        {nombre ?? ""}
                    </Option>
                ))
            }
        </Select>
    );
}

export default SelectCategorias;
