import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from "axios"

const { Option } = Select 

/**
 * @const SelectUsuario
 * @description Select para los usuarios registrados en el sistema
 */
const SelectUsuario = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el usuario", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        mode = null,
        labelInValue,
        renderCustomValue,
        suffixIcon,
        rol = false
    } = props

    const [ usuarios, setUsuarios ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getUsuarios
     * @description Obitiene los usaurios
     */
    const getUsuarios = ({
        page, 
        limit, 
        search, 
    } = usuarios) => {

        axios.get('/usuarios', {
            params: {
                page,
                limit,
                search,
                ...params,
                tipo: rol ? "admins" : undefined
                
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setUsuarios(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los usuarios')
        })
    }

    //componentDidMount
    useEffect(() => {
        getUsuarios()
    }, [])


    return (
        <Select
            {...props}
            bordered={bordered}
            variant='borderless'
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search)=> getUsuarios({search})}
            onSelect={(usuario)=> {
                if(mode === null && !labelInValue){
                    onChange(usuario.value)
                    onSelect(usuario.value)
                }
                if(mode === null && labelInValue){
                    onChange(usuario)
                    onSelect(usuario)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple" && !labelInValue){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }
                if(mode === "multiple" && labelInValue){
                    onChange(values)
                    onSelect(values)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            labelRender={renderCustomValue ? (value) => renderCustomValue(value, usuarios) : null}
        >
            {usuarios?.data?.map(({ _id, nombre, apellidos }) => (
                <Option value={_id} key={_id} label={`${nombre ?? ""} ${apellidos ?? ""}`}>
                    {nombre ?? ""} {apellidos ?? ""}
                </Option>
            ))}
        </Select>
    );
}



export default SelectUsuario;